import Vue from 'vue';
import VTooltip from 'v-tooltip';

/**
 * Fix issue related when the height of the parent element is less than 50px (as on a row, e.g.)
 * https://github.com/Akryum/v-tooltip/issues/485
 */
const options = {
  defaultPopperOptions: {
    modifiers: {
      preventOverflow: {
        boundariesElement: 'offsetParent',
      },
    },
  },
};

Vue.use(VTooltip, options);
