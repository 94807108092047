import { RouteConfig } from 'vue-router';

export const prescriptionsNamePaths = {
  prescription: 'Receta',
  issuePrescription: 'Reemitir',
  createPrescription: 'Nuevareceta',
  electronicPrescription: 'Recetaelectronica',
  electronicPrescriptionHip: 'RecetaelectronicaHIP',
  registryPrescription: 'Regitrosreceta',
  pdfPrescription: 'PdfPrescription',
};

export const prescriptionsRoutes: RouteConfig[] = [
  {
    path: '/receta/:id',
    meta: { breadcrumb: false, requiresAuth: true, forbiddenPatient: true },
    name: prescriptionsNamePaths.prescription,
    component: () =>
      import(
        /* webpackChunkName: "receta" */
        `@/views/Receta.vue`
      ),
  },
  {
    path: '/receta',
    meta: { breadcrumb: false, requiresAuth: true, forbiddenPatient: true },
    name: prescriptionsNamePaths.createPrescription,
    component: () =>
      import(
        /* webpackChunkName: "nueva-receta" */
        `@/views/Receta.vue`
      ),
  },
  {
    path: '/reemitir/:idreceta',
    meta: { breadcrumb: false, requiresAuth: true, forbiddenPatient: true },
    name: prescriptionsNamePaths.issuePrescription,
    component: () =>
      import(
        /* webpackChunkName: "reemitir" */
        `@/views/Receta.vue`
      ),
  },
  {
    path: '/re/:code',
    meta: { public: true, requiresAuth: false },
    name: prescriptionsNamePaths.electronicPrescription,
    component: () =>
      import(
        /* webpackChunkName: "recetaelectronica" */
        `@/views/Datamatrix.vue`
      ),
  },
  {
    path: '/hip/:code/(print)?',
    meta: { public: true, requiresAuth: false },
    name: prescriptionsNamePaths.electronicPrescriptionHip,
    component: () =>
      import(
        /* webpackChunkName: "hip" */
        `@/views/Hip.vue`
      ),
  },
  {
    path: '/ereceta/registro',
    meta: { public: true, requiresAuth: false },
    name: prescriptionsNamePaths.registryPrescription,
    component: () =>
      import(
        /* webpackChunkName: "regitrosreceta" */
        `@/views/Register.vue`
      ),
  },
  {
    path: '/pdfprescription/:patient/:id',
    meta: { requiresAuth: true },
    name: prescriptionsNamePaths.electronicPrescriptionHip,
    component: () =>
      import(
        /* webpackChunkName: "PdfPrescription" */
        `@/views/PdfPrescription.vue`
      ),
  },
];
