<template>
  <div>
    <DialogCard ref="card" :title="title" :actions="actions" :handle="handleClick">
      <v-text-field
        ref="input"
        v-model="editedValue"
        required
        :label="text"
        @keypress.enter="$emit('submit', editedValue)"
      />
    </DialogCard>
  </div>
</template>

<script>
import Confirmable from 'vuedl/src/mixins/confirmable';
import { VTextField } from 'vuetify/lib';
import DialogCard from './DialogCard.vue';

export default {
  components: {
    DialogCard,
    VTextField,
  },
  layout: 'default',
  mixins: [Confirmable],
  props: {
    value: String,
  },
  data() {
    return {
      editedValue: this.value,
    };
  },
  mounted() {
    setTimeout(() => {
      this.$refs.input.focus();
    }, 100);
  },
  methods: {
    handleClick(res, action) {
      this.$emit('submit', action.key ? this.editedValue : action.key);
      return false;
    },
  },
};
</script>
