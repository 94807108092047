<template>
  <div class="webphone-container" :class="{ 'is-mobile-only': $device.isMobileOnly && !$device.isTablet }">
    <v-overlay :value="!hideOverlay" />
    <transition :name="transition">
      <div v-if="isActive" class="webphone-dialog">
        <div class="v-dialog-wrapper">
          <dialog-child ref="dialog" v-bind="$options.propsData" />
        </div>
      </div>
    </transition>
    <v-btn v-if="!hideClose && !persistent && !loading" fab color="black" class="webphone-btn" @click="closeDialog">
      <v-icon color="white">mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
import i18n from '../../../engine/framework/modules/i18n';

export default {
  i18n,
  props: {
    fullscreen: Boolean,
    scrollable: Boolean,
    hideOverlay: Boolean,
    transition: {
      type: [String, Boolean],
      default: 'webphone-transition',
    },
    hideClose: Boolean,
  },
  methods: {
    _destroy() {
      setTimeout(() => {
        this.$destroy();
      }, 600);
    },
    /*
    async confirmClose() {
      const res = await this.$dialog.confirm({
        text: 'Do you really want to exit?',
        title: this.$t('common.confirm'),
        actions: [
          {
            text: this.$t('common.no'),
            key: false,
          },
          {
            text: this.$t('common.yes'),
            color: 'error',
            key: true,
          },
        ],
      });
      if (res) {
        this.closeDialog();
      }
    },
    */
    closeDialog() {
      this.hideClose = false;
      this.close();
    },
  },
};
</script>
<style scoped>
.webphone-container {
  overflow: hidden;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1053;
}
.webphone-container .v-overlay {
  background: rgba(0, 0, 0, 0.5);
}
.webphone-dialog {
  background-color: #fff;
  position: absolute;
  right: 40px;
  bottom: 120px;
  width: 300px;
  height: 520px;
  border-radius: 24px;
  overflow: hidden;
  border: 0;
  z-index: 6;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 5px 30px 0px,
    rgba(0, 0, 0, 0.05) 0px 3px 3px 0px;
}
.webphone-btn {
  position: absolute;
  height: 60px;
  width: 60px;
  right: 120px;
  bottom: 40px;
  cursor: pointer;
  z-index: 6;
}
.webphone-transition-enter-active {
  transition: all 0.3s ease;
}
.webphone-transition-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.webphone-transition-enter,
.webphone-transition-leave-to {
  transform: translateX(10px);
  opacity: 0;
  -webkit-transform: scale(0.1);
  transform: scale(0.1);
  transform-origin: bottom right;
  transform-origin: 100% 140%;
}
.is-mobile-only .webphone-dialog {
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  border-radius: 0;
}
.is-mobile-only .webphone-btn {
  bottom: 10px;
  right: 100px;
  top: 10px;
  right: 10px;
  box-shadow: none;
}
.webphone-container.is-mobile-only .v-overlay {
  display: none;
}
@media screen and (orientation: landscape) {
  .webphone-container.is-mobile-only {
    transform: rotate(-90deg) translate(-100%);
    transform-origin: top left;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    height: 100vw;
    width: 100vh;
  }
}
</style>
