import initHelpHero from 'helphero';

/**
 * @name _helpHero
 * Create Help Hero instance
 * @param {String} appId
 * @returns {Object}
 */
const _helpHero = appId => {
  if (!appId) {
    return null;
  }

  return initHelpHero(appId);
};

/**
 * @name _handleHelpHeroEditor
 * Handles the visibility of the Help Hero editor in frontend
 * @param {Object} [options = {}]
 * @param {Boolean} [options.showEditor = false] should tour editor appear in frontend?
 * @returns {Void}
 */
const _handleHelpHeroEditor = ({ showEditor = false } = {}) => {
  localStorage.setItem('__HLP_EDITOR__', showEditor);
};

/**
 * @name initializeHelpHero
 * Orchestrates the initialization and make it reusable if needed
 * @param {Object} data
 * @param {Object} data.config provides needed info about the account
 * @param {Object} data.user provides needed info about the current user
 * @param {Boolean} [data.forceStartTour = false] forces the start of a tour with the specified tour id
 * @returns {Void}
 */
export const initializeHelpHero = ({ config, user, forceStartTour = false } = {}) => {
  if (!config || !user) {
    return;
  }

  const tour = _helpHero(config?.appId);

  if (!tour) {
    return;
  }

  tour.identify(user?.id);

  if (forceStartTour) {
    tour.startTour(config.firstStepsTourId, { skipIfAlreadySeen: true });
  }

  _handleHelpHeroEditor({ showEditor: config?.showEditor });
};
