import { RouteConfig } from 'vue-router';

export const patientsNameRoutes = {
  consultationList: 'ConsultationList',
  prescriptionList: 'PrescriptionList',
  documentList: 'DocumentList',
  documentUpload: 'DocumentUpload',
  messagingList: 'MessagingList',
  messagingView: 'MessagingView',
  messagingViewTest: 'MessagingViewTest',
  myVideoconsultations: 'myVideoconsultations',
  myMessages: 'myMessages',
  myPrescriptions: 'myPrescriptions',
  mySubscriptions: 'mySubscriptions',
  myDocuments: 'myDocuments',
  sendUsMessage: 'sendUsMessage',
  settingsBeneficiaries: 'settings/beneficiaries',
  settingsBeneficiary: 'settings/beneficiary',
  hiringServiceUrl: 'hiringService',
  hiringService: 'hiring/service',
  payAppointment: 'payAppointment',
};

export const patientsRoutes: RouteConfig[] = [
  {
    path: '/patients/consultations',
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: patientsNameRoutes.consultationList,
    component: () => import(`@/views/patients/ConsultationList.vue`),
  },
  {
    path: '/patients/prescriptions',
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: patientsNameRoutes.prescriptionList,
    component: () => import(`@/views/patients/PrescriptionList.vue`),
  },
  //#region Documents
  {
    path: '/patients/documents',
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: patientsNameRoutes.documentList,
    component: () => import(`@/views/patients/DocumentList.vue`),
  },
  {
    path: '/mydocuments',
    meta: { breadcrumb: false, requiresAuth: true, requiresPatient: true },
    name: patientsNameRoutes.myDocuments,
    component: () => import(`@/views/patients/DocumentList.vue`),
  },
  {
    path: '/patients/documents/upload',
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: patientsNameRoutes.documentUpload,
    component: () => import(`@/views/patients/DocumentUpload.vue`),
  },
  //#endregion

  //#region Messaging
  {
    path: '/patients/messaging',
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: patientsNameRoutes.messagingList,
    component: () => import(`@/views/patients/MessagingList.vue`),
  },
  {
    path: '/mymessages/:id?',
    meta: { breadcrumb: false, requiresAuth: true, requiresPatient: true },
    name: patientsNameRoutes.myMessages,
    component: () => import(`@/views/patients/MessagingList.vue`),
  },
  {
    path: '/patients/messaging/:messagingId',
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: patientsNameRoutes.messagingView,
    component: () => import(`@/views/patients/MessagingView.vue`),
  },
  {
    path: '/patients/messagingtest/:messagingId',
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: patientsNameRoutes.messagingViewTest,
    component: () => import(`@/views/patients/MessagingViewTest.vue`),
  },
  //#endregion
  {
    path: '/myvideoconsultations',
    meta: { breadcrumb: false, requiresAuth: true, requiresPatient: true },
    name: patientsNameRoutes.myVideoconsultations,
    component: () => import(`@/views/patients/ConsultationList.vue`),
  },
  {
    path: '/myprescriptions',
    meta: { breadcrumb: false, requiresAuth: true, requiresPatient: true },
    name: patientsNameRoutes.myPrescriptions,
    component: () => import(`@/views/patients/PrescriptionList.vue`),
  },
  {
    path: '/mysubscriptions',
    meta: { breadcrumb: false, requiresAuth: true, requiresPatient: true },
    name: patientsNameRoutes.mySubscriptions,
    component: () => import(`@/views/patients/SubscriptionList.vue`),
  },
  {
    path: '/sendUsMessage',
    meta: { breadcrumb: false, requiresAuth: true, requiresPatient: true },
    name: patientsNameRoutes.sendUsMessage,
    component: () => import(`@/views/patients/DocumentList.vue`),
  },
  //#region Beneficiaries
  {
    path: '/settings/beneficiaries',
    meta: { breadcrumb: true, requiresAuth: true, requiresPatient: true },
    name: patientsNameRoutes.settingsBeneficiaries,
    component: () => import(`@/views/patients/BeneficiaryList.vue`),
  },
  {
    path: '/settings/beneficiary/:id?',
    meta: { breadcrumb: true, requiresAuth: true, requiresPatient: true },
    name: patientsNameRoutes.settingsBeneficiary,
    component: () => import(`@/views/patients/BeneficiaryView.vue`),
  },
  //#endregion
  {
    path: '/hiringService/:professionalId/:service/:serviceId',
    meta: { breadcrumb: true, requiresAuth: true, requiresPatient: true },
    name: patientsNameRoutes.hiringServiceUrl,
    component: () => import(`@/views/patients/ServiceHiringUrl.vue`),
  },
  {
    path: '/hiring/:professionalId/:service/:serviceId',
    meta: { breadcrumb: true, requiresAuth: true, requiresPatient: true },
    name: patientsNameRoutes.hiringService,
    component: () => import(`@/views/patients/ServiceHiringView.vue`),
  },
  {
    path: '/pay/:professionalId/:service/:serviceId',
    meta: { public: true, breadcrumb: true },
    name: patientsNameRoutes.payAppointment,
    component: () => import(`@/views/patients/ServiceHiringView.vue`),
  },
];
