<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" max-width="280" offset-x>
      <template v-slot:activator="{ on, attrs }">
        <v-card v-bind="attrs" class="mx-auto pa-0" flat :loading="isLoading" v-on="on">
          <v-list-item>
            <v-list-item-avatar class="avatar-role">
              <d-avatar :text="nombre_avatar" size="30" rounded :two-letter="true" :src="img_avatar" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-tooltip="nombre_avatar">{{ nombre_avatar }}</v-list-item-title>
              <v-list-item-subtitle class="grey--text">{{ roleName[roleIdSelected] }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon class="icon-role">
              <v-icon>{{ menu ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-card>
      </template>

      <v-card>
        <v-list class="pa-0">
          <v-subheader class="py-2">{{ $t('common.availableRoles') }}</v-subheader>
          <v-list-item-group :value="selectedItem" @change="switchRole">
            <template v-for="(item, i) in items">
              <v-list-item :key="i" :value="item.id">
                <v-list-item-icon>
                  <v-icon v-text="item.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <p class="text-body-1 mb-0">{{ item.text }}</p>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { switchRoleUrl, getHeader, userUrl } from '../../../config/config';
import DAvatar from '@/components/DAvatar.vue';
import { authUserService } from '@/services/auth-user';
import { AppError, clearString } from '@/utils';
import intercom from '@/services/intercom';
import { useContracts } from '@/use/Marketplace/useContracts';

export default {
  components: {
    DAvatar,
  },
  props: {
    roles: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const { contracts, fetchContracts } = useContracts({
      root: context.root,
    });

    return {
      contracts,
      fetchContracts,
    };
  },
  data: () => ({
    img_avatar: JSON.parse(window.localStorage.getItem('auth_ehealth'))
      ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['img']
      : '',
    nombre_avatar: JSON.parse(window.localStorage.getItem('auth_ehealth'))
      ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['fullName']
      : '',
    roleIdSelected: JSON.parse(window.localStorage.getItem('auth_ehealth'))
      ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol']
      : 2,
    roleUserIdSelected: JSON.parse(window.localStorage.getItem('auth_ehealth'))['user']['userRoleId'],
    roleName: [],
    selectedItem: 0,
    items: [],
    menu: false,
    isLoading: false,
  }),
  computed: {
    ...mapState({
      appName: state => state.app.name,
      appDisplayName: state => state.app.displayName,
      permissions: state => state.app.permissions,
      appIntercomHide: state => state.app.intercom.hide,
      appIntercomId: state => state.app.intercom.id,
      appIntercomUserMaps: state => state.app.intercom.userMaps,
    }),
  },
  mounted() {
    this.setRoleName();
    this.setRoleList();
  },
  methods: {
    ...mapActions('app', ['loadConfigFromUser']),
    ...mapActions('layout', ['showAlert']),
    ...mapActions({
      setAuthUserData: 'account/setAuthUserData',
    }),
    ...mapMutations({
      setFeatureFlags: 'app/setFeatureFlags',
    }),
    setRoleName() {
      this.roleName = [
        this.$t('common.undefined'),
        this.$t('common.admin'),
        this.$t('common.professional'),
        this.$t('common.receptionist'),
        this.$t('common.rrhh'),
        this.$t('common.patient'),
      ];
    },
    setRoleList() {
      this.roleUserIdSelected = JSON.parse(window.localStorage.getItem('auth_ehealth'))['user']['userRoleId'];
      const roles = JSON.parse(window.localStorage.getItem('auth_ehealth'))
        ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['roles']
        : [];
      this.selectedItem = this.roleUserIdSelected;
      roles.forEach(role => {
        let textItem = this.roleName[role.role_id];
        if (role.company_name !== '') {
          textItem += ' (' + role.company_name + ')';
        }
        this.items.push({
          text: textItem,
          icon: role.icon,
          id: role.id,
          roleId: role.role_id,
        });
      });
    },
    async switchRole(itemId) {
      this.selectedItem = itemId;
      this.menu = false;

      if (this.roleUserIdSelected === itemId) {
        return false;
      }

      this.isLoading = true;

      const foundRol = this.roles.find(rol => rol.id === itemId);
      const param = {
        id: JSON.parse(window.localStorage.getItem('auth_ehealth'))['id'],
        role: { id: foundRol.id, roleId: foundRol.role_id },
      };

      try {
        const res = await this.$http.post(switchRoleUrl, param, { headers: getHeader() });
        const userRole = {
          id: foundRol.id,
          rol: foundRol.role_id,
        };
        if (null !== window.localStorage.getItem('channelNotifications')) {
          this.$pusher.unsubscribe(JSON.parse(window.localStorage.getItem('channelNotifications'))['name']);
          window.localStorage.removeItem('channelNotifications');
        }
        authUserService.setAuthUserRole(res.body, userRole);
        await this.getAuthUser();
        location.reload();
      } catch (err) {
        this.$log.error(err);
        this.showAlert({
          color: 'error',
          icon: 'mdi-alert-circle',
          message: err.body.error,
        });
      } finally {
        this.isLoading = false;
      }
    },

    async getAuthUser() {
      let response;

      try {
        response = await this.$http.get(userUrl, { headers: getHeader() });
      } catch (e) {
        throw new AppError({
          color: 'error',
          icon: 'mdi-alert-circle',
          message: 'Login error, please try again',
        });
      }

      this.authUserAppend(response.body);
      this.loadConfigFromUser();
      this.loadHelpServices();

      const {
        body: { features },
      } = response || {};

      await this.setAuthUserData();
      this.setFeatureFlags(features);

      if (features?.marketplace) {
        await this.fetchContracts(response.body.user.email);
      }
    },
    authUserAppend(authData) {
      window.localStorage.setItem(
        'auth_ehealth',
        JSON.stringify(this.authUserMapper(JSON.parse(window.localStorage.getItem('auth_ehealth')), authData))
      );
    },

    authUserMapper(authUser, data) {
      const isPatient = typeof data.patient !== 'undefined' && data.patient.id > 0;
      if (
        !isPatient &&
        typeof data?.empresa?.is_health_personnel !== 'undefined' &&
        data.empresa.is_health_personnel === 0
      ) {
        this.load_i18n_message('noHealthPersonnel');
      }

      return {
        ...authUser,
        email: data.user.email,
        name: data.user.name,
        fullName: !isPatient ? data.perfil.fullName : data.user.name + ' ' + data.user.surname,
        id: data.user.id,
        img: !isPatient ? data.perfil.img : '',
        lang: data.user.lang,
        nombreEmpresa: data?.empresa?.nombre || '',
        company_docline_api_id: data?.empresa?.docline_api_id || '',
        avatarEmpresa: data?.empresa?.avatar || '',
        logoEmpresa: data?.empresa?.logo || '',
        servicios_activos: !isPatient ? data.perfil.servicios_activos : [],
        rol: data.user.rol,
        roles: data.roles,
        user: data.user,
        trial: !isPatient ? data.perfil.trial : false,
        start_trial: !isPatient ? data.perfil.start_trial : '',
        formReserva: !isPatient ? data.perfil.formReserva : false,
        formPago: !isPatient ? data.perfil.formPago : false,
        formStepToStep: !isPatient ? data.perfil.stepToStep : false,
        permisos: !isPatient ? data.perfil.permisos : '',
        belong_to: !isPatient ? data.perfil.belong_to : 0,
        collegiateNumber:
          !isPatient && typeof data.perfil.collegiateNumber !== 'undefined' ? data.perfil.collegiateNumber : '',
        color: data?.empresa?.color || '',
        colorHexadecimal: data?.empresa?.colorHexadecimal || '',
        lastActivityTime: new Date(),
        permissions: !isPatient ? data.usuario.permissions : null,
        is_health_personnel: data?.empresa?.is_health_personnel || 1,
        is_collective: data?.empresa?.is_collective || 0,
        have_interconsultation: data?.empresa?.interconsultation === 1,
        erecipe: !isPatient && typeof data?.perfil?.erecipe !== 'undefined' ? data.perfil.erecipe : 0,
        hasSpecialtyToPrescribe:
          !isPatient && typeof data.perfil.hasSpecialtyToPrescribe !== 'undefined'
            ? data.perfil.hasSpecialtyToPrescribe
            : 0,
        company: {
          themeColorPrimary: data?.empresa?.themeColorPrimary || data?.empresa?.colorHexadecimal,
          themeColorSecondary: data?.empresa?.themeColorSecondary || data?.empresa?.colorHexadecimal,
          permissions: data?.empresa?.permissions || null,
          id: data?.empresa?.id || null,
          sharingReceptionist: data?.empresa?.sharingReceptionist,
        },
        isSuperAdmin: !isPatient ? data.usuario.isSuperAdmin : false,
        payment_module: !isPatient ? data.usuario.payment_module : true,
        isPatient: isPatient,
        patient: isPatient ? data.patient : false,
        show_informed_consent: data?.empresa?.informed_consent || 0,
        stripeConfigured:
          !isPatient && typeof data.perfil.stripeConfigured !== 'undefined' ? data.perfil.stripeConfigured : 0,
        environment: data?.environment || [],
      };
    },
    loadHelpServices() {
      const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));

      let user = {};
      let showIntercom = false;
      if (authUser?.id) {
        user = { ...authUser };
        const name_env = this.appName;
        const user_id = user.id;
        user.id = this.appName + '_' + user.id;
        if (
          name_env.toLowerCase() === 'uniteco' &&
          typeof user.nombreEmpresa !== 'undefined' &&
          user.nombreEmpresa !== ''
        ) {
          user.id = clearString(user.nombreEmpresa) + '_' + user_id;
        }
        showIntercom = true;
      }

      if (this.appIntercomHide !== true && showIntercom) {
        !authUser?.isPatient && intercom(this.appIntercomId, this.appIntercomUserMaps, user);
      }
    },
  },
};
</script>
<style>
.avatar-role {
  width: 30px !important;
  min-width: 30px !important;
}
.icon-role {
  margin-left: 2px !important;
  margin-right: -5px;
}
</style>
