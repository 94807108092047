<template>
  <div>
    <div v-if="toc.length" id="conditions-to-pdf" class="conditions">
      <div class="conditions_inner" v-html="formattedToc" />
    </div>
    <div v-if="isLoadingToc" class="pt-5 text-center">
      <v-progress-circular color="primary" indeterminate size="48" width="2" />
    </div>
  </div>
</template>

<script>
import { useToc } from '@/use/Toc/useToc';

export default {
  name: 'Conditions',

  props: {
    language: {
      type: String,
      default: 'es',
    },
  },

  setup: function(props) {
    const { toc, isLoadingToc, loadedSuccessfully, loadToc } = useToc(props.language);

    loadToc();

    return {
      toc,
      isLoadingToc,
      loadedSuccessfully
    };
  },

  computed: {
    formattedToc() {
      return this.toc;
    }
  },

  watch: {
    loadedSuccessfully(val) {
      if (val) {
        this.$emit("loadedsuccessfully", this.toc)
      }
    }
  }
};
</script>

<style>
.conditions {
  //font-size: 12px;
  //& ul li {list-style-type: none; &:before { content: '• '; margin-left: -1em; } }

  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #000;
  line-height: 1.6em;
  margin: 0 auto;

  &__inner {
    padding: 72pt 72pt 72pt 72pt;
    border: 1px solid #000;
    margin: 0 auto;
    width: 550px;
  }
}
</style>

