export default (valueType, valueDocument) => {
  const nieRegex = /^[XYZ]\d{7,8}[A-Z]$/;

  if (valueDocument.length === 0) {
    return false;
  }

  if (valueType === 3 || valueType === 4) {
    return true;
  }

  if (valueType === 2 && nieRegex.test(valueDocument)) {
    return true;
  }

  let dni = valueDocument;
  dni = dni.replace('-', '');
  const expresion_regular =
    valueType === 1 ? /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i : /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
  if (expresion_regular.test(dni) !== true) {
    return false;
  } else if (valueType === 1) {
    let numero = dni.substr(0, dni.length - 1);
    const letr = dni.substr(dni.length - 1, 1);
    numero = numero % 23;
    let letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
    letra = letra.substring(numero, numero + 1);
    if (letra !== letr.toUpperCase()) {
      return false;
    }
  }

  return true;
};
