const getDomainWithoutSubdomain = (url: string) => {
  const uri = new URL(url);

  if (uri.hostname === 'localhost') {
    return "localhost";
  }

  const urlParts = uri.hostname.split('.');
  return `.${urlParts[urlParts.length - 2]}.${urlParts[urlParts.length - 1]}`;
}

const setItem = <T>(field: string, data: T, exp?: Date) => {
  const parseData = typeof data === 'string' ? data : JSON.stringify(data);
  document.cookie = `${field}=${parseData};${(exp) ? `expires=${exp.toUTCString()};` : ''}domain=${getDomainWithoutSubdomain(window.location.href)};path=/`;
};

export const cookiesService = {
    setItem
};
