import Vue from 'vue';
import Vuedl from 'vuedl/src/index';
import DialogLayout from '@/components/webphone/DialogLayout.vue';
import WebPhone from '@/components/webphone/WebPhone.vue';

const property = '$dialog';

Vue.use(Vuedl, {
  container: '[data-app=true]',
  property: property,
});

const manager = Vue.prototype[property];

Vue.component('WebPhone', WebPhone);

manager.layout('webphone', DialogLayout);
manager.component('WebPhone', WebPhone, {
  waitForResult: true,
});

manager.call = options => {
  manager.WebPhone(options);
};

Vue.directive('call', {
  inserted: function(el, binding, vnode) {
    vnode.elm.addEventListener('click', () => manager.WebPhone({ number: vnode.elm.innerText }));
  },
});
