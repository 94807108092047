import Vue from 'vue';
import router from '@/router';
import VuetifyDialog from '@/modules/VuetifyDialog';
import vuetify from '@/plugins/vuetify';

Vue.use(VuetifyDialog, {
  router,
  confirm: {
    actions: {
      false: 'No',
      true: {
        text: 'Yes',
        color: 'primary',
      },
    },
    icon: false,
    width: 500,
  },
  warning: {},
  error: {},
  prompt: {},
  context: {
    vuetify,
  },
});

// TODO: review use
