<template>
  <v-navigation-drawer id="menu-layout" v-model="show" app clipped width="260" :dark="$vuetify.dark">
    <v-toolbar
      v-if="$vuetify.breakpoint.smAndDown"
      :dark="themeToolbarHeader.enableDarkColor"
      :color="themeToolbarHeader.backgroundColor"
      @click="goToDashboard"
    >
      <img :src="appLogoToolbar ? appLogoToolbar : appLogoSecondary" :class="appMenuLogoClass" alt="Logo" />
    </v-toolbar>

    <vue-perfect-scrollbar class="drawer-menu--scroll" :settings="scrollSettings">
      <v-list nav expand>
        <slot />
        <v-list-item v-if="isPatient" @click="mailToSupport">
          <v-list-item-action>
            <v-icon>mdi-email-edit-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('menu.sendUsMessage') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </vue-perfect-scrollbar>

    <template v-slot:append>
      <v-divider />
      <div class="py-3 body-2 text-center text-decoration-none">
        <a class="grey--text mx-2" @click="setAboutUsDialogVisibility(true)">{{ $t('common.aboutus') }}</a>
      </div>
      <v-divider />
      <div class="py-3 body-2 text-center text-decoration-none">
        <a v-if="rgpd.urlPolicy" :href="rgpd.urlPolicy" target="_blank" class="grey--text mx-2">{{
          $t('common.privacy')
        }}</a>
        <a v-if="rgpd.urlCookies" :href="rgpd.urlCookies" target="_blank" class="grey--text mx-2">{{
          $t('common.cookies')
        }}</a>
        <a v-if="rgpd.urlConditions" :href="rgpd.urlConditions" target="_blank" class="grey--text mx-2">{{
          $t('common.aviso_legal')
        }}</a>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import environment from '@/environment';

export default {
  components: {
    VuePerfectScrollbar,
  },

  data() {
    return {
      show: true,
      scrollSettings: {
        maxScrollbarLength: 160,
      },
      rgpd: environment.footer.rgpd,
      isPatient:
        window.localStorage.getItem('auth_ehealth') &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['isPatient'],
    };
  },
  computed: {
    ...mapState({
      appLogoSecondary: state => state.app.images.logoSecondary,
      appMenuLogoClass: state => state.app.styles.menuLogoClass,
      appLogoToolbar: state => state.app.images.logoToolbar,
      supportEmail: state => state.app.supportEmail,
    }),
    themeToolbarHeader() {
      return this.$store.getters['app/themeToolbarHeader'];
    },
  },

  created() {
    window.getApp.$on('APP_DRAWER_TOGGLED', () => {
      this.show = !this.show;
    });
  },

  methods: {
    ...mapMutations({
      setAboutUsDialogVisibility: 'layout/setAboutUsDialogVisibility',
    }),
    goToDashboard() {
      if (this.$route.path !== '/dashboard') {
        this.$router.push('/dashboard');
      }
    },
    mailToSupport() {
      const supportEmail = this.supportEmail ? this.supportEmail : 'soporte@docline.es';
      window.open(`mailto:${supportEmail}`);
    },
  },
};
</script>

<style scoped>
#menu-layout {
  overflow: hidden;
}

.drawer-menu--scroll {
  height: calc(100vh - 48px);
  overflow: auto;
}

.logo_type_1 {
  max-height: 32px;
  max-width: 164px;
}

.logo_type_2 {
  max-height: 40px;
  margin-left: 12px;
  max-width: 140px;
  margin-top: 6px;
}

.logo_type_3 {
  max-height: 35px;
  margin-left: 12px;
  max-width: 240px;
}

.borderBottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
</style>
