import { RouteConfig } from 'vue-router';

export const professionalsNamePaths = {
  patientsList: 'Pacientes',
  createPatient: 'NuevoPaciente',
  patientById: 'Paciente',
  medyolaPatient: 'MedyolaPaciente', // TODO: Remove
  foundedPatient: 'Buscadopaciente',
  professionalsList: 'Professionals',
  professionalDetails: 'Professional',
};

export const professionalsRoutes: RouteConfig[] = [
  {
    path: '/pacientes',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: professionalsNamePaths.patientsList,
    component: () =>
      import(
        /* webpackChunkName: "pacientes" */
        `@/views/Pacientes.vue`
      ),
  },
  {
    path: '/pacientes/:nuevo',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: professionalsNamePaths.createPatient,
    component: () =>
      import(
        /* webpackChunkName: "nuevo-paciente" */
        `@/views/Pacientes.vue`
      ),
  },
  {
    path: '/paciente/:id/:tab?/:objectId?',
    meta: { breadcrumb: false, requiresAuth: true, forbiddenPatient: true },
    name: professionalsNamePaths.patientById,
    props: route => ({
      prescriptionToExpand: route.query.prescriptionToExpand
        ? parseInt(route.query.prescriptionToExpand.toString())
        : 0,
    }),
    component: () =>
      import(
        /* webpackChunkName: "paciente" */
        `@/views/Paciente.vue`
      ),
  },
  {
    path: '/buscadopaciente/:id',
    meta: { breadcrumb: false, requiresAuth: true, forbiddenPatient: true },
    name: professionalsNamePaths.foundedPatient,
    component: () =>
      import(
        /* webpackChunkName: "buscando-paciente" */
        `@/views/BuscadoPaciente.vue`
      ),
  },
  {
    path: '/prescriptions',
    meta: { breadcrumb: false, requiresAuth: true, forbiddenPatient: true },
    name: 'prescriptionsFilteredList',
    component: () =>
      import(
        /* webpackChunkName: "prescriptions-filtered-list" */
        `@/views/PrescriptionsFilteredList.vue`
      ),
  },
  {
    path: '/professionals',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: professionalsNamePaths.professionalsList,
    component: () =>
      import(
        /* webpackChunkName: "professionals" */
        `@/views/Professionals.vue`
      ),
  },
  {
    path: '/professionals/:id',
    meta: { breadcrumb: false, requiresAuth: true, only_admin: true },
    props: route => ({
      tab: route.query.tab
        ? parseInt(route.query.tab.toString())
        : 0,
    }),
    name: professionalsNamePaths.professionalDetails,
    component: () =>
      import(
        /* webpackChunkName: "professional" */
        `@/views/Professional.vue`
      ),
  },
];
