<template>
  <span :class="{ 'error--text': isWarning }">
    {{ text }}
  </span>
</template>
<script>
export default {
  props: {
    callStatus: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
      default: 1800,
    },
  },
  data() {
    return {
      text: '',
      isWarning: false,
      startTime: null,
      interval: null,
    };
  },
  watch: {
    callStatus(val) {
      if (val === 'answered') {
        this.startTimer();
      } else {
        this.stopTimer();
      }
    },
  },
  methods: {
    startTimer() {
      this.startTime = new Date().getTime();
      this.interval = setInterval(() => {
        const t = new Date().getTime() - this.startTime;

        if (this.limit - t / 1000 < 60) {
          this.isWarning = true;
          this.$emit('warning');
        }

        const seconds = Math.floor((t / 1000) % 60);
        const minutes = Math.floor((t / 1000 / 60) % 60);
        const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
        this.text =
          this.padLeadingZeros(hours, 2) +
          ':' +
          this.padLeadingZeros(minutes, 2) +
          ':' +
          this.padLeadingZeros(seconds, 2);
      }, 1000);
    },
    stopTimer() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    padLeadingZeros(num, size) {
      let s = num + '';
      while (s.length < size) s = '0' + s;
      return s;
    },
  },
};
</script>
