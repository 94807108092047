export default {
  common: {
    videoconferences: 'Appointments',
  },
  menu: {
    videocam: 'Appointments',
  },
  tipovideo: {
    estandar: 'Standard Appointment',
    precio: 'Price of the Appointment',
    duracion: 'Duration of the Appointment',
    guardar: 'Save Appointment',
    nuevo_video: 'Create Appointment Service',
    confirm_delete_titulo: 'Remove appointment service',
    confirm_delete_texto: 'Are you sure you want to delete the selected appointment service?',
  },
  videoconsulta: {
    nueva_video: 'New Appointment',
    nueva_cita: 'New Appointment',
    crear_cita: 'Create Appointment',
    crear_video: 'Create Appointment',
    pendientes: 'Pending Appointments',
    terminadas: 'Finished Appointments',
    solicitando_pago_texto:
      'Send a payment request by Email. Once the payment is received, you can send your patient the details to be able to make the Appointment.',
    cita_cortesia: 'Free',
    cita_cortesia_texto: 'These appointments are free for the patient. These can be useful in cases such as: ',
    cita_cortesia_li:
      'Reschedule an appointment that could not be completed. | Send an invitation for a test appointment or any other reason that you may consider. | Patients that have previously bought a pack of appointments.',
    add_fechahora: 'Indicate the date and time of the appointment',
    add_tiposervicio: 'Indicate the type of appointment',
    add_tiposervicio_cita: 'Indicate the type of appointment',
    solapamiento: 'An appointment already exists on the selected date and time',
    sin_tiposvideo: 'To create new appointment you must have at least one service active.',
    enviado_correctamente: 'The appointment has been created correctly. An email has been sent to the patient',
    enviado_correctamente_sms: 'The appointment has been created correctly. An SMS has been sent to the patient',
    enviado_recordatorio: 'An email has been sent to the patient to remind him of the appointment',
    confirm_delete: 'Are you sure you want to cancel the appointment?',
    confirm_delete2:
      'The appointment is already paid. If necessary to make make a refund of money to the patient contact us through the chat.',
    confirm_video: 'Are you sure you want to confirm the appointment?',
    cancelado_correctamente: 'The appointment has been cancelled successfully',
    notification_email: 'An email will be sent to the patient confirming the cancellation of the appointment',
    confirmado_correctamente: 'The appointment has been confirmed correctly',
    terminada: 'Appointment Finished',
    singular: 'Appointment',
    tipo_cita: 'Type of Appointment',
  },
  suscripciones: {
    videoconsulta_restantes: 'Remaining Appointments',
  },
  permissions: {
    videoConsultation: 'Appointments',
    haveCreateVideoWithTel: 'Appointment by phone',
  },
};
