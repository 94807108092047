import { RouteConfig } from 'vue-router';

export const videoconsultationNamePaths = {
  appointmentDetails: 'VerCita',
  appointments: 'Calendario',
  createVideoconsultation: 'NuevaVideo',
  specialtyCalendar: 'CalendarioEspecialidad',
  videoconsultation: 'Videoconsulta',
};

export const videoconsultationRoutes: RouteConfig[] = [
  {
    path: '/videoconsulta',
    meta: { breadcrumb: false, requiresAuth: true, forbiddenPatient: true },
    name: videoconsultationNamePaths.videoconsultation,
    // TODO: alias: '/calendario',
    component: () =>
      import(
        /* webpackChunkName: "videoconsulta" */
        `@/views/Videoconsultation.vue`
      ),
  },
  // TODO: 👇 This route will be an alias on videoconsulta
  {
    path: '/calendario',
    meta: { breadcrumb: false, requiresAuth: true, forbiddenPatient: true },
    name: videoconsultationNamePaths.appointments,
    component: () =>
      import(
        /* webpackChunkName: "calendario" */
        `@/views/Videoconsultation.vue`
      ),
  },
  {
    path: '/cita/:id',
    meta: { breadcrumb: false, requiresAuth: true, forbiddenPatient: true },
    name: videoconsultationNamePaths.appointmentDetails,
    component: () =>
      import(
        /* webpackChunkName: "paciente" */
        `@/views/Videoconsultation.vue`
      ),
  },
  {
    path: '/videoconsulta/:nuevo',
    meta: { breadcrumb: false, requiresAuth: true, forbiddenPatient: true },
    name: videoconsultationNamePaths.createVideoconsultation,
    component: () =>
      import(
        /* webpackChunkName: "nueva-video" */
        `@/views/Videoconsultation.vue`
      ),
  },
  {
    path: '/calendarioespecialidad',
    meta: { breadcrumb: false, requiresAuth: true, forbiddenPatient: true },
    name: videoconsultationNamePaths.specialtyCalendar,
    component: () =>
      import(
        /* webpackChunkName: "videoconsulta" */
        `@/views/CalendarMain.vue`
      ),
  },
];
