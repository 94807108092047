import store from '../../engine/framework/modules/store';

export const init = () => {
  const { helpScout = null } = store.state?.app || {};
  if (!helpScout) return;

  const { id } = helpScout;
  window.Beacon('init', id);
  window.Beacon('config', { hideFABOnMobile: true });
};

/**
 * HelpScout: Beacon(‘identify’, userObject)
 * https://developer.helpscout.com/beacon-2/web/javascript-api/#beaconidentify-userobject
 */
export const setIdentity = (email, userId, name, companyId, url, professionalId) => {
  const user = {
    name,
    email,
    userId,
    companyId,
    url,
    professionalId,
  };

  window.Beacon('identify', user);
};

export const destroy = () => {
  window.Beacon('reset');
  window.Beacon('logout', { endActiveChat: true });
  window.Beacon('destroy');
};

/**
 * Handle HelpScout container display
 * @param { 'show' | 'hide' } action
 */
const handleHelpScoutContainer = action => {
  const helpScoutContainer = document.getElementById('beacon-container');
  if (helpScoutContainer) {
    helpScoutContainer.style.display = action === 'show' ? 'block' : 'none';
  }
};

export const hideContainer = () => handleHelpScoutContainer('hide');
export const showContainer = () => handleHelpScoutContainer('show');
