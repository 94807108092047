import { RouteConfig } from 'vue-router';

export const medicaltestsNamePaths = {
  medicalTests: 'medicalTestsTypes',
  createMedicalTest: 'createMedicalTest',
  editMedicalTest: 'editMedicalTest',

  rxProtocols: 'rxProtocols',
  createRxProtocol: 'createRxProtocol',
  editRxProtocol: 'editRxProtocol',

  createNomenclator: 'createMedicalTestsNomenclator',
  editNomenclator: 'editMedicalTestsNomenclator',
  nomenclator: 'medicalTestsNomenclator',
  requestAnalyticalTest: 'requestAnalyticalTest',
  requestRxTest: 'requestRxTest',
  requestOtherTest: 'requestOtherTest',
};

export const medicaltestsRoutes: RouteConfig[] = [
  {
    path: '/pruebas/tipos',
    meta: { public: false, requiresAuth: true },
    name: medicaltestsNamePaths.medicalTests,
    component: () => import(`@/views/MedicalTests/Types/MedicalTestsContainer.vue`),
  },
  {
    path: '/pruebas/tipos/nueva',
    meta: { public: false, requiresAuth: true },
    name: medicaltestsNamePaths.createMedicalTest,
    component: () => import(`@/views/MedicalTests/Types/Detail.vue`),
  },
  {
    path: '/pruebas/tipos/:id',
    meta: { public: false, requiresAuth: true },
    name: medicaltestsNamePaths.editMedicalTest,
    component: () => import(`@/views/MedicalTests/Types/Detail.vue`),
  },
  {
    path: '/pruebas/rxprotocols',
    meta: { public: false, requiresAuth: true },
    name: medicaltestsNamePaths.rxProtocols,
    component: () => import(`@/views/MedicalTests/RxProtocols/RxProtocols.vue`),
  },
  {
    path: '/pruebas/rxprotocols/nuevo',
    meta: { public: false, requiresAuth: true },
    name: medicaltestsNamePaths.createRxProtocol,
    component: () => import(`@/views/MedicalTests/RxProtocols/RxProtocolManager.vue`),
  },
  {
    path: '/pruebas/rxprotocols/:id',
    meta: { public: false, requiresAuth: true },
    name: medicaltestsNamePaths.editRxProtocol,
    component: () => import(`@/views/MedicalTests/RxProtocols/RxProtocolManager.vue`),
  },
  {
    path: '/pruebas/nomenclator',
    meta: { public: false, requiresAuth: true },
    name: medicaltestsNamePaths.nomenclator,
    component: () => import(`@/views/MedicalTests/Nomenclator/Nomenclator.vue`),
  },
  {
    path: '/pruebas/nomenclator/nuevo',
    meta: { public: false, requiresAuth: true },
    name: medicaltestsNamePaths.createNomenclator,
    component: () => import(`@/views/MedicalTests/Nomenclator/NomenclatorManager.vue`),
  },
  {
    path: '/pruebas/nomenclator/:id',
    meta: { public: false, requiresAuth: true },
    name: medicaltestsNamePaths.editNomenclator,
    component: () => import(`@/views/MedicalTests/Nomenclator/NomenclatorManager.vue`),
  },
  {
    path: '/pruebas/analiticas/solicitud',
    meta: { public: false, requiresAuth: true },
    name: medicaltestsNamePaths.requestAnalyticalTest,
    props: route => ({ patientId: route.query.patient }),
    component: () => import(`@/views/MedicalTests/RequestAnalyticalTest/RequestAnalyticalTest.vue`),
  },
  {
    path: '/pruebas/rx/solicitud',
    meta: { public: false, requiresAuth: true },
    name: medicaltestsNamePaths.requestRxTest,
    props: route => ({ patientId: route.query.patient }),
    component: () => import(`@/views/MedicalTests/RequestRxTest/RequestRxTest.vue`),
  },
  {
    path: '/pruebas/otras/solicitud',
    meta: { public: false, requiresAuth: true },
    name: medicaltestsNamePaths.requestOtherTest,
    props: route => ({ patientId: route.query.patient }),
    component: () => import(`@/views/MedicalTests/RequestOtherTest/RequestOtherTest.vue`),
  },
];
