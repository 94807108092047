const FEATURES_STORAGE_NAME = 'features';
const FEATURES_COOKIE_NAME_PREFIX = FEATURES_STORAGE_NAME;
export const FEATURE_NAMES = {
  SIGNATURE: 'signature_enabled',
  REP_HOMOLOGATION: 'rep_homologation',
  NEW_ACCOUNT_PAGE: 'new_account_page',
};

interface Features {
  signature_enabled: boolean;
  rep_homologation: boolean;
  new_account_page: boolean;
}

const _getValueFromStorage = (feature: string): boolean => {
  const featuresAsStr: string = localStorage.getItem(FEATURES_STORAGE_NAME);
  const features: Features = featuresAsStr ? JSON.parse(featuresAsStr) : {};

  return Boolean(features[feature as keyof Features]);
};

const _getValueFromCookie = (feature: string): boolean => {
  const featuresCookie: string =
    document.cookie &&
    document.cookie.split('; ').find(row => row.startsWith(`${FEATURES_COOKIE_NAME_PREFIX}_${feature}`));
  const cookieValue: string = featuresCookie && featuresCookie.split('=')[1];

  return Boolean(cookieValue);
};

const featureToggleHandler = (feature: string): boolean => {
  const valueFromStorage = _getValueFromStorage(feature);
  if (valueFromStorage) {
    return valueFromStorage;
  }

  return _getValueFromCookie(feature);
};

export { featureToggleHandler };
