<template>
  <footer>
    <span class="poweredBy">Powered by</span>
    <d-logo height="20" :primary-color="'#ccc'" :secondary-color="'#ccc'" :slogan="false" />
  </footer>
</template>

<script>
import DLogo from '@/components/DLogo.vue';

export default {
  name: 'TheFooter',
  components: { DLogo },
};
</script>

<style lang="scss" scoped>
footer {
  display: inline-block;
  text-align: center;
  padding: 0.6rem;
  margin-bottom: 40px;
  color: #ccc;
  font-size: 0.8rem;
  width: 100%;
  height: 40px;

  span {
    vertical-align: middle;
  }

  #logo {
    height: 1.2rem;
    margin-left: 0.3rem;
    vertical-align: text-bottom;
  }

  .poweredBy {
    padding-right: 12px;
    position: relative;
    top: -8px;
  }
}
</style>
