const getItem = <T>(field: string): T => {
  const result = window.localStorage.getItem(field);
  return result ? JSON.parse(result) : null;
};

const setItem = <T>(field: string, data: T) => {
  const parseData = typeof data === 'string' ? data : JSON.stringify(data);
  window.localStorage.setItem(field, parseData);
};

const removeItem = (field: string) => {
  window.localStorage.removeItem(field);
};

export const localStorageService = {
  getItem,
  setItem,
  removeItem,
};
