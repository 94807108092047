class AppError extends Error {
  constructor({ color = 'error', icon = 'mdi-alert-circle', message }) {
    super(message);
    this.name = 'AppError';
    this.color = color;
    this.icon = icon;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, AppError);
    }
  }
}

export default AppError;
