const mixin = {
  data() {
    return {};
  },
  methods: {
    confirm(message, title) {
      this.$dialog.confirm({
        text: message,
        title: title || 'Alert',
        actions: {
          false: 'No',
          true: {
            color: 'primary',
            text: 'Ok',
            handle: () => {
              return new Promise(() => {
                /** */
              });
            },
          },
        },
      });
    },
    toast(message) {
      this.$toast(message, {
        color: 'black',
        timeout: 6000,
      });
    },
    toastSuccess(message) {
      this.$toast(message, {
        color: 'green',
        timeout: 6000,
      });
    },
    toastWarning(message) {
      this.$toast(message, {
        color: 'orange',
        timeout: 6000,
      });
    },
    toastError(message) {
      this.$toast(message, {
        color: 'red',
        showClose: false,
        timeout: 6000,
      });
    },
    checkAndShowMessage() {
      const msg = JSON.parse(window.localStorage.getItem('msg_ehealth'));
      if (msg && msg.message !== '') {
        this.$toast(msg.message, {
          color: msg.color,
          showClose: false,
          timeout: 10000,
        });
        localStorage.removeItem('msg_ehealth');
      }
    },
  },
  mounted() {
    this.checkAndShowMessage();
  },
};

export default mixin;
