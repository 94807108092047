import { RouteConfig } from 'vue-router';

export const settingsNamePaths = {
  settings: 'Ajustes',
  prescriptionBooklet: 'ajustes/talonario',
  signed: 'ajustes/firma',
  schedule: 'ajustes/agenda',
  specialties: 'ajustes/especialidades',
  daysNotAvailable: 'ajustes/diasnodisponibles',
  availableDays: 'ajustes/diasdisponibles',
  customValues: 'ajustes/valorespersonalizados',
  setupServices: 'configServicios',
  services: 'ajustes/servicios',
  videoconsultation: 'ajustes/videoconsultas',
  packs: 'ajustes/packs',
  coupons: 'ajustes/cupones',
  landing: 'ajustes/landing',
  receptionist: 'ajustes/recepcionista',
  companyConfig: 'ajustes/company-config',
};

export const settingsRoutes: RouteConfig[] = [
  {
    path: '/ajustes',
    meta: { breadcrumb: false, requiresAuth: true, forbiddenPatient: true },
    name: settingsNamePaths.settings,
    component: () =>
      import(
        /* webpackChunkName: "ajustes" */
        `@/views/Ajustes.vue`
      ),
  },
  {
    path: '/ajustes/talonario',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: settingsNamePaths.prescriptionBooklet,
    component: () =>
      import(
        /* webpackChunkName: "talonario" */
        `@/views/ajustes/Talonario.vue`
      ),
  },
  {
    path: '/ajustes/firma',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: settingsNamePaths.signed,
    component: () =>
      import(
        /* webpackChunkName: "ajustes-firma" */
        `@/views/ajustes/Firma.vue`
      ),
  },
  {
    path: '/ajustes/agenda',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: settingsNamePaths.schedule,
    component: () =>
      import(
        /* webpackChunkName: "ajustes-agenda" */
        `@/views/ajustes/Agenda.vue`
      ),
  },
  {
    path: '/ajustes/especialidades',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: settingsNamePaths.specialties,
    component: () =>
      import(
        /* webpackChunkName: "ajustes-especialidades" */
        `@/views/ajustes/Especialidades.vue`
      ),
  },
  {
    path: '/ajustes/diasnodisponibles',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: settingsNamePaths.daysNotAvailable,
    component: () => import(`@/views/ajustes/DiasNoDisponibles.vue`),
  },
  {
    path: '/ajustes/diasdisponibles',
    meta: { breadcrumb: true, requiresAuth: true, only_admin: true },
    name: settingsNamePaths.availableDays,
    component: () => import(`@/views/ajustes/DiasDisponibles.vue`),
  },
  {
    path: '/ajustes/valorespersonalizados',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: settingsNamePaths.customValues,
    component: () => import(`@/views/ajustes/ValoresPersonalizados.vue`),
  },
  {
    path: '/configServicios',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: settingsNamePaths.setupServices,
    component: () =>
      import(
        /* webpackChunkName: "config-servicios" */
        `@/views/ajustes/ServiciosAdmin.vue`
      ),
  },
  {
    path: '/ajustes/servicios',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: settingsNamePaths.services,
    component: () =>
      import(
        /* webpackChunkName: "servicios" */
        `@/views/ajustes/Servicios.vue`
      ),
  },
  {
    path: '/ajustes/videoconsultas',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: settingsNamePaths.videoconsultation,
    component: () =>
      import(
        /* webpackChunkName: "videoconsultas" */
        `@/views/ajustes/Videoconsultas.vue`
      ),
  },
  {
    path: '/ajustes/packs',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: settingsNamePaths.packs,
    component: () =>
      import(
        /* webpackChunkName: "packs" */
        `@/views/ajustes/Packs.vue`
      ),
  },
  {
    path: '/ajustes/cupones',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: settingsNamePaths.coupons,
    component: () =>
      import(
        /* webpackChunkName: "cupones" */
        `@/views/ajustes/Cupones.vue`
      ),
  },
  {
    path: '/ajustes/landing',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: settingsNamePaths.landing,
    component: () =>
      import(
        /* webpackChunkName: "landing" */
        `@/views/ajustes/MiWeb.vue`
      ),
  },
  {
    path: '/ajustes/recepcionista',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: settingsNamePaths.receptionist,
    component: () =>
      import(
        /* webpackChunkName: "recepcionista" */
        `@/views/ajustes/Recepcionista.vue`
      ),
  },
  {
    path: '/ajustes/company-config',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: settingsNamePaths.companyConfig,
    component: () =>
      import(
        /* webpackChunkName: "company-config" */
        `@/views/ajustes/CompanyConfig.vue`
        ),
  },
];
