export default {
  state: {
    showOverlay: false,
  },
  getters: {},
  mutations: {
    SET_OVERLAY_VISIBLILITY(state, value) {
      state.showOverlay = value;
    },
  },
  actions: {
    handleOverlay({ commit }, value) {
      commit('SET_OVERLAY_VISIBLILITY', value);
    },
  },
};
