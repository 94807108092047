export default () => {
  const intercom_container = document.getElementById('intercom-container');
  if (typeof intercom_container !== 'undefined' && intercom_container !== null) {
    intercom_container.style.display = 'none';
  } else {
    const intercom = document.getElementsByClassName('intercom-launcher')[0];
    if (typeof intercom !== 'undefined' && intercom !== null) {
      intercom.style.display = 'none';
    }
  }
};
