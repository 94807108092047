import axios from 'axios';
import { authUserService } from '@/services/auth-user';
import { detectBrowserLocale } from '../../engine/framework/modules/i18n';
import { getHeader, professionalsUrl } from '@/config/config';
import router from '@/router';
import { baseNameRoutes } from '@/router/paths';
import { getSpecialtiesAsStrings } from '@/utils/professional-utils';

export default {
  state: {
    locale: authUserService.getUserLocale() || detectBrowserLocale(),
    slug: '',
    content: null,
    error: null,
    showModal: {
      map: false,
      phone: false,
      info: false,
      service: false,
    },
  },

  getters: {
    getLocale({ locale }) {
      return locale;
    },

    getHeaderContent({ content }) {
      if (!content) {
        return null;
      }

      const { professional, address, phone } = content;
      return {
        image: professional.image,
        name: professional.name,
        specialties: getSpecialtiesAsStrings(professional.specialties),
        collegiateNumber: professional.collegiateNumber,
        address,
        phone,
        description: professional.biography,
      };
    },

    getContentSections({ content }) {
      if (!content) {
        return null;
      }

      const { sections } = content;
      return sections;
    },

    getServices({ content }) {
      if (!content) {
        return null;
      }

      const { services } = content;
      return services;
    },

    getModalVisibility({ showModal }) {
      return showModal;
    },
  },

  actions: {
    setSlug({ commit }, path) {
      const slug = path.split('/').pop();
      commit('SET_LANDING_SLUG', slug);
    },

    async fetchLandingContent({ commit, state: { slug, locale } }) {
      commit('SET_LANDING_API_ERROR', null);

      try {
        const { data } = await axios.get(`${professionalsUrl}/landing/${slug}`, {
          params: { locale },
          headers: getHeader(),
        });

        commit('SET_LANDING_CONTENT', data);
      } catch (e) {
        commit('SET_LANDING_API_ERROR', e);
        console.info(`Error fetching data in Vuex 'fetchLandingContent' action. ${e}`);
        router.push({ name: baseNameRoutes.notFound });
      }
    },

    setLocale({ commit }, locale) {
      commit('SET_LANDING_LOCALE', locale);
    },

    setModalVisibility({ commit }, item) {
      commit('SET_MODAL_VISIBILITY', item);
    },
  },

  mutations: {
    SET_LANDING_SLUG(state, slug) {
      state.slug = slug;
    },

    SET_LANDING_CONTENT(state, content) {
      state.content = content;
    },

    SET_LANDING_API_ERROR(state, error) {
      state.error = error;
    },

    SET_LANDING_LOCALE(state, locale) {
      state.locale = locale;
    },

    SET_MODAL_VISIBILITY(state, item) {
      state.showModal[item] = !state.showModal[item];
    },
  },
};
