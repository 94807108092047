import Vue from 'vue';
import Vuex from 'vuex';
import { moduleReducer } from '../moduleUtils';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const storeConfig = moduleReducer(
  require.context('@/store', false, /\.js$/),

  (carry, name, mod) =>
    name === 'index'
      ? { ...carry, ...mod }
      : { ...carry, modules: { ...carry.modules, [name]: { namespaced: true, ...mod } } },

  { modules: {}, state: {}, mutations: {}, actions: {} }
);

const store = new Vuex.Store({
  ...storeConfig,
  plugins: [
    createPersistedState({
      getState: key => JSON.parse(window.localStorage.getItem(key)),
      setState: (key, state) => window.localStorage.setItem(key, JSON.stringify(state)),
    }),
  ],
});

if (typeof storeConfig.actions.init !== 'undefined') {
  store.dispatch('init');
}

export default store;
