export default () => {
  if (typeof localStorage !== 'undefined') {
    const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));
    const user_config =
      authUser !== null && window.localStorage.getItem('auth_ehealth') !== null
        ? JSON.parse(window.localStorage.getItem('auth_ehealth'))
        : false;
    if (typeof user_config.erecipe !== 'undefined' && user_config.erecipe) {
      return true;
    }
  }

  return false;
};
