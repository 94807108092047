export const A_MINUTE_IN_SECONDS = 1000 * 60;
export const A_SECOND_IN_MILLISECOND = 1000;
export const INACTIVE_USER_TIME_THRESHOLD_IN_MINUTES = 60;
export const INACTIVE_USER_TIME_THRESHOLD_IN_SECONDS = INACTIVE_USER_TIME_THRESHOLD_IN_MINUTES * A_MINUTE_IN_SECONDS;
export const INACTIVE_USER_TIME_THRESHOLD_IN_MILLISECONDS = INACTIVE_USER_TIME_THRESHOLD_IN_SECONDS * A_SECOND_IN_MILLISECOND;
export const USER_ACTIVITY_THROTTLER_TIME_IN_SECONDS = 3000;

export const VALUE_IS_INVALID = 'The value passed is invalid';
export const USER_INVALID_ERROR = 'User passed is invalid';
export const USER_NOT_LOGIN_ERROR = 'User is not logged';
export const TWO_FA_AUTH_USER_KEY = '2fa_enabled';

export const ROL = {
  ADMIN: 1,
  PROFESSIONAL: 2,
  RECEPTIONIST: 3,
  RRHH: 4,
  PATIENT: 5,
};
