<template>
  <div>
    <span>{{ text }}</span>
    <DialogActions :actions="actions" />
  </div>
</template>

<script>
import Confirmable from 'vuedl/src/mixins/confirmable';
import DialogActions from './DialogActions.vue';

export default {
  layout: 'snackbar',
  components: {
    DialogActions,
  },
  mixins: [Confirmable],
};
</script>
