<template>
  <svg class="icon" :width="getIconWidth()" :height="getIconWidth()">
    <use :xlink:href="iconPath + type" />
  </svg>
</template>

<script>
export default {
  props: {
    type: String,
    width: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  data() {
    return {
      iconPath: '/static/icon/icons-sprite.svg#',
      defaultWidth: 24,
    };
  },

  methods: {
    getIconWidth() {
      if (this.width > 0) {
        return this.width;
      }

      return this.defaultWidth;
    },
  },
};
</script>

<style>
.icon {
  cursor: pointer;
}
</style>
