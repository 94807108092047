<template>
  <!-- eslint-disable vue/no-v-html -->
  <v-dialog
    v-model="show"
    scrollable
    max-width="480"
    class="about-us-warning"
    content-class="about-us-warning"
    @click:outside="handleCancel"
    @keydown.esc="handleCancel"
    @keydown.enter="handleCancel"
  >
    <v-btn v-tooltip="$t('common.close')" class="about-us-warning__close-button" icon @click="handleCancel">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-card class="px-6 py-6">
      <!-- title -->
      <v-card-title class="text-h6 font-weight-regular lighten-2 mb-1 px-0 justify-center flex-column">
        <span class="about-us-warning__icon mb-4">
          <v-icon color="white" size="40">mdi-shield-lock-outline</v-icon>
        </span>
        <span>{{ $t('common.aboutus') }}</span>
      </v-card-title>
      <!-- body -->
      <span class="text-body-1 text--secondary">{{
          $t('aboutus.provider')
        }}</span>
      <span class="text-body-1 text--secondary">{{
        $t('aboutus.version', {
          version: `${aboutUsVersion}`,
        })
      }}</span>

      <p class="text-body-1 text--secondary mt-4" v-html="$t('aboutus.firstText')" />
      <p class="text-body-1 text--secondary" v-html="$t('aboutus.secondText')" />

      <a href="https://cgcom.es/plataformas_receta_homologadas/sist-prescripcion/docline" class="text-body-1 text--secondary">
        {{ $t('aboutus.prescriptionSystem') }}
      </a>
      <a href="https://cgcom.es/plataformas_receta_homologadas/repositorio/docline" class="text-body-1 text--secondary">
        {{ $t('aboutus.prescriptionRepository') }}
      </a>

      <!-- actions -->
      <v-card-actions class="mt-12 justify-center">
        <v-btn color="warning" light depressed @click="handleCancel">{{ $t('aboutus.accept') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'AboutUsDialog',

  components: {},

  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },

  data: function() {
    return {
      aboutUsDate: '13/03/2023',
    };
  },

  computed: {
    aboutUsVersion() {
      return process.env.ENV_CURRENT.version
        .split('.')
        .slice(0, -1)
        .join('.');
    },
  },

  methods: {
    ...mapMutations({
      setTwoFaDialogVisibility: 'layout/setTwoFaDialogVisibility',
      setAboutUsDialogVisibility: 'layout/setAboutUsDialogVisibility',
    }),

    handleClick() {
      this.$emit('handle-click');
      this.setAboutUsDialogVisibility(false);
    },

    handleCancel() {
      this.setAboutUsDialogVisibility(false);
    },
  },
};
</script>

<style lang="scss">
.about-us-warning {
  position: relative;

  &__close-button {
    position: absolute !important;
    top: rem(4);
    right: rem(4);
    z-index: 9;
  }

  &__icon {
    height: rem(68);
    width: rem(68);
    border-radius: 50%;
    background-color: #ff9800;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
