import { getKeyByValue } from '@/utils';
import environment from '@/environment';
import menu from '@/config/menu';
import getPermissionSettings from './getPermissionSettings';

function hasPermissionBillingModule() {
  const billingModuleProfessional =
    window.localStorage.getItem('auth_ehealth') &&
    (JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 2 ||
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3) &&
    JSON.parse(window.localStorage.getItem('auth_ehealth'))['stripeConfigured'] === 1 &&
    ((JSON.parse(window.localStorage.getItem('auth_ehealth'))['nombreEmpresa'] !== '' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['is_collective'] === 1) ||
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['nombreEmpresa'] === '');
  const billingModuleAdmin =
    window.localStorage.getItem('auth_ehealth') &&
    JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 1 &&
    JSON.parse(window.localStorage.getItem('auth_ehealth'))['nombreEmpresa'] !== '' &&
    JSON.parse(window.localStorage.getItem('auth_ehealth'))['is_collective'] === 0;
  return billingModuleProfessional || billingModuleAdmin;
}

const menuPermissions = getPermissionSettings('menuName');

const addCustomizations = (items, customizations) => {
  return items.map(item => {
    if (item.divider) {
      return item;
    }

    if (item.items) {
      item.items = addCustomizations(item.items, customizations);
    }

    return Object.keys(customizations).reduce((obj, key) => {
      if (obj.name === key) {
        obj.title = customizations[key].title || obj.title;
        obj.icon = customizations[key].icon || obj.icon;
      }

      return obj;
    }, item);
  });
};

const parseItems = (items, permissions) => {
  const paymentModule =
    window.localStorage.getItem('auth_ehealth') &&
    (typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['payment_module'] === 'undefined' ||
      (typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['payment_module'] !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['payment_module'] === true));
  const messageModule =
    window.localStorage.getItem('auth_ehealth') &&
    typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['servicios_activos'] !== 'undefined' &&
    JSON.parse(window.localStorage.getItem('auth_ehealth'))['servicios_activos']['mensajeria'] === 1;
  const hiredPackModule =
    window.localStorage.getItem('auth_ehealth') &&
    typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['servicios_activos'] !== 'undefined' &&
    JSON.parse(window.localStorage.getItem('auth_ehealth'))['servicios_activos']['packs_contratados'] > 0;
  const packModule =
    window.localStorage.getItem('auth_ehealth') &&
    typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['servicios_activos'] !== 'undefined' &&
    JSON.parse(window.localStorage.getItem('auth_ehealth'))['servicios_activos']['packs'] === 1;
  const hiredSubscription_module =
    window.localStorage.getItem('auth_ehealth') &&
    typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['servicios_activos'] !== 'undefined' &&
    JSON.parse(window.localStorage.getItem('auth_ehealth'))['servicios_activos']['suscripciones_contratados'] > 0;
  return items.reduce((carry, item) => {
    if (!paymentModule && item.title === 'menu.tarifas') {
      item.title = 'menu.servicios';
    }

    if (
      (!messageModule && item.title === 'menu.messaging') ||
      (!hiredPackModule && item.title === 'menu.packcontratados') ||
      (!hiredSubscription_module && item.title === 'suscripciones.suscripcion_contratados') ||
      (!packModule && item.title === 'menu.packs') ||
      ((!paymentModule || !hasPermissionBillingModule()) && item.title === 'menu.facturacion')
    ) {
      return carry;
    }

    if (item.divider) {
      return [...carry, item];
    }

    if (item.items) {
      item = {
        ...item,
        items: parseItems(item.items, permissions),
      };
    }

    const permissionKey = getKeyByValue(menuPermissions, item.name);

    return typeof permissions[permissionKey] === 'undefined' || permissions[permissionKey] === true
      ? [...carry, item]
      : carry;
  }, []);
};

export default permissions => {
  const menuItems =
    environment.menu && Object.keys(environment.menu).length > 0
      ? addCustomizations([...menu], environment.menu)
      : [...menu];

  return parseItems(menuItems, permissions);
};
