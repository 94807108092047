export default (fields, { prop, event } = { prop: 'value', event: 'input' }) =>
  fields.reduce(
    (obj, field) => ({
      ...obj,

      [field]: {
        get() {
          return this[prop][field];
        },

        set(value) {
          this.$emit(event, { ...this[prop], [field]: value });
        },
      },
    }),
    {}
  );
