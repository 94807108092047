<template>
  <v-dialog
    v-model="modalValue"
    scrollable
    :persistent="needsToBeAccepted"
    :max-width="'700px'"
    @click:outside="close"
  >
    <v-card class="dialog_conditions">
      <v-toolbar flat>
        <v-toolbar-title>{{ $t('common.condiciones') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <Conditions :language="$i18n.locale" @loadedsuccessfully="setDownloadAvailable"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!needsToBeAccepted"
          color="primary"
          text
          @click.native="close"
        >{{ $t('common.close') }}</v-btn>

        <v-btn v-if="needsToBeAccepted" :disabled="accepting" color="primary" text @click.native="reject">
          {{ $t('common.cancel') }}
        </v-btn>

        <v-btn
          :disabled="!downloadContent"
          color="primary"
          text
          @click.native="download"
        >{{ $t('common.download') }}</v-btn>

        <v-btn
          v-if="needsToBeAccepted"
          :loading="accepting"
          :disabled="accepting"
          color="primary"
          text
          @click.native="accept"
        >{{ $t('common.aceptar') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Conditions from "@/components/ehealth/header/Conditions.vue";
import {mapActions, mapState} from "vuex";
import {initializeHelpHero} from "@/services/helpHero";
import {acceptConditionsUrl, getHeader} from "@/config/config";
window.jsPDF = window.jspdf.jsPDF;

function generatePdf(html) {
  const jsPdf = new jsPDF('p', 'pt', 'letter');

  html.innerHTML = html.innerHTML.replaceAll(/<br>/ig, '<p><br/></p>').replaceAll("<b>", "<h2>").replaceAll("</b>", "</h2>");

  const opt = {
    callback: function (jsPdf) {
      window.open(jsPdf.output('bloburl'));
    },
    margin: [72, 72, 72, 72],
    autoPaging: 'text',
    html2canvas: {
      allowTaint: true,
      dpi: 300,
      letterRendering: true,
      logging: false,
      scale: .8
    }
  };

  jsPdf.html(html, opt);
}

export default {
  name: 'TermsAndConditionsModal',

  components: {Conditions},

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    needsToBeAccepted: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      accepting: false,
      downloadContent: null,
      controlValue: {
        showLayout: false,
        floatLayout: true,
        enableDownload: true,
        previewModal: true,
        paginateElementsByHeight: 1400,
        manualPagination: false,
        filename: 'test',
        pdfQuality: 2,
        pdfFormat: 'a4',
        pdfOrientation: 'portrait',
        pdfContentWidth: '800px'
      }
    };
  },

  computed: {
    ...mapState({
      appHelpHero: state => state.app.helpHero
    }),
    modalValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    }
  },

  methods: {
    ...mapActions('app', ['loadConfigFromBrand']),
    ...mapActions('layout', ['handleConditionsModal', 'handleConditionsNeedsToBeAccepted']),

    close() {
      this.handleConditionsModal(false);
    },

    setDownloadAvailable(content) {
      this.downloadContent = content;
    },

    download() {
      this.close();

      this.$nextTick(async () => {
        const element = document.getElementById("conditions-to-pdf")
        generatePdf(element);
      });
    },

    reject() {
      this.handleConditionsNeedsToBeAccepted(false);
      this.handleConditionsModal(false);
      localStorage.removeItem('auth_ehealth');
      window.getApp.$emit('APP_LOGOUT');
      this.loadConfigFromBrand();
    },

    async accept() {
      const userId = JSON.parse(window.localStorage.getItem('auth_ehealth')).id;
      this.accepting = true;

      try {
        const response = await this.$http.post(acceptConditionsUrl, { user_id: userId }, { headers: getHeader() });
        if (response.status === 200 && response.data) {
          this.handleConditionsNeedsToBeAccepted(false);
          this.handleConditionsModal(false);

          initializeHelpHero({ config: this.appHelpHero, user: this.authUser, forceStartTour: true });
        }
      } finally {
        this.accepting = false;
      }
    },
  }
};
</script>

<style lang="scss">
  .dialog_conditions {
    max-height: 380px;
  }
</style>
