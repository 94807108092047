import environment from '@/environment';

export default () => {
  const autogenerateEmailEnabled = environment?.forms?.autogenerateEmail?.enabled
    ? environment?.forms?.autogenerateEmail?.enabled
    : false;
  const emailDomain = environment?.forms?.autogenerateEmail?.domain
    ? environment?.forms?.autogenerateEmail?.domain
    : false;

  if (!autogenerateEmailEnabled || !emailDomain) {
    return '';
  }

  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });

  return `patient-${uuid}${emailDomain}`;
};
