<template>
  <v-app id="appRoot" :class="ieClass">
    <modal-notify-close-session v-if="askModalUser" :logout="logout" :cancel-logout="cancelLogout" />
    <two-fa-configuration-warning-dialog :show="showFaConfigModal" @handle-click="handleTwoFaDialogClick" />
    <AboutUsDialog :show="showAboutUsModal" />
    <TermsAndConditionsModal v-model="showConditionsModal" :needs-to-be-accepted="conditionsNeedsToBeAccepted" />
    <template v-if="$route.meta.public">
      <router-view />
    </template>

    <layout
      v-if="authUser && !$route.meta.public"
      :show-docline-original-brand="showDoclineOriginalBrand"
      :show-footer-app="showFooterApp"
    >
      <app-main-banner
        v-if="isProfessionalRole && !twoFaEnabled && useNewAccountPage && repHomologation"
        icon="mdi-shield-lock-outline"
        icon-color="white"
        background-color="#ff9800"
        :text="$t('2fa.bannerText')"
        text-color="white"
        :link="$t('2fa.bannerLink')"
        :link-text="$t('recipe.more_information')"
        link-target="_blank"
        :button-text="$t('2fa.setup')"
        :dismissible="$vuetify.breakpoint.mobile"
        @click:button="handleBannerClick"
      />

      <app-main-banner
        v-else-if="isProfessionalRole && !twoFaEnabled"
        icon="mdi-shield-lock-outline"
        icon-color="white"
        background-color="#ff9800"
        :text="$t('2fa.bannerTextPreviousRep')"
        text-color="white"
        :link="$t('2fa.bannerLinkPreviousRep')"
        :link-text="$t('2fa.bannerLinkTextPreviousRep')"
        link-target="_blank"
        :button-text="$t('2fa.setup')"
        :dismissible="$vuetify.breakpoint.mobile"
        @click:button="handleBannerClick"
      />
      <router-view />
    </layout>

    <alert v-if="showAlert" />
  </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { baseNameRoutes } from './router/paths';
import {
  authUserService,
  INACTIVE_USER_TIME_THRESHOLD_IN_SECONDS,
  showHelpScout,
  USER_ACTIVITY_THROTTLER_TIME_IN_SECONDS,
} from '@/services/auth-user';
import intercom from '@/services/intercom';
import { clearString } from '@/utils';
import { ModalNotifyCloseSession } from '@/common/components';
import Layout from '@/components/Layout/Layout.vue';
import Alert from '@/components/Alert.vue';
import AppEvents from './event';
import AppMainBanner from '@/components/Layout/AppMainBanner';
import TwoFaConfigurationWarningDialog from '@/components/2fa/TwoFaConfigurationWarningDialog';
import AboutUsDialog from '@/components/aboutus/AboutUsDialog.vue';
import { hideContainer, showContainer } from '@/services/helpScout';
import { medicaltestsNamePaths } from '@/router/routes';
import TermsAndConditionsModal from "@/components/TermsAndConditions/components/TermsAndConditionsModal.vue";
import environment from "@/environment";

const hideHelpPages = [
  medicaltestsNamePaths.createMedicalTest,
  medicaltestsNamePaths.editMedicalTest,
  medicaltestsNamePaths.createRxProtocol,
  medicaltestsNamePaths.editRxProtocol,
  medicaltestsNamePaths.createNomenclator,
  medicaltestsNamePaths.editNomenclator,
  medicaltestsNamePaths.requestAnalyticalTest,
  medicaltestsNamePaths.requestRxTest,
  medicaltestsNamePaths.requestOtherTest,
];

export default {
  name: 'App',

  components: {
    TermsAndConditionsModal,
    AppMainBanner,
    Layout,
    Alert,
    ModalNotifyCloseSession,
    TwoFaConfigurationWarningDialog,
    AboutUsDialog,
  },

  metaInfo() {
    return {
      title: this.appDisplayName,
      link: [{ rel: 'icon', href: this.appFavicon }],
    };
  },

  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      isUserInactive: false,
      askModalUser: false,
      userActivityThrottlerTimeout: null,
      userActivityTimeout: null,
      helpScoutInitialized: false,
    };
  },

  computed: {
    ...mapState({
      showAlert: state => state.layout.alert.show,
      appDisplayName: state => state.app.displayName,
      appFavicon: state => state.app.images.favicon,
      appShowHelpScout: state => state.app.helpScout.enabled,
      appIntercomHide: state => state.app.intercom.hide,
      appIntercomId: state => state.app.intercom.id,
      appIntercomUserMaps: state => state.app.intercom.userMaps,
      appName: state => state.app.name,
      appPattern: state => state.app.pattern,
      appThemePrimary: state => state.app.styles.themePrimary,
      appThemeSecondary: state => state.app.styles.themeSecondary,
      showFaConfigModal: state => state.layout.twoFaConfigDialogIsVisible,
      showAboutUsModal: state => state.layout.aboutUsDialogIsVisible,
      showConditionsModal: state => state.layout.conditionsModalIsVisible,
      conditionsNeedsToBeAccepted: state => state.layout.conditionsNeedsToBeAccepted,
    }),

    ...mapGetters({
      isProfessionalRole: 'account/isProfessionalRole',
      twoFaEnabled: 'account/twoFaEnabled',
      useNewAccountPage: 'app/getFeatureNewAccountPageFlag',
      repHomologation: 'app/getFeatureRepHomologationFlag',
    }),

    showDoclineOriginalBrand() {
      return this.$store.getters['app/showDoclineOriginalBrand'];
    },

    showFooterApp() {
      return (
        this.$store.getters['app/showPoweredByDocline'] &&
        ![
          'Mensajeria',
          'MessagingDetails',
          'MessagingList',
          'MessagingView',
          'MessagingViewTest',
          'myMessages',
        ].includes(this.$route.name)
      );
    },

    ieClass() {
      const ua = navigator.userAgent;
      const isInternetExplorer = ua.indexOf('MSIE') > -1 || ua.indexOf('Trident') > -1;
      if (isInternetExplorer || (!!window.MSInputMethodContext && !!document.documentMode)) {
        return 'ie';
      } else {
        return '';
      }
    },
  },

  watch: {
    // TODO: This watch is temporally until the user logged will be moved to the store
    $route(to) {
      this.authUser = authUserService.get();

      if (hideHelpPages.includes(to.name)) {
        hideContainer();
      } else {
        if (!this.helpScoutInitialized && this.appShowHelpScout && this.authUser) {
          showHelpScout(this.authUser, this.appPattern);
          this.helpScoutInitialized = true;
        } else {
          showContainer();
        }
      }
    },

    '$i18n.locale': function(val) {
      localStorage.setItem('selected-locale', val)
    },

    appThemePrimary(value) {
      if (value !== null) {
        this.$vuetify.theme.themes.light.primary = value;
        document.querySelector(':root').style.setProperty('--v-primary-base', value);
        document.querySelector(':root').style.setProperty('--v-anchor-base', value);
      }
    },

    appThemeSecondary(value) {
      if (value !== null) {
        this.$vuetify.theme.themes.light.secondary = value;
        document.querySelector(':root').style.setProperty('--v-secondary-base', value);
      }
    },
  },

  created() {
    //HARCODED: Correct this Styles for IE11
    const style = document.createElement('style');
    style.innerHTML =
      `
      .v-application.ie .primary {
        background-color: ` +
      this.appThemePrimary +
      ` !important;
      border-color: ` +
      this.appThemePrimary +
      ` !important;
      }
      .v-application.ie .v-btn::before {
        background: none !important;
      }
      `;
    document.head.appendChild(style);
    //-----------

    this.init();
    this.listenEvents();
    this.loadCustomI18n();
  },

  beforeMount() {
    this.activateActivityTracker();
  },

  mounted() {
    this.loadHelpServices();
    Object.defineProperty(Element.prototype, 'documentOffsetTop', {
      get: function() {
        return this.offsetTop + (this.offsetParent ? this.offsetParent.documentOffsetTop : 0);
      },
      configurable: true,
    });
    Object.defineProperty(Element.prototype, 'documentOffsetLeft', {
      get: function() {
        return this.offsetLeft + (this.offsetParent ? this.offsetParent.documentOffsetLeft : 0);
      },
      configurable: true,
    });
    document.querySelector('.v-application').addEventListener('mousemove', e => {
      if (!e) {
        return;
      }
      const cList = e.target.classList;
      if (cList.contains('v-btn') && cList.contains('primary')) {
        const x = e.pageX - e.target.documentOffsetLeft;
        const y = e.pageY - e.target.documentOffsetTop;
        e.target.style.setProperty('--x', `${x}px`);
        e.target.style.setProperty('--y', `${y}px`);
      }
    });
  },

  beforeDestroy() {
    this.deactivateActivityTracker();
  },

  methods: {
    ...mapActions('app', ['loadConfigFromBrand', 'getPaymentSettings']),

    activateActivityTracker() {
      window.addEventListener('mousemove', this.userActivityThrottler);
      window.addEventListener('scroll', this.userActivityThrottler);
      window.addEventListener('keydown', this.userActivityThrottler);
      window.addEventListener('resize', this.userActivityThrottler);
    },

    deactivateActivityTracker() {
      window.removeEventListener('mousemove', this.userActivityThrottler);
      window.removeEventListener('scroll', this.userActivityThrottler);
      window.removeEventListener('keydown', this.userActivityThrottler);
      window.removeEventListener('resize', this.userActivityThrottler);

      clearTimeout(this.userActivityTimeout);
      clearTimeout(this.userActivityThrottlerTimeout);
    },

    resetUserActivityTimeout() {
      clearTimeout(this.userActivityTimeout);

      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler();
      }, USER_ACTIVITY_THROTTLER_TIME_IN_SECONDS);
    },

    userActivityThrottler() {
      if (this.isUserInactive) {
        this.isUserInactive = false;
        authUserService.updateLastActivityTime();
      }

      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, INACTIVE_USER_TIME_THRESHOLD_IN_SECONDS);
      }
    },

    inactiveUserAction() {
      if (!this.$route.meta?.public) {
        this.isUserInactive = true;
        if (authUserService.get()) {
          this.askModalUser = true;
        }
      }
    },
    /**
     * The user decides to cancel the session
     */
    cancelLogout() {
      this.askModalUser = false;
      this.userActivityThrottler();
    },

    /**
     * User decides to close the session
     */
    logout() {
      this.isUserInactive = false;
      this.askModalUser = false;

      authUserService.remove();
      this.authUser = null;
      if (environment.isKeycloakIntegration) {
        this.$keycloak.logoutFn();
      }
      this.$store.dispatch('app/loadConfigFromBrand');
      this.$router.replace({ name: baseNameRoutes.login });
    },

    init() {
      if (this.appThemePrimary !== null) {
        this.$vuetify.theme.themes.light.primary = this.appThemePrimary;
        document.querySelector(':root').style.setProperty('--v-primary-base', this.appThemePrimary);
        document.querySelector(':root').style.setProperty('--v-anchor-base', this.appThemePrimary);
      }

      if (this.appThemeSecondary !== null) {
        this.$vuetify.theme.themes.light.secondary = this.appThemeSecondary;
        document.querySelector(':root').style.setProperty('--v-secondary-base', this.appThemeSecondary);
      }
    },

    listenEvents() {
      AppEvents.forEach(item => {
        this.$on(item.name, item.callback);
      });

      window.getApp = this;
    },

    loadHelpServices() {
      const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));

      let user = {};
      let showIntercom = false;
      if (authUser?.id) {
        user = { ...authUser };
        const name_env = this.appName;
        const user_id = user.id;
        user.id = this.appName + '_' + user.id;
        if (
          name_env.toLowerCase() === 'uniteco' &&
          typeof user.nombreEmpresa !== 'undefined' &&
          user.nombreEmpresa !== ''
        ) {
          user.id = clearString(user.nombreEmpresa) + '_' + user_id;
        }
        showIntercom = true;
      }

      if (this.appIntercomHide !== true && showIntercom) {
        !authUser?.isPatient && intercom(this.appIntercomId, this.appIntercomUserMaps, user);
      }
    },

    loadCustomI18n() {
      const user = JSON.parse(window.localStorage.getItem('auth_ehealth'));
      if (this.has_i18n_override(this.appPattern)) {
        this.load_i18n_message(this.appPattern);
      }

      if (typeof user !== 'undefined' && user !== null && user.is_health_personnel === 0) {
        this.load_i18n_message('noHealthPersonnel');
      }
    },

    load_i18n_message: function(folder) {
      const locale = this.$i18n.locale;
      const messages = this.$i18n.messages[locale];
      try {
        const trans_custom = require('@/core/i18n/' + folder + '/' + locale);
        const t = trans_custom.default;
        Object.keys(t).forEach(item => {
          if (typeof t[item] === 'object') {
            Object.keys(t[item]).forEach(trans => {
              if (messages[item]) {
                messages[item][trans] = t[item][trans];
              }
            });
          }
        });
        this.$i18n.setLocaleMessage(locale, messages);
      } catch (e) {
        this.$log.error(e);
      }
    },

    has_i18n_override: function(folder) {
      try {
        const locale = this.$i18n.locale;
        require.resolve('@/core/i18n/' + folder + '/' + locale);
        return true;
      } catch (e) {
        return false;
      }
    },

    handleBannerClick() {
      this.$router.push({ name: baseNameRoutes.account2FA });
    },

    handleTwoFaDialogClick() {
      this.$router.push({ name: baseNameRoutes.account2FA });
    },

    hideHelp() {
      this.$nextTick(() => {
        hideContainer();
      });
    },

    showHelp() {
      this.$nextTick(() => {
        showContainer();
      });
    },
  },
};
</script>

<style lang="scss">
.is-invisible {
  visibility: hidden;
}

.icon-svg {
  &__container {
    display: grid;
    place-content: center;
  }
}

.cursor-pointer {
  cursor: pointer;
}
</style>
