import Vue from 'vue';
import minifyTheme from 'minify-css-string';
import Vuetify, {
  VMain,
  VSnackbar,
  VBtn,
  VIcon,
  VLayout,
  VDialog,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VSpacer,
  VToolbar,
  VToolbarTitle,
  VAlert,
  VColorPicker,
  VSelect,
  VTextField,
  VFlex,
  VChip,
} from 'vuetify/lib';

VBtn.options.props.ripple.default = false;

Vue.use(Vuetify, {
  components: {
    VMain,
    VSnackbar,
    VBtn,
    VIcon,
    VLayout,
    VDialog,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VSpacer,
    VToolbar,
    VToolbarTitle,
    VAlert,
    VColorPicker,
    VSelect,
    VTextField,
    VFlex,
    VChip,
  },
});

export default new Vuetify({
  global: {
    ripples: false,
  },
  buttons: {
    capitalize: false,
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#10587E',
        secondary: '#10587E',
      },
      dark: {
        primary: '#10587E',
        secondary: '#10587E',
      },
    },
    options: {
      minifyTheme,
      customProperties: true,
    },
  },
});

/*

  // theme: {
  //   primary: colors.indigo.base, // #E53935
  //   secondary: colors.indigo.lighten4, // #FFCDD2
  //   accent: colors.indigo.base // #3F51B5
  // },
  options: {
    themeVariations: ["primary", "secondary", "accent"],
    extra: {
      mainToolbar: {
        color: "primary"
      },
      sideToolbar: {},
      sideNav: "primary",
      mainNav: "primary lighten-1",
      bodyBg: ""
    }
  }

  */
