export default {
  card_number: 'Card Number',
  or: 'or',

  account: {
    chooseImage: 'Choose your profile image',
    chooseImageText: 'Click and drag to reposition. Use the trackpad or your mouse to zoom in or out.',
    cancel: 'Cancel',
    continue: 'Continue',
    noImage: "You haven't chosen any profile image.",
    fileNotAllowed: 'Unable to upload the file. This file type is not supported.',
    fileTooBig: 'The file is too big. {fileSize} maximum file size.',
    uploadError: 'Ops! Something went wrong. Please, try again.',
    idPassport: 'ID / Passport',
    biography: 'Biography',
    editBio: 'Edit your bio in {lang}',
    successEdit: 'Your profile has been updated.',
    security: 'Security',
    passwordHint: 'A strong password will prevent your account from being easily hacked',
    change: 'Change',
    marketplaceName: 'Marketplace name',
    terms_and_conditions: 'Terms and conditions',
    terms_and_conditions_documentation_title: 'Documentation',
    terms_and_conditions_documentation_description: 'Review and/or download the contents of this block in PDF format. Whenever it is updated, you will be informed in advance.',
    terms_and_conditions_see: 'See',
  },
  '2fa': {
    title: 'Two-factor Authenticator',
    hint:
      'Add an extra layer of security to your account with two-factor authentication. Once you enable it, the code generated will be required to log in.',
    setup: 'Activate 2FA',
    disable: 'Disable 2FA',
    introText:
      "Two-factor authentication is an extra layer of security for your account designed to ensure that <strong>you're the only person who can access your account,</strong> even if someone knows your password.",
    downloadApp: 'Download the app',
    scanQr: 'Scan the QR code',
    enterCode: 'Enter the 2FA code',
    downloadAppText:
      'Install the<strong> Google Authenticator app</strong> on your phone or tablet to configure double factor authentication into your account.',
    alreadyInstalled: "I've already downloaded it",
    alreadyScanned: "I've already scanned it",
    qrText: 'Open the Google Authenticator app, follow the instructions and scan the QR code displayed on the screen.',
    qrTextMobile: 'Open the Google Authenticator app, follow the instructions and enter the following key.',
    qrHint: "If you can't scan the QR code, please enter the following key manually.",
    checkCodeTitle: 'Enter the 6-digit code',
    invalidCodeError: 'The entered code is incorrect.',
    errorOnSetup: "We can't enable double factor authentication right now, please try again later.",
    enterCodePromptText: 'Enter the 6-digit code displayed in the Google Authenticator app.',
    disablePromptText:
      'Two-factor authentication is <span class = "green - text font-weight-medium"> enabled </span>for your account. To disable it, enter the code displayed on the Google Authenticator app.',
    verify: 'Verify',
    setupFinishedTitle: 'Two-factor authentication enabled.',
    setupFinishedText: 'The two-factor authentication is enabled in your account.',
    downloadCodeButton: 'Download the backup key code.',
    downloadCodeHint:
      'This code lets you restore access to your account in case you change or lose your phone or accidentally deleted Google Authenticator app. It’s very important to keep the key safe.',
    backToMyAccount: 'Back to my account',
    disableOk: 'Two-factor authentication is disabled.',
    disableKo: 'Oops! Something went wrong. Unable to disable the two-factor authentication. Please, try again.',
    googleAppName: 'Google Authenticator',
    doneIt: 'Done',
    copyPasteKey: 'Copy and paste the code',
    smsLinkText: 'Send me an SMS with the app download link',
    smsModalTitle: 'Mobile phone',
    smsModalText:
      'Enter the phone number where you want to receive the downloadable link for the Google Authenticator app.',
    smsModalButton: 'Send the link',
    smsSent: 'SMS sent to the phone number {phone}',
    smsSendingError: 'An error occurred while sending the SMS to the number {phone}',
    smsMaxAllowed: 'Sorry, it is not possible to use SMS code verification more than 3 times a day.',
    storeRedirectWarning:
      "It's not possible to redirect you to the Google Authenticator page. Please contact our Customer Care Team.",
    storeRedirectWarningTitle: "We're sorry",
    loginHelpLink: 'https://help.docline.es/article/242-lost2fa',
    loginHelpLinkText: "I can't log in",
    bannerText: 'Configure the two-factor authentication in your account to be able to issue electronic prescriptions.',
    bannerLink: 'https://help.docline.es/article/238-whatis2fa',
    configureDialogTextUpper:
      'To continue issuing Electronic Prescriptions from your account, you need to activate the double factor authentication (2FA). 2FA code required to log in.',
    configureDialogTextLower: 'If you need help setting it up, {link}',
    configureDialogTextLink: 'our help center.',
    configureDialogLink: 'https://help.docline.es/article/241-configure2fa',
    verifyCodePrompText: 'Introduce the 6 digit-code displays on the Google Authenticator app',
    bannerTextPreviousRep: 'By the end of this month, will introduce an extra layer of security into your account.',
    bannerLinkTextPreviousRep: 'What is 2FA and how to configure it?',
    bannerLinkPreviousRep: 'https://help.docline.es/article/238-whatis2fa',
  },
  agenda: {
    hoy: 'Today',
    lunes: 'Monday',
    martes: 'Tuesday',
    miercoles: 'Wednesday',
    jueves: 'Thursday',
    viernes: 'Friday',
    sabado: 'Saturday',
    domingo: 'Sunday',
    lunes_redu: 'Mon',
    martes_redu: 'Tue',
    miercoles_redu: 'Wed',
    jueves_redu: 'Thu',
    viernes_redu: 'Fri',
    sabado_redu: 'Sat',
    domingo_redu: 'Sun',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    horainicio: 'Start Time',
    horafin: 'End Time',
    configuracion: 'Schedule Settings',
    disponibilidad: 'Availability Calendar',
    nodisponible: 'Unavailable days',
    disponible: 'Available Days',
    sin_disponibles: 'You have not set any {tipo} for the next days',
    nuevo_nodisponible: 'Mark day(s) as unavailable',
    nuevo_disponible: 'Create Available',
    editar_nodisponible: 'Edit Not Available',
    editar_disponible: 'Edit Available',
    diacompleto: 'Complete day',
    delete_nodisponible: 'Delete Day Not Available',
    delete_disponible: 'Delete Available Day',
    confirm_delete_nodisponible: 'The day will become available. Do you wish to continue?',
    confirm_delete_disponible: 'The day will become not available. Do you wish to continue?',
    nuevo_rango: 'New Range',
    hora_inicio_dia: 'There is an error in the {dia} time range',
    hora_inicio_debe_ser: 'The Start Time must be less than the End Time',
    especialidades: 'Specialties Calendar',
    especialidad: 'Specialty',
    seleccione_especialidad: 'Select specialty to set its duration and calendar availability',
    sin_horas_asignadas: 'You currently have no assigned schedule',
    gmtAlert: 'Please remember that appointment slots refer to the GMT+1 (Madrid) time zone',
    gmtAlertAfrican: 'Please remember that appointment slots refer to the GMT+2 (South Africa) time zone',
  },
  anestesia: {
    de_que_opera: 'What will be your surgical procedure?',
    quien_opera: 'Who is your surgeon?',
    edad: 'Age',
    peso: 'Weight',
    talla: 'Size',
    alergico_medicacion: 'Are you allergic to any medicine?',
    indique_a_que: 'If yes, to what?',
    tension_alta: 'Do you have high blood pressure?',
    diabetico: 'Are you diabetic?',
    fumador: 'Do you smoke?',
    enfemedad_actual: 'Do you suffer any disease?',
    enfermedad_previa: 'Have you suffer from any disease in the past?',
    medicacion: 'Are you taking any medication?',
    operado_anterior: 'Have you had any surgeries in the past?',
    problema_anestesia: 'Have you had any issues with anaesthesia?',
    indique_si_ha_sufrido: 'LET US KNOW IF YOU HAVE HAD ANY OF THE FOLLOWING CONDITIONS:',
    corazon: 'Heart Conditions (Angina, strokes, etc.)',
    pulmon: 'Lung Conditions (bronchitis, asthma, etc.)',
    higado: 'Liver Conditions',
    enfermedad_rinon: 'Kidney Conditions',
    comentarios: 'Any further information that you would like to share with us:',
    fecha_intevencion: 'Scheduled date for surgery',
    informe_preanestesia: 'Preanaesthesia Report',
    autorizado: 'Authorized',
    no_autorizado: 'Not Authorized',
  },
  appointments: {
    name: 'Video Consultation | Video Consultations',
    not_have_pending: 'You do not have pending video consultations.',
    error: 'There was a problem loading the last messages',
    onlineBookingNotAvailable: 'Online booking not available. Select another date',
    dateRequired: 'Please select the time suits you best for the video consultation',
  },
  asignar: {
    titulo: 'Assign video consultation',
    titulo_sms: 'Notify by SMS',
    texto_video:
      'Are you sure you want to assign this videconsultation to yourself and therefore committing to respond to the appointment?',
    texto_mensaje:
      'Are you sure you want to assign this message to yourself and therefore committing to respond to the appointment?',
    texto_sms:
      'Are you sure you want to send a text message to the professional to let them know there are patients waiting for their appointment?',
    asignada_correctamente: 'The video consultation has been assigned correctly.',
    enviado_correctamente: 'The text message has been sent successfully',
  },
  beneficiaries: {
    no_beneficiaries: "You haven't added people you care for yet",
    relationship: 'Relationship',
    new: 'New',
  },
  calendario: {
    especialidades: 'Calendar by Specialty',
    professionals: 'Professionals Calendar',
    select_professional: 'Select Professional',
    nodisponible: 'Not Available',
    no_data: 'No information available. Check the calendar settings',
    datePassed: 'The selected date is less than the current',
  },
  change_password: {
    change_password: 'Change password',
    current_password: 'Current password',
    new_password: 'New password',
    confirm_password: 'Confirm password',
    at_least_num_characters: 'At least {num} characters',
    at_least_one_lowercase: 'At least a lowercase',
    at_least_one_uppercase: 'At least an uppercase',
    at_least_one_number: 'At least a number',
    at_least_one_symbol: 'At least a symbol',
    current_password_not_valid: 'Current password is incorrect',
    password_required_message: 'You must fill in the password',
    password_not_valid_message: 'The password is not valid',
    passwords_not_match: 'Passwords do not match',
    change_password_success_message: 'The password was successfully updated',
    unknown_error: 'An error has occurred',
  },
  chequeo: {
    delete: 'Delete Checkup',
    confirm_delete: 'Are you sure you want to delete the selected checkup service?',
  },
  common: {
    search: 'Search',
    go: 'Go',
    create: 'Create',
    aceptar: 'Accept',
    condiciones: 'GENERAL CONDITIONS OF USE',
    cancel: 'Cancel',
    ok: 'OK',
    confirm: 'Confirm',
    save: 'Save',
    name: 'Name',
    category: 'Category',
    lang: 'Language',
    lang_platform: 'Platform Language',
    lang_estracto: 'Biography Language',
    prescription: 'Prescription | Prescriptions',
    documents: 'Documents',
    chequeos: 'Checkups',
    messaging: 'Chat',
    videoconference: 'Video Consultation',
    videoconferences: 'Video Consultations',
    presential: 'Face-to-face appointment',
    pack: 'Pack',
    cita: 'Appointment',
    country: 'Country',
    province: 'Region',
    location: 'Location',
    cp: 'Postal Code',
    address: 'Address',
    spanish: 'Spanish',
    english: 'English',
    catalan: 'Catalan',
    portugues: 'Portuguese',
    polaco: 'Polish',
    date: 'Date',
    type: 'Type',
    size: 'Size',
    actions: 'Actions',
    date_up: 'Creation Date',
    of: 'of',
    changes: 'Changes',
    closed_session: 'Logout successfully',
    closed_session_expired: 'The session has been closed due to inactivity',
    volver_atras: 'Go back',
    acceso_denegado: 'Sorry, access denied.',
    emitir_receta: 'Issue Prescription',
    modify_recipe: 'Modify prescription',
    delete_recipe: 'Delete prescription',
    delete_recipe_confirm: 'Are you sure you want to delete the selected prescription?',
    emitSignPrescription: 'Sign and issue prescription',
    alreadyDiscardedPrescription:
      "You've created a new prescription within an expired treatment plan. Please start a new treatment and include the new prescription there.",
    descripcion: 'Description',
    obligatorio: 'Required',
    invalid: 'Invalid field',
    min_required: 'The field must have at least {tam} characters',
    paciente: 'Patient',
    cualquiera: 'Anyone',
    activado: 'Enabled',
    desactivado: 'Disabled',
    gratuita: 'Free',
    gratuito: 'Free',
    precio: 'Price',
    duracion: 'Duration',
    detalles: 'Details',
    hora: 'Hour',
    close: 'Close',
    view: 'View',
    edit: 'Edit',
    delete: 'Delete',
    emitir: 'Generate',
    no_registros: 'No records',
    status: 'Status',
    estado_servicio: 'Status of Service',
    no_access: 'You do not have permission to access this section',
    prox_consultas: 'Upcoming Appointment | Upcoming Appointments',
    consultas_personales: 'Assigned Appointments',
    ult_mensajes: 'Last Messages',
    mes_redu: 'Jan | Feb | Mar | Apr | May | Jun | Jul | Aug | Sep | Oct | Nov | Dec',
    enviar_enlace: 'Send Link',
    version_trial: 'This functionality is not active during the trial version',
    titulo_salir_sin_guardar: 'Exit without saving',
    salir_sin_guardar:
      'You have made changes to the card that have not been saved. Are you sure you want to exit without saving?',
    fecha_inicio: 'Start Date',
    fecha_fin: 'End Date',
    landing: 'Custom Landing Page',
    backgroundcolor: 'Background Color',
    nuevo_servicio: 'New Service',
    otro: 'Other',
    continuar: 'Next',
    filtrar: 'Filter',
    sin_atender: 'Unassigned Appointments',
    medicos_disponibles: 'Available Doctors',
    cargando: 'Loading...',
    semana: 'Week',
    movil: 'phone',
    celular: 'phone',
    centro: 'Center',
    num: 'No.',
    reconocimiento: 'Medical Recognitions',
    apto: 'Fit',
    no_apto: 'Unfit',
    sin_verificar: 'Unverified',
    aviso_legal: 'Legal Notice',
    term_and_conditions: 'Terms and Conditions',
    term_and_conditions_signup: 'Terms and Conditions (Sign up)',
    term_and_conditions_without_tax: 'Terms without tax',
    politica_privacidad: 'Privacy Policy',
    politica_cookies: 'Cookies',
    privacy: 'Privacy',
    cookies: 'Cookies',
    aboutus: 'About us',
    title: 'Title',
    access: 'Access',
    inactive: 'Inactive',
    in_process: 'In process',
    active: 'Active',
    last_login: 'Last login',
    last_connection: 'Last connection',
    no_last_login: 'Not yet logged in',
    no_last_access: 'Not yet accessed the dashboard',
    verification_state: 'Verification status',
    verified: 'Verified',
    configuracion: 'Setup',
    chequeo: 'Checkup',
    professional: 'Professional',
    modify_image: 'Modify Image',
    warning_image_exceeds_maximum: 'The image is larger than {size} which is the maximum size allowed',
    none: 'None',
    numero_seg_social: 'Health Card Numbers',
    filters: 'Filters',
    yes: 'Yes',
    no: 'No',
    active_web: 'Activate web',
    inactive_web: 'Web disabled',
    phone_number: 'Phone Number',
    availableRoles: 'Available roles',
    undefined: 'Undefined',
    admin: 'Admin',
    receptionist: 'Assistant',
    rrhh: 'RRHH',
    patient: 'Patient',
    man: 'Man',
    woman: 'Woman',
    unspecified: 'Unspecified',
    more: 'More',
    taxPercent: 'Tax Percent',
    taxType: 'Tax Type',
    add: 'Add',
    fieldIncomplete: 'You must fill in the {field} field',
    download_pdf: 'Download PDF',
    show_details: 'Show details',
    authorization: 'Authorization|Authorizations',
    go_to_profile: 'Go to profile',
    notice: 'Notice',
    accepted_at: 'Accepted at',
    chat: 'Chat',
    participants: 'Participants',
    consultation_subject: 'Reason for consultation',
    you_are_online: 'Your are online',
    you_are_offline: 'Your are offline',
    short_date_format: 'DD/MM/YYYY',
    subscriptions_temporarily_disabled:
      "We're sorry, subscriptions are temporarily disabled. Please try again in a few days.",
    confirmGenericMessage: "Are you sure? This action can't be undone",
    and: 'and',
    go_home: 'Go back',
    show_to_hiring: 'Visible for hiring on the web',
    hide_to_hiring: 'Not visible for hiring on the web',
    image_text: 'Colour image up to 5 MB and up to 8000 by 8000px in JPG format',
    dni_front: 'Upload your ID documentation (front)',
    dni_back: 'Upload your ID documentation (reverse)',
    price_v_standard: 'Standard V. price',
    range_of_hours: 'Range of hours',
    registration_status: 'Registration status',

    date_placeholder: 'mm/dd/aaaa',
    years: 'years',
    max_n_characters: 'Max {chars} characters',
    return_back: 'Return',
    download: "Download"
  },
  aboutus: {
    firstText: "Docline's electronic prescription service integrates with pharmacies' data Spanish network. ",
    secondText:
      "Docline's electronic prescription service is approved by the General Council of Official Colleges of Physicians (CGCOM), after passing the audit following the requirements of guides and protocols. ",
    prescriptionSystem: 'Certificate: Electronic Prescription Repository ',
    prescriptionRepository: 'Certificate: Electronic Prescription System',
    provider: 'Provider: Docline developed by Aplicaciones Salud S.L.',
    version: 'Version: {version}',
    date: 'Date: {date}',
    accept: 'Accept',
  },
  cupones: {
    crear: 'Create Coupon',
    codigo: 'Code',
    descuento: 'Discount',
    caducidad: 'Expiration',
    num_usos: 'Number of uses',
    sin_cupones: 'No coupon has been configured',
    crear_nuevo: 'Create New Coupon',
    editar: 'Edit the coupon',
    eliminar_titulo: 'Remove the coupon',
    eliminar_texto: 'The coupon will no longer be active. Are you sure you want to delete the coupon?',
    min_code: 'The code must have at least 6 characters',
    tam_code: 'The code must have {num} characters',
    porusos: 'By uses',
    porfecha: 'By date',
    nocaduca: 'Does not expire',
    error_crear_existe_codigo: 'The coupon has not been created. A coupon with the code already exists: ',
    error_editar_existe_codigo: 'The coupon has not been edited. A coupon with the code already exists: ',
    success_create: 'The coupon has been created correctly',
    caduca_por_usos: 'This coupon expires by uses.',
    sin_caducidad: 'Coupon without expiration.',
    caduca_por_fecha: 'This coupon expires on ',
    usarlo_cualquiera: 'It can be used by any patient.',
    usarlo_paciente: 'Can only be used by: ',
    show_details: 'Show details',
  },
  datatable: {
    rows_per_page: 'Rows per Page',
    all: 'All',
  },
  documentos: {
    nuevo: 'New File',
    confirmar_subida_titulo: 'Upload File',
    confirmar_subida_texto: 'Are you sure you want to upload the selected file?',
    subido_correctamente: 'The file has been uploaded correctly',
    csv_uploaded:
      'The file has been uploaded correctly. This process can take several time. If after half an hour the data has not been updated correctly, contact support',
    subir_reconocimiento: 'Please upload a certificate of medical examination',
    subir_documento: 'Please upload a document that validates this decision.',
    subir_certificado: 'Upload certificate',
    subir_renuncia: 'Upload waiver',
    certificado: 'Certificate',
    renuncia: 'Waiver',
  },
  documents: {
    upload_document: 'Upload File',
    no_documents: 'You do not have documents',
    drag_and_drop: 'Select or Drag and drop your file here',
    browse_file: 'browse file',
    supported_files: 'Supported files',
    size_limited: '{num} MB limited',
    upload_new: 'Upload New',
    select_professional: 'Health professional who wants to enable access to the document',
    are_you_sure_upload: 'Are you sure you want to upload a new document?',
    video: 'Video',
    chat: 'Chat',
    prescription: 'Prescription',
    triage: 'Triage',
    diagnosis: 'Diagnosis',
    medical_report: 'Medical Report',
    other: 'Document',
    electronic_prescription: 'Electronic Prescription',
    link_to_vc: 'Link the document to a videoconsultation (optional)',
  },
  errors: {
    not_access_patient: 'An error has occurred trying to access the patient',
    edit_patient: 'An error occurred when editing the patient',
    value_required: 'Enter {value}',
    address_required: 'It is mandatory to enter the data related to the address',
    fiscal_address_required: 'It is mandatory to enter the fiscal address, CP and town',
    phone_consultation_required:
      'It is mandatory to fill in the Consultation Telephone to activate the payment gateway',
    edit_user: 'An error occurred when editing the doctor',
    existing_patient: 'The patient is already registered on {name}',
    form_invalid: 'Please fill in all the required fields',
    form_empty: 'Please fill in all the required fields',
    email_required: 'Email is required',
    email_or_phone_required: 'Email/Phone is required',
    password_required: 'Password is required',
    usr_pw_incorrect: 'Incorrect username or password',
    email_invalid: 'Invalid email',
    email_repeat: 'The emails do not match',
    file_uploaded_invalid: 'It is not possible to upload the selected document',
    try_again: 'An error occurred while performing the operation, please try again',
    save_history: 'An error has occurred while saving the clinical history',
    existing_user: 'There is already a registered doctor with the selected e-mail or username',
    professional_already_exists: 'There is already a registered doctor with the selected email or slug',
    collegiate_number_already_exists: 'Professional with this collegiate number is already in use in this company',
    professional_not_found: 'Professional not found',
    schedule_not_valid: 'An error occurred in the agenda configuration. Please, go to agenda settings.',
    existing_user_in_company:
      'There is already a user with that email and that company. Please, choose another email address or modify the company with which you want to associate this professional',
    existing_user_without_company:
      'There is already a professional not associated with any company and with the same email. Please choose another email address',
    existing_company: 'The company you are trying to create already exists',
    horaFinMenorHoraInicio: 'The end time can not be less than the start time',
    usr_dado_baja: 'The user is not activated at this moment',
    must_be_at_least_13_years: 'Must be at least 13 years of age',
    trial_caducado: 'The trial period has expired. If you wish to extend it, please contact us through {name}',
    email_no_exist: 'The email is not registered in {name}',
    mayor_cero: 'The quantity must be greater than 0 | The value must be greater than or equal to 0',
    user_exist: 'The email is already registered in the application',
    file_max_upload: 'The file exceeds the maximum allowed size,  that is {tam}MB',
    input_tam_maximo_superado: 'The input {input} exceeds the maximum size allowed ({tam} characters)',
    input_tam_minimo: 'Field {input} should contain at least {tam} chars',
    factura_no_disponible:
      'It is not possible download this invoice at this momment. Please contact us to resolve the issue',
    date_required: 'The date of birth is mandatory',
    format_phone: 'The phone format is not correct. Example: 612345678',
    phone_video_error:
      "You cannot select more than one patient to create an appointment from the patient's phone number",
    max_patients_multi: 'You cannot select more than three patients for a multiconsultation',
    phone_empty: 'You must enter the phone number',
    incorrect_hour: 'The time format is not correct. Example: 10:30',
    incorrect_date: 'The date format is not correct',
    date_must_be_greater_than_now: 'Date and time must be greater than now',
    incorrect_colegiado: 'The license number must have between 8 and 9 characters',
    incorrect_collegiate_psychologist: 'Incorrect psychology collegiate number',
    emit_e_prescription:
      'There has been an error with the E-Prescription. Please report the issue, and we will resolve the error as soon as possible.',
    incorrect_colegiado_emit:
      'Your license number is not correct, please go to your profile and update the registration number',
    incorrect_collegiate_number: 'The collegiate number must be correct to emit the prescription',
    invalid_collegiate_number:
      'Your medical registration number is invalid. Please, contact us directly by email at {email} to fix it and verify the number. *Warning: The medical registration number has 8 or 9 digits.',
    collegiate_not_allow_to_prescribe:
      'With your current licence number you can not prescribe drugs to your patients. Please access your account profile, update your license number and try again',
    invalid_patient_document:
      'The patient DNI / ID number you entered is not valid. Please update the number in their profile and try again',
    freemium_no_multi:
      'With the Freemium version, you cannot create multiconsultation. Contact your sales representative to upgrade to the Premium version.',
    gender_required: 'Gender is required',
    relation_required: 'Relation is required',
    type_prof: 'You must select a type of professional',
    bad_request: 'An error has occurred, parameters are missing to complete the action',
    unauthorized: 'You do not have sufficient permissions to perform this action',
    must_be_adult: 'User must be adult',
    specialty_required: 'It is mandatory to select at least one specialty',
    invalidEinNumber: 'Invalid EIN Number format',
    invalidUrl: 'Invalid URL format (https://example.com)',
    invalidNameOrSurname: 'Invalid format, only letters with or without special characters, and spaces are allowed.',
    fieldDoNotMatch: 'Fields do not match',
    patient_has_no_email_message:
      'The patient has no email. Please edit his or her data and enter a valid email to be able to emit the electronic prescription.',
    not_found_title: 'This page is not available',
    not_found_message:
      'The link you used may be broken or you reached a deleted page. Please check the link you are using is correct.',
    server_down: 'Sorry, the server is down.',
  },
  facturacion: {
    concepto: 'Concept',
    importe: 'Amount',
    pagosrecibidos: 'Payments received',
    dateFactura: 'Date Invoice',
    datePay: 'Date Payment',
    without_payments: 'You haven´t received any payments in the time period selected',
    puede_tardar: 'Please be patient while we retrieve the information from the payment platform',
    rango_fechas: 'The time period cannot exceeds {num} days',
    info_sobre_facturas:
      'You will not see here the invoices for the payments made during the last five days due to the processing time required by our online payment provider',
    aceptar_transaccion: 'Accept Transaction',
    aceptar_transaccion_descrip: 'Are you sure you want to confirm the selected transaction?',
    cancelar_transaccion: 'Cancel transaction',
    cancelar_transaccion_descrip: 'Are you sure you want to cancel the selected transaction?',
    select_professional: 'Select a professional',
  },
  hiring: {
    service: 'Hire Service',
    appointment: 'Hire Video Consultation',
    message: 'Hire Chat',
    pack: 'Hire Pack',
    subscription: 'Hire Subscription',
    select_receiver: 'The service is not for you? Choose the person for whom you are requesting this service',
    reason_of_consultation: 'Write the reason for your consultation',
    consultation_details: 'Details of your appointment',
    professional_will_confirm:
      'The health professional will confirm your booking or will suggest a new appointment as soon as possible',
    professional_will_approve_pack: 'The health professional will get in touch with you as soon as possible',
    have_promotional_code: 'Do you have a Promotional Code?',
    card_payment: 'Card payment',
    proceed_to_payment: 'Proceed to payment',
    discount_applied: '{num}% discount applied',
    add_document:
      'Feel free to attach any relevant document as test results, pictures etc. to faclilitate the diagnostic',
    document_added: 'Document added to the message',
    note_to_professional: 'You can write a note for the health professional',
    monthly_fee_will_charged:
      'A monthly fee will be charged for your active subscription. You can cancel this service form your account',
    send: 'Send',
    free_with_your_subscription: 'You already have an active subscription that has this service included',
    appointment_proposed_by_professional:
      'This is the appointment proposed by the professional. As soon as we validate your payment, we will send you instructions on how to connect to the video consultation by email.',
    you_have_period_to_confirm:
      'You have 2 days to confirm this appointment. After that, the professional can reassign this appointment to another patient or cancel it without prior notice',
    appreciate_your_understanding: 'Thank you for your comprehension.',
    apiError: 'An error occurred, please try again.',
  },
  historia: {
    antecedentes_personales: 'Personal Clinical Records',
    enfermedades_infancia: 'Childhood Conditions',
    enfermedades_adulta: 'Adulthood Conditions',
    medicamentos: 'Ongoing Treatments',
    intervenciones: 'Surgical Interventions',
    actividad_laboral: 'Occupation',
    antecedentes_familiares: "Relative's Clinical Records",
    alergias: 'Allergies',
    episodios: 'Episodes',
    nuevo_episodio: 'New Episode',
  },
  mensajeria: {
    nuevo_mensaje: 'New message',
    pendientes: 'Pendings',
    abiertos: 'Open Messages',
    abierto: 'Open',
    cerrados: 'Closed Messages',
    enviar: 'Send',
    para: 'To',
    motivo: 'Subject',
    subject: 'Subject',
    mensaje: 'Message',
    mensajes: 'Messages',
    select_user: 'Select recipient',
    enviar_nuevo_mensaje: 'Send a new message',
    marcar_como_atendida: 'Mark as solved',
    sin_mensajes_pendientes: "You don't have any pending messages",
    sin_mensajes_abiertos: 'At this time, you have no messaging open to answer with any patient',
    sin_mensajes_atendidos: 'You have not yet marked any messages as closed.',
    archivos_adjuntos: 'Attached Files',
    has_attachments: 'Has attachments',
    attach_file: 'Attach file',
    mensaje_con_adjuntos: 'The message will be sent with an attachment',
    add_texto_al_mensaje: 'Add text to the response to send the message',
    add_destinatario_al_mensaje: 'Indicate the recipient of the message',
    enviado_correctamente: 'Message sent correctly',
    enviado_correctamente_error_adjunto:
      'Message sent correctly, but the file could not be attached, check that it does not exceed the maximum allowed size and that it is an image or a pdf',
    error_enviando: 'The message could not be sent. Try it again later',
    atendido_correctamente: 'The message has been correctly marked as solved',
    error_atendiendo: 'Error marking the message as answered, try again later',
    confirm_atendida:
      'The courier service will be marked as solved. If the patient wishes to reconnect, they must request a new messaging service. Are you sure you want to close this messaging service?',
    sin_contestar: 'WITHOUT ANSWER',
    answered: 'ANSWERED',
    sent: 'SENT',
    sin_cerrar: 'OPEN',
    ver_file: 'Show File',
    delete_file: 'Delete File',
    confirmar_delete_file:
      'If you delete the document the patient will not be able to access it. Are you sure you want to delete the document?',
    modificar_name_file: 'Modify Document Name',
    nombre_file: 'Name of the Document',
    atendido: 'Closed',
    enviar_mensaje: 'Send message',
    cerrado: 'Closed',
    unread_messages: 'Unread messages',
    not_exists_thread: 'There is not a thread with an identifier {id}',
    downloaded_on: 'Downloaded on',
    pdf_footer_link: 'This PDF has been generated through the chat from ',
    see_chat: 'See chat',
    loading_chat: 'Loading chat...',
  },
  menu: {
    home: 'Home',
    calendario: 'Calendar',
    patients: 'Patients',
    messaging: 'Chat',
    videocam: 'Video Consultations',
    productos: 'Products',
    reports: 'Statistics',
    chat: 'Chat',
    inbox: 'Inbox',
    settings: 'Settings',
    help: 'Help',
    profile: 'Profile',
    close_session: 'Close Session',
    agenda: 'Schedule',
    configvideoconsultas: '',
    cupones: 'Coupons',
    talonario: 'Prescription Pad',
    firma: 'Signature',
    users: 'Doctors',
    medicos: 'Doctors',
    metrics: 'Metrics',
    packs: 'Packs',
    packcontratados: 'My Packs',
    contact: 'Contact',
    empresas: 'Business',
    tarifas: 'Services',
    servicios: 'Services',
    miweb: 'My WebSite',
    facturacion: 'Billing',
    recepcionista: 'Assistant',
    companyConfig: 'Company config',
    resenas: 'Reviews',
    dispositivos: 'Devices',
    citas: 'Appointments',
    transacciones: 'Transactions',
    valorespersonalizados: 'Custom Values',
    myMessages: 'Messages',
    myVideoconsultations: 'Video Consultations',
    myPrescriptions: 'Prescriptions',
    mySubscriptions: 'Subscriptions',
    prescriptionsFilteredList: 'Prescriptions Pending',
    myDocuments: 'Documents',
    sendUsMessage: 'Send us a Message',
    beneficiaries: 'Associated users',
    account: 'My Account',
    medicalTests: 'Tests',
    medicalTestTypes: 'Test Types',
    medicalTestNomenclator: 'Nomenclator',
  },
  message: {
    change_language: 'Change Language',
    email_or_phone: 'Email or phone number',
    password: 'Password',
    modify_password: 'Change Password',
    email: 'Email',
    repeat_email: 'Confirm Email',
    intro_email: 'Enter your Email',
    intro_pw: 'Enter your Password',
    intro_security: 'For security reasons, to be able to change the email you must enter the password',
    email_repeat: 'Confirm Email',
    forgot_password: 'Forgot Your Password?',
    remember: 'Remember Password',
    login: 'Login',
    signin: 'Sign In',
    notifications: 'Notifications',
    showall: 'Show All',
    reset_texto: 'Enter your email address. We will send you an email with a link to reset your password.',
    reset_password: 'Reset your password',
    introduzca_password: 'To activate your account, enter your access password.',
    restaurar_password: 'Reset Password',
    password_weak: 'Weak password',
    password_not_secure: 'Password is not secure',
    password_secure: 'Password is secure',
    welcome: 'Welcome to {name}',
    new_here: 'New here? Start using our Digital Health services',
    signup: 'Sign Up',
    start: 'Start',
    already_have_an_account: 'Do you already have an account? Sign in',
    can_help_you: 'We can help you?',
    get_help: 'Get help',
    continue_to: 'Continue {name}',
    estamos_encantados: 'We are delighted to have you with us. ',
    le_guiaremos:
      'We will now guide you through our platform so you complete the necessary fields to start using {name} everyday.',
    password_new: 'New Password',
    activar_cuenta: 'Active Account',
    check_credentials: 'Verifying Credentials',
    cuenta_trial:
      'Your account is in the process of validation. The trial version will expire tomorrow | Your account is in the process of validation. The trial version will expire in  {count} days',
    double_auth_title: 'Two step verification',
    double_auth_desc: 'This extra step indicates that it is you who are trying to log in.',
    double_auth_msg_one: 'An email has just been sent to ',
    double_auth_msg_two: 'with a 6-digit verification code, please enter it below.',
    double_auth_email_again: 'Have you not received the email? Resend',
    double_auth_email_sended: 'The code has been resent',
    double_auth_error_code: 'The code is wrong, try again',
    double_auth_empty_code: 'You must fill in the code',
    double_auth_escribe_codigo: 'Type the code',
    double_auth_placeholder: 'Code',
    select_an_account: 'Select an account',
    acceptConditions:
      'I have read and accept the <a href="{privacyPolicyLink}" target="_blank">privacy policy</a> and <a href="{legalLink}" target="_blank">legal notice</a>',
    acceptGeneralConditions:
      'I have read and accept the <a href="{generalConditionsLink}" target="_blank">general conditions and data processing</a>',
    acceptGeneralConditions_LOPDGDD:
      'I have read and accept the <a href="{generalConditionsLink}" target="_blank">general conditions and data processing and its annex LOPDGDD</a>',
  },
  messages: {
    name: 'Private Messagge | Private Messagges',
    new: 'New',
    unsolved: 'Pending',
    solved: 'Closed',
    all: 'All',
    write_your_message_here: 'Write your message here...',
    write_your_consultation: 'Write your consultation to {name}',
    not_have_pending: 'No messages',
    error: 'There was a problem loading the last messages',
    reset_filter: 'Reset Filter',
    show_messages: 'Show Messages',
    message_closed: 'The message is closed',
    to_me: 'To: me',
    to: 'To',
    me: 'me',
  },
  notificaciones: {
    mensajeria: 'You have a pending message | You have {count} pending messages',
    video_confirm: 'Have a video consultation to be confirmed | You have {count} video consultations to be confirmed',
    video_hoy: 'Today: a video consultation | Have today: {count} video consultations',
    prescriptions: 'You have a prescription pending to emit | You have {count} prescriptions pending to emit',
  },
  packs: {
    name: 'Pack | Packs',
    crear: 'Create Pack',
    packs_configurados: 'Hired packs',
    packs_contratados: 'Hired Packs',
    num_sesiones: 'Number of Sessions',
    editar: 'Edit Pack',
    eliminar_titulo: 'Delete Pack',
    eliminar_texto:
      'Patients will not be able to hire this Pack in the future. Previously hired packs will not be affected.',
    eliminar_confirmar: 'Are you sure you want to delete this pack?',
    sin_packs: 'No packs has been configured',
    completados: 'Completed',
    sin_pendientes: 'At this time you do not have any uncompleted hired packs',
    sin_completados: 'You have not completed any hired pack',
    fecha_contratado: 'Hired Date',
    sesiones_totales: 'Total Sessions',
    sesiones_restantes: 'Remaining Sessions',
    marcar_como_completado: 'Mark as Completed',
    marcar_como_completado_texto: 'The pack still has unused sessions. Are you sure you want to mark is as complete?',
    success_complete: 'The pack has been marked as completed correctly',
    sesion: 'Session',
    not_have_hired: 'You have no contracted pack',
    num_pending: '1 pending session | {num} pending sessions',
  },
  patient: {
    patients: 'Patients',
    search: 'Search Patient',
    without_patients: 'There are no patients',
    without_authorizations: 'There are no authorizations',
    new: 'Add Patient',
    edit: 'Edit Patient',
    delete: 'Delete Patient',
    confirm_delete:
      'The selected patient stopped receiving their services. Are you sure you want to eliminate your patient?',
    personal_data_mayus: 'PERSONAL INFORMATION',
    personal_data: 'Personal Information',
    history_clinical: 'Medical History',
    history_clinic: 'Medical History',
    first_name: 'First name',
    last_name: 'Last name',
    document_id: 'ID Number',
    cedula_or_passport: 'Cedula or Passport',
    cedula: 'Cedula',
    birthdate: 'Date of birth',
    phone: 'Phone',
    gender: 'Gender',
    female: 'Female',
    male: 'Male',
    other: 'Other',
    delete_success: 'The patient has been removed correctly',
    delete_error: 'An error has occurred eliminating the patient. Try again later',
    full_name_required: 'Required name and surname',
    too_long_name: 'Name cannot be longer than 50 characters',
    too_long_surname: 'Last name cannot be longer than 50 characters',
    dni_required: 'ID Number is required',
    type_dni_required: 'ID Document Type is required',
    dni_invalid: 'ID Document is not valid',
    dni_invalid_letra: 'Incorrect ID, the letter of the NIF does not correspond',
    invalidNie: 'NIE Document is not valid',
    birthdate_invalid: 'The date of birth must be less than the current date',
    observations: 'Observations',
    antecedentes_familiares: 'Family Background',
    antecedentes_maternos: 'Maternal Background',
    antecedentes_paternos: 'Paternal Background',
    antecedentes_hermanos: 'Siblings Background',
    antecedentes_otros: 'Others Background',
    antecedentes_personales: 'Electronic Health Record',
    antecedentes_cirugias: 'Surgeries',
    alergias_medicamentos: 'Allergies Medications',
    otras_alergias: 'Others Allergies',
    enfermedades_cronicas: 'Chronic Diseases',
    historial_psiquiatria: 'History of Psychiatry',
    motivo_consulta: 'Reason for consultation',
    antecedentes_somaticos: 'Somatic Background',
    antecedentes_psiquiatricos: 'Psychiatric Background',
    genograma: 'Genogram',
    psicobiografia: 'Psychobiography',
    habitos_toxicos: 'Toxic habits',
    anamnesis: 'Presenting complaints',
    diagnosticos: 'Diagnoses',
    diagnostico: 'diagnosed',
    episodio: 'episode',
    evoluciones: 'Evolutions',
    recomendaciones: 'Recommendations',
    nuevo_diagnostico: 'New Diagnosis',
    nuevo_evolutivo: 'New consultation',
    editar_diagnostico: 'Edit Diagnosis',
    sin_diagnosticos: 'This patient has not yet been {name}',
    diagnoses_require_signature: 'It is necessary to have the signature configured to be able to create diagnoses.',
    delete_diagnostico: 'Delete Diagnosis',
    confirm_delete_diagnostico: 'Are you sure you want to delete the selected diagnosis?',
    nueva_evolucion: 'New Evolution',
    editar_evolucion: 'Edit Evolution',
    sin_evoluciones: 'No evolution has yet been added to this patient',
    delete_evolucion: 'Delete Evolution',
    confirm_delete_evolucion: 'Are you sure you want to delete the selected evolution?',
    sin_documentos: 'The patient does not have documents',
    sin_chequeos: 'The patient does not have checkups',
    sin_recetas: 'No prescriptions have yet been created for the patient',
    generarinforme: 'Generate report',
    pais: 'Country',
    evolucion: 'Evolution',
    exploracion: 'Exploration',
    juicioclinico: 'Clinical Judgment',
    tratamiento: 'Treatment | Treatments',
    num_historia: 'No. History',
    notas: 'Internal Notes (The patient will not be able to see this information and will not appear in the report)',
    tipo_documento: 'Document Type',
    pasaporte: 'Passport',
    enviado_mensaje_vincular:
      'The patient is already registered. We have sent an email to the patient to accept the link between health professional and patient',
    relacion: 'Relationship',
    datos_tutor: 'Tutor Data',
    cuidar_alguien: 'Add dependant patient',
    pacientes_tutelados: 'Users associated with your account',
    preanestesia: 'Preanaesthesia',
    autorizado: 'Authorized',
    no_autorizado: 'Not Authorized',
    peso: 'Weight',
    motivo_y_evolutivos: 'Reason and Evolutionary',
    examenFisico: 'Physical Exam',
    resultadoLaboratorio: 'Lab results',
    pruebasImagen: 'Imaging tests',
    evolutivo: 'Evolutionary',
    aceptar_cambios_apto: 'Confirm that you want to confirm the change to Fit?',
    revision_medica: 'Medical check | medical checkups',
    sin_common: 'The patient does not have {name}',
    aceptar_cambios_noapto: 'Confirm that you want to confirm the change to Unfit?',
    aceptar_cambios_rechazo: 'Do you confirm that you want to accept the rejection?',
    aceptar_cambios_denegado: 'Do you confirm that you want to deny the rejection?',
    new_revision: 'New Checkup',
    pendiente: 'Pending',
    aceptado: 'Accepted',
    edit_date_revision: 'Edit medical check date',
    fecha_ultimo_aviso: 'Date last notice',
    register_date: 'Registration date',
    no_observations: 'No observations',
    allergy_management: 'Allergy Management',
    add_allergy: 'Add Allergy',
    no_allergies: 'The patient has no registered allergies',
    new_allergy: 'New Allergy',
    allergy_to: 'Allergy to',
    addedAllergy: 'Allergy saved',
    removedAllergy: 'Allergy removed',
    allergyExists: 'This patient already has a similar allergy registered',
    saveDuplicateAllergy: 'Yes, save similar allergy',
    searchAllergy: 'Search allergies',
    allergyEndDate: 'End date is not defined',
    allergyStartDate: 'Start date is not defined',
    allergyInfoBox: 'This patient has been diagnosed with the following allergies:',
    allergyDetected: 'This medicine may be incompatible with one of the registered allergies.',
  },
  prescriptions: {
    prescription: 'Prescription',
    prescriptions_list: 'Electronic Prescriptions',
    no_prescriptions: 'You do not have prescriptions',
    not_found: 'No prescriptions found with these search criteria',
    mark_all: 'Check all',
    lote_actions: 'Acciones en lotes',
    action_firm_emit: 'Sign and emit',
    medicine_duration_exceeded_error: 'The duration of this medicine cannot exceed {num} months.',
    medicine_duration_exceeded_one_year:
      'The end date of this treatment plan cannot exceed {date}. The duration of this medicine is recalculated.',
    narcotic_number_of_packages_exceeded_error:
      'This medicine cannot be prescribed in a quantity exceeding {num} packages.',
    cant_mix_narcotics_with_others_error:
      'A narcotic cannot be prescribed together with other medications in the same prescription.',
    some_medicine_exceeds_max_duration_error:
      'One or more medicines exceed the maximum allowed duration. Please review the data.',
    long_duration_prescription_confirmation_notice:
      'The duration of at least one of the prescribed medicines is greater than one month. Are you sure you want to continue?',
    maxPackagesWarning:
      'At the moment, you can only prescribe up to {limit} units of this medicine. The treatment duration has been automatically readjusted to the maximum number of days possible.',
    checking_collegiate_number_1: "We're verifying your medical ID registration number.",
    checking_collegiate_number_2: 'Please do not close this window.',
    maxPackagesWarningNoAdjust: 'At the moment, you can only prescribe up to {limit}.',
    maxPackagesErrorNoCovid: 'The maximum units exceed the 99 units limit',
    maxPackagesErrorCovid: 'The maximun units exceed the 9 units limit',
    medicineAlreadyPrescribed: 'You have an ongoing prescription for this medication that is valid until the {date}',
    limited: 'Maximum {num} {measure}',
    maxDaysForSameMedicineTitle: 'You have exceeded the allowed prescription period.',
    maxDaysForSameMedicineText:
      'This prescription will be removed. The prescriptions for medicines and health products should not exceed 12 months.',
    patientIndentityConfirmationText: 'Confirm the patient identity',
    patientsIndentityConfirmationText: 'Confirm the selected patients identities',
    medicamentsIncluded: 'Includes {numMedicaments} medicaments',
    itemsSelected: ' items selected',
    filterPending: 'Pending',
    noActiveMedicines:
      'This prescription contains at least one medicine that is no longer active. Please issue a new prescription.',
    deletePrescriptions: 'Delete prescriptions',
  },
  recepcionista: {
    recepcionistas: 'Assistants',
    recepcionista: 'Assistant',
    crear: 'Create assistant',
    editar: 'Edit assistant',
    sin_recepcionista: 'You have not yet registered a assistant',
    permisos: 'Permissions',
    agenda: 'Schedule',
    servicios: 'Services',
    historia: 'History',
    talonario: 'Prescription Pad',
    firma: 'Signature',
    facturacion: 'Billing',
    eliminar_titulo: 'Delete assistant',
    eliminar_texto: 'Are you sure you want to delete this assistant user account?',
  },
  recetas: {
    nueva: 'New Prescription',
    num_receta: 'No. of Prescription',
    ver: 'Open',
    reemitir: 'Reissue',
    prescripcion: 'PRESCRIPTION',
    prescripciones: 'Prescriptions',
    prescripcion_detalle:
      'Consign the medicine: INN or brand, pharmaceutical form, route of administration, dose per unit and units per container. (Corresponding data in case of medical device).',
    num_envases: 'No. packaging / units',
    buscar_medicamento: 'Search medication',
    receta_medica_privada: 'PRIVATE MEDICAL PRESCRIPCION',
    receta_medica_asistencia_sanitaria_privada: 'MEDICAL PRESCRIPCION FOR PRIVATE HEALTH ASSISTANCE',
    no_existe_medicamento: 'There is no medication with the indicated name',
    unidades: 'Units',
    duracion: 'Treatment Duration',
    posologia: 'Posology',
    pauta: 'Norm',
    fecha_prevista_dispensacion: 'Expected Date Dispensation',
    info_farmaceutico: 'Information to the Pharmacist, if applicable',
    instrucciones_paciente: 'INSTRUCTIONS TO THE PATIENT (if applicable)',
    paciente_detalle: '(First name, last name, year of birth and ID number / NIE / passport)',
    prescriptor: 'PRESCRIBING',
    prescriptor_detalle: '(identification and signature)',
    vista_previa: 'PREVIEW',
    required: {
      medicamento: 'The name medication is required',
      paciente: 'The name patient is required',
    },
    generar: 'Generate Prescription',
    creada_correctamente: 'Prescription Sent',
    imprimir: 'Print',
    imprimida_correctamente:
      'The Prescription will open in a new tab. If not opened, allow the browser to open pop-up windows',
    nombreaniodni: 'Name, surname, year of birth and ID number',
    aviso_pocas_recetas: 'NOTICE: Only {num} prescription are available',
    logout_with_pending_to_emit:
      'You have {count} pending prescriptions to emit. Are you sure you want to leave the page or emit the prescriptions?',
    emit_prescriptions: 'Emit prescriptions',
    exit_without_emit: 'Close',
    prescription_batch_ended: 'All prescriptions have been signed and emitted successfully.',
    prescription_failed: 'An error occurred while attempting to emit the prescriptions.  Please, try again.',
  },
  success: {
    new_patient: 'The patient has been created correctly',
    patient_exist: 'The patient is already registered',
    patient_exist_otro_mail: 'The patient is already registered with another email',
    new_user: 'The doctor has been created correctly',
    edit_patient: 'The patient information has been edited correctly',
    edit_user: 'The doctor information has been edited correctly',
    save_new_diagnosis: 'A new diagnosis has been created correctly',
    save_edit_diagnosis: 'The diagnosis has been edited correctly',
    new_diagnosis_report: 'The diagnosis report will be available soon in the documents tab.',
    delete_diagnosis: 'The diagnosis has been removed correctly',
    save_new_evolucion: 'An evolution has been created correctly',
    save_edit_evolucion: 'The evolution has been edited correctly',
    delete_evolucion: 'The evolution has been removed correctly',
    save_history: 'The medical history has been saved correctly',
    save_user: 'The user has been saved correctly',
    save_new_coupon: 'A new coupon has been created correctly',
    save_edit_coupon: 'The coupon has been edited correctly',
    delete_coupon: 'The coupon has been removed correctly',
    save_tipovideo: 'The {name} service has been saved correctly',
    save_tipomensajeria: 'The messaging service has been saved correctly',
    delete_common: 'It has been removed correctly',
    active_common: 'It has been activated correctly',
    save_common: 'It has been saved correctly',
    save_agenda: 'The calendar has been saved correctly',
    save_firma: 'The signature has been saved correctly',
    send_password: 'An email has been sent to reset your password',
    password_changed: 'The password has been changed correctly',
    generado_informe: 'The report was generated correctly',
    account_active: 'The account has been activated correctly',
    name_file_modificado: 'The name of the document has been modified correctly',
    file_deleted: 'The document has been successfully deleted',
    delete_treatment: 'The treatment has been successfully removed',
    recipe_emmited: 'The prescription has been issued correctly, the patient will receive the prescription {methods}',
    emit_method_email: 'by email',
    emit_method_sms: 'by SMS',
    email_sent: 'The email has been sent correctly',
    serviceHiring: 'The {Name} service has been successfully contracted',
  },
  talonario: {
    conf_talonario: 'Prescription pad settings',
    subir_talonario: 'Upload prescription pad',
    eliminar_talonario: 'Delete prescription pad',
    confirmar_eliminar_talonario: 'Are you sure you want to delete this prescription pad?',
    confirmar_subida_talonario:
      ' Are you sure you want to upload the prescription pad {msg} to use when you give your patients prescriptions?',
    talonario_actuales: 'Current prescription pads',
    instrucciones_talonario: 'Follow these steps to upload your prescription pad:',
    instrucc_talon_li:
      'Step 1: Save in your computer the pdf file you have received by your Medical College. | Step 2: Click on "Upload prescription pad" and choose the correct pdf file. | Step 3: Save the file in your account.',
    sin_talonarios:
      ' You have not uploaded any prescription pad yet. You will not be able to generate prescriptions until you upload the pdf file.',
    sin_firma: 'You have not added your signature. Please do so before generating prescriptions.',
    conf_firma: 'Set up your signature',
    firma_actual: 'Current Signature',
    explicacion_firma:
      'Draw your signature from your smartphone or tablet. (It is not recommended to draw it from the computer). It will automatically save in your account and you will be able to access it from any device.',
    mandame_link: 'Send a link to your {nombre}.',
    rehacer_firma: 'Redraw Signature',
    timeoutfirma:
      'The link to draw your signature has expired. Remember it is valid for 15 min since you ask to send it.',
    dibuje_firma:
      "Add your signature from your mobile or tablet. It saves in your profile under the password field. You could access your signature from any device. *It's not recommended use the PC to add your signature.",
    send_signature:
      'We have sent a link to your email account. It contains a link that will let you create your signature (valid for 15 min).',
  },
  tipovideo: {
    estandar: 'Standard Video Consultation',
    precio: 'Price of the Video Consultation',
    duracion: 'Duration of the Video Consultation',
    guardar: 'Save Video Consultation',
    guardarmensajeria: 'Save Chat Service',
    guardarchequeo: 'Save Checkup Service',
    confirm_desactivar_mensajeria: 'Are you sure you want to deactivate the chat service?',
    confirm_desactivar: 'Are you sure you want to deactivate the video consultation service?',
    nuevo_titulo: 'Create Service',
    nuevo_video: 'Create video consultation Service',
    nuevo_presencial: 'Create face-to-face Service',
    confirm_delete_titulo: 'Remove Video Consultation Service',
    confirm_delete_texto: 'Are you sure you want to delete the selected Video Consultation service?',
  },
  tour: {
    skip: 'Skip tour',
    prev: 'Previous',
    next: 'Next',
    finish: 'Finish',
    dibuja_firma: 'Try drawing your signature',
    go_to_signature: 'Click here to configure your signature.',
    no_te_gusta: "Don't you like how it turned out?",
    rehacer_firma: 'You can click on the redo signature button to redraw your signature.',
    enviar_firma_mobile:
      'If you are at the computer and want to draw your signature with your finger from {nombre}, click on this button.',
    configura_agenda: 'Select the days that are available in {name}.',
    configura_hora_agenda: 'Once the day has been selected, indicate the start and end time.',
    configura_rango_agenda: 'You can select more than one range of hours.',
    descripcion_mensajeria: 'Indicate the description of the courier service',
    precio_mensajeria: 'You can change the price of the consultation',
    estado_mensajeria: 'If you do not wish to receive queries, you can change the status to disabled.',
    guardar_cambios: 'Click on save to apply changes',
  },
  user: {
    name: 'Username',
    aditional_info: 'Aditional info',
    edit_professional: 'Edit profesional',
    telefono_secretaria: 'Work Phone',
    telefono_paciente: "Patient's phone",
    biografia: 'Profile',
    nuevo: 'New Doctor',
    buscar: 'Search User',
    name_complete: 'Full Name',
    num_colegiado: 'License Number',
    telefono_consulta: 'Consultation phone',
    tipo_prof: 'Profesional Type',
    salutation: 'Salutation',
    password_repeat: 'Confirm password',
    password_no_igual: 'Passwords do not match',
    password_tam_invalid: 'The password must be at least 6 characters',
    password_must_contain_lowercase: 'The password must contain lowercase',
    password_must_contain_uppercase: 'The password must contain uppercase',
    password_must_contain_numbers: 'The password must contain numbers',
    password_must_contain_symbols: 'The password must contain special characters',
    invalid_password: 'The password is not valid',
    password_min_length: 'The password must be at least {num} characters',
    edit: 'Save Changes',
    search: 'Search User',
    especialidades: 'Specialties',
    prof_sanitario: 'Doctor',
    psychiatric_clinic_history: 'Psychiatric Clinic History',
    professional_time_zone: 'Professional Time Zone',
    professional_status: 'Professional status',
    user_status: 'User status',
    restore_user_password: 'Restore doctor password',
    create_by_csv: 'Create Users by CSV',
    csv_with_error: 'There is an error in the document, correct it and upload it again',
    csv_ok: 'All users have been created successfully',
    female_doctor: 'Dra',
    male_doctor: 'Dr',
    female_person: 'Ms.',
    male_person: 'Mr.',
    send_account_activation_email: 'Send account activation email',
    user_status_training_not_reserved: 'Training not reserved',
    user_status_in_workflow_hubspot: 'In Workflow Hubspot',
    user_status_training_reserved: 'Training reserved',
    user_status_training_made: 'Training made',
    user_status_subscription_paid: 'Subscription paid',
    user_status_completed_personal_data: 'Completed personal data',
    user_status_abandoned_lead: 'Abandoned lead',
    date_end_trial: 'End date of the trial period',
    public_on_marketplace: 'Published at the marketplace',
    no_public_on_marketplace: 'Not published at the marketplace',
    select_image: 'Select an image',
    name_patients_will_see: 'Name patients will see',
  },
  videoconsulta: {
    nueva_video: 'New Video Consultation',
    nueva_cita: 'New Appointment',
    crear_cita: 'Create Appointment',
    nueva_tele: 'New Teleconsultation',
    crear_video: 'Create Video Consultation',
    crear_tele: 'Create Phonecall',
    pendientes: 'Pending Video Consultations',
    terminadas: 'Finished Video Consultations',
    informe: 'Report | Reports',
    sin_video_pendientes: 'At this time you do not have pending {name}',
    sin_video_terminadas: 'You have not attended any {name} service yet',
    crear_para_paciente: 'Create a {tipoVideo} for a Patient',
    crear_muticonferencia: 'Create a Multicall',
    como_desea_comunicar: 'How do you want to communicate the appointment to the patient?',
    solicitando_pago: 'Request Advance Payment',
    solicitando_pago_texto:
      'Send a payment request by Email. Once the payment is received, you will be able to set the appointment for de video consultation.',
    cita_cortesia: 'Free',
    cita_cortesia_texto: 'These appointments are free for the patient. These can be useful in cases such as: ',
    cita_cortesia_li:
      'Reschedule an appointment that could not be completed. | Send an invitation for a test video consultation or any other reason that you may consider. | Patients that have previously bought a pack of video consultations.',
    add_new: 'Add new',
    add_destinatario: 'Select the patient',
    add_destinatario_cita: 'Select the patient',
    add_fechahora: 'Indicate the date and time of the video consultation',
    add_fechahora_cita: 'Indicate the date and time of the appointment',
    add_modocomunicacion: 'Indicate the way in which the appointment will be communicated to the patient',
    add_tiposervicio: 'Indicate the type of video consultation',
    add_tiposervicio_cita: 'Indicate the type of appointment',
    solapamiento: 'A video consultation already exists on the selected date and time',
    sin_tiposvideo: 'To create new video consultation you must have at least one service active.',
    enviado_correctamente: 'The video query has been created correctly. An email has been sent to the patient',
    enviado_correctamente_sms: 'The video query has been created correctly. An SMS has been sent to the patient',
    enviado_recordatorio: 'An email has been sent to the patient to remind him of the video consultation',
    cortesia: 'Free',
    esperando_pago: 'Waiting for payment',
    tipo: 'Type',
    videoconsulta_para: '{tipoVideo} para',
    codigo: 'Code',
    abrir_sala: 'Open call Room',
    volver_enviar: 'Resend Code',
    confirm_delete: 'Are you sure you want to cancel the video consultation?',
    confirm_delete2:
      'The video consultation is already paid. If necessary to make make a refund of money to the patient contact us through the chat.',
    confirm_video: 'Are you sure you want to confirm the video consultation?',
    cancelado_correctamente: 'The appointment has been cancelled successfully',
    notification_email: 'An email will be sent to the patient confirming the cancellation of the appointment',
    confirmado_correctamente: 'The appointment has been confirmed correctly',
    terminada: 'Video Consultation Finished',
    singular: 'Video Consultation',
    teleconsulta: 'Phonecall',
    sin_confirmar: 'NOT CONFIRMED',
    metadata_list_text: 'Other data',
    rechazada: 'Rejected',
    aceptada: 'Accepted',
    denegada: 'Refused',
    ok: 'Ok',
    multiconferencia: 'Multicall',
    tipo_cita: 'Type of Video Consultation',
    convocatoria_sms: 'Send the appointment info by {sms}',
    editar_servicio: 'Edit Service',
    presencial: 'Face-to-face appointment | Face-to-face appointments',
    sms: 'SMS',
    mensaje_texto: 'Text Message',
    diagnostico: 'Diagnosis',
    tratamiento: 'Treatment',
    num_videos_por_slot: 'No. of consultations per slot',
    tiempo_min_prox_video: 'Minimum time for next consultation',
    tlf_noty: 'Notification phones',
    tlf_noty_placeholder: 'Press the enter key to add the phone',
    freemium_version_non_free:
      'With the Freemium version you cannot create free or complimentary services. Contact your sales representative to upgrade to the Premium version',
    fixed_fee_message:
      'The management fee for this service is € {feeValue} to be added to your rate at the time of the patient payment.',
    percentage_fee_message:
      'The management fee for this service is {feeValue}% with a minimum of € {feeMin} and a maximum of € {feeMax} to be added to your rate at the time of the patient payment.',
    notice: 'Notice',
    no_specialties_to_create:
      'In order to continue you must assign a speciality. Please go to your profile and update it',
  },
  contact: {
    texto: 'If you have any questions, send us your message through the following form.',
    mensaje_obligatorio: 'To contact us you must write the message about your doubt',
    motivo_obligatorio: 'To contact us you must write the reason for your doubt',
    enviado_correctamente: 'Thank you for contacting us! We will respond as soon as possible.',
  },
  empresa: {
    nueva: 'New company',
    buscar: 'Search company',
    email_noty: 'Notification Email',
    email_admin: 'Admin email',
    email_required: 'Admin email is required',
    share_patients: 'Share Patients',
    nombre_required: 'Company name is required',
    licenses_num: 'Licenses num.',
    guardar_empresa: 'Save company',
    compartir_pacientes: 'Sharing Patients',
    edit_empresa_success: 'The company has been successfully edited',
    has_anesthetist: 'Has anesthetist',
    share_receptionists: 'Share assistants',
    without_licenses: 'Your licenses have run out',
    payment_module: 'Payment module',
    single_tpv_account: 'Single stripe for the company',
    payment_type: 'Payment Type',
    fee_type: 'Fee Type',
    fee_value: 'Fee Value',
    fee_min: 'Fee Min',
    fee_max: 'Fee Max',
    service_of_user_change_to_free:
      'In addition to deactivating the payment module, all professional services are free',
    service_of_user_change_to_free_add_company:
      'As the company does not have a payment module, all professional services will become free',
    user_exists: 'This user already exists',
    forbidden: "You don't have permissions to modify this company",
    user_not_exists: "This user doesn't exist",
    edit: 'Edit company',
    create: 'Create company',
    delete: 'Delete company',
    confirm_delete: 'Are you sure you want to delete this company?',
    cannot_delete:
      'It is not possible to delete this company, as there are professionals / assistants associated with it',
    delete_success: 'The company has been successfully removed',
    premium_payment_type: 'Premium payment type',
    premium_payment_types: 'Premium payment types',
    razon_social: 'Business name',
    empresa: 'company',
    cif: 'CIF',
    web: 'Website',
    name_required: 'It is mandatory to enter the name and business name of the clinic',
    cif_required: 'It is mandatory to enter the CIF of the clinic',
    is_collective: 'Is collective',
    is_health_personnel: 'Is health personnel',
    have_interconsultation: 'Have interconsultation',
    interconsultation: 'Interconsultation',
    informedConsent: 'Telemathic authorization',
    printPrescriptions: 'Print electronic prescriptions',
    users: 'Users',
    public_email: 'Public email',
    public_phone: 'Public phone number',
    public_address: 'Public address'
  },
  landing: {
    titulo: 'Setting up my Website',
    direccion: 'Address',
    sections: 'Sections',
    add_seccion: 'Add Section',
    add_seccion_titulo: 'Add Section to My Website',
    seccion_anadida: 'Section added correctly',
    delete_seccion_titulo: 'Delete Section',
    delete_seccion_texto: 'Are you sure you want to delete this section of your website?',
    seccion_eliminada: 'Section deleted correctly',
    publicar: 'Publish',
    despublicar: 'Unpublish',
    modificar_precios: 'Modify Prices',
    redes_sociales: 'Social Networks',
    ejemplo_web: 'Example of web page',
    en_este_apartado: 'In this section you configure the data that will appear on your website',
    legend:
      'Photo, name, medical specialty and profile data are collected automatically from the data set on your profile.',
    legend_significance: 'Meaning of the fields to fill',
    write_address: 'Enter the address of your company',
    write_web: 'Enter the link of your particular website, if you already have one.',
    write_socials: 'Enter the links of your operational social networks',
    write_sections: 'You can add the sections you want including information',
    go_to_my_web: 'Go to my website',
    free_text: 'Free text',
    list: 'List',
  },
  publicLandingPage: {
    consultationAddress: 'Consultation address',
    consultationPhone: 'Consultation number',
    call: 'Call now',
    availableServicesTitle: 'Available services',
    collegiateNumber: 'Collegiate Nº',
    viewMap: 'View in Google Maps',
  },
  copyButton: {
    onError: 'Ops, something went wrong, try again!',
    onSuccess: 'Copied!',
  },
  suscripciones: {
    suscripcion: 'Subscription',
    suscripciones: 'Subscriptions',
    nueva: 'New Subscription',
    nueva_titulo: 'New Monthly Subscription',
    editar: 'Edit Subscription',
    tarifa_plana: 'Flat Rate',
    a_medida: 'Custom Subscription',
    sin_suscripciones: 'No subscription has been configured',
    destinatario: 'Who can hire it',
    explicacion_destinatario: 'When selecting anyone this service will appear in the marketplace',
    servicio: 'Service',
    ilimitado: 'Unlimited',
    servicios_incluidos: 'Included services',
    sesiones: 'session | sessions',
    error_no_servicios: 'You have not included any service. Press the button (+) to add services',
    delete: 'Remove Subscription',
    confirm_delete: 'Are you sure you want to delete the subscription?',
    suscripcion_contratados: 'My Subscriptions',
    sin_suscripciones_contratadas: 'At this time you do not have any hired subscription',
    mensajeria_restantes: 'Remaining Messaging',
    videoconsulta_restantes: 'Remaining Videoconsults',
    cancelar_suscripcion: 'Cancel Subscription',
    noty_to_all:
      'If the subscription price changes, all active subscriptions will be affected and the patients will be notified.',
    cancelar_suscripcion_texto:
      'The subscription will be canceled from {dia}. Meanwhile, the patient can continue to use this service. Are you sure you want to cancel the subscription?',
    cancelado_correctamente:
      'The subscription has been cancelled successfully. No more charges will be made to the patient.',
  },
  reserva: {
    titulo: 'Book your training appointment',
    titulo_especialidad: 'Select your medical specialty',
    texto1:
      'We encourage you to schedule an appointment with our Medical Director to discover all the tools {name} offers.',
    texto2: 'Do yo wish to book the appointment?',
    boton: 'Book',
    reserva_confirmada: 'Appointment Booked.',
    ha_hecho_una_reserva: 'You have scheduled an appointment with Dr. Medina.',
    recibira_invitacion_por_correo: 'You will receive a confirmation by email..',
    se_reservara_a_las: 'You are booking an appointment on {dia} at {hora}',
    seleccione_fecha_hora: 'Choose the date and time you wish to receive the training.',
    reunirse_roberto: 'Meeting with Dr. Roberto Medina',
  },
  producto: {
    nuevo: 'New Product',
    editar: 'Edit Product',
    buscar: 'Search Product',
    nombre_required: 'The name of the product is required.',
    precio_required: 'The product price is required.',
    creado: 'The product has been created correctly.',
    licencia: 'Number of licences',
  },
  pago: {
    titulo: 'Subscription (Secure Payment)',
    titulo_stripe: 'Subscription',
    pagar_suscripcion: 'Purchase Subscription',
    cuenta_activada_correctamente: 'The account has been activated correctly.',
    ciclo: 'Billing cicle: based on your license description',
    periodo_duracion: 'Valid until: undefined',
    explicacion:
      "You will be charged immediately this month's payment and for every following month, a recurring payment will be charged for the same amount until the service is cancelled.",
  },
  steps: {
    espacio: 'My {name} Page',
    datos_facturacion: 'Billing information',
    clinical_data: 'Clinical Information',
    representative_data: 'Representative Data',
    dni_anverso_subido: 'UPLOADED FRONT DOCUMENT ID.',
    dni_reverso_subido: 'UPLOADED BACK DOCUMENT ID.',
    tarjeta_identificacion_subido: 'UPLOADED ID CARD DOCUMENT',
    subir_dni_anverso: 'UPLOAD FRONT DOCUMENT ID.',
    subir_dni_reverso: 'UPLOAD BACK DOCUMENT ID.',
    subir_tarjeta_identificacion: 'UPLOAD ID CARD DOCUMENT',
    fiscal_address: 'Fiscal address',
    iban_payment_module_message:
      'It is necessary that you complete the IBAN field, with the bank account data, when you intend to make payments to patients, for any of the appointments or consultations made through this platform, and configure the POS for online collections.',
    iban_required: 'Enter a valid IBAN',
    iban_invalid: 'IBAN is invalid, enter a valid IBAN',
    cp_invalid: 'Postal code is not correct',
    identification_doc_required: 'Enter a clear image of the Identity Document',
    identification_card_required: 'It is mandatory to add the identification card',
    archivos_validos: 'Only supports files of type pdf, jpg or png',
    saving_data: 'Saving the data provided.',
  },
  resenas: {
    titulo_publicar: 'Publish Review',
    titulo_despublicar: 'Unpublish Review',
    texto_publicar: 'Are you sure you want to publish this review?',
    texto_despublicar: 'Are you sure you want to unpublish this review?',
    titulo_eliminar: 'Delete Review',
    texto_eliminar: 'Are you sure you want to delete this review?',
  },
  dispositivo: {
    new_entidad: 'New Entity',
    entidad: 'Entity | Entities',
    new_local: 'New Premises',
    local: 'Premises | Premises',
    new_persona: 'New Contact Person',
    persona_contacto: 'Contact Person | Contact PersonS',
    persona: 'Contact Person | Contact PersonS',
    new_dispositivo: 'New Device',
    dispositivo: 'Device | Devices',
    venta: 'Sale',
    alquiler: 'Rent',
    titulo_eliminar: 'Delete {tipo}',
    texto_eliminar: 'Are you sure you want to delete this {tipo}?',
  },
  reportes: {
    informe_pontgrup: 'Pont-Grup activity reports',
    informe_productividad: 'Productivity Report',
    tiempos_atencion_consulta: 'Consultation Attention Times',
    cumplimiento_servicio: 'Service Compliance Control',
    num_altas: 'New Subscriptions',
    num_bajas: 'Cancellations',
    num_renov: 'Renovations',
    total: 'Total',
    seleccione_profesional: 'It is mandatory to select a professional',
    filter_hospital: 'Hospital filter',
    filter_professional: 'Profesional filter',
    filter_from_date: 'Start date',
    filter_to_date: 'End date',
    activation_mails: 'Activation mails sent',
    activated_accounts: 'Activated accounts',
    num_videocons: 'Video Consultation',
    num_chats: 'Chats',
    num_patients: 'Registered patients',
    num_electronic_presc: 'Electronic Prescriptions emited',
    num_pdf_presc: 'PDF Prescriptions emited',
    num_logins: 'Logins',
    num_registers: 'Num. Regs',
    graph_accounts: 'Active accounts/inactive',
    graph_activity: 'Registered Activity',
    chart_electronic_presc: 'Electronic presc.',
    chart_pdf_presc: 'PDF prescriptions',
    active_accounts: 'Actives',
    inactive_accounts: 'Inactives',
    date_filters: 'Date Filters',
  },
  permissions: {
    dashboard: 'Dashboard',
    conf_permissions: 'Permission Settings',
    conf_styles: 'Styles Settings',
    save_permissions: 'Save Permissions',
    save_styles: 'Save Styles',
    users: 'Doctors',
    companies: 'Companies',
    products: 'Products',
    devices: 'Devices',
    patients: 'Patients',
    videoConsultation: 'Video Consultation',
    calendar: 'Calendar',
    billing: 'Billing',
    messaging: 'Chat',
    prescriptions: 'PDF prescription',
    packsHired: 'Packs',
    subscriptionsHired: 'Subscriptions',
    reviews: 'Reviews',
    configServices: 'Services Settings',
    reports: 'Reports',
    settingsBook: 'Settings - Prescription Pad',
    settingsBilling: 'Settings - Billing',
    settingsRates: 'Settings - Rates',
    settingsSign: 'Settings - Sign',
    settingsDiary: 'Settings - Diary',
    settingsPacks: 'Settings - Packs',
    settingsCoupons: 'Settings - Coupons',
    settingsLanding: 'Settings - Landing',
    settingsReceptionist: 'Settings - Assistant',
    settingsSpecialties: 'Settings - Specialties',
    settingsServices: 'Settings - Services',
    settingsDaysNotAvailable: 'Settings - Days not Available',
    settingsDaysAvailable: 'Settings - Available Days',
    contact: 'Contact',
    help: 'Help',
    haveCreateVideoWithTel: 'Video Consultation by phone',
    adminSimplified: 'Admin Simplified',
    marketplace: 'Marketplace',
    showIbanPaymentModuleMessage: 'Show IBAN Message',
    simplifiedLanguages: 'Hide Languages (PT/PL/AR)',
    hideStripeButton: 'Hide Stripe Button',
    hideFormBooking: 'Hide Form Booking',
    hideTrialMessage: 'Hide Trial Message',
    clinicalHistory: 'Clinical History',
    anesthesia: 'Pre-anesthesia form',
    documents: 'Documents',
    haveElectronicPrescription: 'E-prescription',
    myMessages: 'Patients - Messages',
    myVideoconsultations: 'Patients - Video Consultations',
    myPrescriptions: 'Patients - Prescriptions',
    prescriptionsFilteredList: 'Pending Prescriptions',
    mySubscriptions: 'Patients - Subscriptions',
    myDocuments: 'Patients - Documents',
    sendUsMessage: 'Send us a Message',
    settingsBeneficiaries: 'Beneficiary adjustments',
    importCsvOnUsersList: 'Import CSV Users',
    showWelcomeModal: 'Show Welcome Popup',
    prescriptionsList: 'Batch prescriptions',
    moduleMedicalTests: 'Module testing',
    personalDoctor: 'Personal doctor',
    personalDoctorPatient: 'Patient',
    personalDoctorProfessional: 'Professional',
    personalDoctorAssistant: 'Assistant',
    minorsTreatment: 'Minors treatment',
    hidePatientCreation: 'Hide patient creation',
    companyNotifications: 'Company notifications',
    viaEmail: "Via e-mail",
    viaWhatsapp: "Via Whatsapp",
  },
  recipe: {
    diagnostic: 'Diagnostic',
    chronic: 'Chronic',
    confidential: 'Confidential',
    patient_instructions: 'Patient instructions',
    pharmacist_warnings: 'Indications for the pharmacist',
    comments: 'Comments',
    treatment_created: 'The treatment has been created successfully',
    sending: 'Sending',
    err_empty_diagnostic: 'You must complete the diagnostic field',
    err_empty_datestart: 'You must fill in the start date',
    err_empty_dateend: 'You must fill in the end date',
    err_empty_posology: 'You must fill in the posology',
    err_empty_duration: 'You must fill in the duration',
    err_empty_guideline: 'You must fill in the guideline',
    err_empty_tincode: 'You must fill in the TIN Code',
    err_empty_medicine: 'You must select a medicine',
    err_empty_composition: 'You must fill in the composition',
    unit: 'Unit',
    units: 'Units',
    recipe: 'Prescription',
    print_recipe: 'Print recipe',
    tin_code: 'TIN Code',
    master_formula: 'Master Formula',
    master_formula_placeholder: 'Write the formula here',
    medicine: 'Medicine',
    medicines: 'Medicines',
    search_medicine: 'Type the name of the medication to look for',
    search_active_ingredient: 'Search by active substance',
    search_patient: 'Search by patient name',
    add_medicines: '+ Add medication',
    type_electronic: 'Electronics',
    type_pdf: 'PDF',
    type: 'Type',
    sel_type: 'Select type',
    create_treatment: 'Create Treatment',
    create_treatment_treat: 'Create Treatment',
    years: 'years',
    change_patient: 'Change patient',
    allergies: 'Allergies',
    num_recipe: 'Num Prescription',
    last_disp: 'Last Disp',
    next_disp: 'Next Disp',
    active: 'Active',
    inactive: 'Inactive',
    confirm_delete_treatment: 'Are you sure you want to delete the treatment?',
    confirm_delete_prescription: 'Are you sure you want to delete the prescription?',
    confirm_emit_prescription: 'Do you want to emmit this prescription?',
    create_prescription: 'Create prescription',
    edit_prescription: 'Edit prescription',
    typology: 'Typology',
    pending: 'Pending',
    partially_dispensed: 'Partially dispensed',
    dispensed: 'Dispensed',
    download: 'Download prescription',
    narcotic: 'Narcotic',
    generic: 'Generic',
    hours: 'hours',
    days: 'days',
    weeks: 'weeks',
    months: 'months',
    month: 'month',
    year: 'years',
    during: 'During',
    every: 'every',
    created_at: 'Created at',
    frequency: 'Frequency',
    new_prescription: 'Create Electronic prescription',
    emmited: 'Issued',
    save_prescription: 'Save prescription',
    confirm_emmit_prescription_text: 'This action will send the prescription to your patient',
    see_prescription: 'See prescription',
    choose_prescription_type: 'Choose type of prescription',
    paper_recipe: 'PDF prescription',
    title: 'E-prescription',
    from: 'from',
    to: 'to',
    fromShort: 'from',
    toShort: 'to',
    created_date: 'Created date',
    filter_by_created_date: 'Filter by creation date',
    more_information: 'More information',
    prescriptions: 'Prescriptions',
    premium_title: 'Unlock Premium content',
    patient_dni_required:
      "The patient must have the ID number filled to issue a prescription, go to the patient's personal data and fill in the ID number",
    reject_prof: 'Reject',
    reject_without_notification: 'Reject without notification',
    reject_with_notification: 'Ask for more information',
    master_formulas: 'Master Formulas',
    add_master_formula: 'Add master formula',
    psychotropic: 'Psychotropic',
    composition: 'Composition',
    pharmaceuticalForm: 'Pharmaceutical Form',
    administrationWay: 'Administration Way',
    dosage: 'Dosage',
    observations: 'Observations',
    err_master_formulas: 'You must complete all the required fields of the master formulas',
    medicine_detail: 'Medicine detail',
    medicine_information: 'Medicine information',
    registration_number: 'Registration number',
    authorized: 'Authorized',
    revoked: 'Revoked',
    discontinued: 'Discontinued',
    package_leaflet: 'Package leaflet',
    technical_sheet: 'Technical sheet',
    active_ingredient: 'Active ingredient',
    renew_prescription: 'Renew prescription',
    cancel_prescription: 'Cancel prescription',
    select_reason: 'Choose the reason for cancellation',
    cancel_reason_0: 'Precautionary lock confirmed by term',
    cancel_reason_1: 'Cancellation due to end of treatment',
    cancel_reason_2: 'Treatment change',
    cancel_reason_3: 'Incomplete or wrong data',
    canceled: 'Canceled',
    canceled_message: 'Prescription canceled',
    anulate_medicament: 'Cancel',
    anulate_indications: 'Anulate reasons',
    anulate_message: 'Medicament is anulated',
    unlock_medicament: 'Unlock',
    unlock_indications: 'Unlock reasons',
    unlock_message: 'Medicament unlocked',
    emitted: 'Emitted',
    expired: 'Expired',
    finished: 'Finished',
    in_queue: 'In proccess to emit',
    units_dispensed: 'Box or doses dispatched',
    navigate_to_prescription: 'Go to Prescription',
  },
  medicine: {
    filter_by_fav: 'Filter by favorites',
  },
  medicament_status: {
    0: 'Dispensable in the future',
    1: 'Dispensable',
    2: 'Cautiously Locked',
    3: 'Dispensed',
    4: 'Dispensed with replacement',
    5: 'Expired (Ten days passed)',
    6: 'Visa pending',
    7: 'Visa rejected',
    8: 'Partially dispensed',
    9: 'Master Formula under development',
    10: 'Partially dispensed with substitution',
    101: 'Canceled',
  },
  medicament_lock_reason: {
    0: 'Higher than the maximum permissible dose',
    1: 'Possible allergy or intolerance',
    2: 'Contraindication',
    3: 'Treatment finished',
    4: 'Others',
  },
  service: {
    name: 'Service of {name}',
    more_services: 'More Services',
  },
  signup: {
    dont_have_account_yet: "Don't have an account yet? Sign up",
    title_patient: 'Sign up as a Patient',
    subtitle_patient: 'Join {name} and get a consultation with health professionals from everywhere',
    title_professional: 'Request information about {name}',
    subtitle_professional: 'Join {name} and connect with your patients from everywhere',
    i_am_patient: 'I am Patient',
    i_am_professional: 'I am Healthcare Professional',
    start_enjoying: 'Start enjoying the benefits of using the platform {name}',
    signup_successfully: 'You have successfully registered',
    selector_patient_text: 'Register as a patient, and start enjoying our platform.',
    selector_professional_text: 'Request information about our digital health platform.',
    check_your_inbox: 'Please check your email account to activate your account',
    select_professional: 'Select a professional ...',
    select_center: 'Select the center you belong to...',
    valueTooShort: 'Field must contain at least 3 letters',
    valueTooLong: 'Field must not contain more than 20 letters',
    do_you_already_have_an_account: 'Do you already have an account? Log in',
    typeCompany: {
      title: 'Company',
      insurance: 'Insurance',
      hospital: 'Hospital',
      clinic: 'Clinic',
      pharmaceutical: 'Pharmaceutical',
      company: 'Company',
      other: 'Other',
    },
    annotation: 'Tell us how we can help you',
    request_send: 'Your request has been sent.',
    request_send_text: 'We have received your request, within a maximum period of 24 to 48 hours we will contact you.',
  },
  subscriptions: {
    name: 'Subscription | Subscriptions',
    not_have_hired: 'You have no subscription contracted',
    renewal: 'Renewal',
    expire: 'Expire',
    request_new_service: 'Request new service',
    confirm_cancel_explain:
      'This service will not be available from {date} Are you sure you want to cancel your subscription?',
    cancelled_ok: 'Subscription has been successfully canceled',
  },
  videoconsultations: {
    confirmed: 'Confirmed',
    pending_confirm: 'Pending confirmation',
    pending_payment: 'Payment pending',
    go_to_video: 'Enter video consultation',
    pending_confirm_explain: 'The professional must confirm the video consultation',
    pay_video: 'Pay video consultation',
    pay: 'Pay',
    to_access: 'Access',
    expire_at: 'Expire at',
    download_recording: 'Download recording',
    show_video: 'Show video consultation',
    seconds: 'Seconds',
    paymentPatientNotCorresponding:
      'We have detected that there is a session started that does not correspond to that of the patient to whom the video consultation is directed. To pay, please log out and try again.',
  },
  session: {
    title: 'Session Timeout',
    subtitle: 'Your online session will expire in',
    advice: 'Please click "Continue" to keep working or click "Log Off" to end your session now',
    close_session: 'Log Off',
    continue: 'Continue',
    second: 'second | seconds',
  },
  webphone: {
    calling: 'Calling',
    mute: 'Mute',
    pause: 'Pause',
    speaker: 'Speaker',
    hang_up: 'Hang Up',
    call: 'Call',
    new_call: 'Phone Call',
    please_enter_number: 'Please enter a phone number',
    please_enter_valid_number: 'Please enter a valid phone number',
    please_enter_valid_es_number: 'Please enter a valid spanish phone number',
    an_error_ocurred: 'An error ocurred!',
    retry: 'Retry',
    token_expired: 'Token expired',
    webphone: 'Webphone',
  },
  commonModules: {
    telField: {
      modalTitle: 'Select Country',
      modalSearch: 'Search',
      mostUsedCountries: 'Most used countries',
      allCountries: 'All countries',
      searchResults: 'Search results',
    },
  },
  incompatibleBrowser: {
    title: 'Unsupported browser',
    message: 'You are using a web browser that is not supported by Docline.',
    title2: '',
    message2: '',
    helpCenterMessage: 'If you want to know which are our compatible browsers',
    helpCenterLinkName: 'visit our help article.',
    helpCenterLink: 'https://help.docline.es/article/75-supportedbrowsers',
  },
  triage: {
    title: 'Triage',
    code: 'Triage code',
    generate_code: 'Generate code',
    start: 'Start triage',
    explain: 'This code allows the patient to start the triage on their own.',
    finish: 'Finish triage',
    code_error: 'Triage code invalid!',
    type_code: 'Introduce the triage code to start.',
  },
  registro: {
    professional_registration_process: 'Health professional registration process',
    init_session: 'Log in',
    have_an_account: 'Already have an account?',
    personal_info: 'Personal information',
    please_complete_info: 'Please complete your professional information.',
    documents: 'Documents',
    add_passport: 'Please attach your passport.',
    add_nie: 'Please attach your NIE on both sides.',
    add_dni: 'Please attach your identity card on both sides.',
    front_image: 'Image front side',
    rear_image: 'Image rear side',
    accept_conditions:
      'I have read and accept the <a href="{privacyPolicyLink}" target="_blank">terms of use and privacy policy</a>',
    drag_or_select_file: 'Drag the image here or <span class="filepond--label-action open">select the file</span>.',
    save_ok_title: 'Successfully saved',
    save_ok_text:
      'You have successfully registered, you will receive an email when we validate your details and you can start using our service.',
    alert_front_image: 'You must enter the image of the front side of the document.',
    alert_rear_image: 'You must enter an image of the back side of the document',
    alert_accept_conditions: 'You must accept the terms of use and privacy policy.',
    alert_image_max_size: 'The image must not exceed 2MB',
    alert_image_format: 'The format of the image must be .png o .jpg',
    alert_input_required: 'Mandatory field',
    alert_phone_required: 'Telephone is mandatory',
    alert_phone_is_invalid: 'The phone is invalid',
    alert_email_required: 'E-mail is mandatory',
    alert_email_is_invalid: 'E-mail must be valid',
    alert_number_required: 'The license number is mandatory',
    alert_number_is_invalid: 'The license number is not valid',
  },
  verifyusers: {
    to_be_verified: 'To be verified',
    verified: 'Verified',
    verified_pendings: 'Pending verification',
    reject: 'Reject',
    loading: 'Loading',
  },
  medicalTests: {
    analytical_tests_list: 'Analytical tests list',
    analytical_tests_list_types: 'Types of analytical tests',
    created_at: 'Created at',
    code: 'Code',
    description: 'Description',
    new_test: 'New test',
    delete_test: 'Delete analytical test.',
    confirm_delete_test: 'Are you sure you want to delete this analytical test?',
    new_type_of_test: 'New type of test',
    test_type_code: 'Test type code',
    tests_included: 'Tests included',
    observations: 'Observations',
    invalidCodeFormat: 'Invalid code format',
    almostOneAct: 'Must add at least one act',
    description_too_short: 'Description too short',
    create_test_success: 'Type test create success',
    new_analytical_test: 'New test',
    no_data: 'No data available',
    nomenclator: 'Nomenclature',
    new_act: 'New act',
    new_nomenclator: 'New nomenclature entry',
    edit_nomenclator: 'Editar nomenclature entry',
    specialty: 'Specialty',
    group: 'Group',
    collection: 'Collection',
    subgroup: 'Subgroup',
    codeCiepq: 'CIEP-9 Code',
    invalidCiep9: 'The format must be a 4-digit numeric (XXXX)',
    codeCie9: 'CIE-9 Code',
    addAnotherCode: 'Add another code',
    codesCie9: 'CIE-9 codes',
    externalId: 'External ID',
    actDescription: 'Act description',
    search: 'Search',
    request_medical_test: 'Request medical test',
    request_analytical_test: 'Request analytical test',
    patient_info: 'Patient Information',
    select_medical_test: 'Select medical test',
    select_medical_test_type: 'Select medical test type',
    analytical_test: 'Analytical test',
    other_medical_tests: 'Other medical tests',
    medical_tests_requested: 'Requested medical tests',
    other_medical_tests_requested: 'Other requested medical tests',
    medical_test_description: 'Medical test description',
    medical_test_recommendations: 'Medical test recommendations',
    medical_test_request: 'Request',
    add_act: 'Add act',
    delete_act: 'Delete act',
    select_acts: 'Select acts',
    confirm_delete_act: 'Are you sure you want to delete the act?',
    select_some_test: 'Select a test',
    select_patient: 'Select a patient',
    request_medical_test_success: 'Request generated successfully',

    request_rx_test: 'Request rx test',
    rx_test: 'RX test',
    rx_structured_view: 'Structured view',
    rx_simple_view: 'Simple view',
    rx_modality: 'Modality',
    rx_anatomical_region: 'Anatomical region',
    rx_exploration: 'Exploration',
    rx_search_hint: 'Search RX tests',
    rx_not_found: 'We have not found tests with that description',
    rx_protocols: 'RX protocols',
    new_rx_protocol: 'New RX protocol',
    request_other_test: 'Request other tests',
    other_test: 'Other test',
  },
  contracts: {
    you_have_pending_contracts:
      'You have been registered as a medical professional for patient care from {name}. Are you happy to proceed?',
    reject_success: 'You have rejected request of {name}',
    accept_success: 'You have accepted request of {name}',
    select_profile: 'Select an user',
    contracts: 'Channel Contracts',
    empty_list: 'You currently have no contract assigned',
    channel: 'Channel',
    accepted: 'Accepted',
    rejected: 'Rejected',
    pending: 'Pending',
  },
  personal_doctor: {
    personal_doctor: 'Personal doctor',
    personal_doctors_historical: 'Historical personal doctors',
    personal_doctor_present: 'Present',
    loading_personal_doctors: 'Loading professionals list...',
    not_found_personal_doctors: 'We have not found professionals with this name.',
    network_error: 'There was a problem loading the list of professionals.',
    network_error_short: 'There was a problem loading the list',
    retry: 'Retry',
    pending_assignment: 'Assignment pending',
    is_your_personal_doctor: 'I am your personal doctor',
  },
  videoconsultation_selector: {
    select_videoconsultation: 'Select a video consultation',
    loading_videoconsultations: 'Loading video consultations...',
    not_found_videoconsultations: 'No video consultations registered',
  },
  select: {
    retry: 'Volver a intentar',
  },
  document_category_selector: {
    category: 'Category',
    select: 'Select an option',
  },
  tirea: {
    access: "Prescripción de pruebas"
  },
  company_notifications: {
    title: 'Company notifications',
    info_tooltip: "The periodicity of the notifications will be fixed, being sent the day before at 8 p.m.",
    via_email: "Via E-Mail",
    via_whatsapp: "Via Whatsapp",
    failed_set_config: "A problem occurred while updating the Notification Settings. Please try again later.",
    save_config: "Save configuration"
  }
};
