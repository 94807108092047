import getPermissionSettings from './getPermissionSettings';

const getRoutesAllowed = (routesPermissions, modulesAllowed) => {
  return Object.keys(modulesAllowed).reduce((carry, key) => {
    if (modulesAllowed[key]) {
      return Array.isArray(routesPermissions[key])
        ? [...carry, ...routesPermissions[key]]
        : [...carry, routesPermissions[key]];
    }

    return carry;
  }, []);
};

export default (permissions, accessDeniedRoute = 'AccessDenied') => {
  const routesPermissions = getPermissionSettings('routes');

  return (to, from, next) => {
    const routesAvailable = Object.values(routesPermissions);
    const routesAllowed = getRoutesAllowed(routesPermissions, permissions);

    if (routesAvailable.includes(to.name) && !routesAllowed.includes(to.name)) {
      next({ name: accessDeniedRoute });
    } else {
      next();
    }
  };
};
