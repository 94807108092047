<template>
  <v-btn
    v-shortkey="[text]"
    color="grey"
    large
    icon
    fab
    class="mx-3"
    @click="$emit('click', text)"
    @shortkey="$emit('click', text)"
  >
    <div>
      <div class="text-h4 grey--text text--darken-2">{{ text }}</div>
      <div v-if="showCaption" class="caption">&nbsp;{{ caption }}&nbsp;</div>
    </div>
  </v-btn>
</template>
<script>
export default {
  props: {
    text: { type: String, required: true },
    caption: { type: String, default: '' },
  },
  data() {
    return {
      showCaption: true,
    };
  },
};
</script>
