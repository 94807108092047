<template>
  <v-card
    rounded
    :dark="isDark"
    shaped
    flat
    width="300"
    height="520"
    class="keypad text-center animate__animated animate__fadeIn"
  >
    <v-layout v-if="!isError" column align-center align-content-end fill-height>
      <v-layout column align-center flex-grow-1 flex-shrink-0 class="pt-4">
        <template v-if="isCalling">
          <div class="grey--text text--darken-1" style="height: 24px">
            {{ $t('webphone.calling') }}
            <call-timer :call-status="callStatus" />
          </div>

          <h2 class="text-h5 d-inline-block text-truncate" style="max-width: 230px">
            {{ name }}
          </h2>
          <div class="grey--text text--darken-1 mb-10" style="height: 24px">
            {{ phone.number }}
          </div>

          <d-avatar :rounded="true" :text="name" :size="170" :two-letter="true" class="mb-5" :src="image" />

          <v-layout align-center class="mb-3">
            <control-btn
              :disabled="callStatus !== 'answered'"
              :active="isMuted"
              :loading="isLoadingMuted"
              :tooltip="$t('webphone.mute')"
              icon="microphone-off"
              @click="toggleMute"
            />
            <control-btn
              :disabled="callStatus !== 'answered'"
              :active="isHold"
              :loading="isLoadingHold"
              :tooltip="$t('webphone.pause')"
              icon="pause"
              @click="toggleHold"
            />
            <control-btn
              :disabled="callStatus !== 'answered'"
              :active="isEarmuff"
              :loading="isLoadingEarmuff"
              :tooltip="$t('webphone.speaker')"
              icon="volume-off"
              @click="toggleEarmuff"
            />
          </v-layout>
        </template>
        <template v-if="!isCalling">
          <div class="grey--text text--darken-1" style="height: 24px">
            &nbsp;
            <span v-if="getVars.number === phoneNumber">{{ name }}</span>
            &nbsp;
          </div>
          <v-text-field
            v-model="phoneNumber"
            type="tel"
            :autofocus="!phoneNumber"
            :prefix="phonePrefix"
            class="text-h5 grey--text text--darken-3 text-center pt-0 ml-10 mr-5"
            :class="{ 'mr-10': !phoneNumber }"
            x-large
            :error="isError"
            :error-messages="errorMessage"
          >
            <template slot="append-outer">
              <v-btn
                v-show="phoneNumber"
                v-shortkey="['backspace']"
                color="grey"
                icon
                @click="backspace"
                @shortkey="backspace"
              >
                <v-icon color="grey">mdi-backspace</v-icon>
              </v-btn>
            </template>
          </v-text-field>
          <v-layout align-center class="mb-3">
            <dial-btn text="1" caption="" @click="press" />
            <dial-btn text="2" caption="ABC" @click="press" />
            <dial-btn text="3" caption="DEF" @click="press" />
          </v-layout>
          <v-layout align-center class="mb-3">
            <dial-btn text="4" caption="GHI" @click="press" />
            <dial-btn text="5" caption="JKL" @click="press" />
            <dial-btn text="6" caption="MNO" @click="press" />
          </v-layout>
          <v-layout align-center class="mb-3">
            <dial-btn text="7" caption="PQRS" @click="press" />
            <dial-btn text="8" caption="TUV" @click="press" />
            <dial-btn text="9" caption="WXYZ" @click="press" />
          </v-layout>
          <v-layout align-center class="mb-3">
            <dial-btn text="*" caption="" @click="press" />
            <dial-btn text="0" caption="+" @click="press" />
            <dial-btn text="#" caption="" @click="press" />
          </v-layout>
        </template>
      </v-layout>
      <call-btn :loading="isLoading" :calling="isCalling" @click="controlCallClick" />
    </v-layout>
    <v-layout v-if="isError" column align-center justify-center fill-height>
      <div>
        <v-icon size="90" class="mb-10 error--text">mdi-alert</v-icon>
        <h2 class="text-h5 mb-10">{{ $t('webphone.an_error_ocurred') }}</h2>
        <v-btn text color="primary" class="mb-10" @click="logIn">{{ $t('webphone.retry') }}</v-btn>
      </div>
    </v-layout>
  </v-card>
</template>

<script>
import jwtDecode from 'jwt-decode';

import ConversationClient from 'nexmo-client';
import parsePhoneNumber from 'libphonenumber-js';
import axios from 'axios';

import i18n from '../../../engine/framework/modules/i18n';
import DAvatar from '@/components/DAvatar';
import DialBtn from './DialBtn';
import CallBtn from './CallBtn';
import ControlBtn from './ControlBtn';
import CallTimer from './CallTimer';

import { webphoneUrl } from '@/config/config';

export default {
  name: 'WebPhone',
  layout: 'webphone',
  i18n,
  components: {
    DAvatar,
    DialBtn,
    CallBtn,
    ControlBtn,
    CallTimer,
  },
  props: {
    isDark: {
      type: Boolean,
      default: () => false,
    },
    image: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    number: {
      type: String,
      default: '',
    },
    apiUrl: {
      type: String,
      default: webphoneUrl,
    },
  },

  data() {
    return {
      enableKeyAudio: false,
      backspaceAudio: null,
      keypadAudio: null,

      userJwt: null,
      tokenExp: 0,
      app: null,
      call: null,

      member: null,
      phonePrefix: '+34',
      phoneNumber: '',

      phone: {
        number: '',
        isValid: false,
        country: {},
      },

      isLoading: true,
      isCalling: false,
      callStatus: 'waiting',
      callInProgress: false,
      isError: false,
      errorMessage: null,
      getVars: {},
      allowPremiumFeatures: false,

      isMuted: false,
      isEarmuff: false,
      isHold: false,

      isLoadingMuted: false,
      isLoadingEarmuff: false,
      isLoadingHold: false,
    };
  },
  computed: {
    isTokenExpired() {
      return this.tokenExp < (new Date().getTime() + 1) / 1000;
    },
  },
  beforeDestroy() {
    if (this.callInProgress) {
      return this.hangUp();
    }
  },
  created() {
    this.logIn();
  },
  mounted() {
    if (!this.isOnline) {
      this.offlineEvent();
    }
    this.$on('offline', this.offlineEvent);
    this.$on('online', this.onlineEvent);

    if (this.number) {
      this.phone = parsePhoneNumber(this.number, 'ES');
      if (this.phone.isValid) {
        this.phoneNumber = this.phone.nationalNumber;
      } else {
        this.phoneNumber = null;
      }
    }

    // FIXME: load audio
    this.keypadAudio = new Audio('/keypad.mp3');
    this.backspaceAudio = new Audio('/backspace.mp3');
  },
  methods: {
    async logIn() {
      this.isLoading = true;
      this.isError = false;
      try {
        //TODO: vuex
        const token = JSON.parse(window.localStorage.getItem('auth_ehealth'))['access_token'];

        const result = await axios.post(
          this.apiUrl + '/auth',
          {},
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
        this.userJwt = result.data.jwt;
        const decoded = jwtDecode(this.userJwt);
        this.tokenExp = decoded.exp;
        await this.joinConversation();
      } catch (err) {
        this.$log.error(err);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async joinConversation() {
      this.$log.debug('WebPhone join conversation');
      try {
        this.conversationClient = new ConversationClient({
          debug: false,
        });
        this.app = await this.conversationClient.login(this.userJwt);
        this.app.on('member:call', this.memberCallEvent);
        this.app.on('call:status:changed', this.callStatusChangedEvent);
        this.app.on('*', 'NXM-errors', this.nxmErrorsEvent);
        this.app.on('rtcstats:report', this.rtcstatsReportEvent);
      } catch (err) {
        this.isError = true;
        this.$log.error(err);
      }
    },
    memberCallEvent(member, call) {
      this.call = call;
      this.member = member;
    },
    callStatusChangedEvent(call) {
      this.$log.debug('WebPhone Call Status Changed: ' + call.status);
      this.isCalling = this.callInProgress =
        ['machine', 'timeout', 'unanswered', 'rejected', 'busy', 'failed', 'completed'].indexOf(call.status) === -1;

      this.callStatus = call.status;
    },
    nxmErrorsEvent(err) {
      if (err && err.type) {
        this.$log.error('Error thrown with type ' + err.type, err);
      }
    },
    rtcstatsReportEvent(mos, report, conversation, mos_report) {
      this.$log.debug('call quality (MOS)', mos);
      if (mos_report) {
        this.$log.debug('mos_report', mos_report);
      }
    },
    toggleHold() {
      this.$log.debug('WebPhone toggleHold');
      const members = this.call.conversation.members;
      const action = this.isHold ? 'unhand' : 'hold';

      const member = [...members].find(item => {
        if (item[1].channel.type === 'phone') {
          return item[1];
        }
      });

      this.isLoadingHold = true;
      axios
        .get(this.apiUrl + '/' + action + '?id=' + member[1].channel.id)
        .then(res => {
          this.$log.debug(res);
          this.isHold = !this.isHold;
        })
        .catch(err => {
          this.$log.error(err);
        })
        .finally(() => {
          this.isLoadingHold = false;
        });
    },
    toggleMute() {
      this.$log.debug('WebPhone toggleMute');
      this.isLoadingMuted = true;
      this.member
        .mute(!this.isMuted)
        .then(() => {
          this.isMuted = !this.isMuted;
        })
        .catch(err => {
          this.$log.error(err);
        })
        .finally(() => {
          this.isLoadingMuted = false;
        });
    },
    toggleEarmuff() {
      this.$log.debug('WebPhone toggleEarmuff');
      this.isLoadingEarmuff = true;
      this.member
        .earmuff(!this.isEarmuff)
        .then(() => {
          this.isEarmuff = !this.isEarmuff;
        })
        .catch(err => {
          this.$log.error(err);
        })
        .finally(() => {
          this.isLoadingEarmuff = false;
        });
    },
    shortKeyEvent(event) {
      if (this.isCalling) {
        return;
      }
      switch (event.srcKey) {
        case 'backspace':
          this.backspace();
          break;
        default:
          this.press(event.srcKey);
          break;
      }
    },
    backspace() {
      this.errorMessage = null;
      if (this.phoneNumber) {
        if (this.enableKeyAudio) {
          this.backspaceAudio.play();
        }
        this.phoneNumber = this.phoneNumber.slice(0, -1);
      }
    },
    press(key) {
      if (this.enableKeyAudio) {
        this.keypadAudio.play();
      }
      if (!this.phonePrefix && !this.phoneNumber && key === '0') {
        return (this.phoneNumber += '+');
      }
      this.phoneNumber += key;
    },
    validateSpainNumber(number) {
      const patt = new RegExp(/^[6789][0-9]{8}$/);
      return patt.test(number);
    },
    hangUp() {
      this.$log.debug('WebPhone hangUp');
      this.isLoading = true;
      this.call
        .hangUp()
        .then(() => {
          this.$log.debug('WebPhone call ended');
        })
        .catch(err => {
          this.$log.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async controlCallClick() {
      if (!this.isOnline) {
        this.offlineEvent();
        return;
      }

      if (this.isCalling && !this.callInProgress) {
        return;
      }

      if (this.isTokenExpired) {
        this.$log.error(this.$t('webphone.token_expired'));
        return;
      }

      if (this.callInProgress) {
        return this.hangUp();
      }

      if (!this.phoneNumber) {
        this.errorMessage = this.$t('webphone.please_enter_number');
        return;
      }

      this.phone = parsePhoneNumber(this.phoneNumber, 'ES');

      if (!this.phone || !this.phone.isValid) {
        this.errorMessage = this.$t('webphone.please_enter_valid_number');
        return;
      }

      if (!this.validateSpainNumber(this.phoneNumber)) {
        this.errorMessage = this.$t('webphone.please_enter_valid_es_number');
        return;
      }

      this.isCalling = true;
      this.isLoading = true;
      this.$log.debug('WebPhone callServer');
      try {
        await this.app.callServer(this.phone.number);
      } catch (err) {
        this.$log.error(err);
        if (err.type === 'session:error:not-found') {
          this.$log.error(err.description);
        }
        this.isCalling = false;
      } finally {
        this.isLoading = false;
      }
    },
    offlineEvent() {
      this.$toast.error(this.$t('common.you_are_offline'), {
        duration: 5000,
        dismissible: true,
      });
    },
    onlineEvent() {
      this.$toast.success(this.$t('common.you_are_online'));
    },
  },
};
</script>
<style>
.v-snack:not(.v-snack--absolute) {
  z-index: 1100;
}
.keypad {
  margin: 0 auto;
}
.keypad .d-avatar svg {
  border: 2px solid #fff;
}
.keypad .v-btn .caption {
  height: 20px;
}
.keypad .v-btn .caption {
  height: 20px;
  margin-top: -6px;
}
.keypad .v-text-field .v-input__append-outer {
  margin: 0;
}
.keypad .v-text-field > .v-input__control > .v-input__slot {
  padding-right: 0 !important;
}
.keypad .v-btn:not(.v-btn--text):not(.v-btn--outlined):focus:before {
  opacity: 0 !important;
}
/*
.blur-bg {
  overflow: hidden;
}

.blur-bg::before {
  background: url() center center no-repeat;
  background-size: cover;
  filter: blur(8px);
  content: " ";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: -1;
  opacity: 0.4;
}
*/
</style>
