// @ts-check
import Vue from 'vue';
import Router from 'vue-router';
import store from '../../engine/framework/modules/store'; // TODO: STORE MUST BE INSIDE SRC
import { authUserService } from '@/services/auth-user';
import { accessControl } from '@/services/permissions';
import { hasOnlyAdminMeta, hasRequiresPatientMeta, hasForbiddenPatientMeta } from './business';
import paths, { baseNameRoutes } from './paths';
import environment from "../environment";

Vue.use(Router);

const router = new Router({
  base: '/',
  mode: 'history',
  linkActiveClass: 'active',
  routes: paths,
});


router.beforeEach((to, from, next) => {
  try {
    const authUser = authUserService.get();

    if (!environment.isKeycloakIntegration) {
      const isSessionExpired = authUserService.checkIsSessionIsExpired();
      if (isSessionExpired && !to.meta.public) {
        authUserService.remove();
        store.dispatch('app/loadConfigFromBrand');
        next({ name: baseNameRoutes.login });
        return;
      }
    }

    if (to.meta.requiresAuth) {
      if (authUser?.access_token) {
        if (
          (hasOnlyAdminMeta(to) && !authUserService.isAdminReceptionistOrRRHHRol()) ||
          (hasRequiresPatientMeta(to) && !authUserService.isPatientRol()) ||
          (hasForbiddenPatientMeta(to) && authUserService.isPatientRol())
        ) {
          next({ name: baseNameRoutes.dashboard });
        }

        if (to.name.includes('account')) {
          router.push({ name: baseNameRoutes.dashboard }).catch(_ => ({}));
        }

        if (to.name === 'Contacto' && store.getters['app/hasHelpService']) {
          if (!store.state.app.intercom.hide) {
            window.Intercom('show');
          } else if (store.state.app.helpScout.enabled) {
            window.Beacon('open');
          }
        } else {
          accessControl(store.state.app.permissions, baseNameRoutes.accessDenied)(to, from, next);
        }
      }
      // User has not token
      else {
        if (to.name === baseNameRoutes.help) {
          next({ name: baseNameRoutes.faq });
        } else {
          const pathRequiresAuth = {
            fullPath: to.fullPath,
            date: new Date(),
          };
          window.localStorage.setItem('pathRequiresAuth', JSON.stringify(pathRequiresAuth));
          router.push({ name: baseNameRoutes.login }).catch(_ => ({}));
        }
      }
    } else {
      if (authUser && authUser.access_token) {
        if (to.name === baseNameRoutes.signedLink) {
          next({ name: baseNameRoutes.signed });
        } else if (
          [
            baseNameRoutes.blockchain,
            baseNameRoutes.electronicPrescription,
            baseNameRoutes.registryPrescription,
            baseNameRoutes.publicLanding,
            baseNameRoutes.payAppointment,
          ].includes(to.name)
        ) {
          next();
        } else {
          if ((from.fullPath === '/' || from.name === baseNameRoutes.notFound) && to.name === baseNameRoutes.login) {
            next({ name: baseNameRoutes.dashboard });
          } else {
            next();
          }
        }
      } else {
        next();
      }
    }
  } catch (error) {
    console.log('An error has ocurred on Router', error);

    authUserService.remove();
    store.dispatch('app/loadConfigFromBrand');
    next({ name: baseNameRoutes.login });
  }
});

export default router;
