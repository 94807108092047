import environment from '@/environment';

const parsePermissions = (parentPermissions, permissions) => {
  return Object.keys(parentPermissions).reduce(
    (carry, key) => {
      return {
        ...carry,
        [key]: permissions[key],
      };
    },
    {}
  );
};

const parseCompanyPermissions = (parentPermissions, permissions) => {
  return Object.keys(parentPermissions).reduce(
    (carry, key) => {
      return {
        ...carry,
        [key]: parentPermissions[key] && !environment.permissions?.notConfigurable?.includes(key) ? permissions[key] : parentPermissions[key],
      };
    },
    {}
  );
};

// TODO: THIS METHOD IS TEMP, ONLYE DEMO PROPOSE
const buildRequireImages = (env, envDefault) => {
  const getFile = path => require(`@/assets/brands/${path}`);

  return file => {
    try {
      return getFile(`${env}/${file}`);
    } catch (e) {
      try {
        return getFile(`${envDefault}/${file}`);
      } catch (error) {
        return null;
      }
    }
  };
};

export const getUserPermissions = user => {
  const superAdminRol = 6;
  const { permissions } = environment;

  const roles = {
    1: 'admin',
    2: 'user',
    3: 'receptionist',
    4: 'rrhh',
    5: 'patient',
    6: 'superadmin',
  };

  const globalPermissions =
    user.company && user.company.permissions && typeof user.company.permissions !== 'undefined'
      ? parseCompanyPermissions(permissions.global, user.company.permissions)
      : permissions.global;

  const userRol = user.isSuperAdmin ? superAdminRol : user.rol;

  const selectedPermissions =
    typeof user.permissions !== 'undefined' && user.permissions ? user.permissions : permissions.roles[roles[userRol]];

  return parsePermissions(globalPermissions, selectedPermissions);
};

export const getBrandImages = name => {
  const req = buildRequireImages(name, 'docline');

  return {
    logoPrimary: req('logo-primary.png'),
    logoSecondary: req('logo-secondary.png'),
    logoToolbar: req('logo-toolbar.png'),
    favicon: req('favicon.png'),
    loginBackground: req('login-background.png'),
  };
};

export const reorderOptions = object => {
  return object.sort(function(a, b) {
    let keyA = a.name.toLowerCase(),
      keyB = b.name.toLowerCase();
    keyA = keyA.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    keyB = keyB.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });
};
