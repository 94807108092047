export default {
  state: {
    user: null,
    contracts: [],
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getContracts(state) {
      return state.contracts;
    },
    alreadyCheckedContracts(state) {
      return state.alreadyCheckedContracts;
    },
  },
  actions: {
    setUser(context, payload) {
      context.commit('setUser', payload);
    },
    setContracts(context, payload) {
      context.commit('setContracts', payload);
    },
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setContracts(state, payload) {
      state.contracts = payload;
    },
  },
};
