import { ref, watch } from 'vue';
import { getHeader, marketplaceUrl } from '@/config/config';

const CONTRACTS_ENDPOINT = marketplaceUrl + 'received-contracts';

export function useContracts({ root }) {
  const contracts = ref([]);
  const loading = ref(true);

  const toggleLoading = force => {
    if (typeof force !== 'undefined') {
      loading.value = force;
    } else {
      loading.value = !loading.value;
    }
  };

  const fetchContracts = async email => {
    toggleLoading(true);

    const raw = await fetch(`${CONTRACTS_ENDPOINT}?email=${email}&status=pending`, {
      headers: getHeader(),
    });
    const body = await raw.json();
    contracts.value = body.contracts;
    toggleLoading(false);
  };

  watch(
    () => contracts.value,
    currentValue => {
      root.$store.dispatch('user/setContracts', currentValue);
    }
  );

  return {
    contracts,
    loading,
    fetchContracts,
  };
}
