export default [
  {
    name: 'APP_LOGOUT',
    callback: function(_) {
      this.snackbar = {
        show: true,
        color: 'green',
        text: this.$t('common.closed_session'),
      };
      if (null !== window.localStorage.getItem('channelNotifications')) {
        this.$pusher?.unsubscribe(JSON.parse(window.localStorage.getItem('channelNotifications'))['name']);
        window.localStorage.removeItem('channelNotifications');
      }
      this.$router.replace({ path: '/login' });
    },
  },
  {
    name: 'SESSION_EXPIRED',
    callback: function(_) {
      this.snackbar = {
        show: true,
        color: 'amber darken-2',
        text: this.$t('common.closed_session_expired'),
      };
      this.$store.dispatch('app/loadConfigFromBrand');
      this.$router.replace({ path: '/login' });
    },
  },
  {
    name: 'FIRMA_TIMEOUT',
    callback: function(_) {
      this.snackbar = {
        show: true,
        color: 'amber darken-1',
        text: this.$t('talonario.timeoutfirma'),
      };
      this.$router.replace({ path: '/login' });
    },
  },
  {
    name: 'FIRMA_OK',
    callback: function(_) {
      this.snackbar = {
        show: true,
        color: 'green',
        text: this.$t('success.save_firma'),
      };
      this.$router.replace({ path: '/login' });
    },
  },
];
