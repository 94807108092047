import { RouteConfig } from 'vue-router';
import { authUserService } from '@/services/auth-user';
import environment from '@/environment';
import { LANDING_COMMON_PATH, GOOGLE_AUTHENTICATOR_PATH } from '@/config/config';

import {
  accountNamePaths,
  accountRoutes,
  messagingNamePaths,
  messagingRoutes,
  patientsNameRoutes,
  patientsRoutes,
  prescriptionsNamePaths,
  prescriptionsRoutes,
  professionalsNamePaths,
  professionalsRoutes,
  servicesNamePaths,
  servicesRoutes,
  settingsNamePaths,
  settingsRoutes,
  usersNamePaths,
  usersRoutes,
  videoconsultationNamePaths,
  videoconsultationRoutes,
  medicaltestsNamePaths,
  medicaltestsRoutes,
} from './routes';

export const baseNameRoutes = {
  root: 'Root',
  logout: 'Logout',
  accessDenied: 'AccessDenied',
  dashboard: 'Dashboard',
  notFound: 'NotFound',
  publicLanding: 'Landing',
  serverError: 'ServerError',
  notifications: 'Notificaciones',

  ...accountNamePaths,
  ...usersNamePaths,
  ...patientsNameRoutes,
  ...prescriptionsNamePaths,
  ...professionalsNamePaths,
  ...messagingNamePaths,
  ...servicesNamePaths,
  ...settingsNamePaths,
  ...videoconsultationNamePaths,
  ...medicaltestsNamePaths,
};

export const homePage = (): string => {
  let homePage = baseNameRoutes.dashboard;

  try {
    if (authUserService.isPatientRol() && environment.homePage?.patient) {
      homePage = environment.homePage.patient;
    }
  } catch (e) {
    //
  }

  return homePage;
};

const routes: RouteConfig[] = [
  ...prescriptionsRoutes,
  ...accountRoutes,
  ...usersRoutes,
  ...patientsRoutes,
  ...professionalsRoutes,
  ...messagingRoutes,
  ...servicesRoutes,
  ...settingsRoutes,
  ...videoconsultationRoutes,
  ...medicaltestsRoutes,
  {
    path: `/${LANDING_COMMON_PATH}/:slug`,
    meta: { public: true, requiresAuth: false },
    name: 'LandingPage',
    component: () =>
      import(
        /* webpackChunkName: "landing" */
        `@/views/LandingPage.vue`
      ),
  },

  {
    path: `/${GOOGLE_AUTHENTICATOR_PATH}`,
    meta: { public: true, requiresAuth: false },
    name: 'GoogleAuthenticatorRedirect',
    component: () =>
      import(
        /* webpackChunkName: "2fa" */
        `@/views/GoogleAuthenticatorRedirect.vue`
      ),
  },

  {
    path: '/dashboard',
    meta: { breadcrumb: true, requiresAuth: true },
    name: baseNameRoutes.dashboard,
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "dashboard" */
        `@/views/Dashboard.vue`
      ),
  },

  {
    path: '/notificaciones',
    meta: { breadcrumb: false, requiresAuth: true },
    name: baseNameRoutes.notifications,
    component: () =>
      import(
        /* webpackChunkName: "notificaciones" */
        `@/views/NotificacionesAll.vue`
      ),
  },
  {
    path: '/chat',
    meta: { breadcrumb: false, requiresAuth: true },
    name: 'chat',
    component: () =>
      import(
        /* webpackChunkName: "chat" */
        `@/views/Chat.vue`
      ),
  },

  {
    path: '/media',
    meta: {},
    name: 'Media',
    props: route => ({ type: route.query.type }),
    component: () =>
      import(
        /* webpackChunkName: "media" */
        `@/views/Media.vue`
      ),
  },

  {
    path: '/404',
    meta: {
      public: true,
    },
    name: baseNameRoutes.notFound,
    component: () =>
      import(
        /* webpackChunkName: "not-found" */
        `@/views/NotFound.vue`
      ),
  },

  {
    path: '/403',
    meta: {
      public: true,
    },
    name: baseNameRoutes.accessDenied,
    component: () =>
      import(
        /* webpackChunkName: "access-denied" */
        `@/views/Deny.vue`
      ),
  },

  {
    path: '/500',
    meta: {
      public: true,
    },
    name: baseNameRoutes.serverError,
    component: () =>
      import(
        /* webpackChunkName: "server-error" */
        `@/views/Error.vue`
      ),
  },

  {
    path: '/',
    meta: { public: false, requiresAuth: true },
    name: baseNameRoutes.root,
    redirect: {
      name: homePage(),
    },
  },

  {
    path: '/logout',
    meta: { public: false, requiresAuth: true },
    name: baseNameRoutes.logout,
    component: () =>
      import(
        /* webpackChunkName: "server-error" */
        `@/views/Logout.vue`
        ),
  },

  {
    // TODO: FIXME!!
    path: '*',
    meta: { public: true, requiresAuth: false },
    name: baseNameRoutes.publicLanding,
    component: () =>
      import(
        /* webpackChunkName: "landing" */
        `@/views/Landing.vue`
      ),
  },
];

export default routes;
