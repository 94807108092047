<template>
  <v-sheet align-center class="flex-grow-0 flex-shrink-0 pt-2 pb-8 transparent">
    <v-hover v-slot:default="{ hover }">
      <div>
        <v-btn
          v-if="calling"
          v-tooltip="$t('webphone.hang_up')"
          :loading="loading"
          :elevation="hover ? 12 : 2"
          large
          depressed
          fab
          color="error darken-1"
          @click="$emit('click')"
          ><v-icon>mdi-phone-hangup</v-icon>
        </v-btn>
        <v-btn
          v-if="!calling"
          v-tooltip="$t('webphone.call')"
          :loading="loading"
          :elevation="hover ? 12 : 2"
          large
          depressed
          fab
          color="success"
          @click="$emit('click')"
          ><v-icon>mdi-phone</v-icon>
        </v-btn>
      </div>
    </v-hover>
  </v-sheet>
</template>
<script>
export default {
  props: {
    loading: Boolean,
    calling: Boolean,
  },
};
</script>
