import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';

// TODO: move key to another location
const RECAPTCHA_SITE_KEY = '6LcOVhoaAAAAAF6-hcr6T4SqHSZn-FDpWeRhM7MP';

Vue.use(VueReCaptcha, {
  siteKey: RECAPTCHA_SITE_KEY,
  loaderOptions: { autoHideBadge: true },
});
