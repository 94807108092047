export default {
  incompatibleBrowser: {
    title: 'Ya puedes crear recetas electrónicas',
    message:
      'Tu cuenta ha sido activada correctamente y desde hoy puedes empezar a enviar recetas electrónicas a tus pacientes.',
    title2: '¿Cómo acceder a la plataforma?',
    message2:
      'Para acceder a la plataforma, hazlo a través del enlace directo que encontrarás en tu escritorio "Acceso Receta Electrónica" O accede desde cualquier otro dispositivo utilizando el navegador Chrome, Safari o Firefox a través de esta URL: vithas.docline.es ',
    helpCenterMessage: 'Consulta o descárgate cómo crear una receta electrónica',
    helpCenterLinkName: 'AQUÍ',
    helpCenterLink: 'https://vithas.es/getmedia/e0930fac-a8d6-42fb-a414-19040388635d/eReceta_Profesionales.aspx',
  },
};
