import {
  A_MINUTE_IN_SECONDS,
  ROL,
  VALUE_IS_INVALID,
  INACTIVE_USER_TIME_THRESHOLD_IN_MINUTES,
} from './auth-user.constants';
import { AuthUser } from './auth-user.model';

export const isProfessional = (rol: AuthUser['rol']) =>
  [ROL.PROFESSIONAL].includes(rol);

export const isReceptionist = (rol: AuthUser['rol']) =>
  [ROL.RECEPTIONIST].includes(rol);

export const isAdminReceptionistOrRRHH = (rol: AuthUser['rol']) =>
  [ROL.ADMIN, ROL.RECEPTIONIST, ROL.RRHH].includes(rol);

export const isAdmin = (rol: AuthUser['rol']) => rol === ROL.ADMIN;

export const isPatient = (rol: AuthUser['rol']) => rol === ROL.PATIENT;

export const checkIfSessionsIsExpired = (lastActivityTime: AuthUser['lastActivityTime']): boolean => {
  const lastActivityDate = new Date(lastActivityTime).getTime();

  if (lastActivityTime && !Number.isNaN(lastActivityDate)) {
    const dateNow = new Date().getTime();
    const diffTimeInMinutes = Math.round((dateNow - lastActivityDate) / A_MINUTE_IN_SECONDS);

    return diffTimeInMinutes >= INACTIVE_USER_TIME_THRESHOLD_IN_MINUTES;
  } else {
    throw new Error(VALUE_IS_INVALID);
  }
};
