export default (authUser, data) => {
  const dateLogin = new Date();
  const dateAction =
    dateLogin.getFullYear() +
    '-' +
    (dateLogin.getMonth() + 1) +
    '-' +
    dateLogin.getDate() +
    ' ' +
    dateLogin.getHours() +
    ':' +
    dateLogin.getMinutes() +
    ':' +
    dateLogin.getSeconds();

  return {
    ...authUser,
    email: data.usuario.email,
    name: data.usuario.name,
    fullName: data.perfil.fullName,
    id: data.usuario.id,
    img: data.perfil.img,
    lang: data.perfil.idioma,
    nombreEmpresa: data.empresa.nombre,
    avatarEmpresa: data.empresa.avatar,
    logoEmpresa: data.empresa.logo,
    servicios_activos: data.perfil.servicios_activos,
    rol: data.usuario.rol,
    trial: data.perfil.trial,
    start_trial: data.perfil.start_trial,
    formReserva: data.perfil.formReserva,
    formPago: data.perfil.formPago,
    formStepToStep: data.perfil.stepToStep,
    permisos: data.perfil.permisos,
    belong_to: data.perfil.belong_to,
    collegiateNumber: data.perfil.collegiateNumber,
    color: data.empresa.color,
    colorHexadecimal: data.empresa.colorHexadecimal,
    date_action: dateAction,
    permissions: data.usuario.permissions,

    company: {
      themeColorPrimary: data.empresa.themeColorPrimary || data.empresa.colorHexadecimal,
      themeColorSecondary: data.empresa.themeColorSecondary || data.empresa.colorHexadecimal,
      permissions: data.empresa.permissions || null,
    },
  };
};
