export default () => {
  if (typeof localStorage !== 'undefined') {
    const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));
    const smsActive = authUser['environment']['sms_active'];

    return typeof smsActive !== 'undefined' ? smsActive : true;
  }

  return true;
};
