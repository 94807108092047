import axios from 'axios';
import { getHeader, getProfileUrl } from '@/config/config';
import { authUserService, TWO_FA_AUTH_USER_KEY } from '@/services/auth-user';
import { filterSpecialties, getSpecialtiesAsStrings } from '@/utils/professional-utils';
import {
  editImageUserUrl,
  getHeaderMultimedia,
  saveProfileUrl,
  enableTwoFaUrl,
  disableTwoFaUrl,
  twoFaDownloadAppSms,
} from '@/config/config';
import app from './app';
import { TWO_FA_HEADER, TWO_FA_KEY } from '@/config/constants';

const _getImageFormData = ({ id, image }) => {
  const form = new FormData();
  form.append('usuario_id', id);
  form.append('user_id', id);
  form.append('from', 'usuario');
  form.append('file', image, 'filename.png');

  return form;
};

const _getUserPhone = ({ phoneConsultation = null, phone = null } = {}, isProfessional) =>
  isProfessional ? phoneConsultation : phone;
const _setUserPhoneKey = isProfessional => (isProfessional ? 'phoneConsultation' : 'phone');

export default {
  state: {
    authUser: null,
    userId: '',
    data: null,
    editMode: false,
  },

  getters: {
    getHeaderData({ data }) {
      if (!data) {
        return null;
      }

      const { fotoUrl, especialidades, all_especialidades, collegiateNumber, id_document, email, name, surname } = data;

      const filteredSpecialties = filterSpecialties(all_especialidades)(especialidades);
      const specialties = filteredSpecialties && filteredSpecialties.map(item => item[`name_${app.state.locale}`]);

      return {
        image: fotoUrl,
        specialties: getSpecialtiesAsStrings(specialties),
        name,
        surname,
        collegiateNumber: collegiateNumber,
        idDocument: id_document,
        email,
      };
    },

    getBodyData({ data }, getters) {
      if (!data) {
        return null;
      }

      const { lang: locale, phone_prefix: prefix, biography_en: bioEn, biography_es: bioEs, gender } = data;

      return {
        bioEn,
        bioEs,
        phone: _getUserPhone(data, getters.isProfessionalRole),
        prefix,
        locale,
        gender,
      };
    },

    isPatientRole({ authUser }) {
      return authUser && !!authUser.isPatient;
    },

    isProfessionalRole({ authUser }) {
      if (!authUser) return;
      return authUser.rol === 2;
    },

    isAdminRole({ authUser }) {
      if (!authUser) return;

      const { isSuperAdmin } = authUser;
      return !!isSuperAdmin;
    },

    twoFaEnabled({ authUser }) {
      if (!authUser) return;

      const { otp_enabled: twoFaEnabled } = authUser;
      return !!twoFaEnabled;
    },
  },

  actions: {
    setAuthUserData({ commit }) {
      const data = {
        authUser: authUserService.get(),
        userId: authUserService.getUserId(),
      };

      commit('SET_AUTH_USER_DATA', data);
    },

    async fetchAccountData({ commit, state: { userId: id } }) {
      if (!id) {
        console.error('User id is not defined');
        return;
      }

      try {
        const { data } = await axios.post(
          getProfileUrl,
          { id },
          {
            headers: getHeader(),
          }
        );

        commit('SET_ACCOUNT_DATA', data);
      } catch (e) {
        console.info(`Error fetching data in Vuex 'fetchAccountData' action. ${e}`);
      }
    },

    async handleAvatarUpload({ commit, state: { userId: id } }, image) {
      const formData = _getImageFormData({ id, image });
      try {
        const { status, data: { imagen } = {} } =
          (await axios.post(editImageUserUrl, formData, {
            headers: getHeaderMultimedia(),
          })) || {};

        if (status !== 200) {
          return false;
        }

        commit('SET_NEW_AVATAR', imagen);
        commit('SET_AUTH_USER', { [TWO_FA_AUTH_USER_KEY]: 'img', value: imagen });

        return true;
      } catch (e) {
        console.info(`Error saving data in Vuex 'handleAvatarUpload' action. ${e}`);
        return false;
      }
    },

    async saveUserData(
      { commit, state: { data, userId: id }, getters },
      { prefix, phone, locale, bioEn, bioEs, name, surname, dni, gender }
    ) {
      try {
        const user = {
          ...data,
          phone_prefix: prefix,
          [_setUserPhoneKey(getters.isProfessionalRole)]: phone,
          lang: locale,
          biography_en: bioEn,
          biography_es: bioEs,
          name,
          surname,
          id_document: dni,
          gender,
        };

        const { status = null, data: { estado = null } = {} } =
          (await axios.post(saveProfileUrl, { id, user }, { headers: getHeader() })) || {};

        if (status !== 200 || estado !== 1) {
          return false;
        }

        commit('SET_ACCOUNT_DATA', user);
        commit('SET_AUTH_USER', { key: 'lang', value: user.lang });

        return true;
      } catch (e) {
        console.info(`Error saving data in Vuex 'saveUserData' action. ${e}`);
      }
    },

    closeAccountSession({ commit }) {
      commit('RESET_STATE');
    },

    handleEditMode({ commit }, value) {
      commit('SET_EDIT_MODE', value);
    },

    async enableTwoFa({ commit, state: { data: accountData } }, secret) {
      try {
        const { status } = await axios.post(enableTwoFaUrl, { secret }, { headers: getHeader() });

        const success = status === 200;

        commit('SET_ACCOUNT_DATA', {
          ...accountData,
          [TWO_FA_KEY]: success,
        });
        commit('SET_AUTH_USER', { key: TWO_FA_KEY, value: success });

        return success;
      } catch (e) {
        console.info(`Error saving data in Vuex 'enableTwoFa' action. ${e}`);

        return false;
      }
    },

    async disableTwoFa({ commit, state: { data: accountData } }, code) {
      try {
        const { status } = await axios.post(
          disableTwoFaUrl,
          {},
          { headers: { ...getHeader(), [TWO_FA_HEADER]: code } }
        );
        const success = status === 200;

        commit('SET_ACCOUNT_DATA', {
          ...accountData,
          [TWO_FA_KEY]: false,
        });
        commit('SET_AUTH_USER', { key: TWO_FA_KEY, value: false });

        return success;
      } catch (e) {
        console.info(`Error saving data in Vuex 'disableTwoFa' action. ${e}`);
        return false;
      }
    },

    async sendSms(_, telephone) {
      try {
        const { status } = await axios.post(twoFaDownloadAppSms, { telephone }, { headers: getHeader() });
        return {
          success: status === 200,
          status,
        };
      } catch ({ response = {} }) {
        const { status } = response;

        return {
          success: false,
          status,
        };
      }
    },

    setTwoFaStatus({ commit, state: { data: accountData } }, payload) {
      commit('SET_ACCOUNT_DATA', {
        ...accountData,
        [TWO_FA_KEY]: payload,
      });
      commit('SET_AUTH_USER', { key: TWO_FA_KEY, value: payload });
    },
  },

  mutations: {
    SET_AUTH_USER_DATA(state, { authUser, userId }) {
      state.authUser = authUser;
      state.userId = userId;
    },

    SET_ACCOUNT_DATA(state, data) {
      state.data = data;
    },

    SET_NEW_AVATAR(state, image) {
      state.data.fotoUrl = image;
    },

    SET_AUTH_USER(state, { key, value }) {
      state.authUser[key] = value;
      authUserService.set(state.authUser);
    },

    RESET_STATE(state) {
      state.authUser = null;
      state.userId = '';
      state.data = null;
    },

    SET_EDIT_MODE(state, value) {
      state.editMode = value;
    },
  },
};
