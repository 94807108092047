export default {
  asignar: {
    texto_video: '¿Estás seguro que deseas asignarte esta consulta y por tanto te compromete a atender al cliente?',
    texto_sms:
      '¿Estás seguro que deseas enviar un SMS al profesional para notificarle de que hay clientes a la espera de ser atendidos?',
    texto_mensaje: '¿Estás seguro que deseas asignarte este mensaje y por tanto te comprometes a atender al cliente?',
  },
  common: {
    paciente: 'Cliente',
  },
  cupones: {
    usarlo_cualquiera: 'Puede ser utilizado por cualquier cliente.',
    form_codigo: 'Indica el código que utilizará el cliente para aplicar el cupón',
    form_descuento: 'Indica el descuento que tendrá el cliente al aplicar el cupón',
  },
  errors: {
    not_access_patient: 'Ha ocurrido un error intentando acceder a la ficha del cliente',
    edit_patient: 'Ha ocurrido un error al editar al cliente',
    edit_user: 'Ha ocurrido un error al editar al profesional',
    existing_patient: 'El cliente ya se encuentra registrado en {name}',
    phone_video_error:
      'No puedes seleccionar más de un cliente para crear cita a partir del número de teléfono del cliente',
    max_patients_multi: 'No puedes seleccionar más de tres clientes para una multiconsulta',
  },
  mensajeria: {
    sin_mensajes_abiertos: 'En este momento, no tiene mensajería abierta a respuesta con ningún cliente',
    confirm_atendida:
      'El servicio de mensajería va a ser marcado como atendido. Si el cliente desea volver a contactar deberá solicitar un nuevo servicio de mensajería. ¿Estás seguro que deseas cerrar este servicio de mensajería?',
    confirmar_delete_file:
      'Si eliminas el documento el cliente no podrá acceder a él. ¿Estás seguro que deseas eliminar el documento?',
  },
  menu: {
    patients: 'Clientes',
  },
  packs: {
    eliminar_texto:
      'El Pack dejará de poder ser contratado por los clientes, los Packs contratados de este Pack no se verán afectados y podrán ser completados con total normalidad.',
  },
  patient: {
    patients: 'Clientes',
    without_patients: 'Aún no tienes ningún cliente vinculado',
    search: 'Buscar cliente',
    new: 'Nuevo cliente',
    edit: 'Editar cliente',
    delete: 'Eliminar cliente',
    confirm_delete:
      'El cliente seleccionado dejará de recibir tus servicios. ¿Estás seguro que deseas eliminar el cliente?',
    delete_success: 'El cliente se ha eliminado correctamente',
    delete_error: 'Ha ocurrido un error eliminando al cliente. Prueba de nuevo más tarde',
    sin_diagnosticos: 'Aún no se le ha realizado ningún {name} a este cliente',
    sin_evoluciones: 'Aún no se le ha realizado ninguna evolución a este cliente',
    sin_documentos: 'El cliente no dispone de documentos',
    sin_chequeos: 'El cliente no dispone de chequeos',
    sin_recetas: 'Aún no se le han creado recetas al cliente',
    notas: 'Notas internas (el cliente no podrá ver esta información ni aparecerá en el informe)',
    enviado_mensaje_vincular:
      'El cliente ya se encuentra registrado, se le ha enviado al cliente un correo electrónico para que acepte el vínculo entre profesional y cliente',
    pacientes_tutelados: 'Usuarios asociados a tu cuenta',
    sin_common: 'El cliente no dispone de {name}',
    history_clinical: 'Historial del cliente',
    history_clinic: 'Historia cliente',
  },
  recetas: {
    instrucciones_paciente: 'INSTRUCCIONES AL USUARIO (si procede)',
    paciente_detalle: '(Nombre, apellidos, año de nacimiento y DNI, NIE o pasaporte)',
    required: {
      medicamento: 'Indica el medicamento que va a ser recetado',
      paciente: 'Es obligatorio seleccionar un cliente',
    },
  },
  success: {
    new_patient: 'El cliente ha sido creado correctamente',
    patient_exist: 'El cliente ya se encuentra registrado',
    patient_exist_otro_mail: 'El cliente ya se encuentra registrado con otro correo electrónico',
    new_user: 'El profesional ha sido creado correctamente',
    edit_patient: 'Se ha editado la información del cliente correctamente',
    edit_user: 'Se ha editado la información del profesional correctamente',
    save_user: 'Se ha guardado el profesional correctamente',
  },
  talonario: {
    confirmar_subida_talonario:
      '¿Estás seguro que deseas subir el talonario {msg} para usarlo cuando generes recetas a tus clientes?',
  },
  user: {
    telefono_paciente: 'Teléfono del cliente',
    female_doctor: 'Sra.',
    male_doctor: 'Sr.',
  },
  videoconsulta: {
    crear_para_paciente: 'Crear una {tipoVideo} para un cliente',
    solicitando_pago_texto:
      'Enváe una petición de pago por Email. Una vez su cliente haya realizado el pago, podrá enviar a su cliente la convocatoria para poder realizar la videoconsulta.',
    cita_cortesia_texto:
      'Estas citas son gratuitas para el cliente. Facilitar una cita con esta opción puede resultar adecuada para casos como:',
    cita_cortesia_li:
      'Poder repetir videoconsultas que por algún tipo de incidencia no han podido completarse. | Enviar una invitación, para realizar una primera consulta de prueba, o cualquier otro motivo que le anime a ello. | Clientes que han adquirido previamente paquetes de videoconsultas.',
    add_modocomunicacion: 'Indica la forma en la que se va a comunicar la cita al cliente',
    enviado_correctamente:
      'La consulta ha sido creada correctamente. Se ha notificado al cliente mediante correo electrónico',
    enviado_correctamente_sms: 'La consulta ha sido creada correctamente. Se ha notificado al cliente mediante SMS',
    enviado_recordatorio: 'Se ha enviado un recordatorio de la consulta al cliente mediante correo electrónico',
    confirm_delete2:
      'La consulta ya se encuentra pagada. Si es necesario que se realice el reembolso al cliente ponte en contacto con nosotros a través del chat.',
    notification_email: 'Se le enviará un correo electrónico al cliente confirmando la cancelación de la consulta',
    fixed_fee_message:
      'La cuota de gestión de este servicio es de {feeValue} € que se añadirá a tu tarifa en el momento del pago por parte del cliente.',
    percentage_fee_message:
      'La cuota de gestión de este servicio es del {feeValue}% con un mínimo de {feeMin}€ y un máximo de {feeMax}€ que se añadirá a tu tarifa en el momento del pago por parte del cliente.',
  },
  empresa: {
    share_patients: 'Compartir clientes',
    compartir_pacientes: 'Comparte clientes',
  },
  suscripciones: {
    noty_to_all:
      'Al modificar el precio de la suscripción, se alterarán todas las suscripciones activas y se notificará a los clientes suscritos',
    cancelar_suscripcion_texto:
      'El cliente dejará de disponer los servicios que incluye la suscripción a partir del día {dia} ¿Estás seguro que deseas cancelar tu suscripción?',
    cancelado_correctamente:
      'La suscripción ha sido cancelada correctamente. No se le realizarán más cargos al cliente',
  },
  steps: {
    iban_payment_module_message:
      'Es necesario que completes el campo IBAN, con los datos de la cuenta bancaria, cuando pretendas realizar cobros a los clientes, por alguna de las citas o consultas que se realicen a través de esta plataforma y configurar el TPV para cobros online',
  },
  // permissions: {
  //   patients: 'Pacientes',
  // }
};
