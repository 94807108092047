export default {
  card_number: 'Card Number',
  or: 'or',

  agenda: {
    lunes: 'Segunda-feira',
    martes: 'Terça-feira',
    miercoles: 'Quarta-feira',
    jueves: 'Quinta-feira',
    viernes: 'Sexta-feira',
    sabado: 'Saturday',
    domingo: 'Sunday',
    lunes_redu: 'Mon',
    martes_redu: 'Tue',
    miercoles_redu: 'Wed',
    jueves_redu: 'Thu',
    viernes_redu: 'Fri',
    sabado_redu: 'Sat',
    domingo_redu: 'Sun',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    horainicio: 'Data de inicio',
    horafin: 'Data de fim',
    configuracion: 'Configurar Agenda',
    disponibilidad: 'Calendário de Disponibilidades',
    nodisponible: 'Dias Indisponíveis',
    disponible: 'Available Days',
    sin_disponibles: 'Não tem configurado nenhum dia como indisponível nos próximos dias',
    nuevo_nodisponible: 'Criar Indisponível',
    editar_nodisponible: 'Editar Indisponível',
    diacompleto: 'Dia Completo',
    delete_nodisponible: 'Eliminar Dia Indisponível',
    confirm_delete_nodisponible: 'O dia passara a estar disponível, deseja continuar?',
    nuevo_rango: 'Nova Classificação',
    hora_inicio_dia: 'There is an error in the {dia} time range',
    hora_inicio_debe_ser: 'A Hora de Inicio deve ser anterior à Hora de Fim',
    sin_horas_asignadas: 'You currently have no assigned schedule',
  },
  appointments: {
    name: 'Video Consultation | Video Consultations',
    not_have_pending: 'You do not have pending video consultations.',
    error: 'There was a problem loading the last messages',
    onlineBookingNotAvailable: 'Online booking not available. Select another date',
    dateRequired: 'Please select the time suits you best for the video consultation',
  },
  asignar: {
    titulo: 'Asignar consulta',
    texto_video: '¿Esta seguro que desea asignarse esta videoconsulta y por tanto se compromete a atender al paciente?',
    texto_mensaje: '¿Esta seguro que desea asignarse este mensaje y por tanto se compromete a atender al paciente?',
    asignada_correctamente: 'Se ha asignado la consulta correctamente',
  },
  beneficiaries: {
    no_beneficiaries: "You haven't added people you care for yet",
    relationship: 'Relationship',
    new: 'New',
  },
  calendario: {
    especialidades: 'Calendar by Specialty',
    professionals: 'Professionals Calendar',
    select_professional: 'Select Professional',
    nodisponible: 'Not Available',
  },
  common: {
    search: 'Search',
    aceptar: 'Aceitar',
    condiciones: 'CONDIÇÕES GERAIS DE USO',
    cancel: 'Cancelar',
    ok: 'OK',
    confirm: 'Confirmar',
    save: 'Guardar',
    name: 'Nome',
    category: 'Category',
    lang: 'Idioma',
    lang_platform: 'Platform Language',
    lang_estracto: 'Biography Language',
    prescription: 'Receita | Receitas',
    documents: 'Documentos',
    chequeos: 'Chequeos',
    messaging: 'Mensagens',
    videoconference: 'Videoconsulta',
    videoconferences: 'Videoconsultas',
    presential: 'Face-to-face appointment',
    pack: 'Pacote',
    cita: 'Consulta',
    country: 'Country',
    province: 'Distrito',
    location: 'Localidade',
    cp: 'Código postal',
    address: 'Morada',
    spanish: 'Espanhol',
    english: 'Inglês',
    catalan: 'Catalão',
    portugues: 'Português',
    polaco: 'Polonês',
    date: 'Data',
    type: 'Type',
    size: 'Size',
    actions: 'Ações',
    date_up: 'Data Alta',
    of: 'De',
    changes: 'Mudanças',
    closed_session: 'Sessão fechada corretamente',
    closed_session_expired: 'Por motivos de segurança a sessão foi fechada',
    volver_atras: 'Voltar Atrás',
    acceso_denegado: 'Desculpe, acesso negado.',
    emitir_receta: 'Emitir receita',
    modify_recipe: 'Modify prescription',
    delete_recipe: 'Delete prescription',
    delete_recipe_confirm: 'Are you sure you want to delete the selected prescription?',
    emitSignPrescription: 'Sign and issue prescription',
    alreadyDiscardedPrescription:
      "You've created a new prescription within an expired treatment plan. Please start a new treatment and include the new prescription there.",
    descripcion: 'Descrição',
    obligatorio: 'Campo obrigatório',
    min_required: 'The field must have at least {tam} characters',
    paciente: 'Paciente',
    cualquiera: 'Qualquer',
    activado: 'Ativado',
    desactivado: 'Desativado',
    gratuita: 'Gratuita',
    gratuito: 'Gratuito',
    precio: 'Preço',
    duracion: 'Duração',
    detalles: 'Detalhes',
    hora: 'Hora',
    edit: 'Edit',
    delete: 'Apagar',
    emitir: 'Emitir',
    no_registros: 'Não há registos',
    status: 'Estado',
    estado_servicio: 'Status of Service',
    no_access: 'Não tem autorização para aceder a esta secção',
    prox_consultas: 'Próxima consulta | Próximas consultas',
    consultas_personales: 'Consultas Personales',
    ult_mensajes: 'Últimos mensagens',
    mes_redu: 'Jan | Fev | Mar | Abr | Mai | Jun | Jul | Ago | Set | Out | Nov | Dez',
    enviar_enlace: 'Enviar link',
    version_trial: 'Esta funcionalidade não se encontra ativa durante a versão de teste',
    titulo_salir_sin_guardar: 'Sair sem guardar',
    salir_sin_guardar: 'Há alterações não guardadas. Tem a certeza que quer sair sem guardar?',
    fecha_inicio: 'Start Date',
    fecha_fin: 'End Date',
    landing: 'Custom Landing Page',
    backgroundcolor: 'Background Color',
    nuevo_servicio: 'New Service',
    otro: 'Other',
    continuar: 'Next',
    filtrar: 'Filter',
    sin_atender: 'Consultas Sin Atender',
    medicos_disponibles: 'Médicos Disponibles',
    semana: 'Week',
    movil: 'phone',
    celular: 'phone',
    reconocimiento: 'Reconhecimentos médicos',
    apto: 'Fit',
    no_apto: 'Unfit',
    sin_verificar: 'Unverified',
    aviso_legal: 'Legal Notice',
    term_and_conditions: 'Terms and Conditions',
    term_and_conditions_signup: 'Terms and Conditions (Sign up)',
    term_and_conditions_without_tax: 'Terms without tax',
    politica_privacidad: 'Privacy Policy',
    politica_cookies: 'Cookies',
    aboutus: 'About us',
    title: 'Title',
    professional: 'Professional',
    numero_seg_social: 'Health Card Numbers',
    filters: 'Filters',
    yes: 'Yes',
    no: 'No',
    active_web: 'Activate web',
    inactive_web: 'Web disabled',
    close: 'Close',
    availableRoles: 'Available roles',
    undefined: 'Undefined',
    admin: 'Admin',
    receptionist: 'Assistant',
    rrhh: 'RRHH',
    patient: 'Patient',
    man: 'Man',
    woman: 'Woman',
    unspecified: 'Unspecified',
    more: 'More',
    taxPercent: 'Tax Percent',
    taxType: 'Tax Type',
    add: 'Add',
    fieldIncomplete: 'You must fill in the {field} field',
    download_pdf: 'Download PDF',
    show_details: 'Show details',
    go_to_profile: 'Go to profile',
    notice: 'Notice',
    accepted_at: 'Accepted at',
    chat: 'Chat',
    participants: 'Participants',
    consultation_subject: 'Reason for consultation',
    short_date_format: 'DD/MM/YYYY',
    subscriptions_temporarily_disabled:
      "We're sorry, subscriptions are temporarily disabled. Please try again in a few days.",
    show_to_hiring: 'Visible for hiring on the web',
    hide_to_hiring: 'Not visible for hiring on the web',

    date_placeholder: 'mm/dd/aaaa',
    years: 'years',
    max_n_characters: 'Max {chars} characters',
    return_back: 'Return',
  },
  cupones: {
    crear: 'Create Coupon',
    codigo: 'Code',
    descuento: 'Discount',
    caducidad: 'Expiration',
    num_usos: 'Number of uses',
    sin_cupones: 'No coupon has been configured',
    crear_nuevo: 'Create New Coupon',
    editar: 'Edit the coupon',
    eliminar_titulo: 'Remove the coupon',
    eliminar_texto: 'The coupon will no longer be active. Are you sure you want to delete the coupon?',
    min_code: 'The code must have at least 6 characters',
    tam_code: 'The code must have {num} characters',
    porusos: 'By uses',
    porfecha: 'By date',
    nocaduca: 'Does not expire',
    error_crear_existe_codigo: 'The coupon has not been created. A coupon with the code already exists: ',
    error_editar_existe_codigo: 'The coupon has not been edited. A coupon with the code already exists: ',
    success_create: 'The coupon has been created correctly',
    caduca_por_usos: 'This coupon expires by uses.',
    sin_caducidad: 'Coupon without expiration.',
    caduca_por_fecha: 'This coupon expires on ',
    usarlo_cualquiera: 'It can be used by any patient.',
    usarlo_paciente: 'Can only be used by: ',
    confirmGenericMessage: '¿Estás seguro? Esta acción no se puede deshacer',
    and: 'and',
    go_home: 'Go back',
    image_text: 'Colour image up to 5 MB and up to 8000 by 8000px in JPG format',
    dni_front: 'Upload your ID documentation (front)',
    dni_back: 'Upload your ID documentation (reverse)',
    price_v_standard: 'Standard V. price',
    range_of_hours: 'Range of hours',
    registration_status: 'Registration status',
  },
  datatable: {
    rows_per_page: 'Registos por página',
    all: 'Todos',
  },
  documentos: {
    nuevo: 'New File',
    confirmar_subida_titulo: 'Upload File',
    confirmar_subida_texto: 'Are you sure you want to upload the selected file?',
    subido_correctamente: 'The file has been uploaded correctly',
    csv_uploaded:
      'The file has been uploaded correctly. This process can take several time. If after half an hour the data has not been updated correctly, contact support',
    subir_reconocimiento: 'Faça o upload de um certificado de exame médico',
    subir_documento: 'Please upload a document that validates this decision.',
    subir_certificado: 'Upload certificate',
    subir_renuncia: 'Upload waiver',
    certificado: 'Certificate',
    renuncia: 'Waiver',
  },
  documents: {
    upload_document: 'Upload File',
    no_documents: 'You do not have documents',
    drag_and_drop: 'Drag and drop your file here',
    browse_file: 'browse file',
    supported_files: 'Supported files',
    size_limited: '{num} MB limited',
    upload_new: 'Upload New',
    select_professional: 'Health professional who wants to enable access to the document',
    are_you_sure_upload: 'Are you sure you want to upload a new document?',
    video: 'Video',
    chat: 'Chat',
    prescription: 'Prescription',
    triage: 'Triage',
    diagnosis: 'Diagnosis',
    medical_report: 'Medical Report',
    other: 'Document',
    electronic_prescription: 'Electronic Prescription',
  },
  errors: {
    not_access_patient: 'Ocorreu um erro ao aceder à ficha do paciente',
    edit_patient: 'Ocorreu um erro ao editar o paciente',
    edit_user: 'Ocorreu um erro ao editar o utilizador',
    value_required: 'Digite {value}',
    phone_consultation_required: 'É obrigatório preencher o telefone de consulta para ativar o gateway de pagamento',
    existing_patient: 'O paciente já se encontra registado no {name}',
    form_invalid: 'É favor preencher todos os campos obrigatórios',
    form_empty: 'O formulário está vazio, complete ao menos um campo',
    email_required: 'O email é Obrigatório',
    email_or_phone_required: 'E-mail / telefone é obrigatório',
    password_required: 'A Palavra-passe é Obrigatória',
    usr_pw_incorrect: 'Utilizador ou Palavra-passe incorretos',
    email_invalid: 'Mail inválido',
    email_repeat: 'Os emails não coincidem',
    try_again: 'Ocorreu um erro na operação, por favor tente novamente',
    save_history: 'Ocorreu um erro ao guardar o historial clínico',
    existing_user: 'Existe um utilizador registado com o mail ou com o nome do utilizador selecionado',
    existing_user_in_company:
      'There is already a user with that email and that company. Please, choose another email address or modify the company with which you want to associate this professional',
    existing_user_without_company:
      'There is already a professional not associated with any company and with the same email. Please choose another email address',
    horaFinMenorHoraInicio: 'A hora de fim não pode ser anterior à hora de inicio',
    usr_dado_baja: 'O utilizador não se encontra ativo neste momento',
    must_be_at_least_13_years: 'Must be at least 13 years of age',
    trial_caducado: 'O período de teste perdeu validade, se desejar expandir deve contactar {name}',
    email_no_exist: 'O email não se encontra registado na {name} como profissional',
    mayor_cero: 'A quantidade deve ser maior que 0 | O valor deve ser igual ou maior que 0',
    user_exist: 'O email já está registrado na aplicação',
    file_max_upload: 'O arquivo supera o tamanho permitido que é 10 MB',
    input_tam_maximo_superado: 'The input {input} exceeds the maximum size allowed ({tam} characters)',
    factura_no_disponible:
      'It is not possible download this invoice at this momment. Please contact us to resolve the issue',
    date_required: 'The date of birth is mandatory',
    phone_video_error:
      "You cannot select more than one patient to create an appointment from the patient's phone number",
    max_patients_multi: 'You cannot select more than three patients for a multiconsultation',
    phone_empty: 'You must enter the phone number',
    incorrect_hour: 'The time format is not correct. Example: 10:30',
    incorrect_date: 'The date format is not correct',
    date_must_be_greater_than_now: 'Date and time must be greater than now',
    incorrect_colegiado: 'The collegiate number must have between 8 and 9 characters',
    incorrect_collegiate_psychologist: 'Incorrect psychology collegiate number',
    emit_e_prescription:
      'There has been an error with the E-Prescription. Please report the issue, and we will resolve the error as soon as possible.',
    incorrect_colegiado_emit:
      'Your collegiate number is not correct, please go to your profile and update the registration number',
    incorrect_collegiate_number: 'The collegiate number must be correct to emit the prescription',
    invalid_collegiate_number:
      'Your medical registration number is invalid. Please, contact us directly by email at {email} to fix it and verify the number. *Warning: The medical registration number has 8 or 9 digits.',
    collegiate_not_allow_to_prescribe:
      'With your current licence number you can not prescribe drugs to your patients. Please access your account profile, update your license number and try again',
    invalid_patient_document:
      'The patient DNI / ID number you entered is not valid. Please update the number in their profile and try again',
    freemium_no_multi:
      'With the Freemium version, you cannot create multiconsultation. Contact your sales representative to upgrade to the Premium version.',
    gender_required: 'Gender is required',
    relation_required: 'Relation is required',
    type_prof: 'You must select a type of professional',
    must_be_adult: 'User must be adult',
    specialty_required: 'It is mandatory to select at least one specialty',
    patient_has_no_email_message:
      'The patient has no email. Please edit his or her data and enter a valid email to be able to emit the electronic prescription.',
    not_found_title: 'This page is not available',
    not_found_message:
      'The link you used may be broken or you reached a deleted page. Please check the link you are using is correct.',
    server_down: 'Sorry, the server is down.',
  },
  facturacion: {
    concepto: 'Conceito',
    importe: 'Amount',
    dateFactura: 'Fecha Factura',
    datePay: 'Fecha Pago',
    pagosrecibidos: 'Payments received',
    without_payments: 'You haven´t received any payments in the time period selected',
    puede_tardar: 'Please be patient while we retrieve the information from the payment platform',
    rango_fechas: 'The time period cannot exede {num} days',
    info_sobre_facturas:
      'You will not see here the invoices for the payments made during the last five days due to the processing time required by our online payment provider',
    aceptar_transaccion: 'Accept Transaction',
    aceptar_transaccion_descrip: 'Are you sure you want to confirm the selected transaction?',
    cancelar_transaccion: 'Cancel transaction',
    cancelar_transaccion_descrip: 'Are you sure you want to cancel the selected transaction?',
    bad_request: 'An error has occurred, parameters are missing to complete the action',
    unauthorized: 'You do not have sufficient permissions to perform this action',
    select_professional: 'Select a professional',
  },
  hiring: {
    service: 'Hire Service',
    appointment: 'Hire Video Consultation',
    message: 'Hire Chat',
    pack: 'Hire Pack',
    subscription: 'Hire Subscription',
    select_receiver: 'The service is not for you? Choose the person for whom you are requesting this service',
    reason_of_consultation: 'Write the reason for your video consultation',
    consultation_details: 'Details of your appointment',
    professional_will_confirm:
      'The health professional will confirm your booking or will suggest a new appointment as soon as possible',
    professional_will_approve_pack: 'The health professional will get in touch with you as soon as possible',
    have_promotional_code: 'Do you have a Promotional Code?',
    card_payment: 'Card payment',
    proceed_to_payment: 'Proceed to payment',
    discount_applied: '{num}% discount applied',
    add_document:
      'Feel free to attach any relevant document as test results, pictures etc. to faclilitate the diagnostic',
    document_added: 'Document added to the message',
    note_to_professional: 'You can write a note for the health professional',
    monthly_fee_will_charged:
      'A monthly fee will be charged for your active subscription. You can cancel this service form your account',
    send: 'Send',
    free_with_your_subscription: 'You already have an active subscription that has this service included',
    appointment_proposed_by_professional:
      'This is the appointment proposed by the professional. As soon as we validate your payment, we will send you instructions on how to connect to the video consultation by email.',
    you_have_period_to_confirm:
      'You have 2 days to confirm this appointment. After that, the professional can reassign this appointment to another patient or cancel it without prior notice',
    appreciate_your_understanding: 'Thank you for your comprehension.',
  },
  historia: {
    antecedentes_personales: 'Antecedentes Personales',
    enfermedades_infancia: 'Enfermedades en la infancia',
    enfermedades_adulta: 'Enfermedades en edad adulta',
    medicamentos: 'Medicamentos habituales/Crónico',
    intervenciones: 'Intervenciones Quirúrgicas',
    actividad_laboral: 'Actividad Laboral',
    antecedentes_familiares: 'Antecedentes Familiares ',
    alergias: 'Allergies',
  },
  mensajeria: {
    nuevo_mensaje: 'New message',
    pendientes: 'Pendings',
    abiertos: 'Open Messages',
    abierto: 'Open',
    cerrados: 'Closed Messages',
    enviar: 'Send',
    para: 'To',
    motivo: 'Subject',
    subject: 'Subject',
    mensaje: 'Message',
    mensajes: 'Messages',
    select_user: 'Select recipient',
    enviar_nuevo_mensaje: 'Send a new message',
    marcar_como_atendida: 'Mark as solved',
    sin_mensajes_pendientes: "You don't have any pending messages",
    sin_mensajes_abiertos: 'At this time, you have no messaging open to answer with any patient',
    sin_mensajes_atendidos: 'You have not yet marked any messages as closed.',
    archivos_adjuntos: 'Attached Files',
    has_attachments: 'Has attachments',
    attach_file: 'Attach file',
    mensaje_con_adjuntos: 'The message will be sent with an attachment',
    add_texto_al_mensaje: 'Add text to the response to send the message',
    add_destinatario_al_mensaje: 'Indicate the recipient of the message',
    enviado_correctamente: 'Message sent correctly',
    enviado_correctamente_error_adjunto:
      'Message sent correctly, but the file could not be attached, check that it does not exceed the maximum allowed size and that it is an image or a pdf',
    error_enviando: 'The message could not be sent. Try it again later',
    atendido_correctamente: 'The message has been correctly marked as solved',
    error_atendiendo: 'Error marking the message as answered, try again later',
    confirm_atendida:
      'The courier service will be marked as solved. If the patient wishes to reconnect, they must request a new messaging service. Are you sure you want to close this messaging service?',
    sin_contestar: 'WITHOUT ANSWER',
    metadata_list_text: 'Other data',
    answered: 'ANSWERED',
    sent: 'SENT',
    sin_cerrar: 'OPEN',
    ver_file: 'Show File',
    delete_file: 'Delete File',
    confirmar_delete_file:
      'If you delete the document the patient will not be able to access it. Are you sure you want to delete the document?',
    modificar_name_file: 'Modify Document Name',
    nombre_file: 'Name of the Document',
    atendido: 'Closed',
    enviar_mensaje: 'Send message',
    cerrado: 'Closed',
    unread_messages: 'Unread messages',
    not_exists_thread: 'There is not a thread with an identifier {id}',
    downloaded_on: 'Downloaded on',
    pdf_footer_link: 'This PDF has been generated through the chat from ',
    see_chat: 'See chat',
    loading_chat: 'Loading chat...',
  },
  menu: {
    home: 'Inicio',
    patients: 'Pacientes',
    messaging: 'Mensagens',
    videocam: 'Videoconsultas',
    productos: 'Products',
    calendario: 'Calendar',
    reports: 'Relatórios',
    chat: 'Chat',
    inbox: 'Caixa de Entrada',
    settings: 'Ajustes',
    help: 'Ajuda',
    profile: 'Perfil',
    close_session: 'Fechar sessão',
    agenda: 'Agenda',
    configvideoconsultas: '',
    cupones: 'Cupões',
    talonario: 'Talões de receitas',
    firma: 'Assinatura',
    users: 'Utilizadores',
    medicos: 'Doctors',
    metrics: 'Métricas',
    packs: 'Pacotes',
    packcontratados: 'Pacotes contratados',
    contact: 'Contacto',
    empresas: 'Empresas',
    tarifas: 'Serviços',
    servicios: 'Serviços',
    miweb: 'My WebSite',
    facturacion: 'Billing',
    recepcionista: 'Assistant',
    resenas: 'Reviews',
    dispositivos: 'Devices',
    citas: 'Appointments',
    transacciones: 'Transactions',
    valorespersonalizados: 'Custom Values',
    myMessages: 'Messages',
    myVideoconsultations: 'Video Consultations',
    myPrescriptions: 'Prescriptions',
    mySubscriptions: 'Subscriptions',
    myDocuments: 'Documents',
    sendUsMessage: 'Send us a Message',
    beneficiaries: 'Associated users',
    medicalTests: 'Tests',
    medicalTestTypes: 'Test Types',
    medicalTestNomenclator: 'Nomenclator',
  },
  message: {
    password: 'Palavra-passe',
    modify_password: 'Modificar Palavra-passe',
    email: 'Email',
    repeat_email: 'Confirm Email',
    intro_email: 'Enter your Email',
    intro_pw: 'Enter your Password',
    intro_security: 'For security reasons, to be able to change the email you must enter the password',
    email_repeat: 'Repetir Email',
    remember: 'Lembrar Email',
    login: 'Aceder',
    notifications: 'Notificações',
    showall: 'Mostrar Todas',
    reset_texto: 'Introduza um email. Enviaremos um email com o link para repor a Palavra-passe',
    reset_password: 'Repor Palavra-passe',
    introduzca_password: 'Para ativar a sua conta introduza a Palavra-passe de acesso',
    restaurar_password: 'Repor Palavra-passe',
    password_weak: 'Weak password',
    password_not_secure: 'Password is not secure',
    password_secure: 'Password is secure',
    welcome: 'Bem vindo ao {name}',
    new_here: 'New here? Start using our Digital Health services',
    signup: 'Sign Up',
    start: 'Start',
    already_have_an_account: 'Do you already have an account? Sign in',
    estamos_encantados: 'We are delighted to have you with us. ',
    le_guiaremos:
      'We will now guide you through our platform so you complete the necessary fields to start using {name} everyday.',
    password_new: 'Nova Palavra-passe',
    activar_cuenta: 'Ativar Conta',
    check_credentials: 'A Verificar Credenciais',
    cuenta_trial:
      'A sua conta está em processo de validação. A versão de teste termina amanhã | A sua conta encontra-se em processo de validação. A versão de teste  terminará dentro de {count} dias',
    double_auth_title: 'Verificação em duas etapas',
    double_auth_desc: 'Esta etapa extra indica que é você quem está tentando fazer login.',
    double_auth_msg_one: 'Um email acabou de ser enviado para ',
    double_auth_msg_two: 'com um código de verificação de 6 dígitos, insira-o abaixo.',
    double_auth_email_again: 'Você não recebeu o email? Reenviar',
    double_auth_email_sended: 'O código foi reenviado ',
    double_auth_error_code: 'O código está errado, tente novamente',
    double_auth_empty_code: 'Você deve preencher o código',
    double_auth_escribe_codigo: 'Escreva o código',
    double_auth_placeholder: 'Código',
    select_an_account: 'Select an account',
    acceptConditions:
      'I have read and accept the <a href="{privacyPolicyLink}" target="_blank">privacy policy</a> and <a href="{legalLink}" target="_blank">legal notice</a>',
    acceptGeneralConditions:
      'I have read and accept the <a href="{generalConditionsLink}" target="_blank">general conditions and data processing</a>',
    acceptGeneralConditions_LOPDGDD:
      'I have read and accept the <a href="{generalConditionsLink}" target="_blank">general conditions and data processing</a>',
  },
  messages: {
    name: 'Private Messagge | Private Messagges',
    new: 'New',
    unsolved: 'Pending',
    solved: 'Closed',
    all: 'All',
    write_your_message_here: 'Write your message here...',
    write_your_consultation: 'Write your consultation to {name}',
    not_have_pending: "No messages",
    error: 'There was a problem loading the last messages',
    reset_filter: 'Reset Filter',
    show_messages: 'Show Messages',
    message_closed: 'The message is closed',
    to_me: 'To: me',
    to: 'To',
    me: 'me',
  },
  notificaciones: {
    mensajeria: 'Tem uma mensagem pendente | Tem {count} mensagens pendentes',
    video_confirm: 'Tem uma videoconsulta por confirmar | Tem {count} videoconsultas por confirmar',
    video_hoy: 'Hoje tem: uma videoconsulta | Hoje tem {count} videoconsultas',
    prescriptions: 'You have a prescription pending to emit | You have {count} prescriptions pending to emit',
  },
  packs: {
    name: 'Pack | Packs',
    crear: 'Create Pack',
    packs_configurados: 'Hired packs',
    packs_contratados: 'Hired Packs',
    num_sesiones: 'Number of Sessions',
    editar: 'Edit Pack',
    eliminar_titulo: 'Delete Pack',
    eliminar_texto:
      'Patients will not be able to hire this Pack in the future. Previously hired packs will not be affected.',
    eliminar_confirmar: 'Are you sure you want to delete this pack?',
    sin_packs: 'No packs has been configured',
    completados: 'Completed',
    sin_pendientes: 'At this time you do not have any uncompleted hired packs',
    sin_completados: 'You have not completed any hired pack',
    fecha_contratado: 'Hired Date',
    sesiones_totales: 'Total Sessions',
    sesiones_restantes: 'Remaining Sessions',
    marcar_como_completado: 'Mark as Completed',
    marcar_como_completado_texto: 'The pack still has unused sessions. Are you sure you want to mark is as complete?',
    success_complete: 'The pack has been marked as completed correctly',
    sesion: 'Session',
    not_have_hired: 'You have no contracted pack',
    num_pending: '1 pending session | {num} pending sessions',
  },
  patient: {
    patients: 'Pacientes',
    search: 'Procurar Paciente',
    without_patients: 'Ainda não tem vinculado nenhum Paciente',
    new: 'Novo Paciente',
    edit: 'Editar Paciente',
    delete: 'Eliminar Paciente',
    confirm_delete:
      'O paciente selecionado deixará de receber os seus serviços. Tem a certeza que quer eliminar o seu Paciente?',
    personal_data_mayus: 'DADOS PESSOAIS',
    personal_data: 'Dados Pessoais',
    history_clinical: 'Historial Clínico',
    history_clinic: 'Processo Clinico',
    first_name: 'Nome',
    last_name: 'Apelidos',
    document_id: 'CC ou Passaporte',
    cedula_or_passport: 'Cedula ou Passaporte',
    birthdate: 'Data de Nascimento',
    phone: 'Telefone',
    gender: 'Género',
    female: 'Feminino',
    male: 'Masculino',
    other: 'Other',
    delete_success: 'O paciente foi eliminado corretamente',
    delete_error: 'Erro ao eliminar o paciente, tente mais tarde',
    full_name_required: 'Nome e apelidos obrigatórios',
    too_long_name: 'Name cannot be longer than 50 characters',
    too_long_surname: 'Last name cannot be longer than 50 characters',
    dni_required: 'CC obrigatório',
    type_dni_required: 'ID Document Type is required',
    dni_invalid: 'ID Document is not valid',
    dni_invalid_letra: 'Incorrect ID, the letter of the NIF does not correspond',
    birthdate_invalid: 'A data de nascimento deve ser anterior à data atual',
    observations: 'Observações',
    antecedentes_familiares: 'Antecedentes Familiares',
    antecedentes_maternos: 'Antecedentes Maternos',
    antecedentes_paternos: 'Antecedentes Paternos',
    antecedentes_hermanos: 'Antecedentes Irmãos',
    antecedentes_otros: 'Antecedentes otros familiares',
    antecedentes_personales: 'Antecedentes Pessoais',
    antecedentes_cirugias: 'Antecedentes Cirúrgicos',
    alergias_medicamentos: 'Alergias a Medicamentos',
    otras_alergias: 'Outras Alergias',
    enfermedades_cronicas: 'Doenças crónicas',
    historial_psiquiatria: 'Historial de Psiquiatria',
    motivo_consulta: 'Motivo de consulta',
    antecedentes_somaticos: 'Antecedentes Somáticos',
    antecedentes_psiquiatricos: 'Antecedentes Psiquiátricos',
    genograma: 'Hierograma',
    psicobiografia: 'Psicobiografia',
    habitos_toxicos: 'Hábitos Tóxicos',
    anamnesis: 'Anamnese',
    diagnosticos: 'Diagnósticos',
    evoluciones: 'Evoluções',
    recomendaciones: 'Recomendações',
    nuevo_diagnostico: 'Novo Diagnóstico',
    editar_diagnostico: 'Editar Diagnóstico',
    sin_diagnosticos: 'Ainda não tem nenhum diagnóstico este paciente',
    delete_diagnostico: 'Eliminar Diagnóstico',
    confirm_delete_diagnostico: 'Tem a certeza que quer eliminar o diagnóstico?',
    nueva_evolucion: 'Nova Evolução',
    editar_evolucion: 'Editar evolução',
    sin_evoluciones: 'Não foi registada nenhuma evolução para este paciente',
    delete_evolucion: 'Eliminar Evolução',
    confirm_delete_evolucion: 'Tema certeza que quer eliminar a evolução selecionada?',
    sin_documentos: 'O paciente não tem documentos associados',
    sin_recetas: 'Ainda não foram criadas receitas para o paciente',
    generarinforme: 'Gerar Relatório',
    pais: 'Pais',
    evolucion: 'Evolução',
    exploracion: 'Exploração',
    juicioclinico: 'Juízo Clinico',
    tratamiento: 'Tratamento',
    num_historia: 'No. History',
    notas: 'Internal Notes (The patient will not be able to see this information and will not appear in the report)',
    tipo_documento: 'Document Type',
    pasaporte: 'Passport',
    enviado_mensaje_vincular:
      'The patient is already registered. We have sent an email to the patient to accept the link between health professional and patient',
    relacion: 'Relationship',
    datos_tutor: 'Tutor Data',
    cuidar_alguien: 'Add dependant patient',
    pacientes_tutelados: 'Users associated with your account',
    preanestesia: 'Preanaesthesia',
    autorizado: 'Autorizado',
    no_autorizado: 'No Autorizado',
    peso: 'Weight',
    motivo_y_evolutivos: 'Reason and Evolutionary',
    examenFisico: 'Examen Físico',
    resultadoLaboratorio: 'Resultados de laboratorio',
    pruebasImagen: 'Pruebas de imagen',
    evolutivo: 'Evolutivo',
    aceptar_cambios_apto: 'Confirme que deseja confirmar a alteração no apt?',
    aceptar_cambios_noapto: 'Confirme que deseja confirmar a alteração para imprópria?',
    revision_medica: 'Medical check | medical checkups',
    sin_common: 'The patient does not have {name}',
    aceptar_cambios_rechazo: 'Do you confirm that you want to accept the rejection?',
    aceptar_cambios_denegado: 'Do you confirm that you want to deny the rejection?',
    new_revision: 'New Revision',
    pendiente: 'Pending',
    aceptado: 'Accepted',
    edit_date_revision: 'Edit medical check date',
    fecha_ultimo_aviso: 'Date last notice',
    register_date: 'Registration date',
    no_observations: 'No observations',
    allergy_management: 'Allergy Management',
    add_allergy: 'Add Allergy',
    no_allergies: 'The patient has no registered allergies',
    addedAllergy: 'Allergy saved',
    removedAllergy: 'Allergy removed',
    searchAllergy: 'Search allergies',
    allergyEndDate: 'End date is not defined',
    allergyStartDate: 'Start date is not defined',
    allergyInfoBox: 'El paciente tiene registradas las siguientes alergias:',
  },
  prescriptions: {
    prescription: 'Prescription',
    prescriptions_list: 'Electronic Prescriptions',
    no_prescriptions: 'You do not have prescriptions',
    not_found: 'No prescriptions found with these search criteria',
    mark_all: 'Check all',
    lote_actions: 'Acciones en lotes',
    action_firm_emit: 'Sign and emit',
    medicine_duration_exceeded_error: 'The duration of this medicine cannot exceed {num} months.',
    narcotic_number_of_packages_exceeded_error:
      'This medicine cannot be prescribed in a quantity exceeding {num} packages.',
    cant_mix_narcotics_with_others_error:
      'A narcotic cannot be prescribed together with other medications in the same prescription.',
    some_medicine_exceeds_max_duration_error:
      'One or more medicines exceed the maximum allowed duration. Please review the data.',
    long_duration_prescription_confirmation_notice:
      'The duration of at least one of the prescribed medicines is greater than one month. Are you sure you want to continue?',
    maxPackagesWarning:
      'At the moment, you can only prescribe up to {limit} units of this medicine. The treatment duration has been automatically readjusted to the maximum number of days possible.',
    maxPackagesWarningNoAdjust: 'At the moment, you can only prescribe up to {limit}.',
    maxPackagesErrorNoCovid: 'The maximum units exceed the 99 units limit',
    maxPackagesErrorCovid: 'The maximun units exceed the 9 units limit',
    medicineAlreadyPrescribed: 'You have an ongoing prescription for this medication that is valid until the {date}',
    limited: 'Maximum {num} {measure}',
    maxDaysForSameMedicineTitle: 'You have exceeded the allowed prescription period.',
    maxDaysForSameMedicineText:
      'This prescription will be removed. The prescriptions for medicines and health products should not exceed 12 months.',
    patientIndentityConfirmationText: 'Confirm the patient identity',
    patientsIndentityConfirmationText: 'Confirm the selected patients identities',
    medicamentsIncluded: 'Includes {numMedicaments} medicaments',
    itemsSelected: ' items selected',
    noActiveMedicines:
      'Esta receita contém pelo menos um medicamento que já não está activo. Por favor, emita uma nova receita.',
    deletePrescriptions: 'Delete prescriptions',
  },
  anestesia: {
    de_que_opera: 'What will be your surgical procedure?',
    quien_opera: 'Who is your surgeon?',
    edad: 'Age',
    peso: 'Weight',
    talla: 'Size',
    alergico_medicacion: 'Are you allergic to any medicine?',
    indique_a_que: 'If yes, to what?',
    tension_alta: 'Do you have high blood pressure?',
    diabetico: 'Are you diabetic?',
    fumador: 'Do you smoke?',
    enfemedad_actual: 'Do you suffer any disease?',
    enfermedad_previa: 'Have you suffer from any disease in the past?',
    medicacion: 'Are you taking any medication?',
    operado_anterior: 'Have you had any surgeries in the past?',
    problema_anestesia: 'Have you had any issues with anaesthesia?',
    indique_si_ha_sufrido: 'LET US KNOW IF YOU HAVE HAD ANY OF THE FOLLOWING CONDITIONS:',
    corazon: 'Heart Conditions (Angina, strokes, etc.)',
    pulmon: 'Lung Conditions (bronchitis, asthma, etc.)',
    higado: 'Liver Conditions',
    enfermedad_rinon: 'Kidney Conditions',
    comentarios: 'Any further information that you would like to share with us:',
    fecha_intevencion: 'Scheduled date for surgery',
    informe_preanestesia: 'Preanaesthesia Report',
    checking_collegiate_number_1: "We're verifying your medical ID registration number.",
    checking_collegiate_number_2: 'Please do not close this window.',
  },
  recepcionista: {
    recepcionistas: 'Assistants',
    recepcionista: 'Assistant',
    crear: 'Create assistant',
    editar: 'Edit assistant',
    sin_recepcionista: 'You have not yet registered a assistant',
    permisos: 'Permissions',
    agenda: 'Schedule',
    servicios: 'Services',
    historia: 'History',
    talonario: 'Prescription Pad',
    firma: 'Signature',
    facturacion: 'Billing',
    eliminar_titulo: 'Delete assistant',
    eliminar_texto: 'Are you sure you want to delete this assistant user account?',
  },
  recetas: {
    nueva: 'New Prescription',
    num_receta: 'No. of Prescription',
    ver: 'Open',
    reemitir: 'Reissue',
    prescripcion: 'PRESCRIPTION',
    prescripcion_detalle:
      'Consign the medicine: INN or brand, pharmaceutical form, route of administration, dose per unit and units per container. (Corresponding data in case of medical device).',
    num_envases: 'No. packaging / units',
    buscar_medicamento: 'Search medication',
    receta_medica_privada: 'PRIVATE MEDICAL PRESCRIPCION',
    receta_medica_asistencia_sanitaria_privada: 'MEDICAL PRESCRIPCION FOR PRIVATE HEALTH ASSISTANCE',
    no_existe_medicamento: 'There is no medication with the indicated name',
    unidades: 'Units',
    duracion: 'Treatment Duration',
    posologia: 'Posology',
    pauta: 'Norm',
    fecha_prevista_dispensacion: 'Expected Date Dispensation',
    info_farmaceutico: 'Information to the Pharmacist, if applicable',
    instrucciones_paciente: 'INSTRUCTIONS TO THE PATIENT (if applicable)',
    paciente_detalle: '(First name, last name, year of birth and ID number / NIE / passport)',
    prescriptor: 'PRESCRIBING',
    prescriptor_detalle: '(identification and signature)',
    vista_previa: 'PREVIEW',
    required: {
      medicamento: 'The name medication is required',
      paciente: 'The name patient is required',
    },
    generar: 'Generate Prescription',
    creada_correctamente: 'Prescription Sent',
    imprimir: 'Print',
    imprimida_correctamente:
      'The Prescription will open in a new tab. If not opened, allow the browser to open pop-up windows',
    nombreaniodni: 'Name, surname, year of birth and ID number',
    aviso_pocas_recetas: 'NOTICE: Only {num} prescription are available',
    logout_with_pending_to_emit:
      'You have {count} pending prescriptions to emit. Are you sure you want to leave the page or emit the prescriptions?',
    emit_prescriptions: 'Emit prescriptions',
    exit_without_emit: 'Close',
    prescription_batch_ended: 'All prescriptions have been signed and emitted successfully.',
    prescription_failed: 'An error occurred while attempting to emit the prescriptions.  Please, try again.',
  },
  success: {
    new_patient: 'O paciente foi criado corretamente',
    patient_exist: 'The patient is already registered',
    patient_exist_otro_mail: 'The patient is already registered with another email',
    new_user: 'Utilizador criado corretamente',
    edit_patient: 'Informação do paciente alterada corretamente',
    edit_user: 'Informação do utilizador alterada corretamente',
    save_new_diagnosis: 'Criado um novo diagnóstico corretamente',
    save_edit_diagnosis: 'Diagnóstico alterado corretamente',
    delete_diagnosis: 'Diagnóstico eliminado corretamente',
    save_new_evolucion: 'Nova evolução criada corretamente',
    save_edit_evolucion: 'Evolução editada corretamente',
    delete_evolucion: 'Evolução eliminada corretamente',
    save_history: 'Processo clinico guardado corretamente',
    save_user: 'Utilizador guardado corretamente',
    save_new_coupon: 'Cupão criado corretamente',
    save_edit_coupon: 'Cupão editado corretamente',
    delete_coupon: 'Cupão eliminado corretamente',
    save_tipovideo: 'Serviço de {name} guardado corretamente',
    save_tipomensajeria: 'Serviço de mensagens guardado corretamente',
    delete_common: 'Eliminado corretamente',
    save_common: 'Guardado corretamente',
    save_agenda: 'Agenda guardada corretamente',
    save_firma: 'Assinatura guardada corretamente',
    send_password: 'Enviado um mail para repor a sua palavra-passe',
    password_changed: 'A palavra-passe foi alterada corretamente',
    generado_informe: 'Relatório criado corretamente',
    account_active: 'Conta ativada correntemente',
    name_file_modificado: 'Nome do documento modificado corretamente',
    file_deleted: 'Documento eliminado corretamente',
    delete_treatment: 'The treatment has been successfully removed',
    recipe_emmited: 'The prescription has been issued correctly, the patient will receive the prescription {methods}',
    emit_method_email: 'via email',
    emit_method_sms: 'via SMS',
    email_sent: 'Email has been sent successfully',
    serviceHiring: 'The {Name} service has been successfully contracted',
  },
  talonario: {
    conf_talonario: 'Prescription Book Settings',
    subir_talonario: 'Upload Book',
    eliminar_talonario: 'Delete Book',
    confirmar_eliminar_talonario: 'Are you sure you want to delete this book?',
    confirmar_subida_talonario:
      ' Are you sure you want to upload the book {msg} to use when you give your patients prescriptions?',
    talonario_actuales: 'Current Books',
    instrucciones_talonario: 'Follow these steps to upload your prescription book:',
    instrucc_talon_li:
      'Step 1: Save in your computer the pdf file you have received by your Medical College. | Step 2: Click on "Upload Book" and choose the correct pdf file. | Step 3: Save the file in your account.',
    sin_talonarios:
      ' You have not uploaded any prescription book yet. You will not be able to generate prescriptions until you upload the pdf file.',
    sin_firma: 'You have not added your signature. Please do so before generating prescriptions.',
    conf_firma: 'Set up your signature',
    firma_actual: 'Current Signature',
    explicacion_firma:
      'Draw your signature from your smartphone or tablet. (It is not recommended to draw it from the computer). It will automatically save in your account and you will be able to access it from any device.',
    mandame_link: 'Send a link to your {nombre}.',
    rehacer_firma: 'Redraw Signature',
    timeoutfirma:
      'The link to draw your signature has expired. Remember it is valid for 15 min since you ask to send it.',
    dibuje_firma:
      "Add your signature from your mobile or tablet. It saves in your profile under the password field. You could access your signature from any device. *It's not recommended use the PC to add your signature.",
    send_signature:
      'We have sent a link to your email account. It contains a link that will let you create your signature (valid for 15 min).',
  },
  tipovideo: {
    estandar: 'Videoconsulta padrão',
    precio: 'Preço da videoconsulta',
    duracion: 'Duração da videoconsulta',
    guardar: 'Guardar videoconsulta',
    guardarmensajeria: 'Guardar mensagem',
    confirm_desactivar_mensajeria: 'Tem a certeza que quer desativar o serviço de mensagens',
    confirm_desactivar: 'Tem a certeza que quer desativar o serviço de videoconsulta',
    nuevo_titulo: 'Criar serviço',
    nuevo_video: 'Create video consultation Service',
    nuevo_presencial: 'Create face-to-face Service',
    confirm_delete_titulo: 'Eliminar serviço de videoconsulta',
    confirm_delete_texto: 'Tem a certeza que quer eliminar o serviço de videoconsulta selecionado?',
  },
  tour: {
    skip: 'Skip tour',
    prev: 'Previous',
    next: 'Next',
    finish: 'Finish',
    dibuja_firma: 'Pruebe a dibujar su firma',
    no_te_gusta: '¿No te gusta como ha quedado?',
    rehacer_firma: 'Puede pulsar en el botón de rehacer firma para volver a dibujar su firma',
    enviar_firma_mobile:
      'Si se encuenta en el ordenador y desea dibujar su firma con el dedo desde {nombre}, pulse sobre este botón.',
    configura_agenda: 'Seleccione los dias que se encuentra disponible en {name}.',
    configura_hora_agenda: 'Una vez seleccionado el día indique la hora de inicio y la hora de fin.',
    configura_rango_agenda: 'Puede seleccionar más de un rango de horas.',
    descripcion_mensajeria: 'Indique la descripción del servicio de mensajería',
    precio_mensajeria: 'Puede modificar el precio de la consulta',
    estado_mensajeria: 'Si no desea recibir consultas puede cambiar el estado a desactivado',
    guardar_cambios: 'Pulse sobre guardar para aplicar los cambios',
  },
  user: {
    name: 'Nome do utilizador',
    telefono_secretaria: 'Telefone da secretaria',
    telefono_paciente: "Patient's phone",
    biografia: 'Biografia',
    nuevo: 'Novo utilizador',
    buscar: 'Procurar utilizador',
    name_complete: 'Nome completo',
    num_colegiado: 'Número da Ordem dos Médicos',
    telefono_consulta: 'Telefone da consulta',
    tipo_prof: 'Tipo profissional',
    salutation: 'Saudação',
    password_repeat: 'Repetir palavra-passe',
    password_no_igual: 'As palavras-passe não coincidem',
    password_tam_invalid: 'A palavra-passe deve ter pelo menos 6 caracteres',
    password_must_contain_lowercase: 'The password must contain lowercase',
    password_must_contain_uppercase: 'The password must contain uppercase',
    password_must_contain_numbers: 'The password must contain numbers',
    password_must_contain_symbols: 'The password must contain special characters',
    invalid_password: 'The password is not valid',
    password_min_length: 'The password must be at least {num} characters',
    user_status: 'User status',
    edit: 'Guardar alterações',
    search: 'Procurar utilizador',
    especialidades: 'Specialties',
    female_doctor: 'Dra.',
    male_doctor: 'Dr.',
    female_person: 'Sra.',
    male_person: 'Sr.',
    send_account_activation_email: 'Send account activation email',
    csv_with_error: 'There is an error in the document, correct it and upload it again',
    csv_ok: 'All users have been created successfully',
    user_status_training_not_reserved: 'Training not reserved',
    user_status_in_workflow_hubspot: 'In Workflow Hubspot',
    user_status_training_reserved: 'Training reserved',
    user_status_training_made: 'Training made',
    user_status_subscription_paid: 'Subscription paid',
    user_status_completed_personal_data: 'Completed personal data',
    user_status_abandoned_lead: 'Abandoned lead',
    date_end_trial: 'End date of the trial period',
    public_on_marketplace: 'Publicado en el Marketplace',
    no_public_on_marketplace: 'No publicado en el Marketplace',
    select_image: 'Select an image',
    name_patients_will_see: 'Name patients will see',
  },
  videoconsulta: {
    nueva_video: 'Nova videoconsulta',
    nueva_cita: 'Nova consulta',
    crear_cita: 'Criar consulta',
    nueva_tele: 'New Teleconsultation',
    crear_video: 'Criar videoconsulta',
    crear_tele: 'Criar videoconsulta',
    pendientes: 'Pendentes',
    terminadas: 'Finalizadas',
    informe: 'Informação',
    sin_video_pendientes: 'Neste momento não tem {name} pendentes',
    sin_video_terminadas: 'Não atendeu nenhum serviços de {name}',
    crear_para_paciente: 'Criar uma {tipoVideo} para um paciente',
    crear_muticonferencia: 'Create a Multicall',
    como_desea_comunicar: 'Como deseja comunicar a marcação?',
    solicitando_pago: 'Request Advance Payment',
    solicitando_pago_texto:
      'Envie pedido de pagamento por email. Assim que o paciente tenha realizado o pagamento pode enviar a palavra-passe para a videoconsulta',
    cita_cortesia: 'Free',
    cita_cortesia_texto:
      'Estas marcações são gratuitas para o paciente. Facultar uma marcação com esta opção pode ser adequada em casos como:',
    cita_cortesia_li:
      'Poder repetir videoconsultas que por algum motivo não conseguiu completar. | Envie um convite, faça uma primeira consulta de teste ou qualquer outro motivo que a encoraje. | Pacientes que compraram anteriormente pacotes de videoconsulta.',
    add_new: 'Adicionar nova',
    add_destinatario_cita: 'Indique a pessoa que você deseja receber o compromisso',
    add_fechahora: 'Indique hora e data da videoconsulta',
    add_fechahora_cita: 'Indique hora e data da consulta',
    add_modocomunicacion: 'Indique a forma em que a consulta será comunicada ao paciente',
    add_tiposervicio: 'Indique tipo de videoconsulta',
    add_tiposervicio_cita: 'Indique tipo de consulta',
    solapamiento: 'Já existe uma videoconsulta na data e hora escolhida',
    sin_tiposvideo: 'Para criar novas videoconsultas deve ter ativo pelo menos um serviço',
    enviado_correctamente: 'Videoconsulta criada corretamente. Foi enviado um email ao paciente com a notificação',
    enviado_correctamente_sms: 'Videoconsulta criada corretamente. Foi enviado um SMS ao paciente com a notificação',
    enviado_recordatorio: 'Enviado email ao paciente para o lembrar da videoconsulta',
    cortesia: 'Cortesia',
    esperando_pago: 'Esperando pagamento',
    tipo: 'Tipo',
    videoconsulta_para: '{tipoVideo} para',
    codigo: 'Código',
    abrir_sala: 'Abrir sala',
    volver_enviar: 'Voltar a enviar o código',
    confirm_delete: 'Tem a certeza que quer cancelar a videoconsulta?',
    confirm_delete2:
      'The video consultation is already paid. If necessary to make make a refund of money to the patient contact us through the chat.',
    confirm_video: 'Tem a certeza que quer confirmar a videoconsulta?',
    cancelado_correctamente: 'Videoconsulta cancelada corretamente',
    notification_email: 'Um e-mail será enviado ao paciente confirmando o cancelamento da consulta',
    confirmado_correctamente: 'Videoconsulta confirmada corretamente',
    terminada: 'Videoconsulta finalizada',
    singular: 'Videoconsulta',
    teleconsulta: 'Videoconsulta',
    sin_confirmar: 'SEM CONFIRMAÇÃO',
    rechazada: 'Rejected',
    aceptada: 'Accepted',
    denegada: 'Refused',
    ok: 'Ok',
    multiconferencia: 'Multicall',
    tipo_cita: 'Type of Video Consultation',
    convocatoria_sms: 'Send the appointment info by {sms}',
    editar_servicio: 'Editar Servicio',
    presencial: 'Presencial | Presenciales',
    sms: 'SMS',
    mensaje_texto: 'Mensaje de texto',
    diagnostico: 'Diagnóstico',
    tratamiento: 'Tratamento',
    freemium_version_non_free:
      'With the Freemium version you cannot create free or complimentary services. Contact your sales representative to upgrade to the Premium version',
    fixed_fee_message:
      'The management fee for this service is € {feeValue} to be added to your rate at the time of the patient payment.',
    percentage_fee_message:
      'The management fee for this service is {feeValue}% with a minimum of € {feeMin} and a maximum of € {feeMax} to be added to your rate at the time of the patient payment.',
    notice: 'Notice',
    no_specialties_to_create:
      'In order to continue you must assign a speciality. Please go to your profile and update it',
  },
  contact: {
    texto: 'Se tiver alguma dúvida, envie mensagem através do seguinte formulário:',
    mensaje_obligatorio: 'Para nos contactar deve escrever a mensagem sobre a sua dúvida',
    motivo_obligatorio: 'Para nos contactar deve escrever o motivo da sua dúvida',
    enviado_correctamente: 'Obrigado por nos contactar!, responderemos o mais depressa possível.',
  },
  empresa: {
    nueva: 'New Company',
    buscar: 'Search Company',
    email_noty: 'Notification Email',
    email_admin: 'Admin Email',
    share_patients: 'Share Patients',
    nombre_required: 'Company name is required',
    email_required: 'Admin email is required',
    guardar_empresa: 'Save Company',
    licenses_num: 'Licenses num.',
    edit_empresa_success: 'The company has been successfully edited',
    has_anesthetist: 'Tiene Anestesista',
    share_receptionists: 'Share Assistants',
    without_licenses: 'Your licenses have run out',
    user_exists: 'This user already exists',
    forbidden: "You don't have permissions to modify this company",
    user_not_exists: "This user doesn't exist",
    edit: 'Edit company',
    create: 'Create company',
    delete: 'Delete Company',
    confirm_delete: 'Are you sure you want to delete this company?',
    cannot_delete:
      'It is not possible to delete this company, as there are professionals / assistants associated with it',
    delete_success: 'The company has been successfully removed',
    premium_payment_type: 'Premium payment type',
    premium_payment_types: 'Premium payment types',
    is_collective: 'Is collective',
    is_health_personnel: 'Is health personnel',
    have_interconsultation: 'Have interconsultation',
    interconsultation: 'Interconsultation',
    users: 'Users',
    public_email: 'Public email',
    public_phone: 'Public phone number',
    public_address: 'Public address'
  },
  landing: {
    titulo: 'Setting up my Website',
    direccion: 'Address',
    sections: 'Sections',
    add_seccion: 'Add Section',
    add_seccion_titulo: 'Add Section to My Website',
    seccion_anadida: 'Section added correctly',
    delete_seccion_titulo: 'Delete Section',
    delete_seccion_texto: 'Are you sure you want to delete this section of your website?',
    seccion_eliminada: 'Section deleted correctly',
    publicar: 'Publish',
    modificar_precios: 'Modify Prices',
    redes_sociales: 'Redes Sociais',
    ejemplo_web: 'Exemplo de página da web',
    en_este_apartado: 'Nesta seção, você configura os dados que aparecerão no seu site',
    legend:
      'Photo, name, medical specialty and profile data are collected automatically from the data set on your profile.',
    legend_significance: 'Meaning of the fields to fill',
    write_address: 'Enter the address of your company',
    write_web: 'Enter the link of your particular website, if you already have one.',
    write_socials: 'Enter the links of your operational social networks',
    write_sections: 'You can add the sections you want including information',
  },
  suscripciones: {
    suscripcion: 'Subscription',
    suscripciones: 'Subscriptions',
    nueva: 'New Subscription',
    nueva_titulo: 'New Monthly Subscription',
    editar: 'Edit Subscription',
    tarifa_plana: 'Flat Rate',
    a_medida: 'Custom Subscription',
    sin_suscripciones: 'No subscription has been configured',
    destinatario: 'Who can hire it',
    explicacion_destinatario: 'When selecting anyone this service will appear in the marketplace',
    servicio: 'Service',
    ilimitado: 'Unlimited',
    servicios_incluidos: 'Included services',
    sesiones: 'session | sessions',
    error_no_servicios: 'You have not included any service. Press the button (+) to add services',
    delete: 'Remove Subscription',
    confirm_delete: 'Are you sure you want to delete the subscription?',
    suscripcion_contratados: 'My Subscriptions',
    sin_suscripciones_contratadas: 'At this time you do not have any hired subscription',
    mensajeria_restantes: 'Remaining Messaging',
    videoconsulta_restantes: 'Remaining Videoconsults',
    cancelar_suscripcion: 'Cancel Subscription',
    cancelar_suscripcion_texto:
      'The subscription will be canceled from {dia}. Meanwhile, the patient can continue to use this service. Are you sure you want to cancel the subscription?',
    cancelado_correctamente:
      'The subscription has been cancelled successfully. No more charges will be made to the patient.',
  },
  reserva: {
    titulo: 'Book your training appointment',
    texto1:
      'We encourage you to schedule an appointment with our Medical Director to discover all the tools {name} offers.',
    texto2: 'Do yo wish to book the appointment?',
    boton: 'Book',
    reserva_confirmada: 'Appointment Booked.',
    ha_hecho_una_reserva: 'You have scheduled an appointment with Dr. Medina.',
    recibira_invitacion_por_correo: 'You will receive a confirmation by email..',
    se_reservara_a_las: 'You are booking an appointment on {dia} at {hora}',
    seleccione_fecha_hora: 'Choose the date and time you wish to receive the training.',
    reunirse_roberto: 'Meeting with Dr. Roberto Medina',
  },
  producto: {
    nuevo: 'New Product',
    editar: 'Edit Product',
    buscar: 'Search Product',
    nombre_required: 'The name of the product is required.',
    precio_required: 'The product price is required.',
    creado: 'The product has been created correctly.',
    licencia: 'Number of licences',
  },
  pago: {
    titulo: 'Subscription (Secure Payment)',
    titulo_stripe: 'Subscription',
    pagar_suscripcion: 'Purchase Subscription',
    cuenta_activada_correctamente: 'The account has been activated correctly.',
    ciclo: 'Billing cicle: based on your license description',
    periodo_duracion: 'Valid until: undefined',
    explicacion:
      "You will be charged immediately this month's payment and for every following month, a recurring payment will be charged for the same amount until the service is cancelled.",
  },
  steps: {
    espacio: 'My {name} Page',
    datos_facturacion: 'Billing information',
    dni_anverso_subido: 'UPLOADED FRONT DOCUMENT ID.',
    dni_reverso_subido: 'UPLOADED BACK DOCUMENT ID.',
    subir_dni_anverso: 'UPLOAD FRONT DOCUMENT ID.',
    subir_dni_reverso: 'UPLOAD BACK DOCUMENT ID.',
    iban_payment_module_message:
      'It is necessary that you complete the IBAN field, with the bank account data, when you intend to make payments to patients, for any of the appointments or consultations made through this platform, and configure the POS for online collections.',
    iban_invalid: 'IBAN is invalid, enter a valid IBAN',
    cp_invalid: 'Postal code is not correct',
  },
  resenas: {
    titulo_publicar: 'Publicar Reseña',
    texto_publicar: '¿Esta seguro que desea publicar esta reseña?',
    titulo_eliminar: 'Eliminar Reseña',
    texto_eliminar: '¿Esta seguro que desea eliminar esta reseña?',
  },
  dispositivo: {
    new_entidad: 'Nueva Entidad',
    entidad: 'Entidad | Entidades',
    new_local: 'Nuevo Local',
    local: 'Local | Locales',
    new_persona: 'Nueva Persona de Contacto',
    persona_contacto: 'Persona de Contacto | Personas de Contacto',
    persona: 'Persona de Contacto | Personas de Contacto',
    new_dispositivo: 'Nuevo Dispositivo',
    dispositivo: 'Dispositivo | Dispositivos',
    venta: 'Venta',
    alquiler: 'Alquiler',
    titulo_eliminar: 'Eliminar {tipo}',
    texto_eliminar: '¿Esta seguro que desea eliminar esta {tipo}?',
  },
  reportes: {
    informe_pontgrup: 'Informe de actividad Pont-Grup',
    informe_productividad: 'Informe de productividad',
    tiempos_atencion_consulta: 'Tiempos de atención de consultas',
    cumplimiento_servicio: 'Control cumplimiento del servicio',
    num_altas: 'Número de altas',
    num_bajas: 'Número de bajas',
    num_renov: 'Número de renovaciones',
    total: 'Total',
    seleccione_profesional: 'Es obligatorio seleccionar un profesional',
    filter_hospital: 'Filtrar por hospital',
    filter_professional: 'Filtrar por profesional',
    filter_from_date: 'Fecha inicio',
    filter_to_date: 'Fecha fin',
    activation_mails: 'Emails activación enviados',
    activated_accounts: 'Cuentas activadas',
    num_videocons: 'Videoconsultas',
    num_chats: 'Chats',
    num_patients: 'Pacientes registrados',
    num_electronic_presc: 'Recetas Electrónica emitidas',
    num_pdf_presc: 'Recetas PDF emitidas',
    num_logins: 'Accesos al sistema',
    num_registers: 'Num. Registros',
    graph_accounts: 'Cuentas activas/no activas',
    graph_activity: 'Actividad registrada',
    chart_electronic_presc: 'Recetas elect.',
    chart_pdf_presc: 'Recetas PDF',
    active_accounts: 'Activas',
    inactive_accounts: 'No activas',
    date_filters: 'Filtrar por fechas',
  },
  permissions: {
    dashboard: 'Dashboard',
    conf_permissions: 'Permission Settings',
    conf_styles: 'Styles Settings',
    save_permissions: 'Save Permissions',
    save_styles: 'Guardar Styles',
    users: 'Users',
    companies: 'Companies',
    products: 'Products',
    devices: 'Devices',
    patients: 'Patients',
    videoConsultation: 'Video Consultation',
    billing: 'Billing',
    messaging: 'Chat',
    packsHired: 'Packs',
    subscriptionsHired: 'Subscriptions',
    reviews: 'Reviews',
    configServices: 'Services Settings',
    reports: 'Reports',
    settingsBook: 'Settings - Book',
    settingsBilling: 'Settings - Billing',
    settingsRates: 'Settings - Rates',
    settingsSign: 'Settings - Sign',
    settingsDiary: 'Settings - Diary',
    settingsPacks: 'Settings - Packs',
    settingsCoupons: 'Settings - Coupons',
    settingsLanding: 'Settings - Landing',
    settingsReceptionist: 'Settings - Assistant',
    settingsSpecialties: 'Settings - Specialties',
    settingsServices: 'Settings - Services',
    settingsDaysNotAvailable: 'Settings - Days not Available',
    settingsDaysAvailable: 'Settings - Available Days',
    contact: 'Contact',
    help: 'Help',
    haveCreateVideoWithTel: 'Video Consultation by phone',
    adminSimplified: 'Admin Simplified',
    marketplace: 'Marketplace',
    showIbanPaymentModuleMessage: 'Show IBAN Message',
    simplifiedLanguages: 'Hide Languages (PT/PL/AR)',
    hideStripeButton: 'Hide Stripe Button',
    hideFormBooking: 'Hide Form Booking',
    hideTrialMessage: 'Hide Trial Message',
    clinicalHistory: 'Clinical History',
    anesthesia: 'Pre-anesthesia form',
    documents: 'Documents',
    prescriptionsFilteredList: 'Pending Prescriptions',
    myMessages: 'Patients - Messages',
    myVideoconsultations: 'Patients - Video Consultations',
    myPrescriptions: 'Patients - Prescriptions',
    mySubscriptions: 'Patients - Subscriptions',
    myDocuments: 'Patients - Documents',
    sendUsMessage: 'Send us a Message',
    beneficiaries: 'Care for someone',
    settingsBeneficiaries: 'Beneficiary adjustments',
    importCsvOnUsersList: 'Import CSV Users',
    showWelcomeModal: 'Show Welcome Popup',
    prescriptionsList: 'Batch prescriptions',
    moduleMedicalTests: 'Module testing',
    personalDoctor: 'Personal doctor',
    personalDoctorPatient: 'Patient',
    personalDoctorProfessional: 'Professional',
    personalDoctorAssistant: 'Assistant',
    hidePatientCreation: 'Hide patient creation',
  },
  recipe: {
    diagnostic: 'Diagnostic',
    chronic: 'Chronic',
    confidential: 'Confidential',
    patient_instructions: 'Instruções ao paciente',
    pharmacist_warnings: 'Indications for the pharmacist',
    comments: 'Comments',
    treatment_created: 'The treatment has been created successfully',
    sending: 'Sending',
    err_empty_diagnostic: 'You must complete the diagnostic field',
    err_empty_datestart: 'You must fill in the start date',
    err_empty_dateend: 'You must fill in the end date',
    err_empty_posology: 'You must fill in the posology',
    err_empty_duration: 'You must fill in the duration',
    err_empty_guideline: 'Debe rellenar la pauta',
    err_empty_tincode: 'You must fill in the guideline',
    err_empty_medicine: 'You must select a medicine',
    err_empty_composition: 'You must fill in the composition',
    unit: 'Unit',
    units: 'Units',
    recipe: 'Prescription',
    print_recipe: 'Print recipe',
    tin_code: 'TIN Code',
    master_formula: 'Master Formula',
    master_formula_placeholder: 'Write the formula here',
    medicine: 'Medicine',
    medicines: 'Medicines',
    search_medicine: 'Type the name of the medication to look for',
    search_patient: 'Search by patient name',
    add_medicines: '+ Add medication',
    type_electronic: 'Electronics',
    type_pdf: 'PDF',
    type: 'Type',
    sel_type: 'Select type',
    create_treatment: 'Create Treatment',
    create_treatment_treat: 'Create Treatment',
    years: 'years',
    change_patient: 'Change patient',
    allergies: 'Allergies',
    num_recipe: 'Num Prescription',
    last_disp: 'Last Disp',
    next_disp: 'Next Disp',
    active: 'Active',
    inactive: 'Inactive',
    confirm_delete_treatment: 'Are you sure you want to delete the treatment?',
    confirm_delete_prescription: 'Are you sure you want to delete the prescription?',
    confirm_emit_prescription: 'Do you want to emmit this prescription?',
    create_prescription: 'Create prescription',
    edit_prescription: 'Edit prescription',
    typology: 'Typology',
    pending: 'Pending',
    partially_dispensed: 'Partially dispensed',
    dispensed: 'Dispensed',
    download: 'Download prescription',
    narcotic: 'Narcotic',
    generic: 'Generic',
    hours: 'hours',
    days: 'days',
    weeks: 'weeks',
    months: 'months',
    month: 'month',
    year: 'years',
    during: 'During',
    every: 'every',
    created_at: 'Created at',
    frequency: 'Frequency',
    new_prescription: 'Create Electronic prescription',
    emmited: 'Issued',
    save_prescription: 'Save prescription',
    confirm_emmit_prescription_text: 'This action will send the prescription to your patient',
    see_prescription: 'See prescription',
    choose_prescription_type: 'Choose type of prescription',
    paper_recipe: 'PDF prescription',
    title: 'E-prescription',
    from: 'from',
    to: 'to',
    created_date: 'Created date',
    filter_by_created_date: 'Filter by creation date',
    more_information: 'More information',
    prescriptions: 'Prescriptions',
    premium_title: 'Unlock Premium content',
    patient_dni_required:
      "The patient must have the ID number filled to issue a prescription, go to the patient's personal data and fill in the ID number",
    reject_prof: 'Reject',
    reject_without_notification: 'Reject without notification',
    reject_with_notification: 'Ask for more information',
    master_formulas: 'Master Formulas',
    add_master_formula: 'Add master formula',
    psychotropic: 'Psychotropic',
    composition: 'Composition',
    pharmaceuticalForm: 'Pharmaceutical Form',
    administrationWay: 'Administration Way',
    dosage: 'Dosage',
    observations: 'Observations',
    err_master_formulas: 'You must complete all the required fields of the master formulas',
    medicine_detail: 'Medicine detail',
    medicine_information: 'Medicine information',
    registration_number: 'Registration number',
    authorized: 'Authorized',
    revoked: 'Revoked',
    discontinued: 'Discontinued',
    package_leaflet: 'Package leaflet',
    technical_sheet: 'Technical sheet',
    active_ingredient: 'Active ingredient',
    renew_prescription: 'Renew prescription',
    cancel_prescription: 'Cancel prescription',
    select_reason: 'Choose the reason for cancellation',
    cancel_reason_0: 'Precautionary lock confirmed by term',
    cancel_reason_1: 'Cancellation due to end of treatment',
    cancel_reason_2: 'Treatment change',
    cancel_reason_3: 'Incomplete or wrong data',
    canceled: 'Canceled',
    canceled_message: 'Prescription canceled',
    anulate_medicament: 'Cancel',
    anulate_indications: 'Anulate reasons',
    anulate_message: 'Medicament is anulated',
    unlock_medicament: 'Unlock',
    unlock_indications: 'Unlock reasons',
    unlock_message: 'Medicament unlocked',
    emitted: 'Emitted',
    expired: 'Expired',
    finished: 'Finished',
    in_queue: 'In proccess to emit',
    units_dispensed: 'Box or doses dispatched',
    navigate_to_prescription: 'Go to Prescription',
  },
  medicine: {
    filter_by_fav: 'Filter by favorites',
  },
  service: {
    name: 'Service of {name}',
    more_services: 'More Services',
  },
  signup: {
    dont_have_account_yet: "Don't have an account yet? Sign up",
    title_patient: 'Sign up as a Patient',
    subtitle_patient: 'Join {name} and get a consultation with health professionals from everywhere',
    title_professional: 'Sign up as Healthcare Professional',
    subtitle_professional: 'Join {name} and connect with your patients from everywhere',
    i_am_patient: 'I am Patient',
    i_am_professional: 'I am Healthcare Professional',
    start_enjoying: 'Start enjoying the benefits of using the platform {name}',
    signup_successfully: 'You have successfully registered',
    check_your_inbox: 'Please check your email account to activate your account',
    select_professional: 'Select a professional ...',
    select_center: 'Select the center you belong to...',
  },
  subscriptions: {
    name: 'Subscription | Subscriptions',
    not_have_hired: 'You have no subscription contracted',
    renewal: 'renewal',
    expire: 'Expire',
    request_new_service: 'Request new service',
    confirm_cancel_explain:
      'This service will not be available from {date} Are you sure you want to cancel your subscription?',
    cancelled_ok: 'Subscription has been successfully canceled',
  },
  videoconsultations: {
    confirmed: 'Confirmed',
    pending_confirm: 'Pending confirmation',
    pending_payment: 'Payment pending',
    go_to_video: 'Enter video consultation',
    pending_confirm_explain: 'The professional must confirm the video consultation',
    pay_video: 'Pay video consultation',
    pay: 'Pay',
    to_access: 'Access',
    expire_at: 'Expire at',
    download_recording: 'Download recording',
    show_video: 'Show video consultation',
    seconds: 'Seconds',
    paymentPatientNotCorresponding:
      'We have detected that there is a session started that does not correspond to that of the patient to whom the video consultation is directed. To pay, please log out and try again.',
  },
  session: {
    title: 'Session Timeout',
    subtitle: 'Your online session will expire in',
    advice: 'Please click "Continue" to keep working or click "Log Off" to end your session now',
    close_session: 'Log Off',
    continue: 'Continue',
    second: 'second | seconds',
  },
  webphone: {
    calling: 'Calling',
    mute: 'Mute',
    pause: 'Pause',
    speaker: 'Speaker',
    hang_up: 'Hang Up',
    call: 'Call',
    new_call: 'Phone Call',
    please_enter_number: 'Please enter a phone number',
    please_enter_valid_number: 'Please enter a valid phone number',
    please_enter_valid_es_number: 'Please enter a valid spanish phone number',
    an_error_ocurred: 'An error ocurred!',
    retry: 'Retry',
    token_expired: 'Token expired',
    webphone: 'Webphone',
  },
  incompatibleBrowser: {
    title: 'Unsupported browser',
    message: 'You are using a web browser that is not supported by Docline.',
    title2: '',
    message2: '',
    helpCenterMessage: 'If you want to know which are our compatible browsers',
    helpCenterLinkName: 'visit our help article.',
    helpCenterLink: 'https://help.docline.es/article/75-supportedbrowsers',
  },
  triage: {
    start: 'Start triage',
    finish: 'Finish triage',
    title: 'Triage',
    code: 'Triage code',
    generate_code: 'Generate a new code',
    explain: 'This code allows the patient to start the triage on their own.',
    code_error: 'Triage code invalid!',
    type_code: 'Introduce the triage code to start.',
  },
  registro: {
    professional_registration_process: 'Health professional registration process',
    init_session: 'Log in',
    have_an_account: 'Already have an account?',
    personal_info: 'Personal information',
    please_complete_info: 'Please complete your professional information.',
    documents: 'Documents',
    add_passport: 'Please attach your passport.',
    add_nie: 'Please attach your NIE on both sides.',
    add_dni: 'Please attach your identity card on both sides.',
    front_image: 'Image front side',
    rear_image: 'Image rear side',
    accept_conditions:
      'I have read and accept the <a href="{privacyPolicyLink}" target="_blank">terms of use and privacy policy</a>',
    drag_or_select_file: 'Drag the image here or <span class="filepond--label-action open">select the file</span>.',
    save_ok_title: 'Successfully saved',
    save_ok_text:
      'You have successfully registered, you will receive an email when we validate your details and you can start using our service.',
    alert_front_image: 'You must enter the image of the front side of the document.',
    alert_rear_image: 'You must enter an image of the back side of the document',
    alert_accept_conditions: 'You must accept the terms of use and privacy policy.',
    alert_image_max_size: 'The image must not exceed 2MB',
    alert_image_format: 'The format of the image must be .png o .jpg',
    alert_input_required: 'Mandatory field',
    alert_phone_required: 'Telephone is mandatory',
    alert_phone_is_invalid: 'The phone is invalid',
    alert_email_required: 'E-mail is mandatory',
    alert_email_is_invalid: 'E-mail must be valid',
    alert_number_required: 'The license number is mandatory',
    alert_number_is_invalid: 'The license number is not valid',
  },
  verifyusers: {
    to_be_verified: 'To be verified',
    verified: 'Verified',
    verified_pendings: 'Pending verification',
    reject: 'Reject',
    loading: 'Loading',
  },
  medicalTests: {
    analytical_tests_list: 'Analytical tests list',
    analytical_tests_list_types: 'Types of analytical tests',
    created_at: 'Created at',
    code: 'Code',
    description: 'Description',
    new_test: 'New test',
    delete_test: 'Delete analytical test.',
    confirm_delete_test: 'Are you sure you want to delete this analytical test?',
    new_type_of_test: 'New type of test',
    test_type_code: 'Test type code',
    tests_included: 'Tests included',
    observations: 'Observations',
    invalidCodeFormat: 'Invalid code format',
    almostOneAct: 'Must add at least one act',
    description_too_short: 'Description too short',
    create_test_success: 'Type test create success',
    new_analytical_test: 'New test',
    no_data: 'No data available',
    nomenclator: 'Nomenclature',
    new_act: 'New act',
    new_nomenclator: 'New nomenclature entry',
    edit_nomenclator: 'Editar nomenclature entry',
    specialty: 'Specialty',
    group: 'Group',
    collection: 'Collection',
    subgroup: 'Subgroup',
    codeCiepq: 'CIEP-9 Code',
    invalidCiep9: 'The format must be a 4-digit numeric (XXXX)',
    codeCie9: 'CIE-9 Code',
    addAnotherCode: 'Add another code',
    codesCie9: 'CIE-9 codes',
    externalId: 'External ID',
    actDescription: 'Act description',
    search: 'Search',
    request_medical_test: 'Request medical test',
    request_analytical_test: 'Request analytical test',
    patient_info: 'Patient Information',
    select_medical_test: 'Select medical test',
    select_medical_test_type: 'Select medical test type',
    analytical_test: 'Analytical test',
    other_medical_tests: 'Other medical tests',
    medical_tests_requested: 'Requested medical tests',
    other_medical_tests_requested: 'Other requested medical tests',
    medical_test_description: 'Medical test description',
    medical_test_recommendations: 'Medical test recommendations',
    medical_test_request: 'Request',
    add_act: 'Add act',
    delete_act: 'Delete act',
    select_acts: 'Select acts',
    confirm_delete_act: 'Are you sure you want to delete the act?',
    select_some_test: 'Select a test',
    select_patient: 'Select a patient',
    request_medical_test_success: 'Request generated successfully',

    request_rx_test: 'Request rx test',
    rx_test: 'RX test',
    rx_structured_view: 'Structured view',
    rx_simple_view: 'Simple view',
    rx_modality: 'Modality',
    rx_anatomical_region: 'Anatomical region',
    rx_exploration: 'Exploration',
    rx_search_hint: 'Search RX tests',
    rx_not_found: 'We have not found tests with that description',
    rx_protocols: 'RX protocols',
    new_rx_protocol: 'New RX protocol',
    request_other_test: 'Request other tests',
    other_test: 'Other test',
  },
  personal_doctor: {
    personal_doctor: 'Personal doctor',
    personal_doctors_historical: 'Historical personal doctors',
    personal_doctor_present: 'Present',
    loading_personal_doctors: 'Loading professionals list...',
    not_found_personal_doctors: 'We have not found professionals with this name.',
    network_error: 'There was a problem loading the list of professionals.',
    network_error_short: 'There was a problem loading the list',
    retry: 'Retry',
    pending_assignment: 'Assignment pending',
    is_your_personal_doctor: 'I am your personal doctor',
  },
};
