import { ref } from 'vue';
import { getHeader, tocUrl } from '@/config/config';

const TOC_ENDPOINT = tocUrl;

export function useToc(language) {
  const toc = ref('');
  const isLoadingToc = ref(true);
  const loadedSuccessfully = ref(false);
  const failedLoad = ref(false);

  const fetchToc = async () => {
    const url = new URL(TOC_ENDPOINT);
    const params = {
      language: language,
    };

    url.search = new URLSearchParams(params).toString();

    const raw = await fetch(url, {
      headers: getHeader(),
    });
    const body = await raw.json();
    return body.template;
  };

  const loadToc = async () => {
    isLoadingToc.value = true;
    try {
      toc.value = await fetchToc();
      loadedSuccessfully.value = true;
    } catch (e) {
      failedLoad.value = true;
    } finally {
      isLoadingToc.value = false;
    }
  };

  return {
    toc,
    isLoadingToc,
    loadedSuccessfully,
    failedLoad,
    loadToc,
  };
}
