<template>
  <div class="dropdown-box-section">
    <h3 class="dropdown-box-section-title title">
      {{ title }}

      <span class="dropdown-box-section-btn" @click="show = !show">
        <v-btn icon>
          <v-icon color="grey" :style="'font-size: xx-large;'">
            {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </v-btn>
      </span>
    </h3>

    <div class="dropdown-box-section-container" :class="{ show: show }">
      <div ref="content" class="dropdown-box-section-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    show: true,
  }),
};
</script>

<style lang="scss" scoped>
.dropdown-box-section {
  --dropdown-box-section-max-height: 200;
  background-color: #ffffff;
}

.dropdown-box-section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0.75rem 0.25rem;
  color: #3e424a;
  font-weight: bold;
  font-size: 1.5rem;
}

.dropdown-box-section-btn {
  cursor: pointer;
}

.dropdown-box-section-container {
  box-sizing: border-box;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.25s ease-in-out;

  &.show {
    max-height: unset;
  }
}
.dropdown-box-section-content {
  padding: 0.5rem 1rem;
  border-radius: 2px;
  border: 1px solid #cccccc;
}
</style>
