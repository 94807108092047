<template>
  <v-layout v-if="showBreads" class="align-center layout px-4 pt-4 app--page-header">
    <v-breadcrumbs divider="-">
      <v-breadcrumbs-item>
        <v-icon larg @click="redireccionarTo('dashboard')">mdi-home</v-icon>
      </v-breadcrumbs-item>
      <v-breadcrumbs-item v-for="(item, key) in breadcrumbs" :key="key">
        <span @click="redireccionarTo(item)">{{ $t(item) }}</span>
      </v-breadcrumbs-item>
    </v-breadcrumbs>
  </v-layout>
</template>

<script>
import menu from '@/config/menu';

export default {
  data() {
    return {
      showBreads: false,
      title: '',
    };
  },
  computed: {
    breadcrumbs: function() {
      const mn = menu;
      const breadcrumbs = [];
      mn.forEach(item => {
        if (item.items) {
          const child = item.items.find(i => {
            return i.component === this.$route.name;
          });
          if (child) {
            breadcrumbs.push(item.title);
            breadcrumbs.push(child.title);
            this.title = child.title;
          }
        } else {
          if (item.name === this.$route.name) {
            this.title = item.title;
            breadcrumbs.push(item.title);
          }
        }
      });
      return breadcrumbs;
    },
  },
  methods: {
    refreshPage() {
      this.$router.push(this.$route.fullPath);
    },
    redireccionarTo(page) {
      let pagina = '/dashboard';
      if (page === 'menu.settings') {
        pagina = '/ajustes';
      }
      this.$router.push(pagina);
    },
  },
};
</script>
