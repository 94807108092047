import environment from '@/environment';

export default languages => {
  const selectorsLanguages = environment?.forms?.selectorsLanguages ? environment?.forms?.selectorsLanguages : null;
  if (selectorsLanguages) {
    languages = languages.filter(el => el.id in selectorsLanguages);
  }

  return languages;
};
