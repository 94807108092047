<template>
  <!-- eslint-disable vue/no-v-html -->
  <v-dialog
    v-model="show"
    scrollable
    max-width="480"
    class="two-fa-configuration-warning"
    content-class="two-fa-configuration-warning"
    @click:outside="handleCancel"
    @keydown.esc="handleCancel"
    @keydown.enter="handleAccept"
  >
    <v-btn v-tooltip="$t('common.close')" class="two-fa-configuration-warning__close-button" icon @click="handleCancel">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-card class="px-6 py-6">
      <!-- title -->
      <v-card-title class="text-h6 font-weight-regular lighten-2 mb-1 px-0 justify-center flex-column">
        <span class="two-fa-configuration-warning__icon mb-4">
          <v-icon color="white" size="40">mdi-shield-lock-outline</v-icon>
        </span>
        <span>{{ $t('2fa.title') }}</span>
      </v-card-title>
      <!-- body -->
      <p class="text-body-1 text--secondary" v-html="$t('2fa.configureDialogTextUpper')" />

      <span
        tag="p"
        class="text-body-1 text--secondary mt-2"
        v-html="
          $t('2fa.configureDialogTextLower', {
            link: `<a href='${$t('2fa.configureDialogLink')}'>${$t('2fa.configureDialogTextLink')}</a>`,
          })
        "
      />

      <!-- actions -->
      <v-card-actions class="mt-12 justify-center">
        <v-btn color="warning" light depressed @click="handleClick">{{ $t('2fa.setup') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'TwoFaConfigurationWarningDialog',

  components: {},

  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    ...mapMutations({
      setTwoFaDialogVisibility: 'layout/setTwoFaDialogVisibility',
    }),

    handleClick() {
      this.$emit('handle-click');
      this.setTwoFaDialogVisibility(false);
    },

    handleCancel() {
      this.setTwoFaDialogVisibility(false);
    },
  },
};
</script>

<style lang="scss">
.two-fa-configuration-warning {
  position: relative;

  &__close-button {
    position: absolute !important;
    top: rem(4);
    right: rem(4);
    z-index: 9;
  }

  &__icon {
    height: rem(68);
    width: rem(68);
    border-radius: 50%;
    background-color: #ff9800;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
