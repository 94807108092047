export default {
  common: {
    videoconferences: 'Citas',
  },
  menu: {
    videocam: 'Citas',
  },
  tipovideo: {
    estandar: 'Cita estándar',
    precio: 'Precio de la cita',
    duracion: 'Duración de la cita',
    guardar: 'Guardar cita',
    nuevo_video: 'Crear servicio cita',
    confirm_delete_titulo: 'Eliminar servicio de cita',
    confirm_delete_texto: '¿Estás seguro que deseas eliminar el servicio de cita seleccionado?',
  },
  videoconsulta: {
    nueva_video: 'Nueva cita',
    crear_video: 'Crear cita',
    solicitando_pago_texto:
      'Envía una petición de pago por correo electrónico. Una vez el usuario haya realizado el pago, podrás enviarle la convocatoria para realizar la cita.',
    cita_cortesia_li:
      'Poder repetir citas que por algún tipo de incidencia no han podido completarse. | Enviar una invitación, para realizar una primera cita de prueba, o cualquier otro motivo que le anime a ello. | Pacientes que han adquirido previamente paquetes de citas.',
    add_destinatario: 'Indica la persona que recibe la cita',
    add_fechahora: 'Indica la fecha y hora de la cita',
    add_tiposervicio: 'Indica el tipo de cita',
    videoconsulta_para: '{tipoVideo} para',
    terminada: 'Cita terminada',
    singular: 'Cita',
  },
  suscripciones: {
    videoconsulta_restantes: 'Citas restantes',
  },
  permissions: {
    videoConsultation: 'Citas',
    haveCreateVideoWithTel: 'Cita por Tlf.',
  },
};
