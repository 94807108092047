<template>
  <v-alert
    style="margin: 0; min-width: 300px"
    :dismissible="dismissible"
    :type="color"
    :outlined="outlined"
    :prominent="prominent"
    @input="$emit('submit')"
  >
    <div class="d-flex align-center">
      <div class="mr-2">
        {{ text }}
      </div>
      <DialogActions :actions="actions" />
    </div>
  </v-alert>
</template>
<script>
import { VAlert } from 'vuetify/lib';
import DialogActions from './DialogActions.vue';

export default {
  components: {
    DialogActions,
    VAlert,
  },
  layout: ['notification', { showClose: false }],
  props: {
    color: {
      type: String,
      default: () => 'info',
    },
    actions: {
      type: [Array, Object],
      default: () => {
        /** */
      },
    },
    text: {
      type: String,
      default: '',
    },
    outlined: Boolean,
    prominent: Boolean,
    dismissible: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
