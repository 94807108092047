export default {
  state: {},
  mutations: {},

  actions: {
    init({ dispatch }) {
      const user = JSON.parse(window.localStorage.getItem('auth_ehealth'));
      dispatch('app/loadConfigFromBrand');

      if (user) {
        dispatch('app/loadConfigFromUser');
        dispatch('app/getPaymentSettings');
      }
    },
  },
};
