import i18n from '../../engine/framework/modules/i18n';

const getMasterFormulaNewError = () => ({
  composition: false,
  dosage: false,
  takingUnit: false,
});

export default {
  state: {
    valid: true,

    errors: {
      masterFormulas: [],
    },
  },

  mutations: {
    errorsMasterFormulasSet(state, payload) {
      state.errors.masterFormulas = payload;
      state.valid = state.errors.masterFormulas.reduce((carry, error) => {
        Object.keys(error).forEach(field => {
          if (error[field] === true) {
            carry = false;
          }
        });

        return carry;
      }, true);
    },

    errorsMasterFormulasAddNew(state) {
      state.errors.masterFormulas.push(getMasterFormulaNewError());
    },

    errorsMasterFormulasRemove(state, payload) {
      state.errors.masterFormulas.splice(payload, 1);
    },
  },

  actions: {
    masterFormulasAddError({ commit }) {
      commit('errorsMasterFormulasAddNew');
    },

    masterFormulasRemoveError({ commit }, index) {
      commit('errorsMasterFormulasRemove', index);
    },

    masterFormulasValidate({ commit, state }, masterFormulas) {
      const errors = masterFormulas.reduce((carry, masterFormula, index) => {
        const error = getMasterFormulaNewError();

        const mustValidated = Object.keys(error).reduce((carry, key) => {
          return masterFormula[key] !== '' ? true : carry;
        }, false);

        if (mustValidated) {
          Object.keys(error).forEach(field => {
            if (masterFormula[field] === '') {
              error[field] = true;
            }
          });
        }

        carry[index] = error;

        return carry;
      }, []);

      commit('errorsMasterFormulasSet', errors);

      if (!state.valid) {
        throw new Error(i18n.t('recipe.err_master_formulas'));
      }
    },
  },
};
