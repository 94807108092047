<template>
  <v-snackbar
    top="top"
    class="alert"
    :multi-line="false"
    :vertical="false"
    :timeout="timeout"
    :color="color"
    :value="show"
    @input="hideAlert"
  >
    <v-icon v-if="icon"> {{ icon }} </v-icon>&nbsp;
    {{ message }}

    <v-btn v-if="link && link_text" text :color="color" @click="hideAlert">
      <a :href="link" class="text-uppercase text-link">
        {{ link_text }}
      </a>
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  computed: mapState({
    color: state => state.layout.alert.color,
    icon: state => state.layout.alert.icon,
    link_text: state => state.layout.alert.link_text,
    link: state => state.layout.alert.link,
    message: state => state.layout.alert.message,
    show: state => state.layout.alert.show,
    timeout: state => state.layout.alert.timeout,
  }),

  methods: mapActions('layout', ['hideAlert']),
};
</script>

<style>
.alert {
  z-index: 9999;
}

.text-link {
  color: #ffffff !important;
  text-decoration: none;
}
</style>
