<template>
  <v-dialog
    ref="vdialog"
    v-model="isActive"
    eager
    content-class="vuedl-layout"
    :fullscreen="fullscreen"
    :max-width="getWidth"
    :persistent="persistent || loading"
    :scrollable="scrollable"
    :transition="transition"
    :hide-overlay="hideOverlay"
    @keydown.esc="dismiss"
  >
    <div class="v-dialog-wrapper">
      <v-btn v-if="showClose && !persistent && !loading" icon class="vuedl-layout__closeBtn" @click.stop="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <dialog-child ref="dialog" v-bind="$options.propsData" />
    </div>
  </v-dialog>
</template>

<script>
import { VDialog } from 'vuetify/lib';

export default {
  components: {
    VDialog,
  },
  props: {
    fullscreen: Boolean,
    scrollable: Boolean,
    hideOverlay: Boolean,
    transition: {
      type: [String, Boolean],
      default: 'dialog-transition',
    },
    showClose: {
      type: Boolean,
      default: () => true,
    },
  },
  methods: {
    _destroy() {
      // Allow to draw transition, cause vuetify doesn't have onClose method
      setTimeout(() => {
        this.$destroy();
      }, 1000);
      // this.$refs.vdialog.$refs.dialog.addEventListener('transitionend', this.onTransitionEnd)
    },
    // onTransitionEnd (event) {
    //   if (['opacity', 'z-index'].indexOf(event.propertyName) >= 0) {
    //     this.$refs.vdialog.$refs.dialog.removeEventListener('transitionend', this.onTransitionEnd)
    //     this.$destroy()
    //   }
    // }
  },
};
</script>
<style>
.vuedl-layout {
  position: relative;
}
.vuedl-layout__closeBtn {
  position: absolute;
  top: 14px;
  width: 16px;
  height: 16px;
  font-family: -webkit-pictograph;
  right: 14px;
  font-size: 30px;
  opacity: 0.8;
  z-index: 1000;
  cursor: pointer;
  line-height: 0.5;
}
.vuedl-layout__closeBtn:hover {
  opacity: 1;
}
</style>
