import environment from "@/environment";

export const NARCOTIC_DEADLINE_IN_MONTHS = 3;
export const MEDICINE_DEADLINE_IN_MONTHS = 12;
export const MAXIMUM_NARCOTIC_PACKAGES = 4;
export const LIMIT_IN_DAYS_FOR_SAME_MEDICINE = 365;
export const DATEPICKER_MAX_YEARS_SINCE = 100;
export const DEFAULT_CURRENCY_SYMBOL = '€';

export const TWO_FA_HEADER = 'x-doc-2fa';
export const TWO_FA_KEY = 'otp_enabled';
export const TWO_FA_CODES_FILENAME = 'Docline-two-fa-safety-codes.pdf';
export const TWO_FA_ENABLED_LOGIN_CODE_ERROR = 400;
export const TWO_FA_LOGIN_FAIL_CODE_ERROR = 403;
export const TWO_FA_ERROR_MESSAGE_CODE = '2fa';
export const TWO_FA_CODE_LENGTH = 6;
export const GOOGLE_AUTHENTICATOR_PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
export const GOOGLE_AUTHENTICATOR_APP_STORE_LINK = 'https://apps.apple.com/es/app/google-authenticator/id388497605';
