export default {
  card_number: 'Número de tarjeta',
  or: 'o',

  account: {
    chooseImage: 'Elegir imagen',
    chooseImageText: 'Pincha y arrastra para encuadrar. Usa el trackpad o la rueda del ratón para hacer zoom.',
    cancel: 'Cancelar',
    continue: 'Continuar',
    noImage: 'No has elegido una imagen',
    fileNotAllowed: 'No se pudo cargar el archivo. Este tipo de archivo no es compatible.',
    fileTooBig: 'Archivo demasiado grande. {fileSize} máx.',
    uploadError: 'Ha ocurrido un error, inténtalo de nuevo.',
    idPassport: 'DNI/Pasaporte',
    biography: 'Biografía',
    editBio: 'Edita tu bio en {lang}',
    successEdit: 'Tu perfil se ha modificado correctamente',
    security: 'Seguridad',
    passwordHint: 'Una contraseña segura evitará que pueda ser descifrada facilmente',
    change: 'Cambiar',
    marketplaceName: 'Nombre Marketplace',
    terms_and_conditions: 'Términos y Condiciones',
    terms_and_conditions_documentation_title: 'Documentación',
    terms_and_conditions_documentation_description: 'Revisa y/o descarga cómodamente el contenido de este bloque en PDF. Siempre que se produzca una actualización del mismo, serás previamente informado.',
    terms_and_conditions_see: 'Ver',
  },
  '2fa': {
    title: 'Doble factor de autenticación',
    hint:
      'Añade una capa extra de seguridad a tu cuenta configurando 2FA. Una vez activado, el código generado será necesario para iniciar sesión.',
    setup: 'Configurar 2FA',
    disable: 'Desactivar 2FA',
    introText:
      'La autenticación de doble factor es una capa adicional de seguridad para tu perfil, que se ha diseñado con el <strong>objetivo de garantizar que eres la única persona que puede acceder a tu cuenta</strong>, aunque alguien conozca tu contraseña.',
    downloadApp: 'Descarga la app',
    scanQr: 'Escanea el código QR',
    enterCode: 'Introduce el código',
    downloadAppText:
      'Instala la<strong> app Google Authenticator</strong> en tu teléfono o tablet para configurar el doble factor de autenticación en tu cuenta.',
    alreadyInstalled: 'Ya la tengo instalada',
    alreadyScanned: 'Ya lo he escaneado',
    qrText:
      'Abre la app Google Authenticator, sigue las instrucciones y escanea el código QR que se muestra en la pantalla.',
    qrTextMobile:
      'Abre la app Google Authenticator, sigue las instrucciones e introduce el código que se muestra en la pantalla.',
    qrHint: 'Si no puedes escanear el código QR, introduce manualmente el código que aparece a continuación',
    checkCodeTitle: 'Introduce el código de 6 dígitos.',
    invalidCodeError: 'El código introducido no es correcto',
    errorOnSetup: 'No podemos activar el doble factor de autenticación en este momento, inténtalo más tarde.',
    enterCodePromptText: 'Introduce el código de 6 dígitos que muestra la aplicación Google Authenticator.',
    disablePromptText:
      'Su cuenta tiene<span class="green--text font-weight-medium">activado</span>el doble factor de autenticatión. Para desactivarlo, introduce el código que se muestra en pantalla de la app Google Authenticator.',
    verify: 'Verificar',
    setupFinishedTitle: 'Doble factor de autenticación activado',
    setupFinishedText: 'Ya has configurado el doble factor de autenticación.',
    downloadCodeButton: 'Descargar una copia de la clave de acceso.',
    downloadCodeHint:
      'Este código te permitirá restaurar el acceso a tu cuenta en caso de que cambies o pierdas tu teléfono o elimines accidentalmente la app Google Authenticator. ¡Guárdala en un lugar seguro!',
    backToMyAccount: 'Volver a mi cuenta',
    disableOk: 'Doble factor de autenticación desactivado',
    disableKo: 'Error desactivando el doble factor de autenticación. Inténtalo de nuevo.',
    googleAppName: 'Google Authenticator',
    doneIt: 'Hecho',
    copyPasteKey: 'Copia y pega el código',
    smsLinkText: 'Envíame un SMS con el link de descarga de la app',
    smsModalTitle: 'Teléfono móvil',
    smsModalText:
      'Introduce el número de teléfono donde quieres recibir el enlace para descargar la aplicación de Google Authenticator.',
    smsModalButton: 'Enviar enlace',
    smsSent: 'SMS enviado al teléfono {phone}',
    smsSendingError: 'Ha ocurrido un error al enviar el SMS al número {phone}',
    smsMaxAllowed: 'No es posible usar el envío de SMS más de 3 veces al día, sentimos las molestias',
    storeRedirectWarning:
      'No es posible redirigirte a la página de Google Authenticator. Por favor, contacta con nuestro soporte de atención al cliente.',
    storeRedirectWarningTitle: 'Lo sentimos',
    loginHelpLink: 'https://ayuda.docline.es/article/240-lost2faes',
    loginHelpLinkText: 'No puedo acceder',
    requestReset: 'Solicitar reseteo 2FA',
    failedResetRequest: 'Ha ocurrido un error al intentar Solicitar reseteo de 2FA. Prueba de nuevo en unos minutos.',
    sentRequestResetToAdmin:
      'Se ha enviado un correo electrónico al administrador de su cuenta para que proceda a resetear su 2FA y pueda recuperar el acceso a su cuenta',
    sentRequestResetToDocline:
      'Se ha enviado un correo electrónico a soporte@docline.es para resetear su 2FA y que pueda recuperar el acceso a su cuenta. Le informaremos cuando la solicitud se haya completado',
    reset2fa: 'Resetear 2FA',
    resetted2fa: 'Se ha reseteado correctamente el 2FA del usuario.',
    reset2faFailed: 'Ha habido un problema al resetear el 2FA del usuario. Prueba de nuevo más tarde.',
    alreadyRequestedReset:
      'Este usuario ya ha solicitado el reseteo de su 2FA. Le informaremos cuando la solicitud se haya completado',
    bannerText: 'Configura el doble factor de autenticación en tu cuenta para poder emitir recetas electrónicas.',
    bannerLink: 'https://ayuda.docline.es/article/237-whatis2faes',
    configureDialogTextUpper:
      'Para poder seguir emitiendo Recetas Electrónicas desde su cuenta necesita activar el doble factor de autenticación (2FA). El código 2FA también se le requerirá en el login',
    configureDialogTextLower: 'Si necesitas ayuda para configurarlo visita {link}',
    configureDialogTextLink: 'nuestro artículo de ayuda.',
    configureDialogLink: 'https://ayuda.docline.es/article/239-configure2faes',
    verifyCodePrompText: 'Introduce el código de 6 dígitos que se muestra en la app Google Authenticator',
    bannerTextPreviousRep:
      'A finales de mes introduciremos una capa extra de seguridad en tu cuenta con el doble factor de autenticación.',
    bannerLinkTextPreviousRep: '¿Qué es el 2FA y cómo configurarlo?',
    bannerLinkPreviousRep: 'https://ayuda.docline.es/article/237-whatis2faes',
  },
  agenda: {
    hoy: 'Hoy',
    lunes: 'Lunes',
    martes: 'Martes',
    miercoles: 'Miércoles',
    jueves: 'Jueves',
    viernes: 'Viernes',
    sabado: 'Sábado',
    domingo: 'Domingo',
    lunes_redu: 'Lun',
    martes_redu: 'Mar',
    miercoles_redu: 'Mié',
    jueves_redu: 'Jue',
    viernes_redu: 'Vie',
    sabado_redu: 'Sáb',
    domingo_redu: 'Dom',
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
    horainicio: 'Hora de inicio',
    horafin: 'Hora de fin',
    configuracion: 'Configuración de la agenda',
    disponibilidad: 'Calendario de disponibilidad',
    nodisponible: 'Días no disponibles',
    disponible: 'Días disponibles',
    sin_disponibles: 'No has configurado ningún día como no disponible para los próximos días',
    nuevo_nodisponible: 'Crear no disponible',
    nuevo_disponible: 'Crear disponible',
    editar_nodisponible: 'Editar no disponible',
    editar_disponible: 'Editar disponible',
    diacompleto: 'Día completo',
    delete_nodisponible: 'Eliminar día no disponible',
    delete_disponible: 'Eliminar día disponible',
    confirm_delete_nodisponible: 'El día pasará a estar disponible. ¿Deseas continuar?',
    confirm_delete_disponible: '¿Estás seguro de que deseas eliminar el día marcado como disponible?',
    nuevo_rango: 'Nuevo rango',
    hora_inicio_dia: 'Hay un error en el rango de horas del {dia}',
    hora_inicio_debe_ser: 'La hora de inicio debe ser menor a la hora de fin',
    especialidades: 'Agenda de especialidades',
    especialidad: 'Especialidad',
    seleccione_especialidad: 'Selecciona una especialidad para configurar su duración y la disponibilidad de la agenda',
    sin_horas_asignadas: 'Actualmente no tienes asignada ninguna agenda',
    gmtAlert: 'Recuerde que las citas corresponden a la zona horaria GMT+1 (Madrid)',
    gmtAlertAfrican: 'Recuerde que las citas corresponden a la zona horaria GMT+2 (Sudáfrica)',
  },
  anestesia: {
    de_que_opera: '¿De qué vas a ser operado?',
    quien_opera: '¿Quién te va a operar?',
    edad: 'Edad',
    peso: 'Peso',
    talla: 'Talla',
    alergico_medicacion: '¿Eres alérgico a alguna medicación o producto?',
    indique_a_que: 'Indica cuál en caso afirmativo',
    tension_alta: '¿Tienes la tensión arterial alta?',
    diabetico: '¿Tienes diabetes (azúcar en sangre)?',
    fumador: '¿Eres fumador?',
    enfemedad_actual: '¿Padeces alguna enfermedad en la actualidad?',
    enfermedad_previa: '¿Has padecido previamente alguna enfermedad?',
    medicacion: 'Si tomas alguna medicación indica cuál.',
    operado_anterior: '¿Has sido operado anteriormente?',
    problema_anestesia: '¿Has tenido algún problema con la anestesia?',
    indique_si_ha_sufrido: 'INDICA SI HA SUFRIDO O SUFRES ALGUNAS DE LAS ENFERMEDADES QUE SE CITAN A CONTINUACIÓN:',
    corazon: 'Enfermedades del corazón (angina de pecho, infartos, etc)',
    pulmon: 'Enfermedades del pulmón (bronquitis, asma, etc)',
    higado: 'Enfermedades del hígado',
    enfermedad_rinon: 'Enfermedades del riñón',
    comentarios: 'A continuación puedes hacer algún comentario sobre cuestiones no preguntadas:',
    fecha_intevencion: 'Fecha de intervención',
    informe_preanestesia: 'Informe pre-anestesia',
  },
  appointments: {
    name: 'Videoconsulta | Videoconsultas',
    not_have_pending: 'No dispones de videoconsultas pendientes',
    error: 'Ha habido un problema al cargar las videoconsultas pendientes',
    onlineBookingNotAvailable: 'Servicio de citas no disponible. Selecciona otra fecha',
    dateRequired: 'Selecciona la hora a la que deseas realizar la videoconsulta',
  },
  asignar: {
    titulo: 'Asignar consulta',
    titulo_sms: 'Notificar por SMS',
    texto_video:
      '¿Estás seguro de que deseas asignarte esta consulta y por tanto te comprometes a atender al paciente?',
    texto_sms:
      '¿Estás seguro de que deseas enviarle un SMS al profesional para notificarle que hay pacientes a la espera de ser atendidos?',
    texto_mensaje:
      '¿Estás seguro de que deseas asignarte este mensaje y por tanto te comprometes a atender al paciente?',
    asignada_correctamente: 'Se ha asignado la consulta correctamente',
    enviado_correctamente: 'El mensaje ha sido enviado correctamente',
  },
  calendario: {
    especialidades: 'Calendario por especialidad',
    professionals: 'Calendario de profesionales',
    select_professional: 'Selecciona profesional',
    nodisponible: 'No disponible',
    no_data: 'No existe información disponible. Revisa la configuración de la agenda',
    datePassed: 'La fecha seleccionada es inferior a la actual',
  },
  beneficiaries: {
    no_beneficiaries: 'Aún no has añadido usuarios asociados',
    relationship: 'Relación',
    new: 'Nuevo',
  },
  change_password: {
    change_password: 'Cambiar contraseña',
    current_password: 'Contraseña actual',
    new_password: 'Nueva contraseña',
    confirm_password: 'Repite la contraseña',
    at_least_num_characters: 'Al menos {num} caracteres',
    at_least_one_lowercase: 'Al menos una minúscula',
    at_least_one_uppercase: 'Al menos una mayúscula',
    at_least_one_number: 'Al menos un número',
    at_least_one_symbol: 'Al menos un símbolo',
    current_password_not_valid: 'La contraseña actual no es válida',
    password_required_message: 'Debe completar la contraseña',
    password_not_valid_message: 'La contraseña no es válida',
    passwords_not_match: 'Las contraseñas no coinciden',
    change_password_success_message: 'La contraseña se ha modificado correctamente',
    unknown_error: 'Ha ocurrido un error',
  },
  chequeo: {
    delete: 'Eliminar Chequeo',
    confirm_delete: '¿Estás seguro de que deseas eliminar el servicio de chequeo seleccionado?',
  },
  common: {
    search: 'Buscar',
    go: 'Ir',
    create: 'Crear',
    aceptar: 'Aceptar',
    condiciones: 'CONDICIONES GENERALES DE USO',
    cancel: 'Cancelar',
    ok: 'OK',
    confirm: 'Confirmar',
    save: 'Guardar',
    name: 'Nombre',
    category: 'Categoría',
    lang: 'Idioma',
    lang_platform: 'Idioma de la plataforma',
    lang_estracto: 'Idioma del extracto',
    prescription: 'Receta | Recetas',
    documents: 'Documentos',
    chequeos: 'Chequeos',
    messaging: 'Mensajería',
    videoconference: 'Videoconsulta',
    videoconferences: 'Videoconsultas',
    presential: 'Presencial',
    pack: 'Pack',
    cita: 'Cita',
    country: 'País',
    province: 'Provincia',
    location: 'Localidad',
    cp: 'Código postal',
    address: 'Dirección',
    spanish: 'Español',
    english: 'Inglés',
    catalan: 'Catalán',
    portugues: 'Portugués',
    polaco: 'Polaco',
    date: 'Fecha',
    type: 'Tipo',
    size: 'Tamaño',
    actions: 'Acciones',
    date_up: 'Fecha alta',
    of: 'de',
    changes: 'Cambios',
    closed_session: 'Sesión cerrada correctamente',
    closed_session_expired: 'Por motivos de seguridad la sesión ha sido cerrada',
    volver_atras: 'Volver atrás',
    acceso_denegado: 'Lo siento, acceso denegado.',
    emitir_receta: 'Emitir receta',
    modify_recipe: 'Modificar receta',
    delete_recipe: 'Eliminar receta',
    delete_recipe_confirm: '¿Seguro que deseas eliminar la receta seleccionada?',
    deleted_pending_electronic_recipe: 'La receta pendiente de emisión se ha eliminado correctamente',
    failed_delete_pending_electronic_recipe:
      'La receta pendiente de emisión no se ha podido eliminar. Prueba de nuevo más tarde.',
    emitSignPrescription: 'Firmar y emitir receta',
    alreadyDiscardedPrescription:
      'Ha creado una nueva receta dentro de un plan de tratamiento que ya ha expirado. Por favor, cree un nuevo tratamiento e incluya la nueva receta.',
    descripcion: 'Descripción',
    obligatorio: 'Campo obligatorio',
    invalid: 'Campo inválido',
    min_required: 'El campo debe tener al menos {tam} caracteres',
    paciente: 'Paciente',
    cualquiera: 'Cualquiera',
    activado: 'Activado',
    desactivado: 'Desactivado',
    gratuita: 'Gratuita',
    gratuito: 'Gratuito',
    precio: 'Precio',
    duracion: 'Duración',
    detalles: 'Detalles',
    hora: 'Hora',
    close: 'Cerrar',
    view: 'Ver',
    edit: 'Editar',
    delete: 'Eliminar',
    emitir: 'Emitir',
    no_registros: 'No hay registros',
    status: 'Estado',
    estado_servicio: 'Estado del servicio',
    no_access: 'No dispone de permisos suficientes para acceder a esta sección',
    prox_consultas: 'Próxima consulta | Próximas consultas',
    consultas_personales: 'Consultas asignadas',
    ult_mensajes: 'Últimos mensajes',
    mes_redu: 'Ene | Feb | Mar | Abr | May | Jun | Jul | Ago | Sep | Oct | Nov | Dic',
    enviar_enlace: 'Enviar enlace',
    version_trial: 'Esta funcionalidad no se encuentra activa durante la versión de prueba',
    titulo_salir_sin_guardar: 'Salir sin guardar',
    salir_sin_guardar:
      'Has realizado cambios en la ficha y no han sido guardados. ¿Estás seguro de que deseas salir sin guardar?',
    fecha_inicio: 'Fecha de inicio',
    fecha_fin: 'Fecha de fin',
    landing: 'Personalizar Landing Page',
    backgroundcolor: 'Color de fondo',
    nuevo_servicio: 'Nuevo servicio',
    otro: 'Otro',
    continuar: 'Continuar',
    filtrar: 'Filtrar',
    sin_atender: 'Consultas sin atender',
    medicos_disponibles: 'Médicos disponibles',
    cargando: 'Cargando...',
    semana: 'Semana',
    movil: 'móvil',
    celular: 'celular',
    centro: 'Centro',
    num: 'Número',
    reconocimiento: 'Reconocimientos médicos',
    apto: 'Apto',
    no_apto: 'No apto',
    sin_verificar: 'Sin verificar',
    aviso_legal: 'Aviso Legal',
    term_and_conditions: 'Términos y Condiciones',
    term_and_conditions_signup: 'Términos y Condiciones (Registro)',
    term_and_conditions_without_tax: 'Términos sin impuestos',
    politica_privacidad: 'Política privacidad',
    politica_cookies: 'Política Cookies',
    privacy: 'Privacidad',
    cookies: 'Cookies',
    aboutus: 'Acerca de',
    title: 'Título',
    access: 'Acceso',
    inactive: 'Inactivo',
    in_process: 'En proceso',
    active: 'Activo',
    last_login: 'Último login',
    last_connection: 'Última conexión',
    no_last_login: 'Aún no se ha logueado',
    no_last_access: 'Aún no ha accedido al dashboard',
    verification_state: 'Estado verificación',
    verified: 'Verificado',
    configuracion: 'Configuración',
    chequeo: 'Chequeo',
    professional: 'Profesional',
    numero_seg_social: 'Número de asegurado',
    modify_image: 'Modificar imagen',
    warning_image_exceeds_maximum: 'La imagen es mayor de {size} que es el tamaño máximo permitido',
    none: 'Ninguno',
    filters: 'Filtros',
    yes: 'Sí',
    no: 'No',
    active_web: 'Web activa',
    inactive_web: 'Web desactivada',
    phone_number: 'Número de teléfono',
    availableRoles: 'Roles disponibles',
    undefined: 'Sin definir',
    admin: 'Administrador',
    receptionist: 'Recepcionista',
    rrhh: 'RRHH',
    patient: 'Paciente',
    man: 'Hombre',
    woman: 'Mujer',
    unspecified: 'Sin especificar',
    more: 'Más',
    taxPercent: 'Porcentaje impuestos',
    taxType: 'Tipo de Impuestos',
    add: 'Añadir',
    fieldIncomplete: 'Debes rellenar el campo {field}',
    download_pdf: 'Descargar PDF',
    show_details: 'Mostrar detalles',
    authorization: 'Autorización|Autorizaciones',
    go_to_profile: 'Ir al perfil',
    notice: 'Aviso',
    accepted_at: 'Fecha de Aceptación',
    chat: 'Chat',
    participants: 'Participantes',
    consultation_subject: 'Motivo de consulta',
    you_are_online: 'Estás en línea',
    you_are_offline: 'Estás desconectado',
    short_date_format: 'DD/MM/YYYY',
    subscriptions_temporarily_disabled:
      'Lo sentimos, las suscripciones están deshabilitadas temporalmente. Por favor, inténtelo de nuevo dentro de unos días.',
    confirmGenericMessage: '¿Estás seguro? Esta acción no se puede deshacer',
    and: 'y',
    go_home: 'Volver al inicio',
    show_to_hiring: 'Visible para contratación en web',
    hide_to_hiring: 'No visible para contratación en web',
    image_text: 'Imagen en color (menor a 8,000px por 8,000px), en formato JPG, y de menos de 5MB.',
    dni_front: 'SUBIR DOCUMENTO DE IDENTIDAD (Anverso)',
    dni_back: 'SUBIR DOCUMENTO DE IDENTIDAD (Reverso)',
    price_v_standard: 'Precio V. Estándar',
    range_of_hours: 'Rango de horas',
    registration_status: 'Estado del AltaTest',

    date_placeholder: 'dd/mm/aaaa',
    years: 'años',
    max_n_characters: 'Máximo {chars} caracteres',
    return_back: 'Volver',
    download: "Descargar"
  },
  aboutus: {
    firstText:
      'La receta electrónica privada de Docline está integrada con la red de comunicaciones que conecta a todas las farmacias españolas.',
    secondText:
      'La receta electrónica privada de Docline está homologada por el Consejo General de Colegios Médicos Españoles (CGCOM).',
    prescriptionSystem: 'Homologación Sistema Receta Electrónica',
    prescriptionRepository: 'Homologación Repositorio Receta Electrónica',
    provider: 'Proveedor: Docline propiedad de Aplicaciones Salud S.L.',
    version: 'Versión: {version}',
    date: 'Fecha: {date}',
    accept: 'Aceptar',
  },
  cupones: {
    crear: 'Crear cupón',
    codigo: 'Código',
    descuento: 'Descuento',
    caducidad: 'Caducidad',
    num_usos: 'Número de usos',
    sin_cupones: 'No tienes configurado ningún cupón',
    crear_nuevo: 'Crear nuevo cupón',
    editar: 'Editar el cupón',
    eliminar_titulo: 'Eliminar el cupón',
    eliminar_texto: 'El cupón dejará de poder ser utilizado, ¿Estás seguro de que deseas eliminar el cupón?',
    min_code: 'El código debe tener al menos 6 caracteres',
    tam_code: 'El código debe tener {num} caracteres',
    porusos: 'Por usos',
    porfecha: 'Por fecha',
    nocaduca: 'No caduca',
    error_crear_existe_codigo: 'No se ha creado el cupón. Ya existe un cupón con el código',
    error_editar_existe_codigo: 'No se ha editado el cupón. Ya existe un cupón con el código',
    success_create: 'El cupón se ha creado correctamente',
    caduca_por_usos: 'Este cupón caduca por usos.',
    sin_caducidad: 'Cupón sin caducidad.',
    caduca_por_fecha: 'Este cupón caduca el ',
    usarlo_cualquiera: 'Puede ser utilizado por cualquier paciente.',
    usarlo_paciente: 'Solo puede utilizarlo ',
    form_codigo: 'Indica el código que utilizará el paciente para aplicar el cupón',
    form_descuento: 'Indica el descuento que tendrá el paciente al aplicar el cupón',
    form_nusos: 'Indica el número de veces que podrá ser utilizado el cupón',
    form_fecha: 'Selecciona la fecha de caducidad del cupón',
  },
  datatable: {
    rows_per_page: 'por página',
    all: 'Todos',
  },
  documentos: {
    nuevo: 'Nuevo documento',
    confirmar_subida_titulo: 'Subir documento',
    confirmar_subida_texto: '¿Estás seguro de que deseas subir el documento seleccionado?',
    subido_correctamente: 'El documento se ha subido correctamente',
    csv_uploaded:
      'El documento se ha subido correctamente. Este proceso puede llevar un tiempo. Si pasada media hora no se han actualizado los datos correctamente, consulta con soporte.',
    subir_reconocimiento: 'Por favor sube un certificado del reconocimiento médico',
    subir_documento: 'Por favor sube un documento que valide esta decisión',
    subir_certificado: 'Subir certificado',
    certificado: 'Certificado',
    renuncia: 'Renuncia',
    subir_renuncia: 'Subir renuncia',
  },
  documents: {
    upload_document: 'Subir documento',
    no_documents: 'No dispone de documentos',
    drag_and_drop: 'Selecciona o arrastra aquí tu documento',
    browse_file: 'buscar archivo',
    supported_files: 'Archivos soportados',
    size_limited: 'Máximo {num} MB',
    exceed_size_limit: 'El archivo ocupa más de los {num}MB máximos permitidos',
    not_multiple: 'No puedes subir más de un archivo a la vez',
    invalid_format: 'El formato del documento no es válido',
    upload_new: 'Subir nuevo',
    select_professional: 'Selecciona el profesional sanitario al que deseas habilitar el acceso al documento',
    are_you_sure_upload: '¿Estás seguro de que deseas subir el nuevo documento?',
    video: 'Video',
    chat: 'Mensajería',
    prescription: 'Receta',
    triage: 'Triaje',
    diagnosis: 'Diagnóstico',
    medical_report: 'Historia Clínica',
    other: 'Documento',
    electronic_prescription: 'Receta Electrónica',
    link_to_vc: 'Vincula el documento a una videoconsulta (opcional)',
  },
  errors: {
    not_access_patient: 'Ha ocurrido un error intentando acceder a la ficha del paciente',
    edit_patient: 'Ha ocurrido un error al editar al paciente',
    value_required: 'Introduce {value}',
    address_required: 'Es obligatorio introducir los datos relacionados con el domicilio',
    fiscal_address_required: 'Es obligatorio introducir la dirección fiscal, C.P. y localidad',
    phone_consultation_required: 'Es obligatorio rellenar el teléfono de consulta para activar la pasarela',
    edit_user: 'Ha ocurrido un error al editar al profesional',
    existing_patient: 'El paciente ya se encuentra registrado en {name}',
    form_invalid: 'Por favor rellena todos los campos obligatorios',
    form_empty: 'El formulario está vacío, completa al menos un campo',
    email_required: 'El correo electrónico es obligatorio',
    email_or_phone_required: 'El correo electrónico o teléfono es obligatorio',
    password_required: 'La contraseña es obligatoria',
    usr_pw_incorrect: 'Usuario o contraseña incorrectos',
    email_invalid: 'El correo electrónico no es válido',
    email_repeat: 'Los correos electrónicos no coinciden',
    file_uploaded_invalid: 'No es posible subir el documento seleccionado',
    try_again: 'Ha ocurrido un error al realizar la operación, por favor inténtalo de nuevo',
    save_history: 'Ha ocurrido un error guardando el historial clínico',
    existing_user: 'Ya hay un usuario registrado con el correo electrónico o nombre de doctor seleccionado',
    professional_already_exists: 'Ya hay un usuario registrado con el correo electrónico o slug seleccionado',
    collegiate_number_already_exists:
      'Ya hay un usuario registrado con este número de colegiado en la empresa seleccionada',
    professional_not_found: 'El profesional seleccionado no existe o está desactivado',
    schedule_not_valid: 'La configuración de la agenda no es correcta. Por favor, revísela.',
    existing_user_in_company:
      'Ya existe un usuario con ese correo electrónico y esa empresa. Por favor, elija otra dirección de correo electrónico o modifique la empresa a la que quiere asociar este profesional',
    existing_user_without_company:
      'Ya existe un profesional no asociado a ninguna empresa y con el mismo correo electrónico. Por favor, elija otra dirección de correo electrónico',
    existing_company: 'La empresa que estás intentando crear ya existe',
    horaFinMenorHoraInicio: 'La hora de fin no puede ser inferior a la hora de inicio',
    usr_dado_baja: 'El usuario no se encuentra activado en este momento',
    must_be_at_least_13_years: 'Debes tener al menos 13 años de edad',
    trial_caducado: 'El periodo de prueba ha caducado, si deseas ampliarlo contacta con nosotros a través de {name}',
    email_no_exist: 'El correo electrónico no se encuentra registrado en {name}',
    mayor_cero: 'La cantidad debe ser mayor a 0 | El valor debe ser mayor o igual a 0',
    user_exist: 'El correo ya se encuentra registrado en el sistema',
    file_max_upload: 'El archivo supera el tamaño máximo permitido que es de {tam}MB',
    input_tam_maximo_superado: 'El campo {input} supera el tamaño máximo permitido ({tam} caracteres)',
    input_tam_minimo: 'El campo {input} debe contener al menos {tam} caracteres',
    factura_no_disponible:
      'No es posible descargar la factura en estos momentos. Por favor, contacta con soporte para solucionar la incidencia.',
    date_required: 'La fecha de nacimiento es obligatoria',
    format_phone: 'El formato del teléfono no es correcto. Ejemplo: 612345678',
    phone_video_error:
      'No puedes seleccionar más de un paciente para crear cita a partir del número de teléfono del paciente.',
    max_patients_multi: 'No puedes seleccionar más de tres pacientes para una multiconsulta.',
    phone_empty: 'Debes introducir el número de teléfono',
    incorrect_hour: 'El formato de la hora no es correcto. Ejemplo: 10:30',
    incorrect_date: 'El formato de la fecha no es correcto',
    date_must_be_greater_than_now: 'No es posible seleccionar una fecha y hora anterior a la actual',
    incorrect_colegiado: 'El número de colegiado debe tener entre 8 y 9 dígitos',
    incorrect_collegiate_psychologist: 'Número de colegiado de psicología incorrecto',
    emit_e_prescription:
      'Se ha producido un error a la hora de Emitir la receta. Por favor, informe del problema y procederemos a resolverlo a la mayor brevedad',
    incorrect_colegiado_emit:
      'El número de colegiado no es correcto, por favor, accede a tu perfil, actualiza el número de colegiado y vuelve a intentarlo.',
    incorrect_collegiate_number: 'El número de colegiado debe ser correcto para poder emitir la receta',
    invalid_collegiate_number:
      'Tu número de colegiado es incorrecto. Para corregirlo y validarlo, por favor, contacta directamente con nuestro equipo en el email: {email}. *Recuerda que el número de colegiado está compuesto por 8 o 9 dígitos.',
    collegiate_not_allow_to_prescribe:
      'Tu número de colegiado no existe o no está habilitado para prescribir receta electrónica, por favor, accede a tu perfil, actualiza el número de colegiado y vuelve a intentarlo',
    invalid_patient_document:
      'El documento de identidad del paciente no es válido, por favor, acceda a su perfil, actualice su documento de identidad y vuelva a intentarlo',
    freemium_no_multi:
      'Con la versión Freemium no puedes crear multiconsultas. Contacta con tu comercial para actualizar a la versión Premium.',
    gender_required: 'El género es obligatorio',
    relation_required: 'La relación es obligatoria',
    type_prof: 'Debes seleccionar un tipo de profesional',
    bad_request: 'Ha ocurrido un error, faltan parámetros para completar la acción',
    unauthorized: 'No tiene permisos suficientes para realizar esta acción',
    must_be_adult: 'El usuario debe ser mayor de edad',
    specialty_required: 'Es obligatorio seleccionar al menos una especialidad',
    invalidEinNumber: 'El formato del número EIN es inválido',
    invalidUrl: 'Formato de URL inválido (https://example.com)',
    invalidNameOrSurname: 'Formato inválido, sólo se permiten letras con o sin caracteres especiales, y espacios.',
    fieldDoNotMatch: 'Los campos no coinciden',
    patient_has_no_email_message:
      'El paciente no tiene correo electrónico. Por favor, edita sus datos e introduce un email válido para poder realizar la emisión de la receta electrónica.',
    not_found_title: 'Esta página no está disponible',
    not_found_message:
      'Es posible que el enlace esté roto o que se haya eliminado la página. Comprueba que el enlace que quieres abrir es correcto.',
    server_down: 'Lo siento, el servidor no funciona.',
  },
  facturacion: {
    concepto: 'Concepto',
    importe: 'Importe',
    dateFactura: 'Fecha factura',
    datePay: 'Fecha pago',
    pagosrecibidos: 'Pagos recibidos',
    without_payments: 'No ha recibido ningún pago en el rango de fechas seleccionado.',
    puede_tardar: 'Tenga paciencia mientras se obtienen los pagos recibidos de la pasarela de pago.',
    rango_fechas: 'El rango máximo entre las fechas no puede superar los {num} días',
    info_sobre_facturas:
      'No se mostrarán las facturas de los pagos que se hayan realizado en los últimos cinco días, ya que la pasarela de pago requiere este tiempo para realizar la transacción.',
    aceptar_transaccion: 'Aceptar transacción',
    aceptar_transaccion_descrip: '¿Estás seguro de que deseas confirmar la transacción seleccionada?',
    cancelar_transaccion: 'Cancelar transacción',
    cancelar_transaccion_descrip: '¿Estás seguro de que deseas cancelar la transacción seleccionada?',
    select_professional: 'Selecciona un profesional',
  },
  hiring: {
    service: 'Contratar servicio',
    appointment: 'Contratar videoconsulta',
    message: 'Contratar mensajería',
    pack: 'Contratar Pack',
    subscription: 'Contratar suscripción',
    select_receiver: '¿La consulta no es para ti? Selecciona a la persona para la que va destinado el servicio',
    reason_of_consultation: 'Escriba el motivo de la consulta',
    consultation_details: 'Detalles de la consulta',
    professional_will_confirm:
      'El profesional aprobará tu cita propuesta o te propondrá una alternativa lo más inmediata posible.',
    professional_will_approve_pack:
      'El profesional aprobará tu pack y se pondrá en contacto contigo en los próximos días.',
    have_promotional_code: '¿Dispones de un código promocional?',
    card_payment: 'Pago con tarjeta',
    proceed_to_payment: 'Proceder al pago',
    discount_applied: 'Aplicado un {num}% de descuento',
    add_document:
      'Si lo deseas puedes enviarle algún documento al profesional. Piensa que una fotografía, una analítica o cualquier otro tipo de informe médico o documento puede facilitar el diagnóstico por su parte.',
    document_added: 'Documento añadido al mensaje',
    note_to_professional: 'Si lo deseas puedes dejar algún comentario para el profesional',
    monthly_fee_will_charged:
      'Se te realizará un cobro mensual mientras tenga activa la suscripción. Puedes cancelar tu suscripción en cualquier momento desde la sección "Mis suscripciones"',
    send: 'Enviar',
    free_with_your_subscription:
      'Tienes una suscripción activa que te permite contratar el servicio de forma gratuita.',
    appointment_proposed_by_professional:
      'Esta es la cita propuesta por el profesional. En cuanto comprobemos el pago de la misma, te enviaremos por correo electrónico las instrucciones necesarias para realizar la videoconsulta.',
    you_have_period_to_confirm:
      'Dispones de un plazo de 2 días para poder confirmar esta cita. Pasado el plazo indicado, el profesional podrá reasignar esta cita a otra persona o cancelarla sin obligatoriedad alguna de dar aviso.',
    appreciate_your_understanding: 'Agradecemos tu comprensión.',
    apiError: 'Ha ocurrido un error, inténtalo de nuevo.',
  },
  historia: {
    antecedentes_personales: 'Antecedentes personales',
    enfermedades_infancia: 'Enfermedades en la infancia',
    enfermedades_adulta: 'Enfermedades en edad adulta',
    medicamentos: 'Medicamentos habituales/crónico',
    intervenciones: 'Intervenciones quirúrgicas',
    actividad_laboral: 'Actividad laboral',
    antecedentes_familiares: 'Antecedentes familiares ',
    alergias: 'Alergias - Intolerancias',
    episodios: 'Episodios',
    nuevo_episodio: 'Nuevo episodio',
  },
  mensajeria: {
    nuevo_mensaje: 'Nuevo mensaje',
    pendientes: 'Pendientes',
    abierto: 'Abierto',
    abiertos: 'Abiertos',
    cerrados: 'Cerrados',
    enviar: 'Enviar',
    para: 'Para',
    motivo: 'Motivo',
    subject: 'Asunto',
    mensaje: 'Mensaje',
    mensajes: 'Mensajes',
    select_user: 'Seleccionar destinatario',
    enviar_nuevo_mensaje: 'Enviar un nuevo mensaje',
    marcar_como_atendida: 'Marcar como atendido',
    sin_mensajes_pendientes: 'En este momento, no tienes mensajería pendiente de contestar',
    sin_mensajes_abiertos: 'En este momento, no tienes mensajería abierta a respuesta con ningún paciente',
    sin_mensajes_atendidos: 'Aún no has marcado como atendido ningún servicio de mensajería',
    archivos_adjuntos: 'Archivos adjuntos',
    has_attachments: 'Tiene adjuntos',
    mensaje_con_adjuntos: 'El mensaje se enviará con un archivo adjunto',
    attach_file: 'Adjuntar archivo',
    add_texto_al_mensaje: 'Añade texto a la respuesta para poder enviar el mensaje',
    add_destinatario_al_mensaje: 'Indica la persona que deseas que reciba el mensaje',
    enviado_correctamente: 'Mensaje enviado correctamente',
    enviado_correctamente_error_adjunto:
      'Mensaje enviado correctamente, pero no se ha podido adjuntar el archivo, revisa que no sobrepasa el tamaño máximo permitido y que se trata de una imagen o un documento PDF.',
    error_enviando: 'El mensaje no se ha podido enviar. Inténtalo de nuevo más tarde',
    atendido_correctamente: 'El mensaje ha sido marcado como atendido correctamente',
    error_atendiendo: 'Error al marcar como atendido el mensaje, inténtalo de nuevo más tarde',
    confirm_atendida:
      'El servicio de mensajería va a ser marcado como atendido. Si el paciente desea volver a contactar deberás solicitar un nuevo servicio de mensajería. ¿Estás seguro de que deseas cerrar este servicio de mensajería?',
    sin_contestar: 'SIN CONTESTAR',
    answered: 'CONTESTADO',
    sent: 'ENVIADO',
    sin_cerrar: 'ABIERTO',
    ver_file: 'Mostrar documento',
    delete_file: 'Eliminar documento',
    confirmar_delete_file:
      'Si eliminas el documento el paciente no podrá acceder a él. ¿Estás seguro de que deseas eliminar el documento?',
    modificar_name_file: 'Modificar nombre del documento',
    nombre_file: 'Nombre del documento',
    atendido: 'Atendido',
    enviar_mensaje: 'Enviar mensaje',
    cerrado: 'Cerrado',
    unread_messages: 'Mensajes no leídos',
    not_exists_thread: 'No existe un hilo de mensajes con el identificador {id}',
    downloaded_on: 'Descargado el día',
    pdf_footer_link: 'Este PDF ha sido generado a través del chat de ',
    see_chat: 'Ver chat',
    loading_chat: 'Cargando chat...',
  },
  menu: {
    home: 'Inicio',
    patients: 'Pacientes',
    calendario: 'Calendario',
    messaging: 'Mensajería',
    videocam: 'Videoconsultas',
    productos: 'Productos',
    reports: 'Reportes',
    chat: 'Chat',
    inbox: 'Inbox',
    settings: 'Ajustes',
    help: 'Ayuda',
    profile: 'Perfil',
    close_session: 'Cerrar sesión',
    agenda: 'Agenda',
    cupones: 'Cupones',
    talonario: 'Talonario de recetas',
    firma: 'Firma',
    users: 'Profesionales',
    medicos: 'Médicos',
    metrics: 'Métricas',
    packs: 'Packs',
    packcontratados: 'Packs contratados',
    sesion: 'Sesión',
    contact: 'Contacto',
    empresas: 'Empresas',
    tarifas: 'Servicios',
    servicios: 'Servicios',
    miweb: 'Mi Web',
    facturacion: 'Facturación',
    recepcionista: 'Recepcionista',
    companyConfig: 'Configuración del Centro',
    resenas: 'Reseñas',
    dispositivos: 'Dispositivos',
    citas: 'Citas',
    transacciones: 'Transacciones',
    valorespersonalizados: 'Valores personalizados',
    myMessages: 'Mensajes',
    myVideoconsultations: 'Videoconsultas',
    myPrescriptions: 'Recetas',
    prescriptionsFilteredList: 'Recetas pendientes',
    mySubscriptions: 'Suscripciones',
    myDocuments: 'Documentos',
    beneficiaries: 'Usuarios asociados',
    sendUsMessage: 'Envíanos un mensaje',
    account: 'Mi cuenta',
    medicalTests: 'Pruebas',
    medicalTestTypes: 'Tipos de prueba',
    medicalTestRxProtocols: 'Protocolos RX',
    medicalTestNomenclator: 'Nomenclátor',
    parapharmacy: 'De parafarmacia',
  },
  message: {
    change_language: 'Cambiar idioma',
    email_or_phone: 'Correo electrónico o teléfono',
    password: 'Contraseña',
    modify_password: 'Modificar contraseña',
    email: 'Correo electrónico',
    repeat_email: 'Repetir correo electrónico',
    intro_email: 'Introduce tu correo electrónico',
    intro_pw: 'Introduce tu contraseña',
    intro_security:
      'Por motivos de seguridad, para poder cambiar el correo electrónico es necesario que introduzcas tu contraseña',
    email_repeat: 'Repite correo electrónico',
    forgot_password: '¿Olvidaste tu contraseña?',
    remember: 'Recordar contraseña',
    login: 'Acceder',
    signin: 'Acceder',
    notifications: 'Notificaciones',
    showall: 'Mostrar todas',
    reset_texto:
      'Introduce tu dirección de correo electrónico y te enviaremos un enlace para restablecer tu contraseña.',
    reset_password: 'Restablece tu contraseña',
    introduzca_password: 'Para activar tu cuenta introduce tu contraseña de acceso',
    restaurar_password: 'Restablecer Contraseña',
    password_weak: 'Contraseña débil',
    password_not_secure: 'Contraseña poco segura',
    password_secure: 'Contraseña segura',
    welcome: 'Bienvenido a {name}',
    new_here: '¿Eres nuevo? Comienza a usar nuestros servicios de Salud Digital',
    signup: 'Regístrate',
    start: 'Empezar',
    already_have_an_account: '¿Ya tienes una cuenta? Inicia sesión',
    can_help_you: '¿Podemos ayudarte?',
    get_help: 'Obtener ayuda',
    continue_to: 'Continuar {name}',
    estamos_encantados: 'Estamos encantados de tenerte con nosotros.',
    le_guiaremos:
      'A partir de ahora, te guiaremos a través de la plataforma para que rellenes los datos necesarios y empieces a incorporar {name} en tu día a día.',
    password_new: 'Nueva contraseña',
    activar_cuenta: 'Activar cuenta',
    check_credentials: 'Verificando credenciales',
    cuenta_trial:
      'Tu cuenta se encuentra en proceso de validación. La versión de prueba caducará mañana | Tu cuenta se encuentra en proceso de validación. La versión de prueba caducará en {count} días',
    double_auth_title: 'Verificación en dos pasos',
    double_auth_desc: 'Este paso extra nos indica que eres tú quien está intentando iniciar sesión.',
    double_auth_msg_one: 'Se acaba de enviar un email a',
    double_auth_msg_two: 'con un código de verificación de 6 dígitos, por favor introdúcelo a continuación.',
    double_auth_email_again: '¿No has recibido el email? Volver a enviar',
    double_auth_email_sended: 'Se ha reenviado el código',
    double_auth_error_code: 'El código es erróneo, prueba otra vez',
    double_auth_empty_code: 'Debes rellenar el código',
    double_auth_escribe_codigo: 'Escribe el código',
    double_auth_placeholder: 'Código',
    select_an_account: 'Selecciona una cuenta',
    acceptConditions:
      'He leído y acepto la <a href="{privacyPolicyLink}" target="_blank">política de privacidad</a> y el <a href="{legalLink}" target="_blank">aviso legal</a>',
    acceptGeneralConditions:
      'He leído y acepto los <a href="{generalConditionsLink}" target="_blank">términos legales y las condiciones de uso de la plataforma</a>',
    acceptGeneralConditions_LOPDGDD:
      'He leído y acepto las <a href="{generalConditionsLink}" target="_blank">condiciones generales contractuales y su anexo LOPDGDD</a>',
  },
  messages: {
    name: 'Mensajería Privada | Mensajerías',
    new: 'Nuevo',
    unsolved: 'Sin resolver',
    solved: 'Resueltos',
    all: 'Todos',
    write_your_message_here: 'Escribe tu mensaje aquí...',
    write_your_consultation: 'Escribe tu consulta a {name}',
    not_have_pending: 'No dispones de mensajes',
    error: 'Ha habido un problema al cargar los últimos mensajes',
    reset_filter: 'Resetear filtro',
    show_messages: 'Mostrar mensajes',
    message_closed: 'El mensaje está cerrado',
    to_me: 'Para: mi',
    to: 'Para',
    me: 'mi',
  },
  notificaciones: {
    mensajeria: 'Tienes un mensaje pendiente | Tienes {count} mensajes pendientes',
    video_confirm: 'Tienes una {name} por confirmar | Tienes {count} {name} por confirmar',
    video_hoy: 'Tienes hoy: una {name} | Tienes hoy: {count} {name}',
    no_notis: 'En este momento no tienes notificaciones pendientes',
    prescriptions: 'Tienes una prescripción pendiente de emitir | Tienes {count} prescripciones pendientes de emitir',
  },
  packs: {
    name: 'Pack | Packs',
    crear: 'Crear Pack',
    packs_configurados: 'Packs configurados',
    packs_contratados: 'Packs contratados',
    num_sesiones: 'Número de sesiones',
    editar: 'Editar el Pack',
    eliminar_titulo: 'Eliminar el Pack',
    eliminar_texto:
      'El Pack dejará de poder ser contratado por los pacientes, los packs contratados de este Pack no se verán afectados y podrán ser completados con total normalidad.',
    eliminar_confirmar: '¿Estás seguro de que deseas eliminar el Pack?',
    sin_packs: 'No tienes configurado ningún Pack',
    completados: 'Completados',
    sin_pendientes: 'En este momento no tienes packs contratados pendientes',
    sin_completados: 'Aún no has completado ningún Pack contratado',
    fecha_contratado: 'Fecha contratado',
    sesiones_totales: 'Sesiones totales',
    sesiones_restantes: 'Sesiones restantes',
    marcar_como_completado: 'Marcar como completado',
    marcar_como_completado_texto:
      'El Pack todavía tiene sesiones pendientes de ser atendidas, ¿Estás seguro de que deseas completar el Pack contratado?',
    success_complete: 'El Pack se ha marcado como completado correctamente',
    sesion: 'Sesión',
    not_have_hired: 'No tienes ningun Pack contratado',
    num_pending: '{num} sesiones pendientes',
  },
  patient: {
    patients: 'Pacientes',
    without_patients: 'Aún no tiene ningún paciente vinculado',
    without_authorizations: 'Aún no tiene ninguna autorización vinculada',
    search: 'Buscar paciente',
    new: 'Nuevo paciente',
    edit: 'Editar paciente',
    delete: 'Eliminar paciente',
    confirm_delete:
      'El paciente seleccionado dejará de recibir tus servicios. ¿Estás seguro de que deseas eliminar tu paciente?',
    personal_data_mayus: 'DATOS PERSONALES',
    personal_data: 'Datos personales',
    history_clinical: 'Historial clínico',
    history_clinic: 'Historia clínica',
    first_name: 'Nombre',
    last_name: 'Apellidos',
    document_id: 'DNI, NIE o Pasaporte',
    cedula_or_passport: 'Cédula / Pasaporte',
    birthdate: 'Fecha de nacimiento',
    phone: 'Teléfono',
    gender: 'Género',
    female: 'Femenino',
    male: 'Masculino',
    other: 'Otro',
    delete_success: 'El paciente se ha eliminado correctamente',
    delete_error: 'Ha ocurrido un error eliminando al paciente. Prueba de nuevo más tarde',
    full_name_required: 'Es obligatorio rellenar el nombre y apellidos',
    too_long_name: 'El nombre no puede tener más de 50 caracteres',
    too_long_surname: 'El apellido no puede tener más de 50 caracteres',
    dni_required: 'Documento de Identidad obligatorio',
    type_dni_required: 'Es obligatorio indicar el tipo de Documento de Identidad',
    dni_invalid: 'El Documento de Identidad no es válido',
    dni_invalid_letra: 'DNI erróneo, la letra del NIF no se corresponde',
    invalidNie: 'El NIE no es válido',
    birthdate_invalid: 'La fecha de nacimiento debe ser inferior a la fecha actual',
    observations: 'Observaciones',
    antecedentes_familiares: 'Antecedentes familiares',
    antecedentes_maternos: 'Antecedentes maternos',
    antecedentes_paternos: 'Antecedentes paternos',
    antecedentes_hermanos: 'Otros antecedentes familiares',
    antecedentes_otros: 'Antecedentes otros familiares',
    antecedentes_personales: 'Antecedentes personales',
    antecedentes_cirugias: 'Antecedentes cirugías',
    alergias_medicamentos: 'Alergias medicamentos',
    otras_alergias: 'Otras alergias',
    enfermedades_cronicas: 'Enfermedades crónicas',
    historial_psiquiatria: 'Historial de psiquiatría',
    motivo_consulta: 'Motivo de la consulta',
    antecedentes_somaticos: 'Antecedentes somáticos',
    antecedentes_psiquiatricos: 'Antecedentes psiquiátricos',
    genograma: 'Genograma',
    psicobiografia: 'Psicobiografía',
    habitos_toxicos: 'Hábitos tóxicos',
    anamnesis: 'Anamnesis',
    diagnosticos: 'Diagnósticos',
    diagnostico: 'diagnóstico',
    episodio: 'episodio',
    evoluciones: 'Evoluciones',
    recomendaciones: 'Recomendaciones',
    nuevo_diagnostico: 'Nuevo diagnóstico',
    nuevo_evolutivo: 'Nueva consulta',
    editar_diagnostico: 'Editar diagnóstico',
    editar_evolutivo: 'Editar evolutivo',
    sin_diagnosticos: 'Aún no se le ha realizado ningún {name} a este paciente',
    diagnoses_require_signature: 'Es necesario tener configurada la firma para poder crear diagnósticos.',
    delete_diagnostico: 'Eliminar diagnóstico',
    confirm_delete_diagnostico: '¿Estás seguro de que deseas eliminar el diagnóstico seleccionado?',
    nueva_evolucion: 'Nueva evolución',
    editar_evolucion: 'Editar evolución',
    sin_evoluciones: 'Aún no se le ha realizado ninguna evolución a este paciente',
    delete_evolucion: 'Eliminar evolución',
    confirm_delete_evolucion: '¿Estás seguro de que deseas eliminar la evolución seleccionada?',
    sin_documentos: 'El paciente no dispone de documentos',
    sin_chequeos: 'El paciente no dispone de chequeos',
    sin_recetas: 'Aún no se le han creado recetas al paciente',
    generarinforme: 'Generar informe',
    pais: 'País',
    evolucion: 'Evolución',
    exploracion: 'Exploración',
    juicioclinico: 'Juicio clínico',
    tratamiento: 'Tratamiento | Tratamientos',
    num_historia: 'Núm. historia',
    notas: 'Notas internas (el paciente no podrá ver esta información ni aparecerá en el informe)',
    tipo_documento: 'Tipo documento',
    pasaporte: 'Pasaporte',
    cedula: 'Cédula',
    enviado_mensaje_vincular:
      'El paciente ya se encuentra registrado, se le ha enviado al paciente un correo electrónico para que acepte el vínculo entre profesional sanitario y paciente',
    relacion: 'Relación con el tutor',
    datos_tutor: 'Datos del tutor',
    cuidar_alguien: 'Añadir usuario',
    pacientes_tutelados: 'Usuarios asociados a tu cuenta',
    preanestesia: 'Preanestesia',
    autorizado: 'Autorizado',
    no_autorizado: 'No autorizado',
    peso: 'Peso',
    motivo_y_evolutivos: 'Motivos de consulta y evolutivos',
    examenFisico: 'Examen físico',
    resultadoLaboratorio: 'Resultados de laboratorio',
    pruebasImagen: 'Pruebas de imagen',
    evolutivo: 'Evolutivo',
    aceptar_cambios_apto: '¿Estás seguro de que deseas marcar como Apto el reconocimiento?',
    aceptar_cambios_noapto: '¿Estás seguro de que deseas marcar como No Apto el reconocimiento?',
    new_revision: 'Nuevo reconocimiento',
    pendiente: 'Pendiente',
    aceptado: 'Aceptado',
    revision_medica: 'Revisión médica | Revisiones',
    sin_common: 'El paciente no dispone de {name}',
    aceptar_cambios_rechazo: '¿Confirmas que deseas aceptar el rechazo?',
    aceptar_cambios_denegado: '¿Confirmas que deseas denegar el rechazo?',
    edit_date_revision: 'Editar fecha de la revisión',
    fecha_ultimo_aviso: 'Fecha último aviso',
    register_date: 'Fecha de alta',
    no_observations: 'No hay observaciones',
    allergy_management: 'Gestión Alergias',
    add_allergy: 'Añadir Alergia',
    no_allergies: 'El paciente no dispone de alergias registradas',
    allergyExists: 'El paciente ya tiene registrada una alergia similar',
    saveDuplicateAllergy: 'Sí, guardar alergia similar',
    new_allergy: 'Alta de alergia',
    allergy_to: 'Alergia a',
    addedAllergy: 'Alergia guardada',
    removedAllergy: 'Alergia borrada',
    searchAllergy: 'Buscar alergias',
    allergyEndDate: 'Sin fecha de fin definida',
    allergyStartDate: 'Sin fecha de inicio definida',
    allergyInfoBox: 'El paciente tiene registradas las siguientes alergias:',
    allergyDetected: 'Este medicamento puede ser incompatible con una de las alergias registradas.',
    clinical_report_type: 'Tipo de informe',
    clinical_report_type_by_range: 'En un rango de fechas',
    clinical_report_type_specific_day: 'De un día concreto',
    clinical_report_type_full: 'Historia clínica completa',
  },
  prescriptions: {
    prescription: 'Receta',
    prescriptions_list: 'Recetas Electrónicas',
    no_prescriptions: 'No dispones de recetas',
    not_found: 'No se han encontrado recetas con esos criterios de búsqueda',
    mark_all: 'Marcar todas',
    lote_actions: 'Acciones en lotes',
    action_firm_emit: 'Firmar y Emitir',
    medicine_duration_exceeded_error: 'La duración de este medicamento no puede ser superior a {num} meses.',
    medicine_duration_exceeded_one_year:
      'La fecha de fin de este plan terapéutico no puede superar el {date}. Se recalcula la duración de este medicamento.',
    narcotic_number_of_packages_exceeded_error:
      'Este medicamento no permite recetar una cantidad superior a {num} envases.',
    cant_mix_narcotics_with_others_error:
      'No se puede recetar un estupefaciente junto a otros medicamentos en la misma receta.',
    some_medicine_exceeds_max_duration_error:
      'Uno o más medicamentos exceden la duración máxima permitida. Por favor, revisa los datos.',
    long_duration_prescription_confirmation_notice:
      'La duración de al menos uno de los medicamentos prescritos es mayor de un mes. ¿Estás seguro de que deseas continuar?',
    maxPackagesWarning:
      'Actualmente el número máximo de envases a preescribir es {limit}. Se ha reajustado la duración del tratamiento al máximo número de días posible.',
    maxPackagesWarningNoAdjust: 'Actualmente el número máximo de envases a preescribir es {limit}.',
    checking_collegiate_number_1: 'Estamos validando tu número de colegiado',
    checking_collegiate_number_2: 'con el Colegio Oficial de Médicos. Por favor, espera un momento.',
    maxPackagesErrorNoCovid: 'Ha superado el número máximo de envases permitido a preescribir: 99 unidades',
    maxPackagesErrorCovid: 'Ha superado el número máximo de envases permitido a preescribir: 9 unidades.',
    medicineAlreadyPrescribed: 'Tiene una prescripción en curso para ese medicamento que finaliza el {date}',
    limited: 'Máximo {num} {measure}',
    maxDaysForSameMedicineTitle: 'Has superado el tiempo máximo de prescripción.',
    maxDaysForSameMedicineText:
      'Este medicamento se eliminará de la receta. Las prescripciones de medicamentos y productos sanitarios no pueden tener una validez superior a 12 meses.',
    patientIndentityConfirmationText: 'Confirmo la identidad del paciente',
    patientsIndentityConfirmationText: 'Confirmo la identidad de los pacientes seleccionados',
    medicamentsIncluded: 'Contiene {numMedicaments} medicamentos',
    itemsSelected: ' elementos seleccionados',
    filterPending: 'Pendiente',
    noActiveMedicines:
      'Esta receta contiene al menos un medicamento que ha dejado de estar activo. Por favor, emita una nueva receta.',
    deletePrescriptions: 'Eliminar recetas',
  },
  recepcionista: {
    recepcionistas: 'Recepcionistas',
    recepcionista: 'Recepcionista',
    crear: 'Crear recepcionista',
    editar: 'Editar recepcionista',
    sin_recepcionista: 'Aún no has dado de alta a ningún recepcionista',
    permisos: 'Permisos',
    agenda: 'Agenda',
    servicios: 'Servicios',
    historia: 'Historia',
    talonario: 'Talonario',
    firma: 'Firma',
    facturacion: 'Facturación',
    eliminar_titulo: 'Eliminar al recepcionista',
    eliminar_texto: '¿Estás seguro de que deseas eliminar la cuenta de doctor del recepcionista?',
  },
  recetas: {
    nueva: 'Nueva receta',
    num_receta: 'Nº de receta',
    ver: 'Ver',
    reemitir: 'Reemitir',
    prescripcion: 'PRESCRIPCIÓN',
    prescripciones: 'Prescripciones',
    prescripcion_detalle:
      'Consignar el medicamento: DCI o marca, forma farmacéutica, vía de administración, dosis por unidad y unidades por envase. (Datos correspondientes en caso de producto sanitario).',
    num_envases: 'Núm. envases / unidades',
    buscar_medicamento: 'Buscar medicamento',
    receta_medica_privada: 'RECETA MÉDICA PRIVADA',
    receta_medica_asistencia_sanitaria_privada: 'RECETA MÉDICA PARA ASISTENCIA SANITARIA PRIVADA',
    no_existe_medicamento: 'No existe ningún medicamento con el nombre indicado',
    unidades: 'Unidades',
    duracion: 'Duración del tratamiento',
    posologia: 'Posología',
    pauta: 'Pauta',
    fecha_prevista_dispensacion: 'Fecha prevista dispensación',
    info_farmaceutico: 'Información al Farmacéutico, en su caso',
    instrucciones_paciente: 'INSTRUCCIONES AL PACIENTE (si procede)',
    paciente_detalle: '(Nombre, apellidos, año de nacimiento y DNI, NIE o pasaporte)',
    prescriptor: 'PRESCRIPTOR',
    prescriptor_detalle: '(datos de identificación y firma)',
    vista_previa: 'VISTA PREVIA',
    required: {
      medicamento: 'Indica el medicamento que va a ser recetado',
      paciente: 'Es obligatorio seleccionar un paciente',
    },
    generar: 'Generar receta',
    creada_correctamente: 'Receta enviada',
    imprimir: 'Imprimir',
    imprimida_correctamente:
      'La receta se abrirá en una pestaña nueva, en caso de no abrirse, observe en la barra del navegador que no este bloqueando las ventanas emergentes',
    nombreaniodni: 'Nombre, apellidos, año de nacimiento y DNI, NIE o pasaporte',
    aviso_pocas_recetas: 'AVISO: Solo te quedan disponibles {num} recetas',
    logout_with_pending_to_emit:
      'Actualmente tienes {count} recetas pendientes de emisión. ¿Está usted seguro de cerrar la sesión y dejar las recetas pendientes, o prefiere y firmar las recetas?',
    emit_prescriptions: 'Emitir recetas',
    exit_without_emit: 'Salir sin emitir',
    prescription_batch_ended:
      'El proceso por lotes ha finalizado y todas las recetas han sido emitidas y firmadas correctamente.',
    prescription_failed: 'El proceso por lotes ha fallado. Vuelva a intentarlo mas adelante.',
  },
  success: {
    new_patient: 'El paciente ha sido creado correctamente',
    patient_exist: 'El paciente ya se encuentra registrado',
    patient_exist_otro_mail: 'El paciente ya se encuentra registrado con otro email',
    new_user: 'El doctor ha sido creado correctamente',
    edit_patient: 'Se ha editado la información del paciente correctamente',
    edit_user: 'Se ha editado la información del doctor correctamente',
    save_new_diagnosis: 'Se ha creado un nuevo diagnóstico correctamente.',
    save_edit_diagnosis: 'Se ha editado el diagnóstico correctamente.',
    new_diagnosis_report: 'Próximamente tendrá disponible el informe de diagnostico en la pestaña de documentos.',
    delete_diagnosis: 'Se ha eliminado el diagnóstico correctamente',
    save_new_evolucion: 'Se ha creado una nueva evolución correctamente',
    save_edit_evolucion: 'Se ha editado la evolución correctamente',
    delete_evolucion: 'Se ha eliminado la evolución correctamente',
    save_history: 'Se ha guardado el historial clínico correctamente',
    save_user: 'Se ha guardado el usuario correctamente',
    save_new_coupon: 'Se ha creado un nuevo cupón correctamente',
    save_edit_coupon: 'Se ha editado el cupón correctamente',
    delete_coupon: 'Se ha eliminado el cupón correctamente',
    save_tipovideo: 'Se ha guardado el servicio de {name} correctamente',
    save_tipo: 'Se ha guardado el servicio de {name} correctamente',
    save_tipomensajeria: 'Se ha guardado el servicio de mensajería correctamente',
    delete_common: 'Se ha eliminado correctamente',
    active_common: 'Se ha activado correctamente',
    save_common: 'Se ha guardado correctamente',
    save_agenda: 'La agenda se ha guardado correctamente',
    save_firma: 'La firma se ha guardado correctamente',
    send_password: 'Se le ha enviado un email para resetear su contraseña',
    password_changed: 'La contraseña ha sido cambiada correctamente',
    generado_informe: 'Se ha generado el informe correctamente',
    account_active: 'La cuenta ha sido activada correctamente',
    name_file_modificado: 'El nombre del documento se ha modificado correctamente',
    file_deleted: 'El documento ha sido eliminado correctamente',
    delete_treatment: 'Se ha eliminado el tratamiento correctamente',
    delete_prescription: 'Se ha eliminado la receta correctamente',
    recipe_emmited: 'La receta se ha emitido correctamente, el paciente recibirá la receta {methods}',
    emit_method_email: 'por email',
    emit_method_sms: 'por SMS',
    email_sent: 'El email se ha enviado correctamente',
    serviceHiring: 'El servicio de {name} ha sido contratado correctamente',
  },
  talonario: {
    conf_talonario: 'Configuración de talonario',
    subir_talonario: 'Subir talonario',
    eliminar_talonario: 'Eliminar talonario',
    confirmar_eliminar_talonario: '¿Estás seguro de que deseas eliminar el talonario?',
    confirmar_subida_talonario:
      '¿Estás seguro de que deseas subir el talonario {msg} para usarlo cuando generes recetas a tus pacientes?',
    talonario_actuales: 'Talonarios actuales',
    instrucciones_talonario: 'Sigue estas instrucciones para subir tu talonario:',
    instrucc_talon_li:
      'Paso 1. Guarda previamente en tu PC el archivo de recetas en PDF que has recibido vía correo electrónico o en soporte USB de tu colegio. | Paso 2. Pulsa "Subir Talonario..." y selecciona el PDF de tus recetas. | Paso 3. Guarda el archivo de tus recetas en tu cuenta.',
    sin_talonarios:
      'Aún no has subido ningún talonario, no podrás enviar recetas hasta que no subas tu talonario en PDF',
    sin_firma: 'No has configurado su firma, la firma es obligatoria para emitir recetas',
    conf_firma: 'Configuración de la firma',
    firma_actual: 'Firma actual',
    explicacion_firma:
      'Dibuja tu firma desde su dispositivo móvil o Tablet (No se recomienda hacerlo desde el PC), se quedará guardada en tu cuenta bajo doctor y contraseña y podrás acceder a ella desde todos tus dispositivos.',
    mandame_link: 'Mándame un enlace a mi {nombre}',
    rehacer_firma: 'Rehacer firma',
    timeoutfirma:
      'Ha caducado el enlace para dibujar tu firma. Recuerda que tienes 15 minutos desde que solicitas modificar la firma.',
    dibuje_firma:
      'Dibuja tu firma con el dedo, se quedará guardada en tu cuenta bajo doctor y contraseña y podrás acceder a ella desde todos tus dispositivos.',
    send_signature:
      'Te acabamos de enviar un email que contiene un enlace especial para crear tu firma desde tu dispositivo móvil o Tablet. El enlace caduca en 15 minutos.',
  },
  tipovideo: {
    estandar: 'Videoconsulta estándar',
    precio: 'Precio de la videoconsulta',
    duracion: 'Duración de la videoconsulta',
    guardar: 'Guardar videoconsulta',
    guardarmensajeria: 'Guardar mensajería',
    guardarchequeo: 'Guardar chequeo',
    confirm_desactivar_mensajeria: '¿Estás seguro de que deseas desactivar el servicio de mensajería?',
    confirm_desactivar: '¿Estás seguro de que deseas desactivar el servicio de {name}?',
    nuevo_titulo: 'Crear servicio',
    nuevo_video: 'Crear servicio videoconsulta',
    nuevo_presencial: 'Crear servicio presencial',
    confirm_delete_titulo: 'Eliminar servicio de videoconsulta',
    confirm_delete_texto: '¿Estás seguro de que deseas eliminar el servicio de videoconsulta seleccionado?',
  },
  tour: {
    skip: 'Saltar tour',
    prev: 'Previo',
    next: 'Siguiente',
    finish: 'Terminar',
    dibuja_firma: 'Prueba a dibujar tu firma',
    go_to_signature: 'Pulsa aquí para configurar tu firma.',
    no_te_gusta: '¿No te gusta como ha quedado?',
    rehacer_firma: 'Puedes pulsar en el botón de rehacer firma para volver a dibujar tu firma',
    enviar_firma_mobile:
      'Si estás en un PC y deseas dibujar tu firma con el dedo desde tu {nombre}, pulsa sobre este botón.',
    configura_agenda: 'Selecciona los días que te encuentras disponible en {name}.',
    configura_hora_agenda: 'Una vez seleccionado el día indica la hora de inicio y la hora de fin.',
    configura_rango_agenda: 'Puedes seleccionar más de un rango de horas.',
    descripcion_mensajeria: 'Indica la descripción del servicio de mensajería',
    precio_mensajeria: 'Puedes modificar el precio de la consulta',
    estado_mensajeria: 'Si no deseas recibir consultas puedes cambiar el estado a desactivado',
    guardar_cambios: 'Pulsa sobre guardar para aplicar los cambios',
  },
  user: {
    name: 'Nombre de profesional',
    aditional_info: 'Información adicional',
    edit_professional: 'Editar profesional',
    telefono_secretaria: 'Teléfono secretaría',
    telefono_paciente: 'Teléfono del paciente',
    biografia: 'Extracto',
    nuevo: 'Nuevo profesional',
    buscar: 'Buscar profesional',
    name_complete: 'Nombre completo',
    num_colegiado: 'Núm. colegiado',
    telefono_consulta: 'Tlf. consulta',
    tipo_prof: 'Tratamiento',
    salutation: 'Saludo',
    password_repeat: 'Por favor, repita la contraseña para verificar que coinciden',
    password_no_igual: 'Las contraseñas no coinciden',
    password_tam_invalid: 'La contraseña debe ser mayor o igual a 6 caracteres',
    password_must_contain_lowercase: 'La contraseña debe contener minúsculas',
    password_must_contain_uppercase: 'La contraseña debe contener mayúsculas',
    password_must_contain_numbers: 'La contraseña debe contener números',
    password_must_contain_symbols: 'La contraseña debe contener caracteres especiales',
    invalid_password: 'La contraseña no es válida',
    password_min_length: 'La contraseña debe tener al menos {num} caracteres',
    edit: 'Guardar cambios',
    search: 'Buscar profesional',
    especialidades: 'Especialidades',
    prof_sanitario: 'Profesional sanitario',
    psychiatric_clinic_history: 'Historia clínica de psiquiatría',
    professional_time_zone: 'Zona horaria del profesional',
    professional_status: 'Estado del profesional',
    user_status: 'Estado del usuario',
    restore_user_password: 'Restablecer contraseña profesional',
    create_by_csv: 'Crear usuarios por CSV',
    csv_with_error: 'Hay un error en el documento, corrígelo y vuelve a subirlo',
    csv_ok: 'Se han creado todos los usuarios correctamente',
    female_doctor: 'Dra.',
    male_doctor: 'Dr.',
    female_person: 'Sra.',
    male_person: 'Sr.',
    send_account_activation_email: 'Enviar email activación cuenta',
    user_status_training_not_reserved: 'No ha reservado Formación',
    user_status_in_workflow_hubspot: 'En Workflow Hubspot',
    user_status_training_reserved: 'Formación Reservada',
    user_status_training_made: 'Formación Realizada',
    user_status_subscription_paid: 'Pagada Suscripción',
    user_status_completed_personal_data: 'Completados Datos Personales',
    user_status_abandoned_lead: 'Lead Abandonado',
    date_end_trial: 'Fecha fin del periodo de prueba',
    public_on_marketplace: 'Publicado en el Marketplace',
    no_public_on_marketplace: 'No publicado en el Marketplace',
    select_image: 'Seleccione una imagen',
    name_patients_will_see: 'Nombre que verán los pacientes',
  },
  videoconsulta: {
    nueva_video: 'Nueva videoconsulta',
    nueva_cita: 'Nueva cita',
    crear_cita: 'Crear cita',
    nueva_tele: 'Nueva teleconsulta',
    crear_video: 'Crear videoconsulta',
    crear_tele: 'Crear teleconsulta',
    pendientes: 'Pendientes',
    terminadas: 'Terminadas',
    informe: 'Informe | Informes',
    sin_video_pendientes: 'En este momento no tienes {name} pendientes',
    sin_video_terminadas: 'Aún no has atendido ningún servicio de {name}',
    crear_para_paciente: 'Crear una {tipoVideo} para un paciente',
    crear_muticonferencia: 'Crear una Multiconferencia',
    como_desea_comunicar: '¿Cómo quieres comunicar la cita?',
    solicitando_pago: 'Solicitar pago por anticipado',
    solicitando_pago_texto:
      'Envía una petición de pago por correo electrónico. Una vez tu paciente haya realizado el pago, podrás enviarle la convocatoria para realizar la videoconsulta.',
    cita_cortesia: 'Gratuita',
    cita_cortesia_texto:
      'Estas citas son gratuitas para el paciente. Facilitar una cita con esta opción puede resultar adecuada para casos como:',
    cita_cortesia_li:
      'Poder repetir videoconsultas que por algún tipo de incidencia no han podido completarse. | Enviar una invitación, para realizar una primera consulta de prueba, o cualquier otro motivo que le anime a ello. | Pacientes que han adquirido previamente paquetes de videoconsultas.',
    add_new: 'Añadir nueva',
    add_destinatario: 'Indica la persona que recibe la videoconsulta',
    add_destinatario_cita: 'Indica la persona que recibe la cita',
    add_fechahora: 'Indica la fecha y hora de la videoconsulta',
    add_fechahora_cita: 'Indica la fecha y hora de la cita',
    add_modocomunicacion: 'Indica la forma en la que se va a comunicar la cita al paciente',
    add_tiposervicio: 'Indica el tipo de videoconsulta',
    add_tiposervicio_cita: 'Indica el tipo de cita',
    solapamiento: 'Ya existe una consulta en la fecha y hora seleccionada',
    sin_tiposvideo: 'Para crear nuevas consultas debes tener activado al menos un servicio',
    enviado_correctamente:
      'La consulta ha sido creada correctamente. Se ha notificado al paciente mediante un correo electrónico',
    enviado_correctamente_sms: 'La consulta ha sido creada correctamente. Se ha notificado al paciente mediante un SMS',
    enviado_recordatorio: 'Se ha enviado un recordatorio de la consulta al paciente mediante correo electrónico',
    cortesia: 'Cortesía',
    esperando_pago: 'Esperando pago',
    tipo: 'Tipo',
    videoconsulta_para: '{tipoVideo} para',
    codigo: 'Código',
    abrir_sala: 'Abrir sala',
    volver_enviar: 'Volver a enviar código',
    confirm_delete: '¿Estás seguro de que deseas cancelar la consulta?',
    confirm_delete2:
      'La consulta ya se encuentra pagada. Si es necesario que se realice el reembolso al paciente ponte en contacto con nosotros a través del chat.',
    confirm_video: '¿Estás seguro de que deseas confirmar la consulta?',
    cancelado_correctamente: 'Se ha cancelado la consulta correctamente',
    notification_email: 'Se le enviará un email al paciente confirmando la cancelación de la consulta',
    confirmado_correctamente: 'Se ha confirmado la consulta correctamente',
    terminada: 'Videoconsulta terminada',
    singular: 'Videoconsulta',
    teleconsulta: 'Teleconsulta',
    sin_confirmar: 'SIN CONFIRMAR',
    metadata_list_text: 'Otros datos',
    rechazada: 'Rechazada',
    aceptada: 'Aceptada',
    denegada: 'Denegada',
    ok: 'Ok',
    multiconferencia: 'Multiconsulta',
    tipo_cita: 'Tipo de cita',
    convocatoria_sms: 'Enviar convocatoria por {sms}',
    editar_servicio: 'Editar servicio',
    presencial: 'Presencial | Presenciales',
    sms: 'SMS',
    mensaje_texto: 'Mensaje de texto',
    diagnostico: 'Diagnóstico',
    tratamiento: 'Tratamiento',
    num_videos_por_slot: 'Núm. de consultas por slot',
    tiempo_min_prox_video: 'Tiempo mínimo próxima consulta',
    tlf_noty: 'Teléfonos de notificación',
    tlf_noty_placeholder: 'Pulsa la tecla enter para añadir el teléfono',
    freemium_version_non_free:
      'Con la versión Freemium no puedes crear servicios de cortesía o gratuitos. Contacta con tu comercial para actualizar a la versión Premium',
    fixed_fee_message:
      'El fee de gestión de este servicio es de {feeValue} € que se añadirá a tu tarifa en el momento del pago por parte del paciente.',
    percentage_fee_message:
      'El fee de gestión de este servicio es del {feeValue}% con un mínimo de {feeMin}€ y un máximo de {feeMax}€ que se añadirá a tu tarifa en el momento del pago por parte del paciente.',
    notice: 'Aviso',
    no_specialties_to_create:
      'Para poder continuar debes tener una especialidad asignada, por favor, accede a tu perfil y actualiza tu especialidad',
  },
  contact: {
    texto: 'Si tienes alguna duda, envíanos un mensaje a través del siguiente formulario:',
    mensaje_obligatorio: 'Para contactar con nosotros debes escribir el mensaje sobre tu duda',
    motivo_obligatorio: 'Para contactar con nosotros debes escribir el motivo de tu duda',
    enviado_correctamente: '¡Gracias por contactar con nosotros! Te responderemos lo antes posible.',
  },
  empresa: {
    nueva: 'Nueva empresa',
    buscar: 'Buscar empresa',
    email_noty: 'Email de  notificación',
    email_admin: 'Email del administrador',
    share_patients: 'Compartir pacientes',
    nombre_required: 'Es obligatorio rellenar el nombre de la empresa',
    licenses_num: 'Nº licencias',
    compartir_pacientes: 'Comparte pacientes',
    guardar_empresa: 'Guardar empresa',
    edit_empresa_success: 'La empresa se ha editado correctamente',
    has_anesthetist: 'Tiene anestesista',
    share_receptionists: 'Compartir recepcionistas',
    without_licenses: 'Tus licencias se han agotado',
    payment_module: 'Módulo de pago',
    single_tpv_account: 'Stripe único para toda la empresa',
    payment_type: 'Tipo de pago',
    fee_type: 'Tipo de cuota',
    fee_value: 'Valor de la cuota',
    fee_min: 'Fee min.',
    fee_max: 'Fee max.',
    service_of_user_change_to_free:
      'Al desactivar el módulo de pago, todos los servicios de los profesionales pasarán a ser gratuitos',
    service_of_user_change_to_free_add_company:
      'Al no tener módulo de pago la empresa, todos los servicios de los profesionales pasarán a ser gratuitos',
    user_exists: 'Este usuario ya existe',
    forbidden: 'No tienes permisos para modificar esta empresa',
    user_not_exists: 'Este usuario no existe',
    edit: 'Editar empresa',
    create: 'Crear empresa',
    delete: 'Eliminar empresa',
    confirm_delete: '¿Estás seguro de que deseas eliminar esta empresa?',
    cannot_delete: 'No es posible eliminar esta empresa, ya que hay profesionales/recepcionistas asociados a la misma',
    delete_success: 'La empresa ha sido borrada correctamente',
    premium_payment_type: 'Tipo de pago premium',
    premium_payment_types: 'Tipos de pago premium',
    razon_social: 'Razón social',
    empresa: 'Empresa',
    cif: 'CIF',
    web: 'Web',
    name_required: 'Es obligatorio introducir el nombre y la razón social de la clínica',
    cif_required: 'Es obligatorio introducir el CIF de la clínica',
    is_collective: 'Es colectiva',
    is_health_personnel: 'Es personal sanitario',
    have_interconsultation: 'Tiene interconsulta',
    interconsultation: 'Interconsulta',
    informedConsent: 'Autorización telemática',
    printPrescriptions: 'Imprimir Receta Electrónica',
    users: 'Usuarios',
    public_email: 'Email público',
    public_phone: 'Teléfono público',
    public_address: 'Dirección pública'
  },
  landing: {
    titulo: 'Configura tu página web',
    direccion: 'Dirección',
    sections: 'Secciones',
    add_seccion: 'Añadir sección',
    add_seccion_titulo: 'Añadir sección a Mi Web',
    seccion_anadida: 'Sección añadida correctamente',
    delete_seccion_titulo: 'Eliminar sección',
    delete_seccion_texto: '¿Estás seguro de que deseas eliminar esta sección de tu web?',
    seccion_eliminada: 'Sección eliminada correctamente',
    publicar: 'Publicar',
    despublicar: 'Despublicar',
    modificar_precios: 'Modificar precios',
    redes_sociales: 'Redes sociales',
    ejemplo_web: 'Ejemplo de página web',
    en_este_apartado: 'En este apartado se configuran los datos que aparecerán en tu página web',
    legend:
      'Los campos de foto, nombre, especialidad y resto de información se recogen de los datos rellenados en el apartado "Tu perfil".',
    legend_significance: 'Significado de los campos a rellenar',
    write_address: 'Introduce la dirección de tu centro',
    write_web: 'Introduce la URL de tu web particular, si dispones de una',
    write_socials: 'Introduce las URL de tus redes sociales operativas',
    write_sections: 'Puedes añadir las secciones que desees incluyendo la información',
    go_to_my_web: 'Ir a mi web',
    free_text: 'Texto Libre',
    list: 'Lista',
  },
  publicLandingPage: {
    consultationAddress: 'Dirección de la consulta',
    consultationPhone: 'Teléfono de consulta',
    call: 'Llamar ahora',
    availableServicesTitle: 'Servicios disponibles',
    collegiateNumber: 'Núm. colegiado',
    viewMap: 'Ver en Google Maps',
  },
  copyButton: {
    onError: 'Vaya, algo ha fallado copiando el texto, inténtalo de nuevo',
    onSuccess: 'Copiado!',
  },
  suscripciones: {
    suscripcion: 'Suscripción',
    suscripciones: 'Suscripciones',
    nueva: 'Nueva suscripción',
    nueva_titulo: 'Nueva suscripción mensual',
    editar: 'Editar suscripción',
    tarifa_plana: 'Tarifa plana',
    a_medida: 'Suscripción a medida',
    sin_suscripciones: 'No tienes configurada ninguna suscripción',
    destinatario: 'Quién puede contratarlo',
    explicacion_destinatario: 'Al seleccionar cualquiera; este servicio aparecerá en {name}',
    servicio: 'Servicio',
    ilimitado: 'Ilimitado',
    servicios_incluidos: 'Servicios incluidos',
    sesiones: 'sesión | sesiones',
    error_no_servicios: 'No has incluido ningún servicio. Pulsa el botón (+) para añadir servicios',
    delete: 'Eliminar suscripción',
    confirm_delete: '¿Estás seguro de que deseas eliminar el servicio de suscripción?',
    suscripcion_contratados: 'Suscripciones contratadas',
    sin_suscripciones_contratadas: 'En este momento no tienes ninguna suscripción contratada',
    mensajeria_restantes: 'Mensajerias restantes',
    videoconsulta_restantes: 'Videoconsultas restantes',
    cancelar_suscripcion: 'Cancelar suscripción',
    noty_to_all:
      'Al modificar el precio de la suscripción, se alterarán todas las suscripciones activas y se notificará a los pacientes suscritos',
    cancelar_suscripcion_texto:
      'El paciente dejará de disponer los servicios que incluye la suscripción a partir del día {dia}. ¿Estás seguro de que deseas cancelar tu suscripción?',
    cancelado_correctamente:
      'La suscripción ha sido cancelada correctamente. No se le realizarán más cargos al paciente',
  },
  reserva: {
    titulo: 'Reserva tu formación',
    titulo_especialidad: 'Selecciona tu especialidad médica',
    texto1:
      'Disponemos de un servicio de formación Online con nuestro Director Médico para que puedas conocer todo el potencial que te ofrece {name}.',
    texto2: '¿Deseas reservar tu formación online ahora?',
    boton: 'Reserva ahora',
    reserva_confirmada: 'Reserva confirmada',
    ha_hecho_una_reserva: 'Has realizado una reserva con Roberto Medina.',
    recibira_invitacion_por_correo: 'Recibirás una invitación por correo electrónico.',
    se_reservara_a_las: 'La formación será reservada el día {dia} a las {hora}',
    seleccione_fecha_hora: 'Selecciona fecha y hora a la que deseas tener la formación',
    reunirse_roberto: 'Reunirse con Dr. Roberto Medina',
  },
  producto: {
    nuevo: 'Nuevo producto',
    editar: 'Editar producto',
    buscar: 'Buscar producto',
    nombre_required: 'Es obligatorio rellenar el nombre del producto',
    precio_required: 'El precio del producto es obligatorio',
    creado: 'El producto se ha creado correctamente',
    licencia: 'Núm. licencias',
  },
  pago: {
    titulo: 'Servicio de suscripción (pago seguro)',
    titulo_stripe: 'Servicio de suscripción',
    pagar_suscripcion: 'Pagar suscripción',
    ciclo: 'Ciclo de facturación y duración: Según se indica en la descripción de la licencia',
    cuenta_activada_correctamente: 'La cuenta ha sido activada correctamente',
    periodo_duracion: 'Periodo de duración: Sin fecha de finalización',
    explicacion:
      'Se te cobrará inmediatamente por la cantidad mostrada anteriormente y luego se te cobrará automáticamente esa misma cantidad cada ciclo de facturación hasta que tu suscripción caduque o se cancele.',
  },
  steps: {
    espacio: 'Mi espacio {name}',
    clinical_data: 'Datos de la clínica',
    representative_data: 'Datos del representante',
    datos_facturacion: 'Datos de facturación',
    dni_anverso_subido: 'DOC. DE IDENTIDAD ANVERSO SUBIDO',
    dni_reverso_subido: 'DOC. DE IDENTIDAD REVERSO SUBIDO',
    tarjeta_identificacion_subido: 'DOC. DE TARJETA DE IDENTIFICACIÓN SUBIDO',
    subir_dni_anverso: 'SUBIR DOC. DE IDENTIDAD (ANVERSO)',
    subir_dni_reverso: 'SUBIR DOC. DE IDENTIDAD (REVERSO)',
    subir_tarjeta_identificacion: 'SUBIR DOC. DE TARJETA DE IDENTIFICACIÓN',
    fiscal_address: 'Dirección fiscal',
    iban_payment_module_message:
      'Es necesario que completes el campo IBAN, con los datos de la cuenta bancaria, cuando pretendas realizar cobros a los pacientes, por alguna de las citas o consultas que se realicen a través de esta plataforma y configurar el TPV para cobros online',
    iban_required: 'Introduce un IBAN válido',
    iban_invalid: 'El IBAN no es correcto, introduce un IBAN válido',
    cp_invalid: 'El código postal no es correcto',
    identification_doc_required: 'Introduce una imagen nítida del documento de identidad',
    identification_card_required: 'Es obligatorio añadir la tarjeta de identificación fiscal',
    archivos_validos: 'Solo admite archivos de tipo pdf, jpg ó png',
    saving_data: 'Guardando los datos facilitados.',
  },
  resenas: {
    titulo_publicar: 'Publicar reseña',
    titulo_despublicar: 'Despublicar reseña',
    texto_publicar: '¿Estás seguro de que deseas publicar esta reseña?',
    texto_despublicar: '¿Estás seguro de que deseas despublicar esta reseña?',
    titulo_eliminar: 'Eliminar reseña',
    texto_eliminar: '¿Estás seguro de que deseas eliminar esta reseña?',
  },
  dispositivo: {
    new_entidad: 'Nueva entidad',
    edit_entidad: 'Editar entidad',
    entidad: 'Entidad | Entidades',
    new_local: 'Nuevo local',
    edit_local: 'Editar local',
    local: 'Local | Locales',
    new_persona: 'Nueva persona de contacto',
    edit_persona: 'Editar persona de contacto',
    persona_contacto: 'Persona de contacto | Personas de contacto',
    persona: 'Persona de contacto | Personas de contacto',
    new_dispositivo: 'Nuevo dispositivo',
    edit_dispositivo: 'Nuevo dispositivo',
    dispositivo: 'Dispositivo | Dispositivos',
    venta: 'Venta',
    alquiler: 'Alquiler',
    titulo_eliminar: 'Eliminar {tipo}',
    texto_eliminar: '¿Estás seguro de que deseas eliminar esta {tipo}?',
  },
  reportes: {
    informe_pontgrup: 'Informe de actividad Pont-Grup',
    informe_productividad: 'Informe de productividad',
    tiempos_atencion_consulta: 'Tiempos de atención de consultas',
    cumplimiento_servicio: 'Control cumplimiento del servicio',
    num_altas: 'Número de altas',
    num_bajas: 'Número de bajas',
    num_renov: 'Número de renovaciones',
    total: 'Total',
    seleccione_profesional: 'Es obligatorio seleccionar un profesional',
    filter_hospital: 'Filtrar por hospital',
    filter_professional: 'Filtrar por profesional',
    filter_from_date: 'Fecha inicio',
    filter_to_date: 'Fecha fin',
    activation_mails: 'Emails activación enviados',
    activated_accounts: 'Cuentas activadas',
    num_videocons: 'Videoconsultas',
    num_chats: 'Chats',
    num_patients: 'Pacientes registrados',
    num_electronic_presc: 'Recetas Electrónicas emitidas',
    num_pdf_presc: 'Recetas PDF emitidas',
    num_logins: 'Accesos al sistema',
    num_registers: 'Num. Registros',
    graph_accounts: 'Cuentas activas/no activas',
    graph_activity: 'Actividad registrada',
    chart_electronic_presc: 'Recetas elect.',
    chart_pdf_presc: 'Recetas PDF',
    active_accounts: 'Activas',
    inactive_accounts: 'No activas',
    date_filters: 'Filtrar por fechas',
  },
  permissions: {
    dashboard: 'Inicio',
    conf_permissions: 'Configuración de permisos',
    conf_styles: 'Configuración de estilos',
    save_permissions: 'Guardar permisos',
    save_styles: 'Guardar estilos',
    users: 'Profesionales',
    companies: 'Empresas',
    products: 'Productos',
    devices: 'Dispositivos',
    patients: 'Pacientes',
    videoConsultation: 'Videoconsultas',
    calendar: 'Calendario',
    billing: 'Facturación',
    messaging: 'Mensajería',
    prescriptions: 'Receta PDF',
    packsHired: 'Packs contratados',
    subscriptionsHired: 'Suscripciones contratadas',
    reviews: 'Reseñas',
    configServices: 'Configuración de servicios',
    reports: 'Reportes',
    settingsBook: 'Ajustes - Talonario',
    settingsBilling: 'Ajustes - Facturación',
    settingsRates: 'Ajustes - Servicios',
    settingsSign: 'Ajustes - Firma',
    settingsDiary: 'Ajustes - Agenda',
    settingsPacks: 'Ajustes - Packs',
    settingsCoupons: 'Ajustes - Cupones',
    settingsLanding: 'Ajustes - Landing',
    settingsReceptionist: 'Ajustes - Recepcionista',
    settingsSpecialties: 'Ajustes - Especialidades',
    settingsServices: 'Ajustes - Servicios',
    settingsDaysNotAvailable: 'Ajustes - Días no disponibles',
    settingsDaysAvailable: 'Ajustes - Días disponibles',
    contact: 'Contacto',
    help: 'Ayuda',
    haveCreateVideoWithTel: 'Videoconsulta por teléfono',
    adminSimplified: 'Administrador simplificado',
    marketplace: 'Marketplace',
    showIbanPaymentModuleMessage: 'Mostrar leyenda IBAN',
    simplifiedLanguages: 'Ocultar resto de idiomas (PT/PL/AR)',
    hideStripeButton: 'Ocultar botón de configuración Stripe',
    hideFormBooking: 'Ocultar reserva de formación',
    hideTrialMessage: 'Ocultar mensaje de cuenta Trial',
    clinicalHistory: 'Historia clínica',
    anesthesia: 'Formulario pre-anestesia',
    documents: 'Documentos',
    haveElectronicPrescription: 'Receta Electrónica',
    prescriptionsFilteredList: 'Recetas Pendientes',
    myMessages: 'Pacientes - Mensajes',
    myVideoconsultations: 'Pacientes - Videoconsultas',
    myPrescriptions: 'Pacientes - Recetas',
    mySubscriptions: 'Pacientes - Suscripciones',
    sendUsMessage: 'Envíanos un mensaje',
    myDocuments: 'Pacientes - Documentos',
    settingsBeneficiaries: 'Ajustes beneficiarios',
    importCsvOnUsersList: 'Importar CSV usuarios',
    showWelcomeModal: 'Mostrar Popup de Bienvenida',
    prescriptionsList: 'Receta por lotes',
    moduleMedicalTests: 'Módulo de Pruebas',
    personalDoctor: 'Médico personal',
    prescriptionsPermissions: 'Recetas',
    personalDoctorPatient: 'Paciente',
    personalDoctorProfessional: 'Profesional',
    personalDoctorAssistant: 'Asistente',
    profilesFormWithInsuredNumber: 'NºAsegurado',
    generalPermissionsPrescriptions: 'Permisos Generales Receta',
    pdfPrescriptions: 'Permisos Receta PDF',
    electronicPrescriptions: 'Permisos Receta Electrónica Privada',
    parapharmacyPrescriptionPermissions: 'Permisos Receta de Parafarmacia',
    parapharmacyPrescription: 'Permisos Receta de Parafarmacia',
    minorsTreatment: 'Atención a pacientes por edades',
    hidePatientCreation: 'Ocultar crear pacientes'
  },
  recipe: {
    diagnostic: 'Diagnóstico',
    chronic: 'Crónico',
    confidential: 'Confidencial',
    patient_instructions: 'Instrucciones para el paciente',
    pharmacist_warnings: 'Advertencias para el farmacéutico',
    comments: 'Comentarios',
    treatment_created: 'Se ha creado correctamente el tratamiento y la receta',
    sending: 'Enviando',
    err_empty_diagnostic: 'Debes completar el campo diagnóstico del tratamiento',
    err_empty_datestart: 'Debes rellenar la fecha de inicio',
    err_empty_dateend: 'Debes rellenar la fecha de fin',
    err_empty_posology: 'Debes rellenar la posología',
    err_empty_duration: 'Debes rellenar la duración',
    err_empty_guideline: 'Debes rellenar la pauta',
    err_empty_tincode: 'Debes rellenar el TIN Code',
    err_empty_medicine: 'Debes seleccionar un medicamento',
    err_empty_composition: 'Debes completar la composición',
    unit: 'Unidad',
    units: 'Unidades',
    recipe: 'Receta',
    print_recipe: 'Imprimir receta',
    tin_code: 'TIN Code',
    master_formula: 'Fórmula magistral',
    master_formula_placeholder: 'Escribe aquí la fórmula',
    medicine: 'Medicamento',
    medicines: 'Medicamentos',
    search_medicine: 'Busca por nombre de medicamento',
    search_active_ingredient: 'Busca por principio activo',
    search_patient: 'Busca por nombre de paciente',
    add_medicines: 'Agregar medicamento',
    type_electronic: 'Electrónica',
    type_pdf: 'PDF',
    type: 'Tipo',
    sel_type: 'Selecciona tipo',
    create_treatment: 'Crear receta electrónica',
    create_treatment_treat: 'Nuevo tratamiento',
    years: 'años',
    change_patient: 'Cambiar de paciente',
    allergies: 'Alergias',
    num_recipe: 'Nº recetas',
    last_disp: 'Última disp.',
    next_disp: 'Próxima disp.',
    active: 'Activo',
    inactive: 'Inactivo',
    confirm_delete_treatment: '¿Estás seguro de que deseas eliminar el tratamiento?',
    confirm_delete_prescription: '¿Estás seguro de que deseas eliminar la receta?',
    confirm_emit_prescription: '¿Estás seguro de que deseas emitir esta receta?',
    create_prescription: 'Crear receta',
    edit_prescription: 'Editar receta',
    typology: 'Tipología',
    pending: 'Pendiente de emisión',
    partially_dispensed: 'Parcialmente dispensada',
    dispensed: 'Dispensada',
    download: 'Descargar receta',
    narcotic: 'Estupefaciente',
    generic: 'Genérico',
    hours: 'horas',
    days: 'días',
    weeks: 'semanas',
    months: 'meses',
    month: 'mes',
    year: 'años',
    during: 'Durante',
    every: 'cada',
    created_at: 'Creada el',
    frequency: 'Frecuencia',
    new_prescription: 'Nueva receta electrónica',
    emmited: 'Emitida',
    save_prescription: 'Guardar receta',
    confirm_emmit_prescription_text: 'Esta acción enviará la receta al paciente',
    see_prescription: 'Ir a recetas',
    choose_prescription_type: 'Elige tipo de receta',
    paper_recipe: 'Receta PDF',
    title: 'Receta electrónica',
    from: 'desde',
    to: 'hasta',
    fromShort: 'de',
    toShort: 'a',
    created_date: 'Fecha de creación',
    filter_by_created_date: 'Filtra por fecha de creación',
    more_information: 'Más información',
    prescriptions: 'Recetas',
    premium_title: 'Desbloquear contenido Premium',
    patient_dni_required:
      'El paciente debe tener el DNI relleno para poder emitir una receta, accede a los datos personales del paciente y cumplimenta su DNI',
    reject_prof: 'Rechazar',
    reject_without_notification: 'Rechazar sin notificación',
    reject_with_notification: 'Solicitar más información',
    master_formulas: 'Formulas Magistrales',
    add_master_formula: 'Agregar formula magistral',
    psychotropic: 'Psicótropo',
    composition: 'Composición',
    pharmaceuticalForm: 'Forma Farmaceútica',
    administrationWay: 'Vía de administración',
    dosage: 'Dosificación',
    observations: 'Observaciones',
    err_master_formulas: 'Debe completar todos los campos requeridos de las formulas magistrales',
    medicine_detail: 'Detalle del medicamento',
    medicine_information: 'Información del medicamento',
    registration_number: 'Nº de registro',
    authorized: 'Autorizado',
    revoked: 'Revocado',
    discontinued: 'Suspendido',
    package_leaflet: 'Prospecto',
    technical_sheet: 'Ficha técnica',
    active_ingredient: 'Principio activo',
    renew_prescription: 'Renovar receta',
    cancel_prescription: 'Anular receta',
    select_reason: 'Elija el motivo de la cancelación',
    cancel_reason_0: 'Bloqueo cautelar confirmado por plazo',
    cancel_reason_1: 'Anulación por fin de tratamiento',
    cancel_reason_2: 'Cambio de tratamiento',
    cancel_reason_3: 'Datos incompletos o erróneos',
    canceled: 'Anulada',
    canceled_message: 'Receta Anulada correctamente',
    anulate_medicament: 'Anular Medicamento',
    anulate_indications: 'Motivo de la anulación',
    anulate_message: 'Medicamento anulado correctamente',
    unlock_medicament: 'Desbloquear Medicamento',
    unlock_indications: 'Motivo del desbloqueo',
    unlock_message: 'Medicamento desbloqueado correctamente',
    emitted: 'Emitida',
    expired: 'Expirada',
    finished: 'Finalizada',
    in_queue: 'En proceso de emisión',
    units_dispensed: 'Envases / unidades dispensadas',
    navigate_to_prescription: 'Ir a la receta',
  },
  medicine: {
    filter_by_fav: 'Filtrar sólo por favoritos',
  },
  medicament_status: {
    0: 'Dispensable a futuro',
    1: 'Dispensable',
    2: 'Bloqueado Cautelarmente',
    3: 'Dispensado',
    4: 'Dispensado con sustitución',
    5: 'Caducado (Pasado plazo diez días)',
    6: 'Pendiente de visado',
    7: 'Visado rechazado',
    8: 'Dispensado parcialmente',
    9: 'Fórmula Magistral en elaboración',
    10: 'Dispensada parcialmente con sustitución',
    101: 'Anulado',
  },
  medicament_lock_reason: {
    0: 'Dosis superior a la máxima indicada',
    1: 'Posible alergia o intolerancia',
    2: 'Contraindicación',
    3: 'Tratamiento ya finalizado',
    4: 'Otros',
  },
  service: {
    name: 'Servicio de {name}',
    more_services: 'Más servicios',
  },
  signup: {
    dont_have_account_yet: '¿Aún no tienes una cuenta? Regístrate',
    title_patient: 'Regístrate como paciente',
    subtitle_patient: 'Únete a {name} y consulta a profesionales de la salud estés donde estés',
    title_professional: 'Solicita información sobre {name}',
    subtitle_professional: 'Únete a {name} y atiende a tus pacientes estés donde estés',
    i_am_patient: 'Soy paciente',
    i_am_professional: 'Soy profesional sanitario',
    start_enjoying: 'Empieza a disfrutar de los beneficios de usar la plataforma {name}',
    signup_successfully: 'Te has registrado correctamente',
    selector_patient_text: 'Regístrate como paciente, y comienza a disfrutar de nuestra plataforma.',
    selector_professional_text: 'Solicita información sobre nuestra plataforma de salud digital.',
    check_your_inbox: 'Antes de todo, es necesario que revises tu correo electrónico para activar tu cuenta',
    select_professional: 'Selecciona un profesional...',
    select_center: 'Selecciona el centro al que pertenece...',
    valueTooShort: 'El campo debe contener al menos 3 caracteres',
    valueTooLong: 'El campo debe contener como máximo 20 caracteres',
    do_you_already_have_an_account: '¿Ya tienes una cuenta? Inicia sesión',
    typeCompany: {
      title: 'Compañia',
      insurance: 'Aseguradora',
      hospital: 'Hospital',
      clinic: 'Clinica',
      pharmaceutical: 'Farmacéutica',
      company: 'Empresa',
      other: 'Otro',
    },
    annotation: 'Cuéntanos como podemos ayudarte',
    request_send: 'Tu solicitud ha sido enviada.',
    request_send_text:
      'Hemos recibido tu solicitud, en un plazo máximo de 24 a 48 horas nos pondremos en contacto contigo.',
  },
  subscriptions: {
    name: 'Suscripción | Suscripciones',
    not_have_hired: 'No tienes ninguna suscripción contratada',
    renewal: 'Renovación',
    expire: 'Expira',
    request_new_service: 'Solicitar nuevo servicio',
    confirm_cancel_explain:
      'Dejarás de disponer de los servicios que incluye la suscripción a partir del día {date}. ¿Estás seguro de que deseas cancelar tu suscripción?',
    cancelled_ok: 'La suscripción ha sido cancelada correctamente',
  },
  videoconsultations: {
    confirmed: 'Confirmada',
    pending_confirm: 'Pendiente de confirmar',
    pending_payment: 'Pendiente pago',
    go_to_video: 'Entrar a la videoconsulta',
    pending_confirm_explain: 'El profesional debe confirmar la videoconsulta',
    pay_video: 'Pagar videoconsulta',
    pay: 'Pagar',
    to_access: 'Acceder',
    expire_at: 'Expira en',
    download_recording: 'Descargar grabación',
    show_video: 'Ver videconsulta',
    seconds: 'Segundos',
    paymentPatientNotCorresponding:
      'Hemos detectado que hay una sesión iniciada que no se corresponde con la del paciente al que va dirigida la videoconsulta. Para pagar, por favor cierra la sesión y vuelve a intentarlo.',
    link: 'Vincular a videconsulta',
  },
  session: {
    title: 'Aviso de Sesión',
    subtitle: 'Tu sesión online se cerrará en',
    advice:
      'Por favor haz clic en "Continuar" para mantener la sesión abierta o haz clic en "Cerrar sesión" para finalizar tu sesión ahora',
    close_session: 'Cerrar Sesión',
    continue: 'Continuar',
    second: 'segundo | segundos',
  },
  webphone: {
    calling: 'Llamando',
    mute: 'Silenciar',
    pause: 'Pausa',
    speaker: 'Altavoz',
    hang_up: 'Colgar',
    call: 'Llamar',
    new_call: 'Nueva llamada',
    please_enter_number: 'Por favor, introduce un número de teléfono',
    please_enter_valid_number: 'Por favor, introduce un número de teléfono válido',
    please_enter_valid_es_number: 'Por favor, introduce un número de teléfono español válido',
    an_error_ocurred: '¡Ocurrió un error!',
    retry: 'Reintentar',
    token_expired: 'El Token ha expirado',
    webphone: 'Webphone',
  },
  commonModules: {
    telField: {
      modalTitle: 'Seleccionar país',
      modalSearch: 'Buscar',
      mostUsedCountries: 'Países frecuentes',
      allCountries: 'Todos los países',
      searchResults: 'Resultados de búsqueda',
    },
  },
  incompatibleBrowser: {
    title: 'Navegador no compatible',
    message: 'Estás utilizando un navegador web que no es compatible con Docline.',
    title2: '',
    message2: '',
    helpCenterMessage: 'Si quieres saber cuáles son nuestros navegadores compatibles',
    helpCenterLinkName: 'visita nuestro artículo de ayuda.',
    helpCenterLink: 'https://ayuda.docline.es/article/13-compatiblebrowserses',
  },
  triage: {
    title: 'Triaje',
    code: 'Código de triaje',
    generate_code: 'Generar código',
    start: 'Iniciar triaje',
    explain: 'Este código permitirá al paciente realizar un triaje de forma autónoma.',
    finish: 'Finalizar triaje',
    code_error: 'Código de triaje incorrecto!',
    type_code: 'Introduce el código de triaje para comenzar.',
  },
  registro: {
    professional_registration_process: 'Proceso de registro de profesional sanitario',
    init_session: 'Iniciar sesión',
    have_an_account: '¿Ya tiene una cuenta?',
    personal_info: 'Información personal',
    please_complete_info: 'Por favor complete su información profesional.',
    documents: 'Documentos',
    add_passport: 'Por favor adjunte su pasaporte.',
    add_nie: 'Por favor adjunte su NIE por ambas caras.',
    add_dni: 'Por favor adjunte su documento de identidad por ambas caras.',
    front_image: 'Imagen cara frontal',
    rear_image: 'Imagen cara trasera',
    accept_conditions:
      'He leído y acepto los <a href="{privacyPolicyLink}" target="_blank">términos de uso y la política de privacidad</a>',
    drag_or_select_file:
      'Arrastre la imagen aquí o <span class="filepond--label-action open">seleccione el archivo </span>',
    save_ok_title: 'Se ha guardado correctamente',
    save_ok_text:
      'Se ha registrado correctamente, recibirá un email cuando validemos sus datos y pueda comenzar a usar nuestro servicio.',
    alert_front_image: 'Debe introducir la imagen de la cara frontal del documento',
    alert_rear_image: 'Debe introducir imagen de la cara trasera del documento',
    alert_accept_conditions: 'Debe aceptar los términos de uso y la política de privacidad',
    alert_image_max_size: 'La imagen debe pesar como máximo 2MB',
    alert_image_format: 'El formato de la imagen debe ser .png o .jpg',
    alert_input_required: 'Campo obligatorio',
    alert_phone_required: 'El teléfono es obligatorio',
    alert_phone_is_invalid: 'El teléfono no es válido',
    alert_email_required: 'El correo es obligatorio',
    alert_email_is_invalid: 'E-mail debe ser válido',
    alert_number_required: 'El número de colegiado es obligatorio',
    alert_number_is_invalid: 'El número de colegiado no es válido',
  },
  verifyusers: {
    to_be_verified: 'Pendientes de verificar',
    verified: 'Verificados',
    verified_pendings: 'Pendientes de verificación',
    reject: 'Rechazar',
    loading: 'Cargando',
  },
  medicalTests: {
    analytical_tests_list: 'Pruebas analíticas',
    analytical_tests_list_types: 'Tipos de pruebas',
    created_at: 'Fecha de creación',
    code: 'Código',
    description: 'Descripción',
    new_test: 'Nueva prueba',
    delete_test: 'Eliminar tipo de prueba',
    confirm_delete_test: '¿Esta seguro que desea eliminar el tipo de prueba?',
    new_type_of_test: 'Nuevo tipo de prueba',
    test_type_code: 'Cod. tipo de pruebas',
    tests_included: 'Pruebas incluidas',
    observations: 'Observaciones',
    invalidCodeFormat: 'El formato del código debe ser alfanumérico (PA0000)',
    almostOneAct: 'Debe añadir al menos un acto',
    description_too_short: 'La descripción es demasiado corta',
    create_test_success: 'Tipo de prueba creada correctamente',
    new_analytical_test: 'Nueva prueba',
    no_data: 'No hay datos',
    nomenclator: 'Nomenclátor',
    new_act: 'Nuevo acto',
    new_nomenclator: 'Nueva entrada nomenclátor',
    edit_nomenclator: 'Editar entrada nomenclátor',
    specialty: 'Especialidad',
    group: 'Grupo',
    collection: 'Colección',
    subgroup: 'Subgrupo',
    codeCiepq: 'Código CIEP-9',
    invalidCiep9: 'El formato del código debe ser numérico de 4 cifras (XXXX)',
    codeCie9: 'Código CIE-9',
    addAnotherCode: 'Añadir otro código',
    codesCie9: 'Códigos CIE-9',
    externalId: 'ID externo',
    actDescription: 'Descripción del acto',
    search: 'Buscar',
    request_medical_test: 'Solicitud de pruebas',
    request_analytical_test: 'Solicitud de pruebas analíticas',
    patient_info: 'Información del paciente',
    select_medical_test: 'Seleccionar prueba',
    select_medical_test_type: 'Selecciona un tipo de prueba',
    analytical_test: 'Prueba analítica',
    other_medical_tests: 'Otras pruebas',
    medical_tests_requested: 'Pruebas solicitadas',
    other_medical_tests_requested: 'Otras pruebas solicitadas',
    medical_test_description: 'Descripción de prueba',
    medical_test_recommendations: 'Recomendaciones',
    medical_test_request: 'Solicitar',
    add_act: 'Añadir acto',
    delete_act: 'Eliminar acto',
    select_acts: 'Seleccionar actos',
    confirm_delete_act: '¿Está seguro que desea eliminar el acto?',
    select_some_test: 'Selecciona alguna prueba',
    select_patient: 'Seleccionar paciente',
    request_medical_test_success: 'Solicitud generada correctamente',

    request_rx_test: 'Solicitud de pruebas de radiodiagnóstico',
    rx_test: 'Prueba de radiodiagnóstico',
    rx_structured_view: 'Vista estructurada',
    rx_simple_view: 'Vista simple',
    rx_modality: 'Modalidad',
    rx_anatomical_region: 'Región anatómica',
    rx_exploration: 'Exploración',
    rx_search_hint: 'Búsqueda de pruebas de radiodiagnóstico',
    rx_not_found: 'No hemos encontrado pruebas con esa descripción',
    rx_protocols: 'Protocolos RX',
    new_rx_protocol: 'Nuevo protocolo RX',
    request_other_test: 'Solicitud de otras pruebas',
    other_test: 'Otra prueba',
  },
  contracts: {
    you_have_pending_contracts:
      'Ha sido usted dado de alta como profesional médico para la atención de pacientes que provienen del Canal {name}. ¿Está ud. conforme con esta decisión?',
    reject_success: 'Ha rechazado la solicitud de {name}',
    accept_success: 'Ha aceptado la solicitud de {name}',
    select_profile: 'Seleccione un perfil',
    contracts: 'Contratos de canales',
    empty_list: 'Actualmente no tiene ningún contracto asignado',
    channel: 'Canal',
    accepted: 'Aceptado',
    rejected: 'Rechazado',
    pending: 'Pendiente',
  },
  companies_selector: {
    loading_companies: 'Cargando lista de empresas',
    not_found_companies: 'No hemos encontrado empresas con este nombre',
    network_error: 'Hubo un problema al cargar la lista de empresas',
    network_error_short: 'Hubo un problema al cargar la lista',
    retry: 'Volver a intentar',
  },
  professionals_selector: {
    professional: 'Profesional',
    loading_professionals: 'Cargando lista de profesionales',
    not_found_professionals: 'No hemos encontrado profesionales con este nombre',
    network_error: 'Hubo un problema al cargar la lista de profesionales',
    network_error_short: 'Hubo un problema al cargar la lista',
    retry: 'Volver a intentar',
  },
  prescription_selector: {
    select_prescription: 'Selecciona el tipo de receta',
    return_back: 'Volver',
  },
  patients_selector: {
    select_patient: 'Selecciona un paciente',
    loading_patients: 'Cargando listado de pacientes',
    not_found_patients: 'No hemos encontrado pacientes con este nombre',
    network_error: 'Hubo un problema al cargar el listado de pacientes',
    network_error_short: 'Hubo un problema al cargar la lista',
    retry: 'Volver a intentar',
  },
  parapharmacy_templates_selector: {
    select_template: 'Selecciona una plantilla',
    select_option: 'Selecciona una opción',
    loading_templates: 'Cargando lista de plantillas',
    not_found_templates: 'No hemos encontrado plantillas con este nombre',
    network_error: 'Hubo un problema al cargar la lista de plantillas',
    network_error_short: 'Hubo un problema al cargar la lista',
    retry: 'Volver a intentar',
  },
  personal_doctor: {
    personal_doctor: 'Médico personal',
    personal_doctors_historical: 'Histórico de médicos personales',
    personal_doctor_present: 'Actualidad',
    loading_personal_doctors: 'Cargando lista de profesionales...',
    not_found_personal_doctors: 'No hemos encontrado profesionales con este nombre',
    network_error: 'Hubo un problema al cargar la lista de profesionales.',
    network_error_short: 'Hubo un problema al cargar la lista',
    retry: 'Volver a intentar',
    pending_assignment: 'Pendiente de asignación',
    is_your_personal_doctor: 'Soy su médico personal',
  },
  parapharmacy_product_selector: {
    select_product: 'Selecciona el producto',
    search_product: 'Buscar producto',
    loading_products: 'Cargando lista de productos',
    not_found_products: 'No hemos encontrado productos con este nombre',
    network_error: 'Hubo un problema al cargar el listado de productos',
    network_error_short: 'Hubo un problema al cargar el listado',
    retry: 'Volver a intentar',
    product_info: 'Información del producto',
  },
  parapharmacy: {
    prescription: 'Receta de Parafarmacia',
    emit: 'Emitir receta de parafarmacia',
    patient: 'Paciente',
    product: 'Producto',
    confirm_identity_patient: 'Confirmo la identidad del paciente',
    preview_and_emit: 'Previsualizar y emitir',
    edit: 'Editar',
    sign_and_emit: 'Firmar y emitir',
    exit: 'Salir',
    exit_title: 'Salir de la receta',
    exit_message:
      'Si sales del proceso de emisión de receta, todos los datos que has completado hasta este momento se borrarán.',
    continue: 'Continuar proceso',
    emit_success: 'Receta de parafarmacia correctamente emitida',
    emit_fail: 'Error al emitir la receta. inténtelo de nuevo.',
    field_required: 'Completa el campo {field} correctamente',
    information: 'Información',
    see: 'Ver',
    draft: 'Borrador',
    administration_unit_short: 'Uni. de administración',
    creation_date: 'Fecha de creación',
    generic_product: 'Genérico',
    narcotic_product: 'Narcótico',
    psychotropic_product: 'Psicotrópico',
  },
  minors_attention: {
    title: 'Atención a pacientes por edades',
  },
  age_group_selector: {
    select_option: 'Selecciona',
  },
  videoconsultation_selector: {
    select_videoconsultation: 'Selecciona una videoconsulta',
    loading_videoconsultations: 'Cargando videoconsultas...',
    not_found_videoconsultations: 'No hay videoconsultas registradas',
  },
  select: {
    retry: 'Volver a intentar',
  },
  document_category_selector: {
    category: 'Categoría',
    select: 'Selecciona una opción',
  },
  tirea: {
    access: "Prescripción de pruebas"
  },
  company_notifications: {
    title: 'Notificaciones para el centro',
    info_tooltip: "La periodicidad de las notificaciones será fija, enviándose el dia anterior a las 8 p.m.",
    via_email: "Vía correo electrónico",
    via_whatsapp: "Vía Whatsapp",
    failed_set_config: "Ha ocurrido un problema al actualizar la Configuración de Notificaciones. Pruebe de nuevo más tarde.",
    save_config: "Guardar configuración"
  }
};
