<template>
  <menu-layout>
    <template v-if="roles.length > 1">
      <role-selector :roles="roles" />
      <v-divider class="mb-2" />
    </template>
    <template v-for="(item, index) in items">
      <menu-group
        v-if="item.group"
        :key="index"
        :name="item.name"
        :icon="item.icon"
        :title="item.title"
        :group="item.group"
        :items="item.items"
      />

      <v-divider v-else-if="item.divider" :key="index" />

      <menu-item v-else :key="index" :name="item.name" :icon="item.icon" :title="item.title" :href="item.href" />
    </template>
  </menu-layout>
</template>

<script>
import RoleSelector from '../../ehealth/user/RoleSelector.vue';
import MenuLayout from './MenuLayout.vue';
import MenuGroup from './MenuGroup.vue';
import MenuItem from './MenuItem.vue';

export default {
  components: {
    MenuLayout,
    MenuGroup,
    MenuItem,
    RoleSelector,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    roles: JSON.parse(window.localStorage.getItem('auth_ehealth'))
      ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['roles']
      : [],
  }),
};
</script>
<style>
.text-decoration-none,
.text-decoration-none > a {
  text-decoration: none !important;
}
.roleDivider {
  margin: 10px 0px;
}
</style>
