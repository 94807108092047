<template>
  <v-btn
    v-tooltip="tooltip"
    color="grey"
    :dark="active"
    :icon="!active"
    :disabled="disabled || loading"
    :loading="loading"
    large
    fab
    :elevation="0"
    class="mx-3"
    @click="$emit('click')"
  >
    <v-icon>mdi-{{ icon }}</v-icon>
  </v-btn>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
    tooltip: {
      type: String,
      required: true,
    },
    active: Boolean,
    loading: Boolean,
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>
