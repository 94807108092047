import Vue from 'vue';

// TODO: Review https://github.com/vuetifyjs/vuetify/pull/11228 and remove
const ignoredMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';

Vue.config.warnHandler = (message, vm, componentTrace) => {
  if (message !== ignoredMessage) {
    console.error(message + componentTrace);
  } else {
    console.warn(message + componentTrace);
  }
};
