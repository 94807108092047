import Vue from 'vue';
import VueI18n from 'vue-i18n';
import environment from '@/environment';
import { moduleReducer } from '../moduleUtils';

const getReplaceTrans = function() {
  const filesWithLangsToReplace = environment.i18n;

  if (typeof filesWithLangsToReplace === 'undefined') {
    return false;
  }

  return filesWithLangsToReplace;
};

const i18nMessages = moduleReducer(require.context('@/core/i18n', false, /\.js$/), (carry, name, mod) => ({
  ...carry,
  [name]: mod,
}));

export const i18nLocalesAvailable = Object.keys(i18nMessages);

export const detectBrowserLocale = () => {
  const environmentLanguage = environment.language ? environment.language : false;
  const browserLocale = environmentLanguage
    ? environmentLanguage
    : (window.navigator.language || navigator.browserLanguage).substring(0, 2);
  return i18nLocalesAvailable.includes(browserLocale) ? browserLocale : 'en';
};

function getSelectedLocale() {
  if (localStorage.getItem('selected-locale')) {
    return localStorage.getItem('selected-locale')
  }
  return detectBrowserLocale();
}

const buildI18n = messages => {
  Vue.use(VueI18n);

  return new VueI18n({
    locale: getSelectedLocale(),
    messages,
    fallbackLocale: 'es',
  });
};

const getContextToReplace = function(folder) {
  if (folder === 'patient') {
    return require.context('@/core/i18n/patient', false, /\.js$/);
  } else if (folder === 'videoconsultation') {
    return require.context('@/core/i18n/videoconsultation', false, /\.js$/);
  }

  return false;
};

const prepareMessages = i18nMessages => {
  const replaceTrans = getReplaceTrans();

  if (replaceTrans && replaceTrans.replaceFromFolder) {
    const contextReplace = getContextToReplace(replaceTrans.replaceFromFolder);
    if (contextReplace) {
      const messagesToReplace = moduleReducer(contextReplace, (carry, name, mod) => ({ ...carry, [name]: mod }));

      i18nLocalesAvailable.forEach(function(subitem) {
        if (typeof messagesToReplace[subitem] !== 'undefined') {
          Object.keys(i18nMessages[subitem]).forEach(function(key) {
            Object.keys(i18nMessages[subitem][key]).forEach(function(k) {
              if (
                typeof messagesToReplace[subitem][key] !== 'undefined' &&
                typeof messagesToReplace[subitem][key][k] !== 'undefined'
              ) {
                i18nMessages[subitem][key][k] = messagesToReplace[subitem][key][k];
              }
            });
          });
        }
      });
    }
  }

  return i18nMessages;
};
const i18nBuild = buildI18n(prepareMessages(i18nMessages));
export default i18nBuild;
