export default {
  dashboard: {
    routes: 'Dashboard',
    menuName: 'Dashboard',
  },

  users: {
    routes: ['Professionals', 'Professional'],
    menuName: 'Professionals',
  },

  companies: {
    routes: ['Empresas', 'Empresa'],
    menuName: 'Empresas',
  },

  products: {
    routes: 'Productos',
    menuName: 'Productos',
  },

  devices: {
    routes: 'Dispositivos',
    menuName: 'Dispositivos',
  },

  patients: {
    routes: 'Pacientes',
    menuName: 'Pacientes',
  },

  videoConsultation: {
    routes: ['Videoconsulta', 'NuevaVideo', 'Calendario'],
    menuName: 'Videoconsulta',
  },

  calendar: {
    routes: 'CalendarioEspecialidad',
    menuName: 'CalendarioEspecialidad',
  },

  billing: {
    routes: 'Facturacion',
    menuName: 'Facturacion',
  },

  messaging: {
    routes: ['Mensajeria', 'NuevoMensaje'],
    menuName: 'Mensajeria',
  },

  myMessages: {
    routes: 'myMessages',
    menuName: 'myMessages',
  },

  myVideoconsultations: {
    routes: 'myVideoconsultations',
    menuName: 'myVideoconsultations',
  },

  myPrescriptions: {
    routes: 'myPrescriptions',
    menuName: 'myPrescriptions',
  },

  prescriptionsList: {
    routes: 'prescriptionsFilteredList',
    menuName: 'prescriptionsFilteredList',
  },

  mySubscriptions: {
    routes: 'mySubscriptions',
    menuName: 'mySubscriptions',
  },

  myDocuments: {
    routes: 'myDocuments',
    menuName: 'myDocuments',
  },
  sendUsMessage: {
    routes: 'sendUsMessage',
    menuName: 'sendUsMessage',
  },
  packsHired: {
    routes: 'Packscontratados',
    menuName: 'Packscontratados',
  },

  subscriptionsHired: {
    routes: 'Suscripcionescontratadas',
    menuName: 'Suscripcionescontratadas',
  },

  reviews: {
    routes: 'Resenas',
    menuName: 'Resenas',
  },

  configServices: {
    routes: 'configServicios',
    menuName: 'configServicios',
  },

  reports: {
    routes: 'Reportes',
    menuName: 'Reportes',
  },

  settingsBook: {
    routes: 'ajustes/talonario',
    menuName: 'ajustes/talonario',
  },

  settingsSign: {
    routes: 'ajustes/firma',
    menuName: 'ajustes/firma',
  },

  settingsRates: {
    routes: 'ajustes/videoconsultas',
    menuName: 'ajustes/videoconsultas',
  },

  settingsDiary: {
    routes: 'ajustes/agenda',
    menuName: 'ajustes/agenda',
  },

  settingsPacks: {
    routes: 'ajustes/packs',
    menuName: 'ajustes/packs',
  },

  settingsCoupons: {
    routes: 'ajustes/cupones',
    menuName: 'ajustes/cupones',
  },

  settingsLanding: {
    routes: 'ajustes/landing',
    menuName: 'ajustes/landing',
  },

  settingsReceptionist: {
    routes: 'ajustes/recepcionista',
    menuName: 'ajustes/recepcionista',
  },

  settingsCompany: {
    routes: 'ajustes/company-config',
    menuName: 'ajustes/company-config',
  },

  settingsSpecialties: {
    routes: 'ajustes/especialidades',
    menuName: 'ajustes/especialidades',
  },

  settingsServices: {
    routes: 'ajustes/servicios',
    menuName: 'ajustes/servicios',
  },

  settingsDaysNotAvailable: {
    routes: 'ajustes/diasnodisponibles',
    menuName: 'ajustes/diasnodisponibles',
  },

  settingsDaysAvailable: {
    routes: 'ajustes/diasdisponibles',
    menuName: 'ajustes/diasdisponibles',
  },

  settingsCustomValues: {
    routes: 'ajustes/valorespersonalizados',
    menuName: 'ajustes/valorespersonalizados',
  },

  settingsBeneficiaries: {
    routes: 'settings/beneficiaries',
    menuName: 'settings/beneficiaries',
  },

  help: {
    routes: 'Ayuda',
    menuName: 'Ayuda',
  },

  contact: {
    routes: 'Contacto',
    menuName: 'Contacto',
  },

  prescriptions: {
    routes: 'Recetas',
    menuName: 'Recetas',
  },

  marketplace: {
    routes: 'Marketplace',
    menuName: 'Marketplace',
  },

  anesthesia: {
    routes: 'Anesthesia',
    menuName: 'Anesthesia',
  },

  haveCreateVideoWithTel: {
    routes: 'haveCreateVideoWithTel',
    menuName: 'haveCreateVideoWithTel',
  },

  adminSimplified: {
    routes: 'adminSimplified',
    menuName: 'adminSimplified',
  },

  profilesFormWithInsuredNumber: {
    routes: 'profilesFormWithInsuredNumber',
    menuName: 'profilesFormWithInsuredNumber',
  },

  showIbanPaymentModuleMessage: {
    routes: 'showIbanPaymentModuleMessage',
    menuName: 'showIbanPaymentModuleMessage',
  },

  simplifiedLanguages: {
    routes: 'simplifiedLanguages',
    menuName: 'simplifiedLanguages',
  },

  hideStripeButton: {
    routes: 'hideStripeButton',
    menuName: 'hideStripeButton',
  },

  hideFormBooking: {
    routes: 'hideFormBooking',
    menuName: 'hideFormBooking',
  },

  hideTrialMessage: {
    routes: 'hideTrialMessage',
    menuName: 'hideTrialMessage',
  },

  importCsvOnUsersList: {
    routes: 'importCsvOnUsersList',
    menuName: 'importCsvOnUsersList',
  },

  clinicalHistory: {
    routes: 'clinicalHistory',
    menuName: 'clinicalHistory',
  },

  documents: {
    routes: 'documents',
    menuName: 'documents',
  },

  haveElectronicPrescription: {
    routes: 'haveElectronicPrescription',
    menuName: 'haveElectronicPrescription',
  },

  moduleMedicalTests: {
    routes: 'menu.medicalTests',
    menuName: 'moduleMedicalTests',
  },

  parapharmacyPrescription: {
    routes: 'parapharmacyPrescription',
    menuName: 'parapharmacyPrescription',
  }
};
