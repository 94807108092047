
import Vue from 'vue';
import { LocaleMessageObject } from 'vue-i18n/types';
import { Country, Countries, FilterCriteria } from '../types';

export default Vue.extend({
  name: 'SelectModal',

  props: {
    active: {
      type: Object,
      required: true,
    },
    items: {
      type: Array as () => Countries,
      required: true,
    },
    setFocus: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: '100%',
    },
    mostUsedCount: {
      type: Number,
      default: 0,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      default: 'rgba(0, 0, 0, 0.03) !important',
    },
  },

  data() {
    return {
      dialog: false,
      value: this.active,
      query: '',
      filterByCriteria: [
        { name: 'name', canUsePrefix: '' },
        { name: 'dialCode', canUsePrefix: '+' },
      ],
    };
  },

  computed: {
    xsOnly(): boolean {
      return this.$vuetify.breakpoint.xsOnly;
    },

    filteredItems(): Countries {
      if (!this.query) {
        return this.items;
      }
      return this.items.filter(this.filterItemsBy);
    },

    mostUsedCountries(): Countries {
      return this.filteredItems.slice(0, this.mostUsedCount);
    },

    restOfCountries(): Countries {
      if (this.query) {
        /*
         * avoid duplicates in results,
         * due to most used countries existing in both lists
         */
        return this.getUniquesBy(this.filteredItems, 'iso2');
      }
      return this.filteredItems.slice(this.mostUsedCount);
    },

    getLabel(): string {
      const base: LocaleMessageObject | any = this.$i18n.messages[this.$i18n.locale];
      return base.commonModules.telField.modalSearch;
    },

    getIcon(): string {
      return `mdi-menu-${this.dialog ? 'up' : 'down'}`;
    },

    searchIsNotActive(): boolean {
      return !this.query;
    },

    buttonStyles() {
      return `
        background-color: ${this.backgroundColor} !important;
      `;
    },
  },

  methods: {
    handleClick(item: object): void {
      this.$emit('handleChange', item);
      this.dialog = false;
    },

    filterItemsBy(item: Country): boolean {
      return this.filterByCriteria.some((e: FilterCriteria) => {
        const strToSearchIn = this.normalizeString(e.canUsePrefix + item[e.name]);
        return strToSearchIn.includes(this.normalizeString(this.query));
      });
    },

    normalizeString(str: string): string {
      return str.toLowerCase();
    },

    getUniquesBy(arr, key): any {
      return [...new Map(arr.map((item: Country) => [item[key], item])).values()];
    },
  },
});
