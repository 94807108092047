<template>
  <transition name="modal-background-trans">
    <div v-if="show" class="modal-background">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-background {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-background-trans-enter-active,
.modal-background-trans-leave-active {
  transition: background-color 0.3s ease-in-out;
}

.modal-background-trans-enter,
.modal-background-trans-leave-to {
  background-color: rgba(0, 0, 0, 0);
}

@media (max-width: 576px) {
  .modal-background {
    align-items: flex-start;
  }
}
</style>
