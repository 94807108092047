<template>
  <transition name="modal-box-trans">
    <div v-if="show" class="modal-box">
      <h1 v-if="title" class="modal-box-title title-box">
        {{ title }}
      </h1>

      <div class="modal-box-content">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    title: {
      type: [String, null],
      default: null,
    },

    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-box {
  overflow-y: auto;
  display: inline-block;
  position: relative;
  width: calc(100% - 100px);
  max-width: 1000px;
  height: auto;
  max-height: calc(100vh - 50px);
  padding: 1rem;
  border-radius: 5px;
  background-color: #ffffff;
  transform: translateY(0);
}

.title-box {
  font-family: roboto;
  font-size: x-large;
}

@media only screen and (min-width: 660px) {
  .modal-box {
    max-width: 70%;
  }
}

@media only screen and (min-width: 1500px) {
  .modal-box {
    max-width: 1000px;
  }
}

.modal-box-trans-enter-active,
.modal-box-trans-leave-active {
  transition: transform 0.5s ease-in-out;
}

.modal-box-trans-enter,
.modal-box-trans-leave-to {
  transform: translateY(100vh);
}

.modal-box-title {
  margin: 0 0 0.75rem 0;
  padding: 0 0 0.75rem 0.25rem;
  border-bottom: 1px solid #cccccc;
  font-weight: bold;
  font-size: 1.5rem;
}

.modal-box-content {
  padding: 0.5rem;
}

@media (max-width: 576px) {
  .modal-box {
    width: 100%;
    max-height: calc(100vh - 100px);
  }
}
</style>
