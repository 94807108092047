const getEnvironmentByUrl = (environments, url) => {
  const defaultItem = environments.shift();

  return environments.reduce(
    (carry, item) => {
      const { pattern, ...data } = item;
      return url.indexOf(pattern) > -1 ? data : carry;
    },

    Object.keys(defaultItem).reduce((carry, key) => {
      return key !== 'pattern' ? { ...carry, [key]: defaultItem[key] } : carry;
    }, {})
  );
};

const getEnvironment = (env, url) =>
  typeof env.ENV_CURRENT !== 'undefined' ? env.ENV_CURRENT : getEnvironmentByUrl(env.ENV_AVAILABLES, url);

export default getEnvironment(process.env, document.URL);
