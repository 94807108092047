export default () => {
  if (typeof localStorage !== 'undefined') {
    const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));
    const prescriptionActive = true;

    if (prescriptionActive) {
      const user_config =
        authUser !== null && window.localStorage.getItem('auth_ehealth') !== null
          ? JSON.parse(window.localStorage.getItem('auth_ehealth'))
          : false;
      if (typeof user_config.hasSpecialtyToPrescribe !== 'undefined' && user_config.hasSpecialtyToPrescribe) {
        return true;
      } else if (typeof user_config.erecipe !== 'undefined' && user_config.erecipe) {
        return true;
      }
    }
  }
  return false;
};
