<template>
  <v-card class="elevation-0">
    <v-toolbar flat color="transparent">
      <v-toolbar-title>{{ $t('message.notifications') }}</v-toolbar-title>
    </v-toolbar>
    <v-divider />
    <v-card-text class="pa-0">
      <v-list two-line class="pa-0">
        <template v-for="(item, index) in items">
          <v-subheader v-if="item.header" :key="item.header">{{ item.header }}</v-subheader>
          <v-divider v-else-if="item.divider" :key="index" />
          <v-list-item v-else :key="item.title" :to="item.path">
            <v-list-item-avatar :color="item.color">
              <v-icon dark>{{ item.icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-divider />
      <v-btn block text to="/notificaciones">{{ $t('message.showall') }}</v-btn>
      <v-divider />
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'NotificationList',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    handleClick: e => {
      console.log(e);
    },
  },
};
</script>
