<template>
  <div class="d-avatar" :style="avatarStyle" :class="{ pointer: hasClickListener }">
    <!-- dialog -->
    <v-dialog v-model="editMode" scrollable :fullscreen="xsOnly" max-width="460">
      <v-card>
        <v-toolbar flat class="flex-grow-0">
          <h4 class="title">{{ $t('account.chooseImage') }}</h4>
          <v-spacer />
          <v-btn v-tooltip="$t('common.close')" icon @click="handleClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="mb-4 px-4">
          {{ $t('account.chooseImageText') }}
        </v-card-text>

        <croppa
          v-model="imageCrop"
          class="d-avatar__cropper"
          :width="300"
          :height="300"
          :placeholder="$t('account.chooseImage')"
          :show-remove-button="false"
          :file-size-limit="fileSizeLimit"
          canvas-color="#d1d1d1"
          @file-size-exceed="onFileSizeLimit"
        />

        <v-card-actions class="my-6">
          <v-spacer />
          <v-btn text @click="handleClose">
            {{ $t('account.cancel') }}
          </v-btn>
          <v-btn color="primary" @click="handleClick">
            {{ $t('common.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- avatar container -->
    <template v-if="loading">
      <v-progress-circular :size="60" indeterminate color="primary" />
    </template>
    <template v-else>
      <!-- avatar -->
      <div v-if="hasImgSrc || text === ''" class="d-avatar-img" :style="imgStyle" />
      <svg v-else :style="svgStyle">
        <text x="50%" y="50%" text-anchor="middle" dominant-baseline="central" :style="textStyle">
          {{ avatarLetter }}
        </text>
      </svg>
      <!-- trigger -->
      <span v-if="canEdit" class="d-avatar__edit-trigger" @click="handleOpenDialog">
        <v-icon>mdi-camera</v-icon>
      </span>
    </template>
  </div>
</template>
<script>
export default {
  name: 'DAvatar',

  props: {
    color: {
      type: String,
      default: 'rgba(255, 255, 255, .9)',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    twoLetter: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: null,
    },
    size: {
      type: [String, Number],
      default: '50',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    fileSizeLimit: {
      type: Number,
      default: 5242880,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      imageCrop: null,
      editMode: false,
      validBlobTypes: ['image/jpg', 'image/png', 'image/gif'],
    };
  },

  computed: {
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },

    hasImgSrc() {
      return !(!this.src || this.src === '');
    },

    hasClickListener() {
      return this.$listeners && this.$listeners.click;
    },

    avatarLetter() {
      if (!this.text) {
        return ' ';
      }
      let letter;
      if (this.twoLetter) {
        const p = this.text.trim().split(' ');
        const deleteSpaces = p.indexOf('');
        if (deleteSpaces !== -1) {
          p.splice(deleteSpaces, 1);
        }
        if (p.length > 1) {
          letter = p[0][0] + p[1][0];
        } else {
          letter = this.text[0] + this.text[1];
        }
      } else {
        letter = this.text[0];
      }
      return letter.toUpperCase();
    },

    avatarStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        borderRadius: this.rounded ? '100%' : '0',
      };
    },

    imgStyle() {
      const src = this.src || '/static/avatar.svg';
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        borderRadius: this.rounded ? '50%' : '0',
        backgroundImage: 'url(' + src + ')',
      };
    },

    bgColor() {
      let bgColor;
      if (!this.backgroundColor) {
        const hue = (360 / 26) * (this.avatarLetter.charCodeAt() - 64);
        bgColor = `hsl(${hue}, 68%, 48%)`;
      } else {
        bgColor = this.backgroundColor;
      }
      return bgColor;
    },

    svgStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        borderRadius: this.rounded ? '100%' : '0',
        background: this.bgColor,
      };
    },

    textStyle() {
      const fontSize = this.size * (this.avatarLetter.length ? 0.5 : 0.65);
      return {
        fill: this.color,
        fontFamily: "'Lucida Console', Monaco, monospace",
        fontWeight: 700,
        fontSize: `${fontSize}px`,
      };
    },
  },

  methods: {
    handleOpenDialog() {
      this.editMode = true;
    },

    handleClick() {
      this.imageCrop.generateBlob(this.handleBlob);
    },

    handleBlob(blob) {
      if (!blob) {
        this.$toast.error(this.$t('account.noImage'));
        return;
      }
      if (!this.validBlobTypes.includes(blob.type)) {
        this.$toast.error(this.$t('account.fileNotAllowed'));
        return;
      }

      this.$emit('handle-avatar', blob);
      this.handleClose();
    },

    onFileSizeLimit() {
      this.$toast.error(this.$t('account.fileTooBig', { fileSize: '5MB' }));
    },

    handleClose() {
      this.editMode = false;
      this.imageCrop.remove();
    },
  },
};
</script>
<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}

.d-avatar {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  position: relative;

  & > div {
    display: inline-block;
  }

  .d-avatar-img {
    background-color: #e0e0e0;
    background-size: cover;
  }

  &__edit-trigger {
    background-color: #d1d1d1;
    position: absolute;
    bottom: 0;
    right: rem(-12);
    height: rem(34);
    width: rem(34);
    text-align: center;
    line-height: rem(32);
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all 0.2s ease-out;

    &:hover {
      background-color: white;
      border: 1px solid #d1d1d1;
    }
  }

  &__cropper {
    margin: 0 auto;
  }
}
</style>
