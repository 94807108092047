<template>
  <button :class="className" v-bind="$attrs" v-on="$listeners">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: 'primary',
      validator: value => ['primary', 'secondary'].includes(value),
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    className() {
      return `btn btn-${this.mode}${this.disabled ? ' disabled' : ''}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  box-sizing: border-box;
  min-width: 120px;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  outlined: 0;
  text-align: center;
  cursor: pointer;

  &.btn-primary {
    background-color: #1976d2;
    border: 1px solid #1976d2;
    color: #ffffff;
  }

  &.btn-secondary {
    background-color: #ffffff;
    border: 1px solid #333333;
    color: #333333;
  }

  &.disabled {
    background-color: #bbbbbb;
    border: 1px solid #bbbbbb;
    color: #ffffff;
  }
}
</style>
