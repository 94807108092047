export default {
  asignar: {
    texto_sms:
      'Are you sure you want to send a text message to the professional to let them know there are clients waiting for their appointment?',
  },
  common: {
    paciente: 'Client',
  },
  cupones: {
    usarlo_cualquiera: 'It can be used by any client.',
  },
  errors: {
    not_access_patient: 'An error has occurred trying to access the client',
    edit_patient: 'An error occurred when editing the client',
    existing_patient: 'The client is already registered on {name}',
    phone_video_error: "You cannot select more than one client to create an appointment from the client's phone number",
    max_patients_multi: 'You cannot select more than three clients for a multiconsultation',
  },
  facturacion: {
    puede_tardar: 'Please be client while we retrieve the information from the payment platform',
  },
  mensajeria: {
    sin_mensajes_abiertos: 'At this time, you have no messaging open to answer with any client',
    confirm_atendida:
      'The courier service will be marked as solved. If the client wishes to reconnect, they must request a new messaging service. Are you sure you want to close this messaging service?',
    confirmar_delete_file:
      'If you delete the document the client will not be able to access it. Are you sure you want to delete the document?',
  },
  menu: {
    patients: 'Clients',
  },
  packs: {
    eliminar_texto:
      'Clients will not be able to hire this Pack in the future. Previously hired packs will not be affected.',
  },
  patient: {
    patients: 'Clients',
    search: 'Search Client',
    without_patients: 'There are no clients',
    new: 'New Client',
    edit: 'Edit Client',
    delete: 'Delete Client',
    confirm_delete:
      'The selected client stopped receiving their services. Are you sure you want to eliminate your client?',
    delete_success: 'The client has been removed correctly',
    delete_error: 'An error has occurred eliminating the client. Try again later',
    sin_diagnosticos: 'This client has not yet been {name}',
    sin_evoluciones: 'No evolution has yet been added to this client',
    sin_documentos: 'The client does not have documents',
    sin_chequeos: 'The client does not have checkups',
    sin_recetas: 'No prescriptions have yet been created for the client',
    notas: 'Internal Notes (The client will not be able to see this information and will not appear in the report)',
    enviado_mensaje_vincular:
      'The client is already registered. We have sent an email to the client to accept the link between health professional and client',
    pacientes_tutelados: 'Users associated with your account',
    sin_common: 'The client does not have {name}',
    history_clinical: 'Medical history',
    history_clinic: 'Medical history',
  },
  recetas: {
    instrucciones_paciente: 'INSTRUCTIONS TO THE USER (if applicable)',
    required: {
      medicamento: 'The name medication is required',
      paciente: 'The name client is required',
    },
  },
  success: {
    new_patient: 'The client has been created correctly',
    patient_exist: 'The client is already registered',
    patient_exist_otro_mail: 'The client is already registered with another email',
    edit_patient: 'The client information has been edited correctly',
  },
  talonario: {
    confirmar_subida_talonario:
      ' Are you sure you want to upload the book {msg} to use when you give your clients prescriptions?',
  },
  user: {
    telefono_paciente: "Client's phone",
  },
  videoconsulta: {
    crear_para_paciente: 'Create a {tipoVideo} for a Client',
    como_desea_comunicar: 'How do you want to communicate the appointment to the client?',
    cita_cortesia_texto: 'These appointments are free for the client. These can be useful in cases such as: ',
    cita_cortesia_li:
      'Reschedule an appointment that could not be completed. | Send an invitation for a test videoconsultation or any other reason that you may consider. | Clients that have previously bought a pack of videoconsultations.',
    add_destinatario: 'Select the client',
    add_destinatario_cita: 'Select the client',
    add_modocomunicacion: 'Indicate the way in which the appointment will be communicated to the client',
    enviado_correctamente: 'The video query has been created correctly. An email has been sent to the client',
    enviado_correctamente_sms: 'The video query has been created correctly. An SMS has been sent to the client',
    enviado_recordatorio: 'An email has been sent to the client to remind him of the videoconsultation',
    confirm_delete2:
      'The video consultation is already paid. If necessary to make make a refund of money to the client contact us through the chat.',
    notification_email: 'An email will be sent to the client confirming the cancellation of the appointment',
    fixed_fee_message:
      'The management fee for this service is € {feeValue} to be added to your rate at the time of the client payment.',
    percentage_fee_message:
      'The management fee for this service is {feeValue}% with a minimum of € {feeMin} and a maximum of € {feeMax} to be added to your rate at the time of the client payment.',
  },
  empresa: {
    share_patients: 'Share Clients',
    compartir_pacientes: 'Sharing Clients',
  },
  suscripciones: {
    noty_to_all:
      'If the subscription price changes, all active subscriptions will be affected and the clients will be notified.',
    cancelar_suscripcion_texto:
      'The subscription will be canceled from {dia}. Meanwhile, the client can continue to use this service. Are you sure you want to cancel the subscription?',
    cancelado_correctamente:
      'The subscription has been cancelled successfully. No more charges will be made to the client.',
  },
  steps: {
    iban_payment_module_message:
      'It is necessary that you complete the IBAN field, with the bank account data, when you intend to make payments to clients, for any of the appointments or consultations made through this platform, and configure the POS for online collections.',
  },
  permissions: {
    patients: 'Clients',
  },
};
