export default {
  state: {
    alert: {
      show: false,
      timeout: 5000,
      color: '',
      icon: '',
      message: '',
      link: '',
      link_text: '',
    },
    conditionsModalIsVisible: false,
    conditionsNeedsToBeAccepted: false,
    twoFaConfigDialogIsVisible: false,
    aboutUsDialogIsVisible: false,
  },

  getters: {
    getConditionsModalVisibility(state) {
      return state.conditionsModalIsVisible;
    },
  },

  actions: {
    showAlert({ commit }, payload) {
      const colorsAvailable = ['info', 'success', 'warning', 'error', 'green'];

      const iconsAvailable = [
        'mdi-check', //'done',
        'mdi-information', //'info',
        'mdi-alert', //'warning',
        'mdi-alert-circle', //'error',
        'mdi-import', //'input',
        'mdi-lock', //'lock',
        'mdi-send', //'send',
        'mdi-account', //'person',
        'mdi-email', //'email'
        'mdi-account-outline', //'perm_identity',
        'mdi-text-box-plus',
        'mdi-delete',
        'mdi-clock-outline',
        'mdi-file',
      ];

      if (payload.color && !colorsAvailable.includes(payload.color)) {
        throw new Error('Alert color not found');
      }

      if (payload.icon && !iconsAvailable.includes(payload.icon)) {
        throw new Error('Alert icon not found');
      }

      commit('resetAlert');
      commit('setAlert', { show: true, ...payload });
    },

    hideAlert({ commit }) {
      commit('setAlert', { show: false });
    },

    handleConditionsModal({ commit }, payload) {
      commit('setConditionsModal', payload);
    },

    handleConditionsNeedsToBeAccepted({ commit }, payload) {
      commit('setConditionsNeedsToBeAccepted', payload);
    },
  },

  mutations: {
    setAlert(state, payload) {
      ['show', 'timeout', 'color', 'icon', 'message', 'link', 'link_text'].forEach(key => {
        if (typeof payload[key] !== 'undefined' && payload[key] !== null && payload[key] !== '') {
          state.alert[key] = payload[key];
        }
      });
    },

    resetAlert(state) {
      state.alert = {
        show: false,
        timeout: 5000,
        color: '',
        icon: '',
        message: '',
        link: '',
        link_text: '',
      };
    },

    setConditionsModal(state, payload) {
      state.conditionsModalIsVisible = payload;
    },

    setConditionsNeedsToBeAccepted(state, payload) {
      state.conditionsNeedsToBeAccepted = payload;
    },

    setTwoFaDialogVisibility(state, payload) {
      state.twoFaConfigDialogIsVisible = payload;
    },

    setAboutUsDialogVisibility(state, payload) {
      state.aboutUsDialogIsVisible = payload;
    },
  },
};
