<template>
  <v-menu
    ref="menu"
    v-model="menu"
    transition="scale-transition"
    min-width="290px"
    offset-y
    :close-on-content-click="false"
    :nudge-right="40"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="dateForInput"
        prepend-icon="mdi-calendar"
        readonly
        :label="label"
        class="text-capitalize js_clear"
        v-on="on"
      />
    </template>

    <v-date-picker
      v-model="dateForPicker"
      :locale="locale"
      first-day-of-week="1"
      no-title
      scrollable
      :min="min"
      :max="max"
      @input="$refs.menu.save(dateForPicker)"
    />
  </v-menu>
</template>

<script>
import { getLocaleFullFormat } from '@/utils';

export default {
  props: {
    value: {
      type: [Date, null],
      default: new Date(),
    },

    label: {
      type: String,
      default: '',
    },

    min: {
      type: [String, null],
      default: null,
    },

    max: {
      type: [String, null],
      default: null,
    },
  },

  data() {
    return {
      menu: false,
      dateForPicker: this.transformDateToPicker(this.value),
    };
  },

  computed: {
    locale() {
      return getLocaleFullFormat(this.$i18n.locale);
    },

    dateForInput() {
      return this.transformDateToInput(this.value);
    },
  },

  watch: {
    value(value) {
      this.dateForPicker = this.transformDateToPicker(value);
    },

    dateForPicker(value) {
      this.$emit('input', this.transformPickerToDate(value));
    },
  },

  methods: {
    transformDateToInput(date) {
      if (!date) {
        return '';
      }

      return [`0${date.getDate()}`.slice(-2), `0${date.getMonth() + 1}`.slice(-2), date.getFullYear()].join('/');
    },

    transformDateToPicker(date) {
      if (!date) {
        return '';
      }

      return [date.getFullYear(), `0${date.getMonth() + 1}`.slice(-2), `0${date.getDate()}`.slice(-2)].join('-');
    },

    transformPickerToDate(date) {
      const [year, month, day] = date.split('-');
      return new Date(year, parseInt(month) - 1, day);
    },
  },
};
</script>
