import { RouteConfig } from 'vue-router';

export const servicesNamePaths = {
  blockchain: 'Blockchain',
  booking: 'Reserva',
  companiesList: 'Empresas',
  company: 'Empresa',
  devices: 'Dispositivos',
  hiredPacks: 'Packscontratados',
  hiredSubscriptions: 'Suscripcionescontratadas',
  invoicing: 'Facturacion',
  products: 'Productos',
  publicBooking: 'Booking',
  reports: 'Reportes',
  reviews: 'Resenas',
  signedLink: 'FirmaLink',
};

export const servicesRoutes: RouteConfig[] = [
  {
    path: '/blockchain',
    meta: {
      public: true,
    },
    name: servicesNamePaths.blockchain,
    component: () =>
      import(
        /* webpackChunkName: "blockchain" */
        `@/views/Blockchain.vue`
      ),
  },
  {
    path: '/firma/:key',
    meta: { public: true, requiresAuth: false },
    name: servicesNamePaths.signedLink,
    component: () =>
      import(
        /* webpackChunkName: "firma-link" */
        `@/views/FirmaLink.vue`
      ),
  },
  {
    path: '/empresas',
    meta: { breadcrumb: false, requiresAuth: true, only_superadmin: true },
    name: servicesNamePaths.companiesList,
    component: () =>
      import(
        /* webpackChunkName: "empresas" */
        `@/views/Empresas.vue`
      ),
  },
  {
    path: '/empresa/:id',
    meta: { breadcrumb: false, requiresAuth: true, only_superadmin: true },
    name: servicesNamePaths.company,
    component: () =>
      import(
        /* webpackChunkName: "empresa" */
        `@/views/Empresa.vue`
      ),
  },
  {
    path: '/productos',
    meta: { breadcrumb: false, requiresAuth: true, forbiddenPatient: true },
    name: servicesNamePaths.products,
    component: () =>
      import(
        /* webpackChunkName: "productos" */
        `@/views/Productos.vue`
      ),
  },
  {
    path: '/reserva',
    meta: { breadcrumb: true, requiresAuth: true },
    name: servicesNamePaths.booking,
    component: () =>
      import(
        /* webpackChunkName: "reserva" */
        `@/views/Reserva.vue`
      ),
  },

  {
    path: '/booking/:key',
    meta: {
      public: true,
      breadcrumb: false,
    },
    name: servicesNamePaths.publicBooking,
    component: () =>
      import(
        /* webpackChunkName: "booking" */
        `@/views/Booking.vue`
      ),
  },
  {
    path: '/packscontratados',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: servicesNamePaths.hiredPacks,
    component: () =>
      import(
        /* webpackChunkName: "packs-contratados" */
        `@/views/Packscontratados.vue`
      ),
  },
  {
    path: '/suscripcionescontratadas',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: servicesNamePaths.hiredSubscriptions,
    component: () =>
      import(
        /* webpackChunkName: "suscripciones-contratadas" */
        `@/views/Suscripcionescontratadas.vue`
      ),
  },

  {
    path: '/reportes',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: servicesNamePaths.reports,
    component: () =>
      import(
        /* webpackChunkName: "reportes" */
        `@/views/Reportes.vue`
      ),
  },

  {
    path: '/resenas',
    meta: { breadcrumb: true, requiresAuth: true, only_admin: true },
    name: servicesNamePaths.reviews,
    component: () =>
      import(
        /* webpackChunkName: "resenas" */
        `@/views/Resenas.vue`
      ),
  },

  {
    path: '/dispositivos',
    meta: { breadcrumb: true, requiresAuth: true, only_admin: true },
    name: servicesNamePaths.devices,
    component: () =>
      import(
        /* webpackChunkName: "dispositivos" */
        `@/views/Dispositivos.vue`
      ),
  },

  {
    path: '/facturacion',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: servicesNamePaths.invoicing,
    component: () =>
      import(
        /* webpackChunkName: "facturacion" */
        `@/views/Facturacion.vue`
      ),
  },
];
