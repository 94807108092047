import Vue from 'vue';
import Pusher from 'vue-pusher';
import environment from '@/environment';

if (typeof environment.publish_key_pusher !== 'undefined') {
  Vue.use(Pusher, {
    api_key: environment.publish_key_pusher,
    options: {
      cluster: 'eu',
      encrypted: true,
    },
  });
}
