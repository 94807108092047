export default {
  incompatibleBrowser: {
    title: 'You can now create electronic prescriptions',
    message:
      'Your account has been successfully activated and from today you can start sending electronic prescriptions to your patients.',
    title2: 'How to access the platform?',
    message2:
      'To access the platform, use the direct link you will find on your desktop "Electronic Prescription Access". Or access from any other device using the Chrome, Safari or Firefox browser using this URL: vithas.docline.es ',
    helpCenterMessage: 'View or download how to create an electronic prescription',
    helpCenterLinkName: 'HERE',
    helpCenterLink: 'https://vithas.es/getmedia/e0930fac-a8d6-42fb-a414-19040388635d/eReceta_Profesionales.aspx',
  },
};
