<template>
  <v-app-bar
    app
    fixed
    clipped-left
    elevate-on-scroll
    :style="themeToolbarHeader.enableDarkColor ? null : styleDarkToolbar"
    :color="themeToolbarHeader.backgroundColor"
    :dark="themeToolbarHeader.enableDarkColor"
  >
    <v-app-bar-nav-icon class="mr-2" @click.stop="handleDrawerToggle" />

    <div class="mx-2 hidden-sm-and-down link-toolbar d-flex">
      <d-logo
        v-if="showSvgLogo"
        class="d-flex"
        primary-color="#ffffff"
        secondary-color="#ffffff"
        height="28"
        :slogan="false"
        :brand="appDisplayName.toLowerCase()"
      />
      <img v-else :src="appLogoToolbar ? appLogoToolbar : appLogoSecondary" class="img-toolbar" alt="Logo" />
    </div>

    <v-autocomplete
      v-if="!isPatient"
      v-model="searchModel"
      prepend-inner-icon="mdi-magnify"
      flat
      dense
      clearable
      hide-details
      hide-selected
      item-text="fullName"
      item-value="id"
      :no-filter="true"
      solo-inverted
      class="ml-2 hidden-sm-and-down"
      style="max-width: 500px"
      :label="esadmin || esrrhh ? $t('user.search') : $t('patient.search')"
      :items="pacientes"
      :loading="isSearching"
      :search-input.sync="search"
      @change="openUserDetail"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title />
        </v-list-item>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title>{{ item.fullName }}</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>

    <v-spacer />
    <v-menu offset-y origin="center center" class="elelvation-1" :nudge-bottom="14" transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn icon text v-on="on">
          <v-badge v-if="notificationsTotal" color="red" overlap :content="notificationsTotal">
            <v-icon class="rotate-45" medium>mdi-bell-outline</v-icon>
          </v-badge>
          <v-icon v-if="!notificationsTotal" medium>mdi-bell-outline</v-icon>
        </v-btn>
      </template>
      <notification-list :items="notifications" />
    </v-menu>
    <v-menu left bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon large text v-on="on">
          <d-avatar :text="nombre_avatar" size="30" rounded :two-letter="true" :src="img_avatar" />
        </v-btn>
      </template>
      <v-list class="pa-0">
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :to="!item.href ? { name: item.name } : null"
          :href="item.href"
          ripple="ripple"
          :disabled="item.disabled"
          :target="item.target"
          rel="noopener"
          @click="item.click"
        >
          <v-list-item-content>
            <v-list-item-title>{{ $t('menu.' + item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog
      v-model="dialog.show"
      scrollable
      :max-width="'400px'"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{ dialog.title }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="dialog.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div>{{ dialog.text }}</div>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer />
          <v-btn color="primary" text @click.native="closeDialog()">{{ dialog.title }}</v-btn>
          <v-btn color="primary" dark depressed @click.native="emitPrescriptions">
            {{ $t('recetas.emit_prescriptions') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { resetHelpScout } from '@/services/auth-user';
import {
  getHeader,
  getListadoPacientesUrl,
  getNotificacionesUrl,
  logoutUrl,
} from '@/config/config';
import NotificationList from '@/components/ehealth/header/Notificaciones.vue';
import DAvatar from '@/components/DAvatar.vue';
import DLogo from '@/components/DLogo.vue';
import { initializeHelpHero } from '@/services/helpHero';
import { baseNameRoutes } from '@/router/paths';
import Alerts from '@/mixins/Alerts';
import environment from '@/environment';

export default {
  name: 'AppToolbar',

  components: {
    DAvatar,
    DLogo,
    NotificationList,
  },

  mixins: [Alerts],

  props: {
    showDoclineOriginalBrand: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    isSearching: false,
    search: null,
    searchModel: null,
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    colorPrimario:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['color'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['color'] !== '' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['color'] !== 'primary'
        ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['color'] + '_toolbar'
        : 'primary',
    notificationsTotal: 0,
    prescriptionsPendingToEmit: 0,
    notificationsAmount: 0,
    buscadorPaciente: '',
    buscaPacienteClase:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['color'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['color'] !== '' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['color'] !== 'primary'
        ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['nombreEmpresa'] + '_buscador_pacientes'
        : 'buscador_pacientes',
    isPatient:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['isPatient'] !== 'undefined'
        ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['isPatient']
        : false,
    isReceptionist:
      JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3,
    dialog: {
      name: '',
      title: '',
      show: false,
      text: '',
      loading: false,
      type: '',
    },
    loader: null,
    notifications: {
      items: [{ title: '', color: '', icon: '', path: '' }],
    },
    pacientes: [],
    nudge: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    img_avatar: JSON.parse(window.localStorage.getItem('auth_ehealth'))
      ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['img']
      : '',
    nombre_avatar: JSON.parse(window.localStorage.getItem('auth_ehealth'))?.['fullName'] ?? '',
    esadmin:
      JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 1,
    esrrhh:
      JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 4,
    show_conditions: environment.footer?.rgpd?.showOnFirstLogin,
    talkSession: null,
    totalNoLeidos: 0,
    on_line: false,
    styleDarkToolbar: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    items: [],
  }),

  computed: {
    ...mapState({
      appLogoSecondary: state => state.app.images.logoSecondary,
      appLogoToolbar: state => state.app.images.logoToolbar,
      appHelpHero: state => state.app.helpHero,
      conditionsModalIsVisible: state => state.layout.conditionsModalIsVisible,
      appDisplayName: state => state.app.displayName,
      permissions: state => state.app.permissions,
    }),

    ...mapGetters({
      userFromVuex: 'user/getUser',
      useNewAccountPage: 'app/getFeatureNewAccountPageFlag',
      twoFaEnabled: 'account/twoFaEnabled',
    }),

    themeToolbarHeader() {
      return this.$store.getters['app/themeToolbarHeader'];
    },

    showSvgLogo() {
      return this.showDoclineOriginalBrand;
    },
  },

  watch: {
    search(_val) {
      this.pacientes = [];
      if (this.search && this.search.length > 2) {
        this.isSearching = true;
        this.$http
          .post(getListadoPacientesUrl, { filtro: this.search }, { headers: getHeader() })
          .then(res => {
            this.pacientes = res.data;
          })
          .catch(err => {
            this.$log.error(err);
          })
          .finally(() => {
            this.isSearching = false;
          });
      }
    },
    totalNoLeidos(val) {
      if (val > 0) {
        const audio = new Audio('https://docline.es/notychat.mp3');
        audio.play();
      }
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => {
        this[l] = false;
      }, 3000);
      this.loader = null;
    },
    $route() {
      this.listNotifications();
    },

    'userFromVuex.landing'(val) {
      if (!val) {
        return;
      }

      if (this.authUser !== null && this.authUser.rol === 2) {
        this.items.unshift({
          name: 'ajustes/landing',
          icon: 'mdi-tablet-dashboard',
          title: 'miweb',
          click: _e => {
            // console.log(e);
          },
        });
      }
    },
  },

  mounted() {
    this.setUserMenuItems();
    this.subscribeToChannelOfNotifications();
  },

  async created() {
    await this.setAuthUserData();
    await this.getPaymentSettings();
    if (this.authUser) {
      this.listNotifications();
      this.loadHelpHeroTour();
    }
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handlerBeforeUnload);
  },

  methods: {
    ...mapActions('app', ['loadConfigFromBrand', 'getPaymentSettings']),
    ...mapActions('layout', ['handleConditionsModal', 'handleConditionsNeedsToBeAccepted']),
    ...mapActions('account', ['closeAccountSession']),
    ...mapActions('account', ['setAuthUserData']),
    ...mapActions('layout', ['showAlert']),

    setUserMenuItems() {
      setTimeout(_ => {
        this.items.push(
          {
            name:
              this.useNewAccountPage && !this.isPatient && !this.isReceptionist
                ? baseNameRoutes.accountMain
                : baseNameRoutes.profile,
            icon: 'mdi-account-circle',
            title: this.useNewAccountPage && !this.isPatient && !this.isReceptionist ? 'account' : 'profile',
            click: _ => null,
          },
          {
            icon: 'mdi-exit-to-app',
            href: '#',
            title: 'close_session',
            click: _e => {
              this.handlerLogout();
            },
          }
        );
      }, 500);
    },

    listNotifications() {
      this.$http
        .get(getNotificacionesUrl, { headers: getHeader() })
        .then(response => {
          this.notificationsAmount = response.data.total;
          this.prescriptionsPendingToEmit = response.data.prescriptions_pending;
          this.notificationsTotal = this.hasPrescriptionsList()
            ? this.notificationsAmount + this.prescriptionsPendingToEmit
            : this.notificationsAmount;

          this.notifications = [];

          if (response.data.mensajeria > 0) {
            const text =
              response.data.mensajeria > 1
                ? this.$tc('notificaciones.mensajeria', response.data.mensajeria, { count: response.data.mensajeria })
                : this.$tc('notificaciones.mensajeria', 1);
            this.notifications.push({
              title: text,
              color: 'pink darken-2',
              icon: 'mdi-email',
              path: '/Mensajeria',
            });
          }

          if (response.data.video_confirm > 0) {
            const text =
              response.data.video_confirm > 1
                ? this.$tc('notificaciones.video_confirm', response.data.video_confirm, {
                    count: response.data.video_confirm,
                  })
                : this.$tc('notificaciones.video_confirm', 1);
            this.notifications.push({
              title: text,
              color: 'primary',
              icon: 'mdi-video',
              path: '/Videoconsulta',
            });
          }

          if (response.data.video_hoy > 0) {
            const text =
              response.data.video_hoy > 1
                ? this.$tc('notificaciones.video_hoy', response.data.video_hoy, { count: response.data.video_hoy })
                : this.$tc('notificaciones.video_hoy', 1);
            this.notifications.push({
              title: text,
              color: 'light-blue lighten-2',
              icon: 'mdi-calendar-today',
              path: '/Videoconsulta',
            });
          }
          this.prescriptionsToNotifications();
          this.handleShowConditionsDialog(response.data.mostrar_condiciones);

          if (JSON.parse(window.localStorage.getItem('auth_ehealth'))['trial'] && response.data.estado_usuario === 1) {
            const auth_user = JSON.parse(window.localStorage.getItem('auth_ehealth'));
            auth_user.trial = false;
            window.localStorage.setItem('auth_ehealth', JSON.stringify(auth_user));
          }
          this.img_avatar = JSON.parse(window.localStorage.getItem('auth_ehealth'))
            ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['img']
            : '';
        })
        .catch(e => {
          console.info(`User is not logged in, an error occurred while retrieving notifications data`);
          localStorage.removeItem('auth_ehealth');
          window.getApp.$emit('SESSION_EXPIRED');
        });
    },

    loadHelpHeroTour() {
      if (!this.appHelpHero?.enabled || !this.authUser || this.conditionsModalIsVisible) {
        return;
      }

      initializeHelpHero({ config: this.appHelpHero, user: this.authUser });
    },

    handleDrawerToggle() {
      window.getApp.$emit('APP_DRAWER_TOGGLED');
    },

    handleClick(e) {
      e.preventDefault();
    },

    usaBuscadorPaciente() {
      this.pacientes = [];
      if (this.buscadorPaciente.length > 2) {
        this.pacientes.push({ buscando: true });
        const me = document.getElementById('listadoBuscador');
        const padre = me.parentElement;
        padre.style.display = 'block';
        this.$http
          .post(getListadoPacientesUrl, { filtro: this.buscadorPaciente }, { headers: getHeader() })
          .then(response => {
            this.pacientes = response.data;
          });
      }
    },

    setDialogPrescriptionsPendingToEmit(beforeUnload) {
      this.dialog.show = true;
      this.dialog.title = beforeUnload ? this.$t('recetas.exit_without_emit') : this.$t('menu.close_session');
      this.dialog.text = this.$tc('recetas.logout_with_pending_to_emit', this.prescriptionsPendingToEmit);
      this.dialog.type = beforeUnload ? 'beforeUnload' : 'logout';
      if (beforeUnload) {
        this.dialog.action = performance.navigation.type === 1 ? 'reload' : 'close';
      }
    },

    closeDialog() {
      this.dialog.show = false;
      if (this.dialog.type === 'logout') {
        this.logout();
      }
    },

    handlerLogout() {
      if (this.hasPrescriptionsList() && this.prescriptionsPendingToEmit > 0) {
        this.setDialogPrescriptionsPendingToEmit(false);
        return false;
      }
      this.logout();
    },

    logout() {
      const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));
      if (authUser?.isHelpScoutInitialized) {
        resetHelpScout(authUser);
      }

      typeof window.Intercom === 'function' && window.Intercom('shutdown');

      this.$http.post(logoutUrl, { user_id: this.authUser.id }, { headers: getHeader() }).finally(() => {
        localStorage.removeItem('auth_ehealth');
        localStorage.removeItem('features');
        if (environment.isKeycloakIntegration) {
          this.$keycloak.logoutFn();
        }
        window.getApp.$emit('APP_LOGOUT');
        this.loadConfigFromBrand();
        this.closeAccountSession();
      });
    },

    openUserDetail() {
      this.$router.push('/buscadopaciente/' + this.searchModel);
      this.buscadorPaciente = '';
    },

    emitPrescriptions() {
      this.dialog.show = false;
      if (this.$route.name !== 'prescriptionsFilteredList') {
        this.$router.push('/prescriptions');
      }
    },

    handleShowConditionsDialog(value) {
      if (!value) {
        return;
      }

      this.handleConditionsNeedsToBeAccepted(this.show_conditions);
      this.handleConditionsModal(this.show_conditions);
    },

    subscribeToChannelOfNotifications() {
      if (
        !this.hasPrescriptionsList() ||
        this.authUser.user.rol !== 2 ||
        typeof environment.publish_key_pusher === 'undefined'
      ) {
        return;
      }
      window.addEventListener('beforeunload', this.handlerBeforeUnload);

      const channelName = environment.name + '-professional-' + this.authUser.user.professional_id;
      const channel = this.$pusher.subscribe(channelName);
      const subscribers =
        null !== window.localStorage.getItem('channelNotifications')
          ? JSON.parse(window.localStorage.getItem('channelNotifications'))['subscribers'] + 1
          : 1;

      window.localStorage.setItem(
        'channelNotifications',
        JSON.stringify({
          name: channelName,
          subscribers: subscribers,
        })
      );

      channel.bind(
        'prescription_pending',
        function(data) {
          this.prescriptionsPendingToEmit = JSON.parse(data)['total'];
          this.notificationsTotal = this.hasPrescriptionsList()
            ? this.notificationsAmount + this.prescriptionsPendingToEmit
            : this.notificationsAmount;
          this.prescriptionsToNotifications();
          this.showAlert({
            color: 'info',
            icon: 'mdi-text-box-plus',
            message: this.$tc('notificaciones.prescriptions', this.prescriptionsPendingToEmit),
          });
        }.bind(this)
      );

      channel.bind(
        'prescription_batch_ended',
        function() {
          this.listNotifications();
          this.prescriptionsToNotifications();
          this.showAlert({
            color: 'success',
            icon: 'mdi-text-box-plus',
            message: this.$t('recetas.prescription_batch_ended'),
          });
        }.bind(this)
      );

      channel.bind(
        'prescription_failed',
        function() {
          this.showAlert({
            color: 'error',
            icon: 'mdi-alert-circle',
            message: this.$t('recetas.prescription_failed'),
          });
        }.bind(this)
      );
    },

    handlerBeforeUnload() {
      this.unsubscribeBeforeToLeave();
    },

    unsubscribeBeforeToLeave() {
      if (null !== window.localStorage.getItem('channelNotifications')) {
        this.$pusher.unsubscribe(JSON.parse(window.localStorage.getItem('channelNotifications'))['name']);
        if (JSON.parse(window.localStorage.getItem('channelNotifications'))['subscribers'] === 1) {
          window.localStorage.removeItem('channelNotifications');
        } else {
          const channelNotifications = JSON.parse(window.localStorage.getItem('channelNotifications'));
          window.localStorage.setItem(
            'channelNotifications',
            JSON.stringify({
              name: channelNotifications.name,
              subscribers: channelNotifications.subscribers - 1,
            })
          );
        }
      }
    },

    prescriptionsToNotifications() {
      if (this.hasPrescriptionsList()) {
        const index =
          this.notifications.length > 0
            ? this.notifications.findIndex(element => element.icon === 'mdi-text-box-plus')
            : -1;
        if (this.prescriptionsPendingToEmit > 0) {
          const item = {
            title: this.$tc('notificaciones.prescriptions', this.prescriptionsPendingToEmit),
            color: 'primary',
            icon: 'mdi-text-box-plus',
            path: '/prescriptions',
          };
          if (index > -1) {
            if (this.prescriptionsPendingToEmit > 0) {
              this.notifications[index] = item;
            } else {
              this.notifications.splice(index, 1);
            }
          } else {
            this.notifications.push(item);
          }
        } else if (index > -1) {
          this.notifications.splice(index, 1);
        }
      }
    },

    hasPrescriptionsList() {
      return this.permissions.prescriptionsList ?? false;
    },
  },
};
</script>
<style lang="css">
.link-toolbar {
  flex-basis: 176px;
}

.img-toolbar {
  object-fit: contain;
  vertical-align: middle;
  max-height: 48px;
  max-width: 176px;
  width: auto;
  height: auto;
}
.search_pacientes {
  background: rgba(255, 255, 255, 0.95) !important;
}
.buscador_pacientes .v-input__prepend-outer .v-input__icon .v-icon {
  color: #2373d9 !important;
}
.buscador_pacientes .v-input__control .v-input__slot .v-text-field__slot label {
  color: #2373d9 !important;
}

.buscador_pacientes .v-input__control .v-input__slot .v-text-field__slot input {
  color: #0a2953 !important;
}

.ICOnnecta_buscador_pacientes .v-input__prepend-outer .v-input__icon .v-icon {
  color: #da2b49 !important;
}
.ICOnnecta_buscador_pacientes .v-input__control .v-input__slot .v-text-field__slot label {
  color: #da2b49 !important;
}

.ICOnnecta_buscador_pacientes .v-input__control .v-input__slot .v-text-field__slot input {
  color: #da2b49 !important;
}

.iconnecta_toolbar {
  background-color: #e8d0d5 !important;
  border-color: #e8d0d5 !important;
}

.iconnecta_menu {
  background-color: #ddacb0 !important;
  border-color: #ddacb0 !important;
}

.iconnecta {
  background-color: #dd868a !important;
  border-color: #dd868a !important;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

.estado_conectado {
  background: #8279792e;
}

.estado_conectado .v-input--switch label {
  color: #1d1d1d !important;
  padding-right: 4px;
  margin-left: -4px;
}

.estado_conectado .v-input--is-label-active label {
  color: green !important;
}

#menu-layout .v-navigation-drawer__content {
  overflow-y: hidden;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
