export default {
  card_number: 'Número de tarjeta',
  or: 'or',

  account: {
    chooseImage: 'Elegir imagen',
    chooseImageText: 'Pincha y arrastra para encuadrar. Usa el trackpad o la rueda del ratón para hacer zoom.',
    cancel: 'Cancelar',
    continue: 'Continuar',
    noImage: 'No has elegido una imagen',
    fileNotAllowed: 'No se pudo cargar el archivo. Este tipo de archivo no es compatible.',
    fileTooBig: 'Archivo demasiado grande. {fileSize} máx.',
    uploadError: 'Ha ocurrido un error, inténtalo de nuevo.',
    idPassport: 'DNI/Pasaporte',
    biography: 'Biografía',
    editBio: 'Edita tu bio en {lang}',
    successEdit: 'Tu perfil se ha modificado correctamente',
    security: 'Seguridad',
    passwordHint: 'Una contraseña segura evitará que pueda ser descifrada facilmente',
    change: 'Cambiar',
    marketplaceName: 'Nombre Marketplace',
  },
  agenda: {
    hoy: 'Hoy',
    lunes: 'Lunes',
    martes: 'Martes',
    miercoles: 'Miércoles',
    jueves: 'Jueves',
    viernes: 'Viernes',
    sabado: 'Sábado',
    domingo: 'Domingo',
    lunes_redu: 'Lun',
    martes_redu: 'Mar',
    miercoles_redu: 'Mié',
    jueves_redu: 'Jue',
    viernes_redu: 'Vie',
    sabado_redu: 'Sáb',
    domingo_redu: 'Dom',
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
    horainicio: 'Hora de Inicio',
    horafin: 'Hora de Fin',
    configuracion: 'Configuración de la Agenda',
    disponibilidad: 'Calendario de Disponibilidad',
    nodisponible: 'Días no Disponibles',
    sin_disponibles: 'No ha configurado ningún dia como no disponible para los próximos días',
    nuevo_nodisponible: 'Crear No Disponible',
    editar_nodisponible: 'Editar No Disponible',
    diacompleto: 'Día Completo',
    delete_nodisponible: 'Eliminar Día No Disponible',
    confirm_delete_nodisponible: 'El día pasará a estar disponible. ¿Desea continuar?',
    nuevo_rango: 'Nuevo Rango',
    hora_inicio_dia: 'Hay un error en el rango de horas del {dia}',
    hora_inicio_debe_ser: 'La Hora de Inicio debe ser menor a la Hora de Fin',
    sin_horas_asignadas: 'Actualmente no tiene asignada ninguna agenda',
  },
  appointments: {
    name: 'Videoconsulta | Videoconsultas',
    not_have_pending: 'No dispone de videoconsultas pendientes',
    error: 'Ha habido un problema al cargar las videoconsultas pendientes',
    onlineBookingNotAvailable: 'Servicio de citas no disponible',
    dateRequired: 'Seleccione la hora que desea realizar la videoconsulta',
  },
  asignar: {
    titulo: 'Asignar consulta',
    texto_video: '¿Esta seguro que desea asignarse esta videoconsulta y por tanto se compromete a atender al paciente?',
    texto_mensaje: '¿Esta seguro que desea asignarse este mensaje y por tanto se compromete a atender al paciente?',
    asignada_correctamente: 'Se ha asignado la consulta correctamente',
  },
  beneficiaries: {
    no_beneficiaries: 'Aún no ha añadido personas a las que cuidas',
    relationship: 'Relación',
    new: 'Nuevo',
  },
  calendario: {
    especialidades: 'Calendario por Especialidad',
    professionals: 'Calendario de Profesionales',
    select_professional: 'Seleccione Profesional',
    nodisponible: 'No Disponible',
  },
  change_password: {
    change_password: 'Cambiar contraseña',
    current_password: 'Contraseña actual',
    new_password: 'Nueva contraseña',
    confirm_password: 'Repite la contraseña',
    at_least_num_characters: 'Al menos {num} caracteres',
    at_least_one_lowercase: 'Al menos una minúscula',
    at_least_one_uppercase: 'Al menos una mayúscula',
    at_least_one_number: 'Al menos un número',
    at_least_one_symbol: 'Al menos un símbolo',
    password_required_message: 'Debe completar la contraseña',
    password_not_valid_message: 'La contraseña no es válida',
    passwords_not_match: 'Las contraseñas no coinciden',
    change_password_success_message: 'La contraseña se ha modificado correctamente',
    unknown_error: 'Ha ocurrido un error',
  },
  chequeo: {
    delete: 'Eliminar Chequeo',
    confirm_delete: '¿Está seguro que desea eliminar el servicio de chequeo seleccionado?',
  },
  common: {
    search: 'Search',
    aceptar: 'Aceptar',
    condiciones: 'CONDICIONES GENERALES DE USO',
    cancel: 'Cancel',
    ok: 'OK',
    confirm: 'Confirmar',
    save: 'Guardar',
    name: 'Nom',
    category: 'Category',
    lang: 'Idioma',
    lang_platform: 'Idioma Plataforma',
    lang_estracto: 'Idioma del Extracto',
    prescription: 'Receta | Recetas',
    documents: 'Documents',
    chequeos: 'Chequeos',
    messaging: 'Mensajería',
    videoconference: 'Videoconsulta',
    videoconferences: 'Videoconsultas',
    presential: 'Presencial',
    pack: 'Pack',
    cita: 'Cita',
    country: 'País',
    province: 'Provincia',
    location: 'Localidad',
    cp: 'Código Postal',
    address: 'Dirección',
    spanish: 'Espanyol',
    english: 'Anglès',
    catalan: 'Català',
    portugues: 'Portuguès',
    polaco: 'Polonès',
    date: 'Fecha',
    type: 'Tipo',
    size: 'Tamaño',
    actions: 'Accions',
    date_up: 'Fecha Alta',
    of: 'de',
    changes: 'Cambios',
    closed_session: 'Sesión Cerrada Correctamente',
    closed_session_expired: 'Por motivos de seguridad la sesión ha sido cerrada',
    volver_atras: 'Volver Atras',
    acceso_denegado: 'Lo siento, acceso denegado.',
    emitir_receta: 'Emitir Receta',
    modify_recipe: 'Modificar receta',
    delete_recipe: 'Eliminar receta',
    delete_recipe_confirm: '¿Seguro que deseas eliminar la receta seleccionada?',
    emitSignPrescription: 'Firmar y emitir receta',
    alreadyDiscardedPrescription:
      'Ha creado una nueva receta dentro de un plan de tratamiento que ya ha expirado. Por favor, cree un nuevo tratamiento e incluya la nueva receta.',
    descripcion: 'Descripción',
    obligatorio: 'Campo Obligatorio',
    min_required: 'El campo debe tener al menos {tam} caracteres',
    paciente: 'Paciente',
    cualquiera: 'Cualquiera',
    activado: 'Activado',
    desactivado: 'Desactivado',
    gratuita: 'Gratuita',
    gratuito: 'Gratuito',
    precio: 'Precio',
    duracion: 'Duración',
    detalles: 'Detalles',
    hora: 'Hora',
    edit: 'Editar',
    delete: 'Eliminar',
    emitir: 'Emetre',
    no_registros: 'No hay registros',
    status: 'Estat',
    estado_servicio: 'Estado del Servicio',
    no_access: 'No dispone de permisos suficientes para acceder a esta sección',
    prox_consultas: 'Properes Consulte | Properes Consultes',
    consultas_personales: 'Consultes Personals',
    ult_mensajes: 'Darrer Missatge',
    mes_redu: 'Ene | Feb | Mar | Abr | May | Jun | Jul | Ago | Sep | Oct | Nov | Dic',
    enviar_enlace: 'Enviar Enlace',
    version_trial: 'Esta funcionalidad no se encuentra activa durante la versión de prueba',
    titulo_salir_sin_guardar: 'Salir sin Guardar',
    salir_sin_guardar:
      'Ha realizado cambios en la ficha y no han sido guardados. ¿Está seguro que desea salir sin guardar?',
    fecha_inicio: 'Fecha de Inicio',
    fecha_fin: 'Fecha de Fin',
    landing: 'Personalizar Landing Page',
    backgroundcolor: 'Color de Fondo',
    nuevo_servicio: 'Nuevo Servicio',
    otro: 'Otro',
    continuar: 'Continuar',
    filtrar: 'Filtrar',
    sin_atender: 'Consultas Sin Atender',
    medicos_disponibles: 'Médicos Disponibles',
    semana: 'Week',
    movil: 'móvil',
    celular: 'celular',
    reconocimiento: 'Reconeixements mèdic',
    apto: 'Apte',
    no_apto: 'No apte',
    sin_verificar: 'Unverified',
    aviso_legal: 'Legal Notice',
    term_and_conditions: 'Terminos y Condiciones',
    term_and_conditions_signup: 'Terminos y Condiciones (Registro)',
    term_and_conditions_without_tax: 'Terminos sin impuestos',
    politica_privacidad: 'Privacy Policy',
    politica_cookies: 'Cookies',
    aboutus: 'Acerca de',
    title: 'Título',
    access: 'Accés',
    inactive: 'Inactiu',
    in_process: 'En procés',
    active: 'Actiu',
    last_login: 'Últim login',
    last_connection: 'Última connexió',
    no_last_login: "Encara no s'ha logat",
    no_last_access: 'Encara no ha accedit al dashboard',
    verification_state: 'Estat verificació',
    verified: 'Verificat',
    configuracion: 'Configuración',
    chequeo: 'Chequeo',
    professional: 'Profesional',
    numero_seg_social: 'Health Card Numbers',
    filters: 'Filters',
    yes: 'Yes',
    no: 'No',
    active_web: 'Web activa',
    inactive_web: 'Web desactivada',
    close: 'Cerrar',
    phone_number: 'Número de teléfono',
    availableRoles: 'Roles disponibles',
    undefined: 'Sin definir',
    admin: 'Administrador',
    receptionist: 'Recepcionista',
    rrhh: 'RRHH',
    patient: 'Paciente',
    man: 'Hombre',
    woman: 'Mujer',
    unspecified: 'Sin especificar',
    more: 'Más',
    taxPercent: 'Porcentaje Impuestos',
    taxType: 'Tipo de Impuestos',
    add: 'Añadir',
    fieldIncomplete: 'Debe rellenar el campo {field}',
    download_pdf: 'Download PDF',
    show_details: 'Show details',
    authorization: 'Autorización|Autorizaciones',
    go_to_profile: 'Ir al perfil',
    notice: 'Aviso',
    accepted_at: 'Accepted at',
    chat: 'Chat',
    participants: 'Participantes',
    consultation_subject: 'Motivo de consulta',
    short_date_format: 'DD/MM/YYYY',
    subscriptions_temporarily_disabled:
      'Lo sentimos, las suscripciones están deshabilitadas temporalmente. Por favor, inténtelo de nuevo dentro de unos días.',
    show_to_hiring: 'Visible for hiring on the web',
    hide_to_hiring: 'Not visible for hiring on the web',

    date_placeholder: 'dd/mm/aaaa',
    years: 'años',
    max_n_characters: 'Máximo {chars} caracteres',
    return_back: 'Volver',
  },
  cupones: {
    crear: 'Crear Cupón',
    codigo: 'Código',
    descuento: 'Descuento',
    caducidad: 'Caducidad',
    num_usos: 'Número de usos',
    sin_cupones: 'No tiene configurado ningún cupón',
    crear_nuevo: 'Crear Nuevo Cupón',
    editar: 'Editar el cupón',
    eliminar_titulo: 'Eliminar el cupón',
    eliminar_texto: 'El cupón dejará de poder ser utilizado, ¿Está seguro que desea eliminar el cupón?',
    min_code: 'El código debe tener al menos 6 caracteres',
    tam_code: 'El código debe tener {num} caracteres',
    porusos: 'Por Usos',
    porfecha: 'Por Fecha',
    nocaduca: 'No caduca',
    error_crear_existe_codigo: 'No se ha creado el cupón. Ya existe un cupón con el código',
    error_editar_existe_codigo: 'No se ha editado el cupón. Ya existe un cupón con el código',
    success_create: 'El cupón se ha creado correctamente',
    caduca_por_usos: 'Este cupón caduca por usos.',
    sin_caducidad: 'Cupón sin caducidad.',
    caduca_por_fecha: 'Este cupón caduca el ',
    usarlo_cualquiera: 'Puede ser utilizado por cualquier paciente.',
    usarlo_paciente: 'Solo puede utilizarlo ',
    form_codigo: 'Indique el código que utilizará el paciente para aplicar el cupón',
    form_descuento: 'Indique el descuento que tendrá el paciente al aplicar el cupón',
    form_nusos: 'Indique el número de veces que podrá ser utilizado el cupón',
    form_fecha: 'Seleccione la fecha de caducidad del cupón',
    confirmGenericMessage: '¿Estás seguro? Esta acción no se puede deshacer',
    and: 'y',
    go_home: 'Volver al inicio',
    image_text: 'Imagen en color (menor a 8,000px por 8,000px), en formato JPG, y de menos de 5MB.',
    dni_front: 'SUBIR DOCUMENTO DE IDENTIDAD (Anverso)',
    dni_back: 'SUBIR DOCUMENTO DE IDENTIDAD (Reverso)',
    price_v_standard: 'Precio V. Estándar',
    range_of_hours: 'Rango de horas',
    registration_status: 'Estado del AltaTest',
  },
  datatable: {
    rows_per_page: 'Registros por Página',
    all: 'Todos',
  },
  documentos: {
    nuevo: 'Nuevo Documento',
    confirmar_subida_titulo: 'Subir Documento',
    confirmar_subida_texto: '¿Está seguro que desea subir el documento seleccionado?',
    subido_correctamente: 'El documento se ha subido correctamente',
    csv_uploaded:
      'El documento se ha subido correctamente. Este proceso puede llevar un tiempo. Si pasada media hora no se han actualizado los datos correctamente, consulte con soporte',
    subir_reconocimiento: 'Si us plau pugi un certificat de el reconeixement mèdic',
    subir_documento: 'Please upload a document that validates this decision.',
    subir_certificado: 'Subir certificado',
    subir_renuncia: 'Subir renuncia',
  },
  documents: {
    upload_document: 'Subir documento',
    no_documents: 'No dispone de documentos',
    drag_and_drop: 'Arrastra y suelta tu archivo aquí',
    browse_file: 'buscar archivo',
    supported_files: 'Archivos soportados',
    size_limited: 'Máximo {num} MB',
    upload_new: 'Subir nuevo',
    select_professional: 'Seleccione al profesional sanitario que desea habilitarle el acceso al documento',
    are_you_sure_upload: '¿Estás seguro que desea subir el nuevo documento?',
    video: 'Video',
    chat: 'Mensajería',
    prescription: 'Receta',
    triage: 'Triaje',
    diagnosis: 'Diagnóstico',
    medical_report: 'Historia Clínica',
    other: 'Documento',
    electronic_prescription: 'Receta Electrónica',
  },
  errors: {
    not_access_patient: 'Ha ocurrido un error intentando acceder a la ficha del paciente',
    edit_patient: 'Ha ocurrido un error al editar al paciente',
    edit_user: 'Ha ocurrido un error al editar al usuario',
    existing_patient: 'El paciente ya se encuentra registrado en {name}',
    form_invalid: 'Por favor rellene todos los campos obligatorios',
    form_empty: 'El formulario esta vacio, complete al menos un campo',
    email_required: 'El Correo Electrónico es Obligatorio',
    email_or_phone_required: 'El correo electrónico/teléfono es obligatorio',
    password_required: 'La contraseña es Obligatoria',
    usr_pw_incorrect: 'Usuario o contraseña incorrectos',
    email_invalid: 'Correo electronico invalido',
    email_repeat: 'Los Correos electronicos no coinciden',
    try_again: 'Ha ocurrido un error al realizar la operación, por favor intentelo de nuevo',
    save_history: 'Ha ocurrido un error guardando el historial clínico',
    professional_already_exists: 'Ya hay un usuario registrado con el correo electrónico o slug seleccionado',
    collegiate_number_already_exists:
      'Ya hay un usuario registrado con este Número de colegiado en la Empresa seleccionada',
    existing_user: 'Ya hay un usuario registrado con el e-mail o nombre de usuario seleccionado',
    existing_user_in_company:
      'Ya existe un usuario con ese correo electrónico y esa empresa. Por favor, elija otra dirección de correo electrónico o modifique la empresa a la que quiere asociar este profesional',
    existing_user_without_company:
      'Ya existe un profesional no asociado a ninguna empresa y con el mismo correo electrónico. Por favor, elija otra dirección de correo electrónico',
    horaFinMenorHoraInicio: 'La hora de fin no puede ser inferior a la hora de inicio',
    usr_dado_baja: 'El usuario no se encuentra activado en este momento',
    must_be_at_least_13_years: 'Debe tener al menos 13 años de edad',
    trial_caducado:
      'El periodo de prueba ha caducado, si desea ampliarlo pongase en contacto con nosotros a través de {name}',
    email_no_exist: 'El correo electronico no se encuentra registrado en la {name} como profesional',
    mayor_cero: 'La cantidad debe ser mayor a 0 | El valor debe ser mayor o igual a 0',
    user_exist: 'El correo ya se encuentra registrado en la aplicación',
    file_max_upload: 'El archivo supera el tamaño máximo permitido que es de {tam}MB',
    input_tam_maximo_superado: 'El campo {input} supera el tamaño máximo permitido ({tam} caracteres)',
    factura_no_disponible:
      'No es posible descargar esta factura en estos momentos. Contacte con soporte para solucionar la incidencia',
    date_required: 'La fecha de nacimiento es obligatoria',
    phone_video_error:
      "You cannot select more than one patient to create an appointment from the patient's phone number",
    max_patients_multi: 'You cannot select more than three patients for a multiconsultation',
    phone_empty: 'You must enter the phone number',
    incorrect_hour: 'The time format is not correct. Example: 10:30',
    incorrect_date: 'El formato de la fecha no es correcto',
    date_must_be_greater_than_now: 'No es posible seleccionar una fecha y hora anterior a la actual',
    incorrect_colegiado: 'El número de colegiado debe tener entre 8 y 9 caracteres',
    incorrect_collegiate_psychologist: 'Número de colegiado de psicología incorrecto',
    emit_e_prescription:
      'Se ha producido un error a la hora de Emitir la receta. Por favor, informe del problema y procederemos a resolverlo a la mayor brevedad',
    incorrect_colegiado_emit:
      'Su número de colegiado no es correcto, por favor, dirijase a su perfil y actualice el número de colegiado',
    incorrect_collegiate_number: 'El número de colegiado debe ser correcto para poder emitir la receta',
    invalid_collegiate_number:
      'Tu número de colegiado es incorrecto. Para corregirlo y validarlo, por favor, contacta directamente con nuestro equipo en el email: {email}. *Recuerda que el número de colegiado está compuesto por 8 o 9 dígitos.',
    invalid_patient_document:
      'El documento de identidad del paciente no es válido, por favor, acceda a su perfil, actualice su documento de identidad y vuelva a intentarlo',
    freemium_no_multi:
      'With the Freemium version, you cannot create multiconsultation. Contact your sales representative to upgrade to the Premium version.',
    gender_required: 'El género es obligatorio',
    relation_required: 'La relación es obligatoria',
    type_prof: 'Debe seleccionar un tipo de profesional',
    must_be_adult: 'El usuario debe ser mayor de edad',
    specialty_required: 'Es obligatorio seleccionar al menos una especialidad',
    patient_has_no_email_message:
      'El paciente no tiene correo electrónico. Por favor, edita sus datos e introduce un email válido para poder realizar la emisión de la receta electrónica.',
    not_found_title: 'Esta página no está disponible',
    not_found_message:
      'Es posible que el enlace esté roto o que se haya eliminado la página. Comprueba que el enlace que quieres abrir es correcto.',
    server_down: 'Lo siento, el servidor no funciona.',
  },
  facturacion: {
    concepto: 'Concepte',
    importe: 'Import',
    pagosrecibidos: 'Pagos Recibidos',
    without_payments: 'No ha recibido ningún pago en el rango de fechas seleccionado',
    puede_tardar: 'Tenga paciencia mientras se obtienen los pagos recibidos de la pasarela de Pago',
    rango_fechas: 'El rango máximo entre las fechas no puede superar los {num} días',
    info_sobre_facturas:
      'No se mostrarán las facturas de los pagos que se hayan realizado en los últimos dos días, ya que la pasarela de pago requiere este tiempo para realizar la transacción',
    aceptar_transaccion: 'Accept Transaction',
    aceptar_transaccion_descrip: 'Are you sure you want to confirm the selected transaction?',
    cancelar_transaccion: 'Cancel transaction',
    cancelar_transaccion_descrip: 'Are you sure you want to cancel the selected transaction?',
    select_professional: 'Selecciona un profesional',
  },
  hiring: {
    service: 'Contratar Servicio',
    appointment: 'Contratar Videoconsulta',
    message: 'Contratar Mensajería',
    pack: 'Contratar Pack',
    subscription: 'Contratar Suscripción',
    select_receiver: '¿La consulta no es para usted? Seleccione a la persona para la que va destinada el servicio',
    reason_of_consultation: 'Escriba el motivo de su videoconsulta',
    consultation_details: 'Detalles de su consulta',
    professional_will_confirm:
      'El profesional aprobará su cita propuesta, o le propondrá una alternativa lo más inmediata posible',
    professional_will_approve_pack:
      'El profesional aprobará su pack y se pondrá en contacto con usted en los próximos días',
    have_promotional_code: '¿Dispone de un Código Promocinal?',
    card_payment: 'Pago con tarjeta',
    proceed_to_payment: 'Proceder al pago',
    discount_applied: 'Aplicado un {num}% de descuento',
    add_document:
      'Si lo desea puede enviarle algún documento al doctor. Piense que una fotografía, una analitica o cualquier otro tipo de informe médico o documento puede facilitar el diagnóstico por parte del profesional',
    document_added: 'Documento añadido al mensaje',
    note_to_professional: 'Si lo desea puede dejarle algún comentario al profesional',
    monthly_fee_will_charged:
      'A monthly fee will be charged for your active subscription. You can cancel this service form your account',
    send: 'Enviar',
    appointment_proposed_by_professional:
      'Esta es la cita propuesta por el profesional. En cuanto comprobemos el pago de la misma, te enviaremos por correo electrónico las instrucciones necesarias para realizar la videoconsulta.',
    you_have_period_to_confirm:
      'Dispones de un plazo de 2 días para poder confirmar esta cita. Pasado el plazo indicado, el profesional podrá reasignar esta cita a otra persona o cancelarla sin obligatoriedad alguna de dar aviso.',
    appreciate_your_understanding: 'Agradecemos tu comprensión.',
    free_with_your_subscription: 'Tiene una suscripción activa que le permite contratar el servicio de forma gratuita',
  },
  historia: {
    antecedentes_personales: 'Antecedentes Personales',
    enfermedades_infancia: 'Enfermedades en la infancia',
    enfermedades_adulta: 'Enfermedades en edad adulta',
    medicamentos: 'Medicamentos habituales/Crónico',
    intervenciones: 'Intervenciones Quirúrgicas',
    actividad_laboral: 'Actividad Laboral',
    antecedentes_familiares: 'Antecedentes Familiares ',
    alergias: 'Alergias - Intolerancias',
  },
  mensajeria: {
    nuevo_mensaje: 'Nuevo Mensaje',
    pendientes: 'Pendientes',
    abiertos: 'Abiertos',
    abierto: 'Abierto',
    cerrados: 'Cerrados',
    enviar: 'Enviar',
    para: 'Para',
    motivo: 'Motivo',
    subject: 'Asunto',
    mensaje: 'Mensaje',
    mensajes: 'Mensajes',
    select_user: 'Select destination',
    enviar_nuevo_mensaje: 'Enviar un nuevo mensaje',
    marcar_como_atendida: 'Marcar como atendido',
    sin_mensajes_pendientes: 'En este momento, no tiene mensajería pendiente de contestar',
    sin_mensajes_abiertos: 'En este momento, no tiene mensajería abierta a respuesta con ningún paciente',
    sin_mensajes_atendidos: 'Aún no ha marcado como atendido ningún servicio de mensajería',
    archivos_adjuntos: 'Archivos Adjuntos',
    attach_file: 'Attach file',
    mensaje_con_adjuntos: 'El mensaje se enviará con un archivo adjunto',
    add_texto_al_mensaje: 'Añade texto a la respuesta para poder enviar el mensaje',
    add_destinatario_al_mensaje: 'Indique la persona que desea que reciba el mensaje',
    enviado_correctamente: 'Mensaje enviado correctamente',
    enviado_correctamente_error_adjunto:
      'Mensaje enviado correctamente, pero no se ha podido adjuntar el archivo, revise que no sobrepasa el tamaño maximo permitido y que se trata de una imagen o un pdf',
    error_enviando: 'El mensaje no se ha podido enviar. Intentelo de nuevo más tarde',
    atendido_correctamente: 'El mensaje ha sido marcado como atendido correctamente',
    error_atendiendo: 'Error al marcar como atendido el mensaje, intentelo de nuevo más tarde',
    confirm_atendida:
      'El servicio de mensajería va a ser marcado como atendido. Si el paciente desea volver a contactar deberá solicitar un nuevo servicio de mensajería. ¿Esta seguro que desea cerrar este servicio de mensajería?',
    sin_contestar: 'SIN CONTESTAR',
    metadata_list_text: 'Otros datos',
    answered: 'CONTESTADO',
    sent: 'ENVIADO',
    sin_cerrar: 'ABIERTO',
    ver_file: 'Mostrar Documento',
    delete_file: 'Eliminar Documento',
    confirmar_delete_file:
      'Si elimina el documento el paciente no podrá acceder a él. ¿Esta seguro que desea eliminar el documento?',
    modificar_name_file: 'Modificar Nombre del Documento',
    nombre_file: 'Nombre del Documento',
    atendido: 'Atendido',
    enviar_mensaje: 'Enviar missatge',
    cerrado: 'Tancat',
    unread_messages: 'Unread messages',
    not_exists_thread: 'There is not a thread with an identifier {id}',
    downloaded_on: 'Descargado el día',
    pdf_footer_link: 'Este PDF ha sido generado a través del chat de ',
    see_chat: 'Ver chat',
    loading_chat: 'Cargando chat...',
  },
  menu: {
    home: 'Inicio',
    patients: 'Pacientes',
    messaging: 'Mensajería',
    videocam: 'Videoconsultas',
    reports: 'Reportes',
    productos: 'Productos',
    calendario: 'Calendar',
    chat: 'Chat',
    inbox: 'Inbox',
    settings: 'Ajustes',
    help: 'Ayuda',
    profile: 'Perfil',
    close_session: 'Cerrar Sesión',
    agenda: 'Agenda',
    cupones: 'Cupones',
    talonario: 'Talonario de Recetas',
    firma: 'Firma',
    users: 'Usuarios',
    medicos: 'Doctors',
    metrics: 'Metricas',
    packs: 'Packs',
    packcontratados: 'Packs Contratados',
    sesion: 'Sesión',
    contact: 'Contacto',
    empresas: 'Empresas',
    tarifas: 'Servicios',
    servicios: 'Servicios',
    miweb: 'Mi Web',
    facturacion: 'Facturación',
    recepcionista: 'Recepcionista',
    resenas: 'Reseñas',
    dispositivos: 'Dispositivos',
    citas: 'Appointments',
    transacciones: 'Transactions',
    valorespersonalizados: 'Valores Personalizados',
    prescriptionsFilteredList: 'Recetas Pendientes',
    myMessages: 'Mensajes',
    myVideoconsultation: 'Videoconsultas',
    myPrescriptions: 'Recetas',
    mySubscriptions: 'Suscripciones',
    myDocuments: 'Documentos',
    beneficiaries: 'Usuarios asociados',
    medicalTests: 'Pruebas',
    medicalTestTypes: 'Tipos de prueba',
    medicalTestNomenclator: 'Nomenclátor',
  },
  message: {
    password: 'Contraseña',
    modify_password: 'Modificar Contraseña',
    email: 'Correo Electrónico',
    repeat_email: 'Repetir Correo electrónico',
    intro_email: 'Introduzca su Correo Electrónico',
    intro_pw: 'Introduzca su Contraseña',
    intro_security:
      'Por motivos de seguridad, para poder cambiar el correo electrónico es necesario que introduzca la contraseña',
    email_repeat: 'Repita Correo Electrónico',
    remember: 'Recordar Contraseña',
    login: 'Acceder',
    notifications: 'Notificaciones',
    showall: 'Mostrar Todas',
    reset_texto:
      'Introduzca su dirección de correo electrónico. Le enviaremos un correo electrónico con un enlace para restablecer su contraseña.',
    reset_password: 'Restablezca su contraseña',
    introduzca_password: 'Para activar su cuenta introduzca su contraseña de acceso',
    restaurar_password: 'Restablecer Contraseña',
    password_weak: 'Contraseña debil',
    password_not_secure: 'Contraseña poco segura',
    password_secure: 'Contraseña segura',
    welcome: 'Bienvenido a {name}',
    new_here: '¿Eres nuevo? Comience a usar nuestros servicios de Salud Digital',
    signup: 'Regístrate',
    start: 'Empezar',
    already_have_an_account: '¿Ya tienes una cuenta? Inicia sesión',
    estamos_encantados: 'Estamos encantados de tenerlo con nosotros. ',
    le_guiaremos:
      'A partir de ahora, le guiaremos a través de la plataforma para que rellene los datos necesarios y empiece a incorporar {name} en su día a día.',
    password_new: 'Nueva Contraseña',
    activar_cuenta: 'Activar Cuenta',
    check_credentials: 'Verificando Credenciales',
    cuenta_trial:
      'Su cuenta se encuentra en proceso de validación. La versión de prueba caducará mañana | Su cuenta se encuentra en proceso de validación. La versión de prueba caducará en {count} días',
    double_auth_title: 'Verificació en dos passos',
    double_auth_desc: 'Aquest pas addicional indica que sou vosaltres els que intenteu iniciar la sessió.',
    double_auth_msg_one: "S'ha acabat d'enviar un correu electrònic ",
    double_auth_msg_two: 'amb un codi de verificació de 6 dígits, introduïu-lo a continuació.',
    double_auth_email_again: 'No heu rebut el correu electrònic? Reenviar',
    double_auth_email_sended: "El codi s'ha reenviat",
    double_auth_error_code: 'El codi no és correcte, torna-ho a provar',
    double_auth_empty_code: "Heu d'omplir el codi",
    double_auth_escribe_codigo: 'Escriu el codi',
    double_auth_placeholder: 'Codi',
    select_an_account: 'Selecciona una cuenta',
    acceptConditions:
      'He leído y acepto la <a href="{privacyPolicyLink}" target="_blank">política de privacidad</a> y el <a href="{legalLink}" target="_blank">aviso legal</a>',
    acceptGeneralConditions:
      'He leído y acepto los <a href="{generalConditionsLink}" target="_blank">términos legales y las condiciones de uso de la plataforma</a>',
    acceptGeneralConditions_LOPDGDD:
      'He leído y acepto las <a href="{generalConditionsLink}" target="_blank">condiciones generales contractuales and its annex LOPDGDD</a>',
  },
  messages: {
    name: 'Mensajería Privada | Mensajerías',
    new: 'Nuevo',
    unsolved: 'Sin resolver',
    solved: 'Resueltos',
    all: 'Todos',
    write_your_message_here: 'Write your message here...',
    write_your_consultation: 'Escriba su consulta a {name}',
    not_have_pending: 'No dispones de mensajes',
    error: 'Ha habido un problema al cargar los últimos mensajes',
    reset_filter: 'Reset Filter',
    show_messages: 'Show Messages',
    message_closed: 'El mensaje esta cerrado',
    to_me: 'Para: mi',
    to: 'Para',
    me: 'mi',
  },
  notificaciones: {
    mensajeria: 'Tiene un mensaje pendiente | Tiene {count} mensajes pendientes',
    video_confirm: 'Tiene un videoconsulta por confirmar | Tiene {count} videoconsultas por confirmar',
    video_hoy: 'Tiene hoy: una videoconsulta | Tiene hoy: {count} videoconsultas',
    no_notis: 'En este momento no tiene notificaciones pendientes',
    prescriptions: 'Tienes una prescripción pendiente de emitir | Tienes {count} prescripciones pendientes de emitir',
  },
  packs: {
    name: 'Pack | Packs',
    crear: 'Crear Pack',
    packs_configurados: 'Packs Configurados',
    packs_contratados: 'Packs Contratados',
    num_sesiones: 'Núm Sesiones',
    editar: 'Editar el Pack',
    eliminar_titulo: 'Eliminar el pack',
    eliminar_texto:
      'El pack dejará de poder ser contratado por un paciente, los packs contratados de este pack no se verán afectados y podrán ser completados con total normalidad.',
    eliminar_confirmar: '¿Está seguro que desea eliminar el pack?',
    sin_packs: 'No tiene configurado ningún pack',
    completados: 'Completados',
    sin_pendientes: 'En este momento no tiene packs contratados pendientes',
    sin_completados: 'Aún no ha completado ningún pack contratado',
    fecha_contratado: 'Fecha Contratado',
    sesiones_totales: 'Sesiones Totales',
    sesiones_restantes: 'Sesiones Restantes',
    marcar_como_completado: 'Marcar como Completado',
    marcar_como_completado_texto:
      'El pack todavia tiene sesiones pendientes de ser atendidas, ¿Está seguro que desea completar el pack contratado?',
    success_complete: 'El pack se ha marcado como completado correctamente',
    sesion: 'Sesión',
    not_have_hired: 'No tiene ningun pack contratado',
    num_pending: '1 sesión pendiente | {num} sesiones pendientes',
  },
  patient: {
    patients: 'Pacientes',
    without_patients: 'Aún no tiene ningún paciente vinculado',
    search: 'Buscar Paciente',
    new: 'Nuevo Paciente',
    edit: 'Editar Paciente',
    delete: 'Eliminar Paciente',
    confirm_delete:
      'El paciente seleccionado dejará de recibir sus servicios. ¿Está seguro que desea eliminar su paciente?',
    personal_data_mayus: 'DATOS PERSONALES',
    personal_data: 'Datos Personales',
    history_clinical: 'Historial Clínico',
    history_clinic: 'Historia Clínica',
    first_name: 'Nombre',
    last_name: 'Apellidos',
    document_id: 'DNI, NIE o Pasaporte',
    cedula_or_passport: 'Cedula o Pasaporte',
    birthdate: 'Fecha de Nacimiento',
    phone: 'Teléfono',
    gender: 'Género',
    female: 'Femenino',
    male: 'Masculino',
    other: 'Otro',
    delete_success: 'El paciente se ha eliminado correctamente',
    delete_error: 'Ha ocurrido un error eliminando al paciente. Pruebe de nuevo más tarde',
    full_name_required: 'Es obligatorio rellenar el nombre y apellidos',
    too_long_name: 'El nombre no puede tener más de 50 caracteres',
    too_long_surname: 'El apellido no puede tener más de 50 caracteres',
    dni_required: 'El Documento de Identidad es obligatorio',
    type_dni_required: 'Es obligatorio indicar el tipo de Documento de Identidad',
    dni_invalid: 'El Documento de Identidad no es valido',
    dni_invalid_letra: 'DNI erroneo, la letra del NIF no se corresponde',
    birthdate_invalid: 'La fecha de nacimiento debe ser inferior a la fecha actual',
    observations: 'Observaciones',
    antecedentes_familiares: 'Antecedentes Familiares',
    antecedentes_maternos: 'Antecedentes Maternos',
    antecedentes_paternos: 'Antecedentes Paternos',
    antecedentes_hermanos: 'Antecedentes Hermanos',
    antecedentes_otros: 'Antecedentes otros familiares',
    antecedentes_personales: 'Antecedentes Personales',
    antecedentes_cirugias: 'Antecedentes Cirugías',
    alergias_medicamentos: 'Alergias Medicamentos',
    otras_alergias: 'Otras Alergias',
    enfermedades_cronicas: 'Enfermedades Crónicas',
    historial_psiquiatria: 'Historial de Psiquiatría',
    motivo_consulta: 'Motivo de la consulta',
    antecedentes_somaticos: 'Antecedentes Somáticos',
    antecedentes_psiquiatricos: 'Antecedentes Psiquiátricos',
    genograma: 'Genograma',
    psicobiografia: 'Psicobiografia',
    habitos_toxicos: 'Hábitos Tóxicos',
    anamnesis: 'Anamnesis',
    diagnosticos: 'Diagnósticos',
    evoluciones: 'Evoluciones',
    recomendaciones: 'Recomendaciones',
    nuevo_diagnostico: 'Nuevo Diagnostico',
    editar_diagnostico: 'Editar Diagnostico',
    sin_diagnosticos: 'Aún no se le ha realizado ningún diagnostico a este paciente',
    delete_diagnostico: 'Eliminar Diagnostico',
    confirm_delete_diagnostico: '¿Está seguro que desea eliminar el diagnostico seleccionado?',
    nueva_evolucion: 'Nueva Evolución',
    editar_evolucion: 'Editar Evolución',
    sin_evoluciones: 'Aún no se le ha realizado ninguna evolución a este paciente',
    delete_evolucion: 'Eliminar Evolución',
    confirm_delete_evolucion: '¿Está seguro que desea eliminar la evolución seleccionada?',
    sin_documentos: 'El paciente no dispone de documentos',
    sin_recetas: 'Aún no se le han creado recetas al paciente',
    generarinforme: 'Generar Informe',
    pais: 'Pais',
    evolucion: 'Evolución',
    exploracion: 'Exploración',
    juicioclinico: 'Juicio Clínico',
    tratamiento: 'Tratamiento | Tratamientos',
    num_historia: 'Núm. Historia',
    notas: 'Notas Internas (El paciente no podrá ver esta información ni aparecerá en el informe)',
    tipo_documento: 'Tipo Documento',
    pasaporte: 'Pasaporte',
    enviado_mensaje_vincular:
      'El paciente ya se encuentra registrado, se le ha enviado al paciente un email para que acepte el vinculo entre profesional sanitario y paciente',
    relacion: 'Relación con el tutor',
    datos_tutor: 'Datos del Tutor',
    cuidar_alguien: 'Añadir usuario',
    pacientes_tutelados: 'Usuarios asociados a su cuenta',
    preanestesia: 'Preanestesia',
    autorizado: 'Autorizado',
    no_autorizado: 'No Autorizado',
    peso: 'Weight',
    motivo_y_evolutivos: 'Motivo y Evolutivos',
    examenFisico: 'Examen Físico',
    resultadoLaboratorio: 'Resultados de laboratorio',
    pruebasImagen: 'Pruebas de imagen',
    evolutivo: 'Evolutivo',
    aceptar_cambios_apto: '¿Confirma que desea confirmar el cambio a Apte?',
    aceptar_cambios_noapto: '¿Confirma que desea confirmar el cambio a No apte?',
    revision_medica: 'Medical check | medical checkups',
    sin_common: 'El paciente no dispone de {name}',
    aceptar_cambios_rechazo: '¿Confirma que desea aceptar el rechazo?',
    aceptar_cambios_denegado: '¿Confirma que desea denegar el rechazo?',
    edit_date_revision: 'Editar fecha de la revisión',
    fecha_ultimo_aviso: 'Fecha último aviso',
    register_date: 'Fecha de alta',
    no_observations: 'No hay observaciones',
    allergy_management: 'Gestión Alergias',
    add_allergy: 'Añadir Alergia',
    no_allergies: 'El paciente no dispone de alergias registradas',
    new_allergy: 'Alta de alergia',
    allergy_to: 'Alergia a',
    addedAllergy: 'Alergia guardada',
    removedAllergy: 'Alergia borrada',
    searchAllergy: 'Buscar alergias',
    allergyEndDate: 'Sin fecha de fin definida',
    allergyStartDate: 'Sin fecha de inicio definida',
    allergyInfoBox: 'El paciente tiene registradas las siguientes alergias:',
    clinical_report_type: 'Tipo de informe',
    clinical_report_type_by_range: 'En un rango de fechas',
    clinical_report_type_specific_day: 'De un día concreto',
    clinical_report_type_full: 'Historia clínica completa',
  },
  prescriptions: {
    prescription: 'Receta',
    no_prescriptions: 'No dispone de recetas',
    medicine_duration_exceeded_error: 'La duración de este medicamento no puede ser superior a {num} meses.',
    narcotic_number_of_packages_exceeded_error:
      'Este medicamento no permite recetar una cantidad superior a {num} envases.',
    cant_mix_narcotics_with_others_error:
      'No se puede recetar un estupefaciente junto a otros medicamentos en la misma receta.',
    some_medicine_exceeds_max_duration_error:
      'Uno o más medicamentos exceden la duración máxima permitida. Por favor, revisa los datos.',
    long_duration_prescription_confirmation_notice:
      'La duración de al menos uno de los medicamentos prescritos es mayor de un mes. ¿Estás seguro de que deseas continuar?',
    maxPackagesWarning:
      'Actualmente el número máximo de envases a preescribir es {limit}. Se ha reajustado la duración del tratamiento al máximo número de días posible.',
    maxPackagesWarningNoAdjust: 'Actualmente el número máximo de envases a preescribir es {limit}.',
    maxPackagesErrorNoCovid: 'Ha superado el número máximo de envases permitido a preescribir: 99 unidades',
    maxPackagesErrorCovid: 'Ha superado el número máximo de envases permitido a preescribir: 9 unidades.',
    medicineAlreadyPrescribed: 'Tiene una prescripción en curso para ese medicamento que finaliza el {date}',
    limited: 'Máximo {num} {measure}',
    maxDaysForSameMedicineTitle: 'Has superado el tiempo máximo de prescripción.',
    maxDaysForSameMedicineText:
      'Este medicamento se eliminará de la receta. Las prescripciones de medicamentos y productos sanitarios no pueden tener una validez superior a 12 meses.',
    patientIndentityConfirmationText: 'Confirmo la identidad del paciente',
    patientsIndentityConfirmationText: 'Confirmo la identidad de los pacientes seleccionados',
    medicamentsIncluded: 'Contiene {numMedicaments} medicamentos',
    itemsSelected: ' elementos seleccionados',
    noActiveMedicines:
      'Esta receta contiene al menos un medicamento que ha dejado de estar activo. Por favor, emita una nueva receta.',
    deletePrescriptions: 'Eliminar recetas',
  },
  anestesia: {
    de_que_opera: '¿De qué se va a operar?',
    quien_opera: '¿Quién le va a operar?',
    edad: 'Edad',
    peso: 'Peso',
    talla: 'Talla',
    alergico_medicacion: '¿Es usted alérgico a alguna medicación/producto?',
    indique_a_que: 'Indique a qué en caso afirmativo',
    tension_alta: '¿Tiene la tensión arterial alta?',
    diabetico: '¿Es usted diabético (azúcar en sangre)?',
    fumador: '¿Es usted fumador?',
    enfemedad_actual: '¿Padece alguna enfermedad en la actualidad?',
    enfermedad_previa: '¿Ha padecido previamente alguna enfermedad?',
    medicacion: 'Si toma alguna medicación indique cuál.',
    operado_anterior: '¿Se ha operado usted anteriormente?',
    problema_anestesia: '¿Tuvo algún problema con la anestesia?',
    indique_si_ha_sufrido: 'INDIQUE SI HA SUFRIDO O SUFRE ALGUNAS DE LAS ENFERMEDADES QUE SE CITAN A CONTINUACIÓN:',
    corazon: 'Enfermedades del corazón (Angina de pecho, infartos, etc)',
    pulmon: 'Enfermedades del pulmón (bronquitis, asma, etc)',
    higado: 'Enfermedades del hígado',
    enfermedad_rinon: 'Enfermedades del riñón',
    comentarios: 'A continuación puede hacer algún comentario sobre cuestiones no preguntadas:',
    fecha_intevencion: 'Fecha de intervención',
    informe_preanestesia: 'Informe Preanestesia',
    checking_collegiate_number_1: 'Estamos validando tu número de colegiado',
    checking_collegiate_number_2: 'con el Colegio Oficial de Médicos. Por favor, espera un momento.',
  },
  recepcionista: {
    recepcionistas: 'Recepcionistas',
    recepcionista: 'Recepcionista',
    crear: 'Crear Recepcionista',
    editar: 'Editar Recepcionista',
    sin_recepcionista: 'Aún no has dado de alta a ningún recepcionista',
    permisos: 'Permisos',
    agenda: 'Agenda',
    servicios: 'Servicios',
    historia: 'Historia',
    talonario: 'Talonario',
    firma: 'Firma',
    facturacion: 'Facturación',
    eliminar_titulo: 'Eliminar al recepcionista',
    eliminar_texto: '¿Esta seguro que desea eliminar la cuenta de usuario del recepcionista?',
  },
  recetas: {
    nueva: 'Nueva Receta',
    ver: 'Ver',
    reemitir: 'Reemitir',
    num_receta: 'Nº de Receta',
    prescripcion: 'PRESCRIPCIÓN',
    prescripcion_detalle:
      'Consignar el medicamento: DCI o marca, forma farmaceutica, via de administración, dosis por unidad y unidades por envase. (Datos correspondientes en caso de producto sanitario).',
    num_envases: 'Núm. envases / unidades',
    buscar_medicamento: 'Buscar Medicamento',
    receta_medica_privada: 'RECETA MÉDICA PRIVADA',
    receta_medica_asistencia_sanitaria_privada: 'RECETA MÉDICA PARA ASISTENCIA SANITARIA PRIVADA',
    no_existe_medicamento: 'No existe ningún medicamento con el nombre indicado',
    unidades: 'Unidades',
    duracion: 'Duración del Tratamiento',
    posologia: 'Posología',
    pauta: 'Pauta',
    fecha_prevista_dispensacion: 'Fecha Prevista Dispensación',
    info_farmaceutico: 'Información al Farmaceutico, en su caso',
    instrucciones_paciente: 'INSTRUCCIONES AL PACIENTE (si procede)',
    paciente_detalle: '(Nombre, apellidos, año de nacimiento y nº de DNI/NIE/pasaporte)',
    prescriptor: 'PRESCRIPTOR',
    prescriptor_detalle: '(datos de identificación y firma)',
    vista_previa: 'VISTA PREVIA',
    required: {
      medicamento: 'Indique el medicamento que va a ser recetado',
      paciente: 'Es obligatorio seleccionar un paciente',
    },
    generar: 'Generar Receta',
    creada_correctamente: 'Receta Enviada',
    imprimida_correctamente:
      'La receta se abrirá en una pestaña nueva, en caso de no abrirse; observe en la barra del navegador que no este bloqueando las ventanas emergentes',
    imprimir: 'Imprimir',
    nombreaniodni: 'Nombre, apellidos, año de nacimiento y nº de DNI/NIE/pasaporte',
    aviso_pocas_recetas: 'AVISO: Solo le quedan disponibles {num} recetas',
    logout_with_pending_to_emit:
      'Actualmente tienes {count} recetas pendientes de emisión. ¿Está usted seguro de cerrar la sesión y dejar las recetas pendientes, o prefiere y firmar las recetas?',
    emit_prescriptions: 'Emitir recetas',
    exit_without_emit: 'Salir sin emitir',
    prescription_batch_ended:
      'El proceso por lotes ha finalizado y todas las recetas han sido emitidas y firmadas correctamente.',
    prescription_failed: 'El proceso por lotes ha fallado. Vuelva a intentarlo mas adelante.',
  },
  success: {
    new_patient: 'El paciente ha sido creado correctamente',
    patient_exist: 'El paciente ya se encuentra regirstrado',
    patient_exist_otro_mail: 'El paciente ya se encuentra regirstrado con otro email',
    new_user: 'El usuario ha sido creado correctamente',
    edit_patient: 'Se ha editado la información del paciente correctamente',
    edit_user: 'Se ha editado la información del usuario correctamente',
    save_new_diagnosis: 'Se ha creado un nuevo diagnostico correctamente',
    save_edit_diagnosis: 'Se ha editado el diagnostico correctamente',
    delete_diagnosis: 'Se ha eliminado el diagnostico correctamente',
    save_new_evolucion: 'Se ha creado una nueva evolución correctamente',
    save_edit_evolucion: 'Se ha editado la evolución correctamente',
    delete_evolucion: 'Se ha eliminado la evolución correctamente',
    save_history: 'Se ha guardado el historial clínico correctamente',
    save_user: 'Se ha guardado el usuario correctamente',
    save_new_coupon: 'Se ha creado un nuevo cupón correctamente',
    save_edit_coupon: 'Se ha editado el cupón correctamente',
    delete_coupon: 'Se ha eliminado el cupón correctamente',
    save_tipovideo: 'Se ha guardado el servicio de {name} correctamente',
    save_tipomensajeria: 'Se ha guardado el servicio de mensajería correctamente',
    delete_common: 'Se ha eliminado correctamente',
    save_common: 'Se ha guardado correctamente',
    save_agenda: 'La agenda se ha guardado correctamente',
    save_firma: 'La firma se ha guardado correctamente',
    send_password: 'Se le ha enviado un email para resetear su contraseña',
    password_changed: 'La contraseña ha sido cambiada correctamente',
    generado_informe: 'Se ha generado el informe correctamente',
    account_active: 'La cuenta ha sido activada correctamente',
    name_file_modificado: 'El nombre del documento se ha modificado correctamente',
    file_deleted: 'El documento ha sido eliminado correctamente',
    delete_treatment: 'Se ha eliminado el tratamiento correctamente',
    recipe_emmited: 'La receta se ha emitido correctamente, el paciente recibirá la receta {methods}',
    emit_method_email: 'por email',
    emit_method_sms: 'por SMS',
    email_sent: 'El email se ha enviado correctamente',
    serviceHiring: 'El servicio de {name} ha sido contratado correctamente',
  },
  talonario: {
    conf_talonario: 'Configuración de Talonario',
    subir_talonario: 'Subir Talonario...',
    eliminar_talonario: 'Eliminar Talonario',
    confirmar_eliminar_talonario: '¿Esta seguro que desea eliminar el talonario?',
    confirmar_subida_talonario:
      '¿Esta seguro que desea subir el talonario {msg} para usarlo cuando genere recetas a sus pacientes?',
    talonario_actuales: 'Talonarios actuales',
    instrucciones_talonario: 'Siga estas instrucciones para subir su talonario:',
    instrucc_talon_li:
      'Paso 1. Guarde previamente en su Pc el archivo de recetas en PDF que ha recibido vía e-mail o en soporte USB de su colegio. | Paso 2. Pinche "Subir Talonario..." y elija el PDF de sus recetas. | Paso 3. Guarde el archivo de sus recetas en su cuenta.',
    sin_talonarios: 'Aún no ha subido ningún talonario, no podrá enviar recetas hasta que no suba su talonario en PDF',
    sin_firma: 'No ha configurado su firma, la firma es obligatoria para emitir recetas',
    conf_firma: 'Configuración de la Firma',
    firma_actual: 'Firma Actual',
    explicacion_firma:
      'Dibuje su firma desde su smartphone o tablet (No se recomienda hacerlo desde el PC), se quedará guardada en su cuenta bajo usuario y contraseña y podrá acceder a ella desde todos sus dispositivos.',
    mandame_link: 'Mándame un link a mi {nombre}',
    rehacer_firma: 'Rehacer Firma',
    timeoutfirma:
      'Ha caducado el enlace para dibujar su firma. Recuerde que tiene 15 minutos desde que solicita modificar la firma',
    dibuje_firma:
      'Dibuje su firma con el dedo, se quedará guardada en su cuenta bajo usuario y contraseña y podrá acceder a ella desde todos sus dispositivos.',
    send_signature:
      'Le hemos mandado un email que contiene un link especial para crear su firma desde Smartphone o Tablet. El link caducará en 15 minutos.',
  },
  tipovideo: {
    estandar: 'Videoconsulta Estandar',
    precio: 'Precio de la Videoconsulta',
    duracion: 'Duración de la Videoconsulta',
    guardar: 'Guardar Videoconsulta',
    guardarmensajeria: 'Guardar Mensajería',
    confirm_desactivar_mensajeria: '¿Esta seguro que desea desactivar el servicio de mensajería?',
    confirm_desactivar: '¿Esta seguro que desea desactivar el servicio de videoconsulta?',
    nuevo_titulo: 'Crear Servicio de Videoconsulta',
    nuevo_video: 'Create videoconsultation Service',
    nuevo_presencial: 'Create face-to-face Service',
    confirm_delete_titulo: 'Eliminar Servicio de Videoconsulta',
    confirm_delete_texto: '¿Está seguro que desea eliminar el servicio de Videoconsulta seleccionado?',
  },
  tour: {
    skip: 'Skip tour',
    prev: 'Previous',
    next: 'Next',
    finish: 'Finish',
    dibuja_firma: 'Pruebe a dibujar su firma',
    no_te_gusta: '¿No te gusta como ha quedado?',
    rehacer_firma: 'Puede pulsar en el botón de rehacer firma para volver a dibujar su firma',
    enviar_firma_mobile:
      'Si se encuenta en el ordenador y desea dibujar su firma con el dedo desde {nombre}, pulse sobre este botón.',
    configura_agenda: 'Seleccione los dias que se encuentra disponible en {name}.',
    configura_hora_agenda: 'Una vez seleccionado el día indique la hora de inicio y la hora de fin.',
    configura_rango_agenda: 'Puede seleccionar más de un rango de horas.',
    descripcion_mensajeria: 'Indique la descripción del servicio de mensajería',
    precio_mensajeria: 'Puede modificar el precio de la consulta',
    estado_mensajeria: 'Si no desea recibir consultas puede cambiar el estado a desactivado',
    guardar_cambios: 'Pulse sobre guardar para aplicar los cambios',
  },
  user: {
    name: 'Nombre de Usuario',
    telefono_secretaria: 'Teléfono secretaría',
    telefono_paciente: "Patient's phone",
    biografia: 'Extracto',
    nuevo: 'Nuevo Usuario',
    buscar: 'Buscar Usuario',
    name_complete: 'Nombre Completo',
    num_colegiado: 'Num. Colegiado',
    telefono_consulta: 'Teléfono de Consulta',
    tipo_prof: 'Tratamiento',
    saludation: 'Saludo',
    password_repeat: 'Por favor, repita la contraseña para verificar que coinciden',
    password_no_igual: 'Las contraseñas no coinciden',
    password_tam_invalid: 'La contraseña debe ser mayor o igual a 6 caracteres',
    password_must_contain_lowercase: 'La contraseña debe contener minúsculas',
    password_must_contain_uppercase: 'La contraseña debe contener mayúsculas',
    password_must_contain_numbers: 'La contraseña debe contener números',
    password_must_contain_symbols: 'La contraseña debe contener caracteres especiales',
    invalid_password: 'La contraseña no es válida',
    password_min_length: 'La contraseña debe tener al menos {num} caracteres',
    edit: 'Guardar Cambios',
    search: 'Buscar Usuario',
    especialidades: 'Especialidades',
    psychiatric_clinic_history: 'Història Clínica de psiquiatria',
    professional_time_zone: 'Zona Horària del professional',
    professional_status: 'Estat del professional',
    user_status: "estat de l'usuari",
    restore_user_password: 'Restablir contrassenya usuari',
    send_account_activation_email: 'Enviar email activación cuenta',
    csv_with_error: 'Hay un error en el documento, corrígelo y vuelve a subirlo',
    csv_ok: 'Se han creado todos los usuarios correctamente',
    user_status_training_not_reserved: 'No ha reservado Formación',
    user_status_in_workflow_hubspot: 'En Workflow Hubspot',
    user_status_training_reserved: 'Formación Reservada',
    user_status_training_made: 'Formación Realizada',
    user_status_subscription_paid: 'Pagada Suscripción',
    user_status_completed_personal_data: 'Completados Datos Personales',
    user_status_abandoned_lead: 'Lead Abandonado',
    date_end_trial: 'Fecha fin del periodo de prueba',
    public_on_marketplace: 'Publicado en el Marketplace',
    no_public_on_marketplace: 'No publicado en el Marketplace',
    select_image: 'Seleccione una imagen',
    name_patients_will_see: 'Nombre que verán los pacientes',
  },
  videoconsulta: {
    nueva_video: 'Nueva Videoconsulta',
    nueva_cita: 'Nueva Cita',
    crear_cita: 'Crear Cita',
    nueva_tele: 'New Teleconsultation',
    crear_video: 'Crear Videoconsulta',
    crear_tele: 'Create Phonecall',
    pendientes: 'Pendientes',
    terminadas: 'Terminadas',
    informe: 'Informe',
    sin_video_pendientes: 'En este momento no tiene {name} pendientes',
    sin_video_terminadas: 'Aún no ha atendido ningún servicio de {name}',
    crear_para_paciente: 'Crear una {tipoVideo} para un Paciente',
    crear_muticonferencia: 'Crear una Multiconferencia',
    como_desea_comunicar: '¿Como desea comunicar la cita?',
    solicitando_pago: 'Solicitar Pago por Anticipado',
    solicitando_pago_texto:
      'Envie una petición de pago por Email. Una vez su paciente haya realizado el pago, podrá enviar a su paciente la convocatoria para poder realizar la videoconsulta.',
    cita_cortesia: 'Gratuita',
    cita_cortesia_texto:
      'Estas citas son gratuitas para el paciente. Facilitar una cita con esta opción puede resultar adecuada para casos como:',
    cita_cortesia_li:
      'Poder repetir videoconsultas que por algún tipo de incidencia no ha podido completarse. | Enviar una invitación, para realizar una primera consulta de prueba, o cualquier otro motivo que le anime a ello. | Pacientes que han adquirdo previamente paquetes de videoconsultas.',
    add_new: 'Añadir nueva',
    add_destinatario: 'Indique la persona que desea que reciba la videoconsulta',
    add_destinatario_cita: 'Indique la persona que desea que reciba la cita',
    add_fechahora: 'Indique la fecha y hora de la videoconsulta',
    add_fechahora_cita: 'Indique la fecha y hora de la cita',
    add_modocomunicacion: 'Indique la forma en la que se va a comunicar la cita al paciente',
    add_tiposervicio: 'Indique el tipo de videoconsulta',
    add_tiposervicio_cita: 'Indique el tipo de cita',
    solapamiento: 'Ya existe una videoconsulta en la fecha y hora seleccionada',
    sin_tiposvideo: 'Para crear nuevas videoconsulta debe tener activo al menos un servicio',
    enviado_correctamente:
      'La videoconsulta ha sido creada correctamente. Se le ha enviado un email al paciente para notificarselo',
    enviado_correctamente_sms:
      'La videoconsulta ha sido creada correctamente. Se le ha enviado un SMS al paciente para notificarselo',
    enviado_recordatorio: 'Se le ha enviado un email al paciente para recordarle la videoconsulta',
    cortesia: 'Cortesía',
    esperando_pago: 'Esperando Pago',
    tipo: 'Tipo',
    videoconsulta_para: '{tipoVideo} para',
    codigo: 'Código',
    abrir_sala: 'Abrir Sala',
    volver_enviar: 'Volver a Enviar Código',
    confirm_delete: '¿Esta seguro que desea cancelar la videconsulta?',
    confirm_delete2:
      'La videoconsulta ya encuentra pagada. Pongase en contacto con nosotros a través del chat, si fuese necesario, que se realice el reembolso al paciente.',
    confirm_video: '¿Esta seguro que desea confirmar la videoconsulta?',
    cancelado_correctamente: 'Se ha cancelado la videoconsulta correctamente',
    notification_email: 'Se le enviará un email al paciente confirmando la cancelación de la consulta',
    confirmado_correctamente: 'Se ha confirmado la videoconsulta correctamente',
    terminada: 'Videoconsulta Terminada',
    singular: 'Videoconsulta',
    teleconsulta: 'Videoconsulta',
    sin_confirmar: 'SIN CONFIRMAR',
    rechazada: 'Rechazada',
    aceptada: 'Aceptada',
    denegada: 'Denegada',
    ok: 'Ok',
    multiconferencia: 'Multiconsulta',
    tipo_cita: 'Tipo de Cita',
    convocatoria_sms: 'Enviar convocatoria por {sms}',
    editar_servicio: 'Editar Servicio',
    presencial: 'Presencial | Presenciales',
    sms: 'SMS',
    mensaje_texto: 'Mensaje de texto',
    diagnostico: 'Diagnòstic',
    tratamiento: 'Tractament',
    freemium_version_non_free:
      'Con la versión Freemium no puede crear servicios de cortesía o gratuitos. Contacte con su comercial para actualizar a la versión Premium',
    fixed_fee_message:
      'El fee de gestión de este servicio es de {feeValue} € que se añadirá a su tarifa en el momento del pago por parte del paciente.',
    percentage_fee_message:
      'El fee de gestión de este servicio es del {feeValue}% con un mínimo de {feeMin}€ y un max de {feeMax}€ que se añadirá a su tarifa en el momento del pago por parte del paciente.',
    notice: 'Aviso',
    no_specialties_to_create:
      'Para poder continuar debes tener una especialidad asignada, por favor, accede a tu perfil y actualiza tu especialidad',
  },
  contact: {
    texto: 'Si tiene alguna duda, envíenos su mensaje a través del siguiente formulario:',
    mensaje_obligatorio: 'Para contactar con nosotros debe escribir el mensaje sobre su duda',
    motivo_obligatorio: 'Para contactar con nosotros debe escribir el motivo de su duda',
    enviado_correctamente: '¡Gracias por contactar con nosotros! Le responderemos lo antes posible.',
  },
  empresa: {
    nueva: 'Nueva Empresa',
    buscar: 'Buscar Empresa',
    email_noty: 'Email de  Notificación',
    email_admin: 'Email del Administrador',
    share_patients: 'Compartir Pacientes',
    nombre_required: 'Es obligatorio rellenar el nombre de la empresa',
    email_required: 'Es obligatorio rellenar el email del administrador',
    licenses_num: 'Núm. licencias',
    guardar_empresa: 'Guardar Empresa',
    edit_empresa_success: 'La empresa se ha editado correctamente',
    has_anesthetist: 'Tiene Anestesista',
    share_receptionists: 'Compartir Recepcionistas',
    without_licenses: 'Tus licencias se han agotado',
    user_exists: 'El usuario ya existe',
    forbidden: 'No tienes permisos para modificar esta empresa',
    user_not_exists: 'Este usuario no existe',
    edit: 'Editar empresa',
    create: 'Crear empresa',
    delete: 'Eliminar Empresa',
    confirm_delete: '¿Está seguro que desea eliminar esta empresa?',
    cannot_delete: 'No es posible eliminar esta empresa, ya que hay profesionales/recepcionistas asociados a la misma',
    delete_success: 'La empresa ha sido borrada correctamente',
    premium_payment_type: 'Tipo de pago premium',
    premium_payment_types: 'Tipos de pago premium',
    is_collective: 'Es colectiva',
    is_health_personnel: 'Es personal sanitario',
    have_interconsultation: 'Tiene interconsulta',
    interconsultation: 'Interconsultation',
    users: 'Usuarios',
    public_email: 'Email público',
    public_phone: 'Teléfono público',
    public_address: 'Dirección pública'
  },
  landing: {
    titulo: 'Configuración de mi Web',
    direccion: 'Dirección',
    sections: 'Secciones',
    add_seccion: 'Añadir Sección',
    add_seccion_titulo: 'Añadir Sección a Mi Web',
    seccion_anadida: 'Sección añadida correctamente',
    delete_seccion_titulo: 'Eliminar Sección',
    delete_seccion_texto: '¿Está seguro que desea eliminar esta sección de su web?',
    seccion_eliminada: 'Sección eliminada correctamente',
    publicar: 'Publicar',
    modificar_precios: 'Modificar Precios',
    redes_sociales: 'Xarxes Socials',
    ejemplo_web: 'Exemple de pàgina web',
    en_este_apartado: 'En este apartado se configura los datos que aparecerán en su página web',
    legend:
      'Los campos de foto, nombre, especialidad y resto de información se recogen de los datos rellenados en el apartado "Su perfil".',
    legend_significance: 'Significado de los campos a rellenar',
    write_address: 'Introduzca la dirección de su centro',
    write_web: 'Introduzca el link de su web particular, si ya dispusiese de una',
    write_socials: 'Introduzca los enlaces de sus redes sociales operativas',
    write_sections: 'Puede añadir las secciones que desee incluyendo información',
  },
  suscripciones: {
    suscripcion: 'Suscripción',
    suscripciones: 'Suscripciones',
    nueva: 'Nueva Suscripción',
    nueva_titulo: 'Nueva Suscripción Mensual',
    editar: 'Editar Suscripción',
    tarifa_plana: 'Tarifa Plana',
    a_medida: 'Suscripción a Medida',
    sin_suscripciones: 'No tiene configurada ninguna suscripción',
    destinatario: 'Quien puede contratarlo',
    explicacion_destinatario: 'Al seleccionar cualquiera; este servicio aparecerá en el marketplace',
    servicio: 'Servicio',
    ilimitado: 'Ilimitado',
    servicios_incluidos: 'Servicios Incluidos',
    sesiones: 'sesión | sesiones',
    error_no_servicios: 'No ha incluido ningún servicio. Pulse el botón (+) para añadir servicios',
    delete: 'Eliminar Suscripción',
    confirm_delete: '¿Está seguro que desea eliminar el servicio de suscripción?',
    suscripcion_contratados: 'Suscripciones Contratadas',
    sin_suscripciones_contratadas: 'En este momento no tiene ninguna suscripción contratada',
    mensajeria_restantes: 'Mensajerias Restantes',
    videoconsulta_restantes: 'Videoconsultas Restantes',
    cancelar_suscripcion: 'Cancelar Suscripción',
    cancelar_suscripcion_texto:
      'El paciente dejará de disponer los servicios que incluye la suscripción a partir del día {dia} ¿Está seguro que desea cancelar su suscripción?',
    cancelado_correctamente:
      'La suscripción ha sido cancelada correctamente. No se le realizarán más cargos al paciente',
  },
  reserva: {
    titulo: 'Reserva tu Formación',
    titulo_especialidad: 'Seleccione su Especialidad Médica',
    texto1:
      'Disponemos de un servicio de formación Online con nuestro Director Médico para conocer todo el potencial que te ofrece {name}.',
    texto2: '¿Deseas reservar tu formación online ahora?',
    boton: 'Reserva Ahora',
    reserva_confirmada: 'Reserva confirmada',
    ha_hecho_una_reserva: 'Ha hecho una reserva con Roberto Medina.',
    recibira_invitacion_por_correo: 'Recibirá una invitación por correo electrónico.',
    se_reservara_a_las: 'La formación será reservada el día {dia} a las {hora}',
    seleccione_fecha_hora: 'Seleccione fecha y hora a la que desea tener la formación',
    reunirse_roberto: 'Reunirse con Dr. Roberto Medina',
  },
  producto: {
    nuevo: 'Nuevo Producto',
    editar: 'Editar Producto',
    buscar: 'Buscar Producto',
    nombre_required: 'Es obligatorio rellenar el nombre del producto',
    precio_required: 'El precio del producto es obligatorio',
    creado: 'El producto se ha creado correctamente',
    licencia: 'Núm. Licencias',
  },
  pago: {
    titulo: 'Servicio de Suscripción (Pago Seguro)',
    titulo_stripe: 'Servicio de Suscripción',
    pagar_suscripcion: 'Pagar Suscripción',
    ciclo: 'Billing cicle: based on your license description',
    cuenta_activada_correctamente: 'La cuenta ha sido activada correctamente',
    periodo_duracion: 'Periodo de duración: Sin fecha de finalización',
    explicacion:
      'Se le cobrará inmediatamente por la cantidad mostrada anteriormente y luego se le cobrará automáticamente esa misma cantidad cada ciclo de facturación hasta que su suscripción caduque o se cancele.',
  },
  steps: {
    espacio: 'Mi Espacio {name}',
    datos_facturacion: 'Datos de Facturación',
    dni_anverso_subido: 'DOC. DE IDENTIDAD ANVERSO SUBIDO',
    dni_reverso_subido: 'DOC. DE IDENTIDAD REVERSO SUBIDO',
    subir_dni_anverso: 'SUBIR DOC. DE IDENTIDAD (ANVERSO)',
    subir_dni_reverso: 'SUBIR DOC. DE IDENTIDAD (REVERSO)',
    iban_payment_module_message:
      'Es necesario que complete el campo IBAN, con los datos de la cuenta bancaria, cuando pretenda realizar cobros a los pacientes, por alguna de las citas o consultas que se realicen a través de esta plataforma, y configurar el TPV para cobros online',
    iban_invalid: 'El IBAN no es correcto, introduzca un IBAN válido',
    cp_invalid: 'Postal code is not correct',
  },
  resenas: {
    titulo_publicar: 'Publicar Reseña',
    texto_publicar: '¿Esta seguro que desea publicar esta reseña?',
    titulo_eliminar: 'Eliminar Reseña',
    texto_eliminar: '¿Esta seguro que desea eliminar esta reseña?',
  },
  dispositivo: {
    nueva_entidad: 'Nueva Entidad',
    entidad: 'Entidad | Entidades',
    nuevo_local: 'Nuevo Local',
    local: 'Local | Locales',
    nueva_persona: 'Nueva Persona de Contacto',
    persona_contacto: 'Persona de Contacto | Personas de Contacto',
    persona: 'Persona de Contacto | Personas de Contacto',
    nuevo_dispositivo: 'Nuevo Dispositivo',
    dispositivo: 'Dispositivo | Dispositivos',
    venta: 'Venta',
    alquiler: 'Alquiler',
    titulo_eliminar: 'Eliminar {tipo}',
    texto_eliminar: '¿Esta seguro que desea eliminar esta {tipo}?',
  },
  reportes: {
    informe_pontgrup: 'Informe de actividad Pont-Grup',
    informe_productividad: 'Informe de productividad',
    tiempos_atencion_consulta: 'Tiempos de atención de consultas',
    cumplimiento_servicio: 'Control cumplimiento del servicio',
    num_altas: 'Número de altas',
    num_bajas: 'Número de bajas',
    num_renov: 'Número de renovaciones',
    total: 'Total',
    seleccione_profesional: 'Es obligatorio seleccionar un profesional',
    filter_hospital: 'Filtrar por hospital',
    filter_professional: 'Filtrar por profesional',
    filter_from_date: 'Fecha inicio',
    filter_to_date: 'Fecha fin',
    activation_mails: 'Emails activación enviados',
    activated_accounts: 'Cuentas activadas',
    num_videocons: 'Videoconsultas',
    num_chats: 'Chats',
    num_patients: 'Pacientes registrados',
    num_electronic_presc: 'Recetas Electrónica emitidas',
    num_pdf_presc: 'Recetas PDF emitidas',
    num_logins: 'Accesos al sistema',
    num_registers: 'Num. Registros',
    graph_accounts: 'Cuentas activas/no activas',
    graph_activity: 'Actividad registrada',
    chart_electronic_presc: 'Recetas elect.',
    chart_pdf_presc: 'Recetas PDF',
    active_accounts: 'Activas',
    inactive_accounts: 'No activas',
    date_filters: 'Filtrar por fechas',
  },
  permissions: {
    dashboard: 'Inicio',
    conf_permissions: 'Configuración de permisos',
    conf_styles: 'Configuración de estilos',
    save_permissions: 'Guardar permisos',
    save_styles: 'Guardar estilos',
    users: 'Profesionales',
    companies: 'Empresas',
    products: 'Productos',
    devices: 'Dispositivos',
    patients: 'Pacientes',
    videoConsultation: 'Videoconsultas',
    calendar: 'Calendario',
    billing: 'Facturación',
    messaging: 'Mensajería',
    prescriptions: 'Receta PDF',
    packsHired: 'Packs contratados',
    subscriptionsHired: 'Suscripciones contratadas',
    reviews: 'Reseñas',
    configServices: 'Configuración de servicios',
    reports: 'Reportes',
    settingsBook: 'Ajustes - Talonario',
    settingsBilling: 'Ajustes - Facturación',
    settingsRates: 'Ajustes - Servicios',
    settingsSign: 'Ajustes - Firma',
    settingsDiary: 'Ajustes - Agenda',
    settingsPacks: 'Ajustes - Packs',
    settingsCoupons: 'Ajustes - Cupones',
    settingsLanding: 'Ajustes - Landing',
    settingsReceptionist: 'Ajustes - Recepcionista',
    settingsSpecialties: 'Ajustes - Especialidades',
    settingsServices: 'Ajustes - Servicios',
    settingsDaysNotAvailable: 'Ajustes - Días no disponibles',
    settingsDaysAvailable: 'Ajustes - Días disponibles',
    contact: 'Contacto',
    help: 'Ayuda',
    haveCreateVideoWithTel: 'Videoconsulta por teléfono',
    adminSimplified: 'Administrador simplificado',
    marketplace: 'Marketplace',
    showIbanPaymentModuleMessage: 'Mostrar leyenda IBAN',
    simplifiedLanguages: 'Ocultar resto de idiomas (PT/PL/AR)',
    hideStripeButton: 'Ocultar botón de configuración Stripe',
    hideFormBooking: 'Ocultar reserva de formación',
    hideTrialMessage: 'Ocultar mensaje de cuenta Trial',
    clinicalHistory: 'Historia clínica',
    anesthesia: 'Formulario pre-anestesia',
    documents: 'Documentos',
    haveElectronicPrescription: 'Receta Electrónica',
    myMessages: 'Pacientes - Mensajes',
    myVideoconsultations: 'Pacientes - Videoconsultas',
    myPrescriptions: 'Pacientes - Recetas',
    mySubscriptions: 'Pacientes - Suscripciones',
    sendUsMessage: 'Envíanos un mensaje',
    myDocuments: 'Pacientes - Documentos',
    settingsBeneficiaries: 'Ajustes beneficiarios',
    importCsvOnUsersList: 'Importar CSV usuarios',
    showWelcomeModal: 'Mostrar Popup de Bienvenida',
    prescriptionsList: 'Receta por lotes',
    moduleMedicalTests: 'Módulo de Pruebas',
    personalDoctor: 'Médico personal',
    personalDoctorPatient: 'Paciente',
    personalDoctorProfessional: 'Profesional',
    personalDoctorAssistant: 'Asistente',
    hidePatientCreation: 'Ocultar crear pacientes',
  },
  recipe: {
    diagnostic: 'Diagnóstico',
    chronic: 'Crónico',
    confidential: 'Confidencial',
    patient_instructions: 'Instrucciones para el paciente',
    pharmacist_warnings: 'Advertencias para el farmacéutico',
    comments: 'Comentarios',
    treatment_created: 'Se ha creado correctamente el tratamiento',
    sending: 'Enviando',
    err_empty_diagnostic: 'Debe completar el campo diagnóstico',
    err_empty_datestart: 'Debe rellenar la fecha de inicio',
    err_empty_dateend: 'Debe rellenar la fecha de fin',
    err_empty_posology: 'Debe rellenar la posología',
    err_empty_duration: 'Debe rellenar la duración',
    err_empty_guideline: 'Debe rellenar la pauta',
    err_empty_tincode: 'Debe rellenar el TIN Code',
    err_empty_medicine: 'Debe seleccionar un medicamento',
    err_empty_composition: 'Debe completar la composición',
    unit: 'Unit',
    units: 'Unidades',
    recipe: 'Receta',
    print_recipe: 'Imprimir receta',
    tin_code: 'TIN Código',
    master_formula: 'Formula Magistral',
    master_formula_placeholder: 'Escriba aquí la fórmula',
    medicine: 'Medicamento',
    medicines: 'Medicamentos',
    search_medicine: 'Busque por nombre de medicamento',
    search_patient: 'Busque por nombre de paciente',
    add_medicines: '+ Agregar medicamento',
    type_electronic: 'Electrónica',
    type_pdf: 'PDF',
    type: 'Tipo',
    sel_type: 'Seleccione tipo',
    create_treatment: 'Crear Tratamiento',
    create_treatment_treat: 'Crear Tratamiento',
    years: 'años',
    change_patient: 'Cambiar de paciente',
    allergies: 'Alergias',
    num_recipe: 'Num Prescription',
    last_disp: 'Last Disp',
    next_disp: 'Next Disp',
    active: 'Activo',
    inactive: 'Inactivo',
    confirm_delete_treatment: '¿Está seguro que desea eliminar el tratamiento?',
    confirm_delete_prescription: '¿Está seguro que desea eliminar la receta?',
    confirm_emit_prescription: '¿Desea emitir la receta?',
    create_prescription: 'Crear receta',
    edit_prescription: 'Editar receta',
    typology: 'Tipología',
    pending: 'Pendiente',
    partially_dispensed: 'Parcialmente dispensada',
    dispensed: 'Dispensada',
    download: 'Descargar receta',
    narcotic: 'Estupefaciente',
    generic: 'Genérico',
    hours: 'hours',
    days: 'days',
    weeks: 'weeks',
    months: 'months',
    month: 'mes',
    year: 'years',
    during: 'During',
    every: 'every',
    created_at: 'Creada el',
    frequency: 'Frequency',
    new_prescription: 'Crear Receta Electrónica',
    emmited: 'Issued',
    save_prescription: 'Guardar Receta',
    confirm_emmit_prescription_text: 'Esta acción enviará la receta a su paciente',
    see_prescription: 'Ver receta',
    choose_prescription_type: 'Choose type of prescription',
    paper_recipe: 'PDF prescription',
    title: 'Receta Electrónica',
    from: 'desde',
    to: 'hasta',
    created_date: 'Fecha de creación',
    filter_by_created_date: 'Filtra por fecha de creación',
    more_information: 'Más información',
    prescriptions: 'Recetas',
    premium_title: 'Desbloquear contenido Premium',
    patient_dni_required:
      'El paciente debe tener el DNI relleno para poder emitirle una receta, vaya a los datos personales del paciente y rellene el DNI',
    reject_prof: 'Rechazar',
    reject_without_notification: 'Rechazar sin notificación',
    reject_with_notification: 'Solicitar más información',
    master_formulas: 'Formulas Magistrales',
    add_master_formula: 'Agregar formula magistral',
    psychotropic: 'Psicótropo',
    composition: 'Composición',
    pharmaceuticalForm: 'Forma Farmaceútica',
    administrationWay: 'Vía de administración',
    dosage: 'Dosificación',
    observations: 'Observaciones',
    err_master_formulas: 'Debe completar todos los campos requeridos de las formulas magistrales',
    medicine_detail: 'Detalle del medicamento',
    medicine_information: 'Información del medicamento',
    registration_number: 'Nº de registro',
    authorized: 'Autorizado',
    revoked: 'Revocado',
    discontinued: 'Suspendido',
    package_leaflet: 'Prospecto',
    technical_sheet: 'Ficha técnica',
    active_ingredient: 'Principio activo',
    renew_prescription: 'Renovar receta',
    cancel_prescription: 'Anular receta',
    select_reason: 'Elija el motivo de la cancelación',
    cancel_reason_0: 'Bloqueo cautelar confirmado por plazo',
    cancel_reason_1: 'Anulación por fin de tratamiento',
    cancel_reason_2: 'Cambio de tratamiento',
    cancel_reason_3: 'Datos incompletos o erróneos',
    canceled: 'Anulada',
    canceled_message: 'Receta Anulada correctamente',
    anulate_medicament: 'Anular Medicamento',
    anulate_indications: 'Motivo de la anulación',
    anulate_message: 'Medicamento anulado correctamente',
    unlock_medicament: 'Desbloquear Medicamento',
    unlock_indications: 'Motivo del desbloqueo',
    unlock_message: 'Medicamento desbloqueado correctamente',
    emitted: 'Emitida',
    expired: 'Expirada',
    finished: 'Finalizada',
    in_queue: 'En proceso de emisión',
    units_dispensed: 'Envases / unidades dispensadas',
    navigate_to_prescription: 'Ir a la receta',
  },
  medicine: {
    filter_by_fav: 'Filtrar sólo por favoritos',
  },
  service: {
    name: 'Servicio de {name}',
    more_services: 'Más Servicios',
  },
  signup: {
    dont_have_account_yet: '¿Aún no tienes una cuenta? Regístrate',
    title_patient: 'Registrate como paciente',
    subtitle_patient: 'Únete a {name} y consulta a profesionales de la salud estés donde estés',
    title_professional: 'Registrate como profesional sanitarios',
    subtitle_professional: 'Únete a {name} y atiende a tus pacientes estés donde estés',
    i_am_patient: 'Soy Paciente',
    i_am_professional: 'Soy profesional sanitario',
    start_enjoying: 'Empieza a disfrutar de los beneficios de usar la plataforma {name}',
    select_professional: 'Seleccione un profesional...',
  },
  subscriptions: {
    name: 'Suscripción | Suscripciones',
    not_have_hired: 'No tiene ninguna suscripción contratada',
    renewal: 'Renovación',
    expire: 'Expira',
    request_new_service: 'Solicitar Nuevo Servicio',
    confirm_cancel_explain:
      'Dejará de disponer los servicios que incluye la suscripción a partir del día {date} ¿Está seguro que desea cancelar su suscripción?',
    cancelled_ok: 'La suscripción ha sido cancelada correctamente',
  },
  videoconsultations: {
    confirmed: 'Confirmada',
    pending_confirm: 'Pendiente de Confirmar',
    pending_payment: 'Pendiente Pago',
    go_to_video: 'Entrar a la videoconsulta',
    pending_confirm_explain: 'El profesional debe confirmar la videoconsulta',
    pay_video: 'Pagar videoconsulta',
    pay: 'Pagar',
    to_access: 'Acceder',
    expire_at: 'Expira en',
    download_recording: 'Descargar grabación',
    show_video: 'Ver videconsulta',
    seconds: 'Segundos',
    paymentPatientNotCorresponding:
      'Hemos detectado que hay una sesión iniciada que no se corresponde con la del paciente al que va dirigida la videoconsulta. Para pagar, por favor cierra la sesión y vuelve a intentarlo.',
  },
  session: {
    title: 'Session Timeout',
    subtitle: 'Your online session will expire in',
    advice: 'Please click "Continue" to keep working or click "Log Off" to end your session now',
    close_session: 'Log Off',
    continue: 'Continue',
    second: 'second | seconds',
  },
  webphone: {
    calling: 'Calling',
    mute: 'Mute',
    pause: 'Pause',
    speaker: 'Speaker',
    hang_up: 'Hang Up',
    call: 'Call',
    new_call: 'Phone Call',
    please_enter_number: 'Please enter a phone number',
    please_enter_valid_number: 'Please enter a valid phone number',
    please_enter_valid_es_number: 'Please enter a valid spanish phone number',
    an_error_ocurred: 'An error ocurred!',
    retry: 'Retry',
    token_expired: 'Token expired',
    webphone: 'Webphone',
  },
  incompatibleBrowser: {
    title: 'Navegador no compatible',
    message: 'Estás utilizando un navegador web que no es compatible con Docline.',
    title2: '',
    message2: '',
    helpCenterMessage: 'Si quieres saber cuáles son nuestros navegadores compatibles',
    helpCenterLinkName: 'visita nuestro artículo de ayuda.',
    helpCenterLink: 'https://ayuda.docline.es/article/13-compatiblebrowserses',
  },
  triage: {
    title: 'Triaje',
    code: 'Código de triaje',
    generate_code: 'Generar código',
    start: 'Empezar triaje',
    explain: 'Este código permitirá al paciente realizar un triaje de forma autónoma.',
    finish: 'Finalizar triaje',
    code_error: 'Código de triaje incorrecto!',
    type_code: 'Introduce the triage code to start.',
  },
  registro: {
    professional_registration_process: 'Proceso de registro de profesional sanitario',
    init_session: 'Iniciar sesión',
    have_an_account: '¿Ya tiene una cuenta?',
    personal_info: 'Información personal',
    please_complete_info: 'Por favor complete su información profesional.',
    documents: 'Documentos',
    add_passport: 'Por favor adjunte su pasaporte.',
    add_nie: 'Por favor adjunte su NIE por ambas caras.',
    add_dni: 'Por favor adjunte su documento de identidad por ambas caras.',
    front_image: 'Imagen cara frontal',
    rear_image: 'Imagen cara trasera',
    accept_conditions:
      'He leído y acepto los <a href="{privacyPolicyLink}" target="_blank">términos de uso y la política de privacidad</a>',
    drag_or_select_file:
      'Arrastre la imagen aquí o <span class="filepond--label-action open">seleccione el archivo </span>',
    save_ok_title: 'Se ha guardado correctamente',
    save_ok_text:
      'Se ha registrado correctamente, recibirá un email cuando validemos sus datos y pueda comenzar a usar nuestro servicio.',
    alert_front_image: 'Debe introducir la imagen de la cara frontal del documento',
    alert_rear_image: 'Debe introducir imagen de la cara trasera del documento',
    alert_accept_conditions: 'Debe aceptar los términos de uso y la política de privacidad',
    alert_image_max_size: 'La imagen debe pesar como máximo 2MB',
    alert_image_format: 'El formato de la imagen debe ser .png o .jpg',
    alert_input_required: 'Campo obligatorio',
    alert_phone_required: 'El teléfono es obligatorio',
    alert_phone_is_invalid: 'El teléfono no es válido',
    alert_email_required: 'El correo es obligatorio',
    alert_email_is_invalid: 'E-mail debe ser válido',
    alert_number_required: 'El número de colegiado es obligatorio',
    alert_number_is_invalid: 'El número de colegiado no es válido',
  },
  verifyusers: {
    to_be_verified: 'Pendientes de verificar',
    verified: 'Verificados',
    verified_pendings: 'Pendientes de verificación',
    reject: 'Rechazar',
    loading: 'Cargando',
  },
  medicalTests: {
    analytical_tests_list: 'Pruebas analíticas',
    analytical_tests_list_types: 'Tipos de pruebas',
    created_at: 'Fecha de creación',
    code: 'Código',
    description: 'Descripción',
    new_test: 'Nueva prueba',
    delete_test: 'Eliminar prueba analítica.',
    confirm_delete_test: '¿Esta seguro que desea eliminar la prueba?',
    new_type_of_test: 'Nuevo tipo de prueba',
    test_type_code: 'Cod. tipo de pruebas',
    tests_included: 'Pruebas incluidas',
    observations: 'Observaciones',
    invalidCodeFormat: 'El formato del código es inválido',
    almostOneAct: 'Debe añadir al menos un acto',
    description_too_short: 'La descripción es demasiado corta',
    create_test_success: 'Tipo de prueba creada correctamente',
    new_analytical_test: 'Nueva prueba',
    no_data: 'No data available',
    nomenclator: 'Nomenclátor',
    new_act: 'Nuevo acto',
    new_nomenclator: 'Nueva entrada nomenclátor',
    edit_nomenclator: 'Editar entrada nomenclátor',
    specialty: 'Especialidad',
    group: 'Grupo',
    collection: 'Colección',
    subgroup: 'Subgrupo',
    codeCiepq: 'Código CIEP-9',
    invalidCiep9: 'El formato del código debe ser numérico de 4 cifras (XXXX)',
    codeCie9: 'Código CIE-9',
    addAnotherCode: 'Añadir otro código',
    codesCie9: 'Códigos CIE-9',
    externalId: 'ID externo',
    actDescription: 'Descripción del acto',
    search: 'Buscar',
    request_medical_test: 'Solicitud de pruebas',
    request_analytical_test: 'Solicitud de pruebas analíticas',
    patient_info: 'Información del paciente',
    select_medical_test: 'Seleccionar prueba',
    select_medical_test_type: 'Selecciona un tipo de prueba',
    analytical_test: 'Analítica',
    other_medical_tests: 'Otras pruebas',
    medical_tests_requested: 'Pruebas solicitadas',
    other_medical_tests_requested: 'Otras pruebas solicitadas',
    medical_test_description: 'Descripción de prueba',
    medical_test_recommendations: 'Recomendaciones',
    medical_test_request: 'Solicitar',
    add_act: 'Añadir acto',
    delete_act: 'Eliminar acto',
    select_acts: 'Seleccionar actos',
    confirm_delete_act: '¿Está seguro que desea eliminar el acto?',
    select_some_test: 'Selecciona alguna prueba',
    select_patient: 'Seleccionar paciente',
    request_medical_test_success: 'Solicitud generada correctamente',

    request_rx_test: 'Solicitud de pruebas de radiodiagnóstico',
    rx_test: 'Prueba de radiodiagnóstico',
    rx_structured_view: 'Vista estructurada',
    rx_simple_view: 'Vista simple',
    rx_modality: 'Modalidad',
    rx_anatomical_region: 'Región anatómica',
    rx_exploration: 'Exploración',
    rx_search_hint: 'Búsqueda de pruebas de radiodiagnóstico',
    rx_not_found: 'No hemos encontrado pruebas con esa descripción',
    rx_protocols: 'Protocolos RX',
    new_rx_protocol: 'Nuevo protocolo RX',
    request_other_test: 'Solicitud de otras pruebas',
    other_test: 'Otra prueba',
  },
  personal_doctor: {
    personal_doctor: 'Médico personal',
    personal_doctors_historical: 'Histórico de médicos personales',
    personal_doctor_present: 'Actualidad',
    loading_personal_doctors: 'Cargando lista de profesionales...',
    not_found_personal_doctors: 'No hemos encontrado profesionales con este nombre',
    network_error: 'Hubo un problema al cargar la lista de profesionales.',
    network_error_short: 'Hubo un problema al cargar la lista',
    retry: 'Volver a intentar',
    pending_assignment: 'Pendiente de asignación',
    is_your_personal_doctor: 'Soy su médico personal',
  },
};
