<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    to: {
      type: [String, null],
      default: null,
    },
  },

  mounted() {
    let container = this.to ? document.querySelector(this.to) : document.body;

    if (!container) {
      container = document.body;
    }

    container.append(this.$el);
  },

  destroyed() {
    this.$el.remove();
  },

  render(h) {
    return h(
      'div',
      {
        attrs: {
          id: `portal-${Math.random()
            .toString(36)
            .substr(2, 10)}`,
        },
      },
      this.$slots.default
    );
  },
});
</script>
