import { RouteConfig } from 'vue-router';

export const messagingNamePaths = {
  messagingList: 'Mensajeria',
  createMessage: 'MessagingCreate',
  messagingDetails: 'MessagingDetails',
};

export const messagingRoutes: RouteConfig[] = [
  {
    path: '/mensajeria',
    meta: { breadcrumb: false, requiresAuth: true, forbiddenPatient: true },
    name: messagingNamePaths.messagingList,
    component: () =>
      import(
        /* webpackChunkName: "mensajeria" */
        `@/views/MessagingScene.vue`
      ),
  },
  {
    path: '/mensajeria/(create|nuevo)',
    meta: { breadcrumb: false, requiresAuth: true, forbiddenPatient: true },
    name: messagingNamePaths.createMessage,
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "mensajeria" */
        `@/views/MessagingScene.vue`
      ),
  },
  {
    path: '/mensajeria/:id',
    meta: { breadcrumb: false, requiresAuth: true, forbiddenPatient: true },
    name: messagingNamePaths.messagingDetails,
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "mensajeria" */
        `@/views/MessagingScene.vue`
      ),
  },
];
