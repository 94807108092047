import { RouteConfig } from 'vue-router';

export const usersNamePaths = {
  usersList: 'Usuarios',
  createUser: 'NuevoUsuario',
  user: 'Usuario',
};

export const usersRoutes: RouteConfig[] = [
  {
    path: '/usuarios',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: usersNamePaths.usersList,
    component: () =>
      import(
        /* webpackChunkName: "usuarios" */
        `@/views/Usuarios.vue`
      ),
  },
  {
    path: '/usuarios/:nuevo',
    meta: { breadcrumb: true, requiresAuth: true, forbiddenPatient: true },
    name: usersNamePaths.createUser,
    component: () => import(`@/views/Usuarios.vue`),
  },
  {
    path: '/usuario/:id',
    meta: { breadcrumb: false, requiresAuth: true, only_admin: true },
    name: usersNamePaths.user,
    component: () =>
      import(
        /* webpackChunkName: "usuario" */
        `@/views/Usuariomain.vue`
      ),
  },
];
