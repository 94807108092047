<template>
  <div class="app-main-banner">
    <v-container fluid class="px-0 py-0">
      <v-banner :value="isVisible" :height="getHeight" :color="backgroundColor" tile elevation="0">
        <v-icon v-if="icon" slot="icon" :color="iconColor" size="24">
          {{ icon }}
        </v-icon>
        <p v-if="text || link" class="app-main-banner__text mb-0">
          <span v-if="text" :style="getTextStyles">{{ text }}</span>
          <a v-if="link" :href="link" :target="linkTarget" :style="getTextStyles" class="ml-1">{{ linkText }}</a>
        </p>
        <template v-if="buttonText && repHomologation" v-slot:actions>
          <v-btn v-show="!isConfig2faRoute()" outlined small :color="textColor" @click="handleClick">
            {{ buttonText }}
          </v-btn>
          <v-btn
            v-if="dismissible"
            v-tooltip="$t('common.close')"
            icon
            class="app-main-banner__close ml-2"
            :color="textColor"
            @click="isVisible = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-banner>
    </v-container>
    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppMainBanner',

  props: {
    icon: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: 'primary',
    },
    text: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: 'rgba(0, 0, 0, 0.87)',
    },
    buttonText: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    linkText: {
      type: String,
      default: '',
    },
    linkTarget: {
      type: String,
      default: '_self',
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isVisible: true,
    };
  },

  computed: {
    getTextStyles() {
      return {
        color: this.textColor,
      };
    },

    getHeight() {
      return this.$vuetify.breakpoint.mobile ? null : '54';
    },

    ...mapGetters({
      repHomologation: 'app/getFeatureRepHomologationFlag',
    }),
  },

  methods: {
    handleClick() {
      this.$emit('click:button');
    },
    isConfig2faRoute() {
      return this.$route.path === '/account/two-factor-authentication';
    },
  },
};
</script>

<style lang="scss" scoped>
.app-main-banner {
  ::v-deep {
    .v-banner__wrapper {
      border-bottom: none !important;
      padding: rem(8) rem(24) !important;
    }

    .v-banner__icon,
    .v-banner__icon * {
      margin-right: rem(8);
    }

    .v-banner__actions {
      align-items: center;
      margin-bottom: 0;
      flex: 1;
      align-self: center;
      justify-content: flex-start;
      padding-top: rem(4);
      padding-left: rem(40);

      @include breakpoint($--lg) {
        padding-top: 0;
        justify-content: center;
        padding-left: rem(0);
      }
    }
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
