const intercomSettings = (id, userMaps = {}, user = null) => {
  if (!user) {
    return { app_id: id };
  }

  return {
    app_id: id,
    name: user?.fullName ? user.fullName : user.name,
    email: userMaps[user.email] || user.email,
    user_id: user.id,
  };
};

const intercomLoad = appId => {
  (function() {
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      const d = document;
      const i = function() {
        // eslint-disable-next-line prefer-rest-params
        i.c(arguments);
      };
      i.q = [];
      i.c = function(args) {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = function() {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + appId;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};

export default (id = null, userMaps = {}, user = null) => {
  if (id) {
    window.intercomSettings = intercomSettings(id, userMaps, user);
    intercomLoad(id);
  }
};
