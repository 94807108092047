<template>
  <span class="tag">
    {{ content }}
  </span>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: true,
    },

    color: {
      type: Number,
      default: 1,
      validator: value => [1, 2, 3, 4, 5, 6].includes(value),
    },

    changeFontColor: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data: () => ({
    colors: {
      1: 'fac6c6',
      2: 'ffd5b1',
      3: 'dceaff',
      4: 'c5ef95',
      5: 'e08af2',
      6: 'f7afcb',
      7: 'edf963',
    },
    fontColors: {
      1: '7d0a0a',
      2: '795332',
      3: '0c4395',
      4: '4d5309',
      5: '490557',
      6: '953258',
      7: '4d5309',
    },
  }),

  watch: {
    color(value) {
      this.setColor(value);
      if (this.changeFontColor) {
        this.setFontColor(this.color);
      }
    },

    changeFontColor() {
      if (this.changeFontColor) {
        this.setFontColor(this.color);
      }
    },
  },

  mounted() {
    this.setColor(this.color);
    if (this.changeFontColor) {
      this.setFontColor(this.color);
    }
  },

  methods: {
    setColor(color) {
      this.$el.style.setProperty('--tag-color', `#${this.colors[color]}`);
    },

    setFontColor(color) {
      this.$el.style.setProperty('color', `#${this.fontColors[color]}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.tag {
  --tag-color: #eeeeee;

  display: inline-block;
  padding: 0.2rem 0.5rem;
  border-radius: 11px;
  background-color: var(--tag-color);
  color: #333333;
  text-align: center;
  font-weight: 400;
  font-size: 0.75rem;
}
</style>
