<template>
  <v-card :flat="flat" :class="{ 'v-inner-scroll': innerScroll }">
    <slot name="title">
      <v-card-title v-if="title">
        <div :class="titleClass" v-text="title" />
      </v-card-title>
    </slot>
    <v-card-text>
      <slot />
    </v-card-text>
    <v-card-actions v-if="actions">
      <v-spacer />
      <DialogActions ref="actions" :actions="actions" flat :handle="handle" />
    </v-card-actions>
  </v-card>
</template>

<script>
import { VCard, VCardTitle, VCardText, VCardActions, VSpacer } from 'vuetify/lib';
import DialogActions from './DialogActions.vue';

export default {
  components: {
    DialogActions,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VSpacer,
  },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: '',
    },
    flat: Boolean,
    innerScroll: Boolean,
    titleClass: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line
    actions: [Array, Object, Function],
    // eslint-disable-next-line
    handle: Function,
  },
  methods: {
    trigger(name) {
      this.$refs.actions && this.$refs.actions.trigger(name);
    },
  },
};
</script>
<style lang="scss">
.v-dialog:not(.v-dialog--fullscreen) {
  .v-card.v-inner-scroll {
    .v-card__text {
      max-height: 70vh;
    }
  }
}
.v-card.v-inner-scroll {
  .v-card__text {
    overflow: auto;
    background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)),
      linear-gradient(hsla(0, 0%, 100%, 0) 10px, white 70%) bottom,
      radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%),
      radial-gradient(at bottom, rgba(0, 0, 0, 0.2), transparent 70%) bottom;
    background-repeat: no-repeat;
    background-size: 100% 30px, 100% 30px, 100% 10px, 100% 10px;
    background-attachment: local, local, scroll, scroll;
  }
}
.theme--dark.v-inner-scroll {
  .v-card__text {
    background: linear-gradient(#1d1d1d 30%, rgba(255, 255, 255, 0)),
      linear-gradient(hsla(0, 0%, 100%, 0) 10px, #1d1d1d 70%) bottom,
      radial-gradient(at top, rgba(255, 255, 255, 0.2), transparent 70%),
      radial-gradient(at bottom, rgba(255, 253, 253, 0.2), transparent 70%) bottom;
    background-repeat: no-repeat;
    background-size: 100% 30px, 100% 30px, 100% 10px, 100% 10px;
    background-attachment: local, local, scroll, scroll;
  }
}
</style>
