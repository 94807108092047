import Vue from 'vue';
import { setupCalendar, Calendar } from 'v-calendar';
import 'v-calendar/lib/v-calendar.min.css';

setupCalendar({
  firstDayOfWeek: 2, // Monday
});

Vue.component('v-calendar', Calendar);

// TODO: Remove this plugin
