export const capitalize = value => {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const formatDateLocaleDateString = (value, locale = 'es-ES') => {
  const timestamp = Date.parse(value);
  if (isNaN(timestamp)) return;

  const date = new Date(value);
  const dateLocale = date.toLocaleDateString(locale);

  return dateLocale;
};

export const spacedPhoneNumber = value => {
  if (!value) return '';

  return value.replace(/(\d{3})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4');
};

export const maskPhone = value => {
  if (!value) return '';

  const matched = value.match(/(\d{0,3})(\d{0,2})(\d{0,2})(\d{0,2})/);

  if (!matched.length) return value;

  const [, a, b, c, d] = matched;

  return `${a} ${b} ${c} ${d}`;
};
