<template>
  <div>
    <v-snackbar
      v-model="isActive"
      :timeout="timeout || 5000"
      :color="getColor"
      class="application"
      v-bind="$options.propsData"
      :top="top"
      :left="left"
      :right="right"
      :bottom="bottom"
      @click="dismiss"
    >
      <dialog-child ref="dialog" v-bind="$options.propsData" />
    </v-snackbar>
  </div>
</template>

<script>
import { VSnackbar } from 'vuetify/lib';
import Colorable from '../mixins/colorable';

export default {
  components: {
    VSnackbar,
  },
  mixins: [Colorable],
  props: {
    timeout: Number,
    position: String,
  },
  data() {
    const position = this.position || this.$options.propsData.position || '';
    return {
      top: position.indexOf('top') !== -1,
      left: position.indexOf('left') !== -1,
      right: position.indexOf('right') !== -1,
      bottom: position.indexOf('bottom') !== -1,
    };
  },
  methods: {
    _destroy() {
      setTimeout(() => {
        this.$destroy();
      }, 500);
    },
  },
};
</script>
