<template>
  <v-list-group
    :key="name"
    no-action="no-action"
    :group="group"
    :prepend-icon="icon"
    :data-test="`menu-section-${name}`"
  >
    <v-list-item-content slot="activator">
      <v-list-item-title>{{ $t(title) }}</v-list-item-title>
    </v-list-item-content>

    <menu-item v-for="(item, index) in items" :key="index" :name="item.name" :icon="item.icon" :title="item.title" />
  </v-list-group>
</template>

<script>
import MenuItem from './MenuItem.vue';

export default {
  components: {
    MenuItem,
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    icon: {
      type: [String, null],
      default: null,
    },

    title: {
      type: String,
      required: true,
    },

    group: {
      type: String,
      required: true,
    },

    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
