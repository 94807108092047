import axios from 'axios';
import environment from '@/environment';
import { getDefaultPermissions } from '@/services/permissions';
import { getSettingServiceUrl, getHeader } from '../config/config';
import { getUserPermissions, getBrandImages } from './helpers';
import { detectBrowserLocale } from '../../engine/framework/modules/i18n';
import $device from 'mobile-device-detect';
import { localStorageService, LOCAL_STORAGE_KEYS } from '@/services/local-storage';
import { featureToggleHandler, FEATURE_NAMES } from '@/utils/featureToggle';


export default {
  state: {
    locale: detectBrowserLocale(),
    name: null,
    displayName: null,
    isDoclineEnvironment: null,
    supportEmail: null,
    hasBrandLogo: null,
    pattern: null,

    images: {
      favicon: null,
      loginBackground: null,
      logoPrimary: null,
      logoSecondary: null,
      logoToolbar: null,
    },

    styles: {
      themePrimary: null,
      themeSecondary: null,
      toolbarHeader: {
        backgroundColor: null,
        enableDarkColor: null,
      },
      menuLogoBackground: null,
      menuLogoClass: null,
    },
    features: null,

    intercom: {
      id: null,
      userMaps: {},
      hide: false,
    },

    helpScout: {
      id: null,
      enabled: false,
    },

    helpHero: {
      appId: null,
      enabled: false,
      showEditor: false,
      firstStepsTourId: '',
    },

    permissions: getDefaultPermissions(),

    paymentSettings: [],
  },

  mutations: {
    setLocale(state, locale) {
      state.locale = locale;
    },

    setConfigBranding(
      state,
      { isDoclineEnvironment, isPatient, hasBrandLogo, toolbarBackgroundColor, toolbarEnableDarkColor, supportEmail }
    ) {
      const isDoclineEnvAndIsPatient = isDoclineEnvironment && Boolean(isPatient);
      state.isDoclineEnvironment = isDoclineEnvironment;
      state.supportEmail = supportEmail;
      state.hasBrandLogo = !!hasBrandLogo;
      state.styles.toolbarHeader = {
        backgroundColor: isDoclineEnvAndIsPatient ? 'secondary' : toolbarBackgroundColor,
        enableDarkColor: toolbarEnableDarkColor,
      };
    },

    setMainConfig(state, payload) {
      ['locale', 'name', 'displayName', 'pattern'].forEach(key => {
        if (payload[key] && payload[key] !== '') {
          state[key] = payload[key];
        }
      });
    },

    setImages(state, payload) {
      ['logoPrimary', 'logoSecondary', 'favicon', 'loginBackground', 'logoToolbar'].forEach(key => {
        if (payload[key] && payload[key] !== '') {
          state.images[key] = payload[key];
        }
      });
    },

    setStyles(state, payload) {
      ['themePrimary', 'themeSecondary', 'menuLogoBackground', 'menuLogoClass'].forEach(key => {
        if (payload[key] && payload[key] !== '') {
          state.styles[key] = payload[key];
        }
      });
    },

    setIntercom(state, payload) {
      state.intercom = {
        id: payload.id || null,
        userMaps: payload.userMaps || {},
        hide: payload.hide || false,
      };
    },

    setHelpScout(state, payload) {
      state.helpScout = payload;
    },

    setHelpHero(state, payload) {
      state.helpHero = payload;
    },

    setPermissions(state, payload) {
      Object.keys(getDefaultPermissions()).forEach(key => {
        if (typeof payload[key] === 'boolean') {
          state.permissions[key] = payload[key];
        }
      });
    },

    setPaymentSettings(state, payload) {
      const FREEMIUM_PAYMENT_TYPE = '1';
      payload.isFreemium = payload.length !== 0 && payload['payment_type'] === FREEMIUM_PAYMENT_TYPE;
      state.paymentSettings = payload || [];
    },

    setFeatures(state, payload) {
      state.features = payload;
    },

    setFeatureFlags(state, payload) {
      state.features = payload;
      localStorageService.setItem(LOCAL_STORAGE_KEYS.FEATURES, state.features);
    },
  },

  actions: {
    loadConfigFromBrand({ commit }) {
      const { name, displayName, styles, intercom, helpScout, helpHero, features, pattern } = environment;

      commit('setMainConfig', { name, displayName, pattern });
      commit('setPermissions', getDefaultPermissions());
      commit('setImages', getBrandImages(pattern));
      commit('setStyles', styles);
      commit('setIntercom', intercom);
      commit('setHelpScout', helpScout);
      commit('setHelpHero', helpHero);
      commit('setFeatures', features);
    },

    loadConfigFromUser({ commit, state }) {
      const user = JSON.parse(window.localStorage.getItem('auth_ehealth'));
      const { forceDisplayName, displayName } = environment;
      commit('setMainConfig', {
        locale: user.lang || detectBrowserLocale(),
        displayName: forceDisplayName !== true ? user.nombreEmpresa : displayName,
      });
      try {
        commit('setPermissions', getDefaultPermissions());
        commit('setPermissions', getUserPermissions(user));
      } catch (e) {
        console.error(e);
        localStorage.removeItem('auth_ehealth');
        location.reload();
      }
      commit('setImages', { logoPrimary: user.logoEmpresa, logoSecondary: user.logoEmpresa });
      commit('setStyles', {
        themePrimary:
          user.company && user.company.themeColorPrimary
            ? user.company.themeColorPrimary
            : state.styles.themeColorPrimary,
        themeSecondary:
          user.company && user.company.themeColorSecondary
            ? user.company.themeColorSecondary
            : state.styles.themeColorSecondary,
        menuLogoBackground:
          user.color && user.color !== 'primary' ? `${user.color}_menu darken-1` : state.styles.menuLogoBackground,
      });

      commit('setConfigBranding', {
        isDoclineEnvironment: environment.isDoclineEnvironment,
        isPatient: user.isPatient,
        hasBrandLogo: user.logoEmpresa,
        toolbarBackgroundColor: environment.styles?.toolbarHeader?.backgroundColor,
        toolbarEnableDarkColor: environment.styles?.toolbarHeader?.enableDarkColor,
        supportEmail: environment.supportEmail
      });
    },

    getPaymentSettings({ commit }) {
      const user = JSON.parse(window.localStorage.getItem('auth_ehealth'));
      if (typeof user.isPatient === 'undefined' || !user.isPatient) {
        axios.get(getSettingServiceUrl, { headers: getHeader() }).then(response => {
          if (response.status === 200 && response.data) {
            commit('setPaymentSettings', response.data.paymentSettings);
            const features = response.data?.features;
            features && commit('setFeatureFlags', features);
          } else {
            commit('setPaymentSettings', false);
          }
        });
      }
    },
  },

  getters: {
    showDoclineOriginalBrand(state) {
      const isDoclineEnvironmentAndNotHasLogo = state.isDoclineEnvironment && !state.hasBrandLogo;
      const isCustomBrand = !state.isDoclineEnvironment;
      return isDoclineEnvironmentAndNotHasLogo && !isCustomBrand;
    },
    showPoweredByDocline(state) {
      const isDoclineEnvironmentAndHasLogo = state.isDoclineEnvironment && state.hasBrandLogo;
      return isDoclineEnvironmentAndHasLogo;
    },
    themeToolbarHeader(state) {
      return {
        backgroundColor: state.styles.toolbarHeader.backgroundColor,
        enableDarkColor: state.styles.toolbarHeader.enableDarkColor,
      };
    },
    hasHelpService(state) {
      return state.helpScout.enabled || !state.intercom.hide;
    },
    getFeatureRepHomologationFlag(state) {
      return (
        state.features?.rep_homologation || localStorageService.getItem(LOCAL_STORAGE_KEYS.FEATURES)?.rep_homologation
      );
    },
    getFeatureNewAccountPageFlag(state) {
      return state.features?.new_account_page || featureToggleHandler(FEATURE_NAMES.NEW_ACCOUNT_PAGE);
    },
    getAppStyles({ styles }) {
      return styles;
    },
    isAndroidOrIos() {
      const { isAndroid, isIOS } = $device;
      return isAndroid || isIOS;
    },
    getChatV2IntegrationFeatureFlag(state) {
      return (
        state.features?.chat_v2_integration ||
        localStorageService.getItem(LOCAL_STORAGE_KEYS.FEATURES)?.chat_v2_integration
      );
    },
    getMarketplaceFeatureFlag(state) {
      return state.features?.marketplace || localStorageService.getItem(LOCAL_STORAGE_KEYS.FEATURES)?.marketplace;
    },
    getPDFInformInsideHtmlContainerFeatureFlag(state) {
      return (
        state.features?.pdf_inform_inside_html_container ||
        localStorageService.getItem(LOCAL_STORAGE_KEYS.FEATURES)?.pdf_inform_inside_html_container
      );
    },
  },
};
