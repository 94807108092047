<template>
  <v-autocomplete
    v-model="value"
    :items="items"
    :placeholder="placeholder"
    :label="title"
    :search-input.sync="searchString"
    item-text="text"
    item-value="id"
    :loading="loading"
    outlined
    :dense="dense"
    :disabled="disabled"
    @change="sendSelected"
  />
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    resultData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      value: '',
      searchString: '',
      items: [],
    };
  },

  watch: {
    searchString(val) {
      if (val !== null && val.trim() !== '') {
        this.$emit('changeSearchVal', { searchString: val });
      }
    },

    resultData() {
      this.items = this.resultData;
    },
  },

  created() {
    this.items = this.resultData;
  },

  methods: {
    sendSelected() {
      this.$emit('seloption', this.value);
    },
  },
};
</script>
