import { RouteConfig } from 'vue-router';

export const accountNamePaths = {
  login: 'Login',
  loginIconnecta: 'LoginIconnecta',
  loginMediktor: 'LoginMediktor',
  signup: 'Signup',
  triage: 'Triage',
  faq: 'FAQ',
  resetPassword: 'ResetPassword',
  activateAccount: 'ActivarCuenta',
  activateProfessionalAsPatient: 'ActivateProfessionalAsPatient',
  linkPatientToProfessional: 'LinkPatientToProfessional',
  verifyUsers: 'Verifyusers',
  help: 'Ayuda',
  contact: 'Contacto',
  profile: 'Perfil',
  accountMain: 'AccountMainView',
  account: 'AccountView',
  accountPassword: 'AccountPasswordView',
  account2FA: 'Account2FAView',
};

export const accountRoutes: RouteConfig[] = [
  {
    path: '/login',
    meta: {
      public: true,
    },
    name: accountNamePaths.login,
    props: route => {
      return {
          accessToken: route.query.access_token,
          refreshToken: route.query.refresh_token,
          redirect: route.query.redirect
      };
    },
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "login" */
        `@/views/Login.vue`
      ),
  },
  {
    path: '/ico/:token/:email',
    meta: { public: true, requiresAuth: false },
    name: accountNamePaths.loginIconnecta,
    component: () =>
      import(
        /* webpackChunkName: "login-iconnecta" */
        `@/views/LoginBackDoor.vue`
      ),
  },
  {
    path: '/mdk/:token/:email',
    meta: { public: true, requiresAuth: false },
    name: accountNamePaths.loginMediktor,
    component: () =>
      import(
        /* webpackChunkName: "login-mediktor" */
        `@/views/LoginBackDoor.vue`
      ),
  },
  {
    path: '/signup',
    meta: {
      public: true,
    },
    name: accountNamePaths.signup,
    component: () =>
      import(
        /* webpackChunkName: "login" */
        `@/views/Signup.vue`
      ),
  },
  {
    path: '/triage',
    meta: {
      public: true,
    },
    name: accountNamePaths.triage,
    component: () =>
      import(
        /* webpackChunkName: "triage" */
        `@/views/Triage.vue`
      ),
  },
  {
    path: '/reset/:token/:email',
    meta: { public: true, requiresAuth: false },
    name: accountNamePaths.resetPassword,
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */
        `@/views/ResetPassword.vue`
      ),
  },
  {
    path: '/activar/:email/:token',
    meta: { public: true, requiresAuth: false },
    name: accountNamePaths.activateAccount,
    component: () =>
      import(
        /* webpackChunkName: "activar-cuenta" */
        `@/views/ActivarCuenta.vue`
      ),
  },
  {
    path: '/activateprofaspatient/:email/:token',
    meta: { public: true, requiresAuth: false },
    name: accountNamePaths.activateProfessionalAsPatient,
    component: () =>
      import(
        /* webpackChunkName: "activar-cuenta" */
        `@/views/ActivateProfessionalAsPatient.vue`
      ),
  },
  {
    path: '/linkPacienteProfesional/:token',
    meta: { public: true, requiresAuth: false },
    name: accountNamePaths.linkPatientToProfessional,
    component: () =>
      import(
        /* webpackChunkName: "link-patient-to-professional" */
        `@/views/LinkPacienteProfesional.vue`
      ),
  },
  {
    path: '/verifyusers',
    meta: { breadcrumb: false, requiresAuth: true, forbiddenPatient: true },
    name: accountNamePaths.verifyUsers,
    component: () =>
      import(
        /* webpackChunkName: "verifyusers" */
        `@/views/VerifyUsers.vue`
      ),
  },
  {
    path: '/faq',
    meta: {
      public: true,
    },
    name: accountNamePaths.faq,
    component: () =>
      import(
        /* webpackChunkName: "faq" */
        `@/views/FAQ.vue`
      ),
  },
  {
    path: '/ayuda',
    meta: { breadcrumb: true, requiresAuth: true },
    name: accountNamePaths.help,
    component: () =>
      import(
        /* webpackChunkName: "ayuda" */
        `@/views/Ayuda.vue`
      ),
  },
  {
    path: '/contacto',
    meta: { breadcrumb: true, requiresAuth: true },
    name: accountNamePaths.contact,
    component: () =>
      import(
        /* webpackChunkName: "contacto" */
        `@/views/Contacto.vue`
      ),
  },
  {
    path: '/perfil',
    meta: { breadcrumb: true, requiresAuth: true },
    name: accountNamePaths.profile,
    component: () =>
      import(
        /* webpackChunkName: "perfil" */
        `@/views/Perfil.vue`
      ),
  },
  {
    path: '/account',
    meta: { breadcrumb: true, requiresAuth: true },
    name: accountNamePaths.accountMain,
    component: () =>
      import(
        /* webpackChunkName: "login" */
        `@/views/account/AccountMainView.vue`
      ),
    redirect: { name: accountNamePaths.account },
    children: [
      {
        path: '',
        meta: { breadcrumb: true, requiresAuth: true },
        name: accountNamePaths.account,
        component: () =>
          import(
            /* webpackChunkName: "login" */
            `@/views/account/AccountView.vue`
          ),
      },
      {
        path: 'password',
        meta: { breadcrumb: true, requiresAuth: true },
        name: accountNamePaths.accountPassword,
        component: () =>
          import(
            /* webpackChunkName: "login" */
            `@/views/account/AccountPasswordView.vue`
          ),
      },
      {
        path: 'two-factor-authentication',
        meta: { breadcrumb: true, requiresAuth: true },
        name: accountNamePaths.account2FA,
        component: () =>
          import(
            /* webpackChunkName: "login" */
            `@/views/account/Account2FAView.vue`
          ),
      },
    ],
  },
];
