<template>
  <v-dialog v-model="dialog" max-width="400" persistent>
    <v-card>
      <v-card-title class="title red lighten-1 white--text">{{ $t('session.title') }}</v-card-title>

      <v-card-text class="pa-6">
        <p class="body-1 text-center">
          <v-icon color="red">mdi-clock-time-eight-outline</v-icon>
          {{ $t('session.subtitle') }}
        </p>
        <p class="text-h2 text-center pb-3">{{ second }} {{ $tc('session.second', second === 1 ? 1 : 2) }}</p>
        <p class="body-2">{{ $t('session.advice') }}.</p>
      </v-card-text>

      <v-card-actions class="pt-0 pb-6 px-6">
        <v-spacer />

        <v-btn color="red white--text mr-2" @click="handleOnClick('continue')">
          {{ $t('session.continue') }}
        </v-btn>

        <v-btn color="red" outlined @click="handleOnClick('close')">
          {{ $t('session.close_session') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const TWENTY_SECONDS_IN_MS = 20000;
const A_SECOND_IN_MS = 1000;

export default {
  name: 'ModalNotifyCloseSession',
  props: {
    logout: {
      type: Function,
      required: true,
    },
    cancelLogout: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      dialog: true,
      time: TWENTY_SECONDS_IN_MS,
      timerId: null,
    };
  },
  computed: {
    second() {
      return this.time / A_SECOND_IN_MS;
    },
  },
  created() {
    this.timerId = setInterval(() => {
      this.time -= A_SECOND_IN_MS;
      if (this.time < 1) {
        clearInterval(this.timerId);
        this.dialog = false;
        this.logout();
      }
    }, A_SECOND_IN_MS);
  },
  beforeDestroy() {
    clearInterval(this.timerId);
  },
  methods: {
    /**
     * @param {'continue' | 'close' } action
     */
    handleOnClick(action) {
      action === 'continue' ? this.cancelLogout() : this.logout();
      this.dialog = false;
    },
  },
};
</script>
