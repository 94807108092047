// https://github.com/antonreshetov/vue-unicons
import Vue from 'vue';
import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd';

import {
  uniMapMarker,
  uniPhone,
  uniInfoCircle,
  uniMultiply,
  uniCopyAlt,
  uniCheck,
  uniUserSquare,
  uniBook,
  uniGraduationCap,
  uniGlobe,
  uniMedkit,
  uniAward,
  uniBandAid,
  uniAngleRightB,
  uniAngleLeftB,
  uniCommentAltMessage,
  uniClinicMedical,
  uniCommentExclamation,
} from 'vue-unicons/dist/icons';

Unicon.add([
  uniMapMarker,
  uniPhone,
  uniInfoCircle,
  uniMultiply,
  uniCopyAlt,
  uniCheck,
  uniUserSquare,
  uniBook,
  uniGraduationCap,
  uniGlobe,
  uniMedkit,
  uniAward,
  uniBandAid,
  uniAngleRightB,
  uniAngleLeftB,
  uniCommentAltMessage,
  uniClinicMedical,
  uniCommentExclamation,
]);

Vue.use(Unicon);
