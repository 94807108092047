<template>
  <v-list-item :key="name" rel="noopener" v-bind="redirectProps" :data-test="`menu-section-${name}`">
    <v-list-item-action v-if="icon">
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-action>

    <v-list-item-content>
      <v-list-item-title>{{ $t(title) }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },

    href: {
      type: String,
      default: null,
    },

    icon: {
      type: [String, null],
      default: null,
    },

    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    redirectProps() {
      const props = {};

      if (this.href) {
        props.href = this.href;
      }

      if (this.name && !this.href) {
        props.to = { name: this.name };
      }

      return props;
    }
  }
};
</script>
