const cleanFilename = filename => filename.slice(filename.lastIndexOf('/') + 1, filename.lastIndexOf('.'));

export const moduleIterator = (requireModule, handler = null) => {
  requireModule.keys().forEach(filename => {
    const dirLevel = filename.split('/').length - 1;
    let name = cleanFilename(filename);

    if (dirLevel === 1 || (dirLevel === 2 && name === 'index')) {
      if (dirLevel === 2) {
        name = filename.substring(filename.indexOf('/') + 1, filename.lastIndexOf('/'));
      }

      const mod = requireModule(filename);

      if (handler) {
        handler(name, mod.default || mod);
      }
    }
  });
};

export const moduleReducer = (requireModule, handler, initialValue = {}) =>
  requireModule
    .keys()
    .reduce(
      (carry, filename) => handler(carry, cleanFilename(filename), requireModule(filename).default),
      initialValue
    );
