<template>
  <portal>
    <modal-background :show="showBackground">
      <modal-box v-click-outside="hide" :title="title" :show="showBox">
        <v-app class="v-app-modal">
          <slot />
        </v-app>
      </modal-box>
    </modal-background>
  </portal>
</template>

<script>
import ModalBackground from './ModalBackground.vue';
import ModalBox from './ModalBox.vue';

export default {
  components: {
    ModalBackground,
    ModalBox,
  },

  model: {
    prop: 'show',
    event: 'hide',
  },

  props: {
    title: {
      type: [String, null],
      default: null,
    },

    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showBackground: false,
      showBox: false,
    };
  },

  watch: {
    show(value) {
      this[value ? 'onShow' : 'onHide']();
    },
  },

  methods: {
    onShow() {
      this.bodyScrollEnabled(false);
      this.showBackground = true;

      setTimeout(() => {
        this.showBox = true;
      }, 300);
    },

    onHide() {
      this.showBox = false;

      setTimeout(() => {
        this.showBackground = false;
        this.bodyScrollEnabled(true);
      }, 500);
    },

    bodyScrollEnabled(value) {
      document.body.style.overflow = value ? 'visible' : 'hidden';
    },

    hide() {
      if (this.show) {
        this.$emit('hide');
      }
    },
  },
};
</script>

<style lang="scss">
.v-app-modal {
  &.theme--light {
    background-color: white;
  }
  .v-application--wrap {
    /* min-height: auto; */
  }
}
</style>
