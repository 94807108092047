import environment from '@/environment';
import i18n from '../../engine/framework/modules/i18n';

export default documentTypes => {
  const selectorDocumentTypes = environment?.forms?.documentType ? environment?.forms?.documentType : null;
  if (selectorDocumentTypes) {
    selectorDocumentTypes.forEach(type => {
      type.name = i18n.t(type.trans);
    });

    return selectorDocumentTypes;
  }

  return documentTypes;
};
